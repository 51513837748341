<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
  <div *ngIf="expiredDocuments.length > 0">
    <p class="text-center" style="text-decoration: underline;font-size: 20pt;">Notifications</p>
    <div class="expired">
      <div class="select">
        Show
        <select [(ngModel)]="expiredPageSize" (ngModelChange)="expiredPageSizeChanged()">
          <option [value]="10">10</option>
          <option [value]="25">25</option>
          <option [value]="50">50</option>
        </select> notifications
      </div>
      <table class="table expiredDocuments">
        <tbody *ngIf="expiredDocuments.length > 0">
          <tr *ngFor="let document of expiredDocuments">
            <ng-container *ngIf="document && document.documentsInformation && document.ownerInformation">
              <td>
                {{ document.ownerInformation.entityName }} has expired document "{{ document.documentsInformation.documentDescription }}".
              </td>
              <td class="right">
                <button class="btn btn-success" (click)="replaceDocument(document)">Review</button>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
      <pagination [maxSize]="expiredMaxSize" [totalItems]="expiredTotalItems" [itemsPerPage]="expiredPageSize" [(ngModel)]="expiredCurrentPage" (pageChanged)="expiredPageChanged($event)"></pagination>
    </div>
  </div>

  <!-- <div class="probable">
    <div class="select">
      Show
      <select [(ngModel)]="probablePageSize" (ngModelChange)="probablePageSizeChanged()">
        <option [value]="10">10</option>
        <option [value]="25">25</option>
        <option [value]="50">50</option>
      </select> entities
    </div>
    <table class="table probableMatches">
      <tbody *ngIf="probableMatches.length > 0">
        <tr *ngFor="let match of probableMatches">
          <ng-container *ngIf="match && match.entityName">
            <td>
              <i *ngIf="match.type == 'MEDIA'" class="fa fa-newspaper-o"></i>
              <i *ngIf="match.type == 'PEP_OR_SANCTION' || match?.matchType == null" class="fa fa-user"></i>
              <i *ngIf="match.type == 'SEARCH_ENGINE'" class="fa fa-search"></i>
              Probable match {{ match.entityName }}</td>
            <td class="right">
              <button class="btn btn-success" (click)="reviewEntity(match.entityId)">Review</button>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>
    <pagination [maxSize]="probableMaxSize" [totalItems]="probableTotalItems" [itemsPerPage]="probablePageSize" [(ngModel)]="probableCurrentPage" (pageChanged)="probablePageChanged($event)"></pagination>
  </div> -->

  <p class="text-center" style="text-decoration: underline;font-size: 20pt;">Statistics</p>
  <button class="btn btn-primary" (click)="toggleFilters()">Filters&nbsp;<i class="fa fa-filter" aria-hidden="true"></i></button>
  <div *ngIf="displayFilters">
    <div style="height: 20px;"></div>
    Select the tag(s) that you would like to see:
    <div style="height: 10px;"></div>
    <div class="row">
      <div class="col-md-6">
        <ng-select
        class="custom"
        [items]="userTags"
        [multiple]="true"
        bindLabel="name"
        [closeOnSelect]="false"
        bindValue="id"
        [clearable]="false"
        (change)="tagsChanged($event)"
        [(ngModel)]="includedTags">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
        </ng-template>
        </ng-select>
      </div>
      <div class="col-md-6" style="margin-left: -20px;margin-top: 5px;">
        <button (click)="selectAllTags()" style="background-color:#355B89;border-color: #355B89;padding: 3px;font-size: 12px;" class="btn btn-success">Select all</button>&nbsp;&nbsp;
        <button (click)="deselectAllTags()" style="background-color:#355B89;border-color: #355B89;padding: 3px;font-size: 12px;" class="btn btn-success">Clear all</button>
      </div>
    </div>
  </div>
  <div style="height: 30px"></div>
  <div style="height: 30px"></div>
  <div class="row">
    <div class="col-md-6"><div [chart]="completenessPie"></div></div>
    <div class="col-md-6"><div [chart]="probableMatchesPie"></div></div>
  </div>
  <div style="height: 20px"></div>
  <div class="row">
    <div class="col-md-6"><div [chart]="assessmentResultPie"></div></div>
    <div class="col-md-6"><div [chart]="entityTypePie"></div></div>
  </div>
<!--   <div style="height: 40px"></div>
  <div class="row">
    <div class="col-md-6"><div [chart]="completenessChart"></div></div>
    <div class="col-md-6"><div [chart]="probableMatchesChart"></div></div>
  </div>
  <div style="height: 20px"></div>
  <div class="row">
    <div class="col-md-6"><div [chart]="assessmentResultChart"></div></div>
    <div class="col-md-6"><div [chart]="entityTypeChart"></div></div>
  </div> -->
  <!-- <div class="row"> -->
    <!-- <div class="col-md-4"><div [chart]="entityTypeChart"></div></div> -->
    <!-- <div class="col-md-4"><div [chart]="entityStatusChart"></div></div> -->
  <!-- </div> -->
  <div style="height: 80px"></div>



</div>



