/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./risk-level.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./risk-level.component";
import * as i3 from "../../../../_services/webData.service";
var styles_RiskLevelComponent = [i0.styles];
var RenderType_RiskLevelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RiskLevelComponent, data: {} });
export { RenderType_RiskLevelComponent as RenderType_RiskLevelComponent };
export function View_RiskLevelComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["style", "height: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["RISK LEVEL:"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRiskLevel("low") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Low Risk\u00A0"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-warning"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRiskLevel("medium") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0\u00A0Medium Risk\u00A0\u00A0\u00A0"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-danger"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRiskLevel("high") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["High Risk"]))], null, null); }
export function View_RiskLevelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "risk-level", [], null, null, null, View_RiskLevelComponent_0, RenderType_RiskLevelComponent)), i1.ɵdid(1, 114688, null, 0, i2.RiskLevelComponent, [i3.WebDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RiskLevelComponentNgFactory = i1.ɵccf("risk-level", i2.RiskLevelComponent, View_RiskLevelComponent_Host_0, {}, { riskAssessmentEvent: "riskAssessmentEvent" }, []);
export { RiskLevelComponentNgFactory as RiskLevelComponentNgFactory };
