import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, TemplateRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../_services';
import { config } from '../../../assets/configuration';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-select';
import 'datatables.net-buttons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { EditUser } from '../../_models';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

/**
 * Implements the enterprise page
*/
@Component({
    moduleId: module.id,
    templateUrl: 'nonAdmin.component.html',
    styleUrls: [ './nonAdmin.component.css' ]
})

export class NonAdminComponent implements OnInit, OnDestroy {

  model: any = {};
  editUser: any = EditUser;
  loading = false;
  loadingCheck = false;
  displayBar = false;
  userRole = 'STANDARD_USER';
  loadingImg = 'data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==';
  progress = 22;
  companyName: string = config.companyName;
  userName = '';
  response: any = {};
  dataTable: any;
  allUsers: any[];
  allUserstmp: any = [];
  usersToCheckNo = 0;
  mainColor: string = config.mainColor;
  exportModal: BsModalRef;
  editModal: BsModalRef;
  evaluateModal: BsModalRef;
  screenerResultsModal: BsModalRef;
  evaluateDocumentsModal: BsModalRef;
  exportOption = '';
  currency = '';
  currencyFilled = true;
  taxCountryFilled = true;
  adminsList: any = {};
  value = '';
  allSelected = false;
  isOpen = false;
  showImage = true;

  entityResponse: any = {};
  allEntities: any;
  entities: any = [];

  identityDocuments: any = [];

  currentUser: any;
  formattedMatches: any = [];
  matchesResponse: any = {};
  singleUserResponse: any = {};
  userData: any;
  currentMatch: any;
  matches: any = {};
  limit = 50;
  offset = 0;
  matchCount = 1;
  matchNo = 0;

  documentsResponse: any = {};
  currentEvaluateUser: any;
  currentEvaluateDocument: any;
  evaluateUserData: any = {};
  safeImage: SafeResourceUrl;
  currentDocument = 0;
  documentOffset = 0;
  documentLimit = 6;

  kycStatus: any = [];
  countriesAll: any = [];
  sourcesOfFunds: any = [];
  ethAmounts: any = [];
  kycStatusForProgress: any = [];
  progressData: any = [];
  statuses: any = ['Not Started', 'Low Risk', 'Medium Risk', 'High Risk'];

  userResponse: any = {};
  userResponseStandard: any = {};
  tempUsers: any = [];
  users: any = [];
  standardUsers: any = [];
  dtOptions = {};

  note = '';

  errorMessage = '';

  @ViewChild('editTemplate', {static: false}) editTemplate: TemplateRef<any>;
  @HostListener('document:keyup.esc', ['$event'])
  onKeyupHandler(event: KeyboardEvent) {
    const table: any = $('.table');
    table.DataTable().ajax.reload(null, false);
  }

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private dataService: DataService,
    private chRef: ChangeDetectorRef,
    private modalService: BsModalService,
    private domSanitizer: DomSanitizer,
    private kybService: KYBService
  ) {}

  ngOnInit() {
    // this.ngOnDestroy();
    this.userName = localStorage.getItem('username');
    this.getAllEntities();
  }

  getAllEntities() {
    this.kybService.getAllEntities({}).subscribe(
      data => {
        this.entityResponse = data;
        this.allEntities = this.entityResponse.data;

        for (let i = 0; i < this.entityResponse.data.length; i++) {
          if (this.entityResponse.data[i].owner.identifier === localStorage.getItem('identifier')) {
            this.entities.push(this.entityResponse.data[i]);
          }
        }

        const table: any = $('table');

        if ( $.fn.dataTable.isDataTable( '.table' ) ) {
          this.dataTable = table.DataTable();
        } else {
          this.dataTable = table.DataTable(
            {
                'oLanguage': { 'sEmptyTable': 'No entities found.'},
                'orderCellsTop': true,
                'columnDefs': [
                  // { 'orderable': false, 'targets': 3 },
                ],
                'order': [],
                'paging': true,
                'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
                'dom': `<'top'flp<'clear'>>rt<'bottom'ip<'clear'>>`,
                'select': {style: 'os'}
              }
          );

          if (this.entities.length > 0) {
            const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
            empty[0].setAttribute('style', 'display: none;');
            const info = (document.getElementById('DataTables_Table_0_info')) as HTMLElement;
            info.setAttribute('style', 'display: none;');
          }
            // Enable selection of rows
            $('tbody').on( 'click', 'tr', function () {
            $(this).toggleClass('selected');
          });

        }
      },
      error => {
      }
    );

  }


  closeModalRefreshTable() {
    this.screenerResultsModal.hide();
    const table: any = $('.table');
    table.DataTable().ajax.reload(null, false);
  }

  closeDocumentModalRefreshTable() {
    this.evaluateDocumentsModal.hide();
    const table: any = $('.table');
    table.DataTable().ajax.reload(null, false);
  }

  getAllUserIdentityDocuments() {
    for (let i = 0; i < this.users.length; i++) {
      this.identityDocuments.push({ 'username': this.users[i].email, 'passportBack': this.users[i].documents.fileMapping.passportBackFileId, 'passportFront': this.users[i].documents.fileMapping.passportFrontFileId, 'proofOfResidence': this.users[i].documents.fileMapping.proofOfResidenceFileId, 'identityDocumentStatus': this.users[i].systemMetadata.identityDocumentsStatus, 'proofOfResidenceStatus': this.users[i].systemMetadata.proofOfResidenceStatus });
    }
  }

  /**
   * Function used to retreive details for a specific user
  */
  getAccount(username: any) {
    this.callsService.getUserDetails(username).subscribe(
      data => {
        this.model = data;
        this.editUser.firstName = this.model.data.formData.firstName;
        this.editUser.lastName = this.model.data.formData.lastName;
        this.editUser.email = this.model.data.email;
        this.editUser.dateOfBirth = this.model.data.formData.dateOfBirth;
        this.editUser.nationality = this.model.data.formData.nationality;
        this.editUser.address = this.model.data.formData.address;
        this.editUser.taxCountry = this.model.data.formData.taxCountry;
        this.editUser.amount = this.model.data.formData.amount;
        this.editUser.currencyType = this.model.data.formData.currencyType;
        this.editUser.walletAddress = this.model.data.formData.walletAddress;
        this.editUser.sourceOfFunds = this.model.data.formData.sourceOfFunds;
        // this.editUser.password = this.model.password;
        // this.editUser.userName = this.model.userName;
      },
      error => {
      }
    );
  }

  /**
   * Function used to detect selected user name
  */
  getUserInfo() {
    const selected = (document.getElementsByClassName('selected') as HTMLCollectionOf<Element>);

    if (selected.length > 0 && selected.length < 2) {
      const nodeList = Array.from(selected[0].childNodes);
      let username: any;

      if (nodeList[2]['innerHTML'].indexOf('title') !== -1) {
        username = nodeList[2]['innerHTML'].split('\'')[1];
      } else {
        username = nodeList[2]['innerHTML'];
      }

      this.getAccount(username);
      this.displayEdit(this.editTemplate);
    } else {
      this.alertService.showError('Please select exactly one user to edit.');
    }
  }

  /**
   * Function used for setting a KYC status for a user
   * @param {any} userName - username to set KYC status for
   * @param {any} kycStatus - the value of KYC status (1 - Low Risk, 2 - Medium Risk, 3 - High Risk)
  */
  setKYCStatus(userName: any, kycStatus: any) {
    this.callsService.setKYCStatus(userName, kycStatus).subscribe(
      data => {
        if (data) {
          this.loadingCheck = false;
          this.displayBar = false;
          this.callsService.addEvent(userName, localStorage.getItem('username'), '[KYC STATUS] ' + localStorage.getItem('username') + ` changed KYC status of '' + userName + '' to ` + kycStatus).subscribe(
            eventData => {
            },
            error => {}
          );

          const table: any = $('.table');
          table.DataTable().ajax.reload(null, false );
        }
      },
      error => {
          this.alertService.showError('Something went wrong.');
          this.loadingCheck = false;
          this.displayBar = false;
      }
    );
  }

  setEntityStatus(id: any, entityStatus: any) {
  }

  setDossierStatus(id: any, dossierStatus: any) {
  }

  /**
   * Function for displaying the exporting modal window
  */
  displayExport(template: TemplateRef<any>) {
    this.exportModal = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-md' })
    );
  }

  /**
   * Function for displaying the editing modal window
  */
  displayEdit(template: TemplateRef<any>) {
    this.editModal = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-md' })
    );
  }

  /**
   * Function for displaying the screener results modal
  */
 displayScreenerModal(template: TemplateRef<any>) {
  this.limit = 50;
  this.offset = 0;

  this.callsService.getProbableMatches(this.limit, this.offset).subscribe(
    data => {
      this.matchCount = 1;
      this.matchesResponse = data;
      this.matches = this.matchesResponse.data;
      this.formattedMatches = [];

      if (this.matches.length === 0) {
        window.alert('There are no new results to review.');
      } else {
        for (let i = 0; i < this.matches.length; i++) {
          this.formattedMatches.push({ 'email': this.matches[i].email, 'match': this.matches[i].match });
        }
        this.currentMatch = 0;

        this.callsService.getUserDetails(this.formattedMatches[this.currentMatch].email).subscribe(
          userData => {
            this.singleUserResponse = userData;
            this.userData = this.singleUserResponse.data;
            this.currentUser = this.userData.email;
            this.matchNo = this.userData.analysisResult.probableMatches.length;

            this.screenerResultsModal = this.modalService.show(
              template,
              Object.assign({}, { class: 'modal-lg' })
            );
          },
          error => {}
        );
      }
    },
    error => {}
  );
}

displayEvaluateDocumentsModal(template: TemplateRef<any>) {
  this.callsService.getUnevaluatedDocuments(8, 0).subscribe(
    data => {
      this.documentsResponse = data;
      // currentUser = 0;
      if (this.documentsResponse.data.length === 0) {
        window.alert('There are no documents to review.');
      } else {
        this.currentDocument = 0;
        this.safeImage = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
        + this.documentsResponse.data[this.currentDocument].document);
        this.callsService.getUserDetails(this.documentsResponse.data[this.currentDocument].email).subscribe(
          evaluateUserData => {
            this.evaluateUserData = evaluateUserData;
          }
        );
        this.evaluateDocumentsModal = this.modalService.show(template, Object.assign({}, { class: 'modal-lg'}));
      }
    },
    error => {}
  );
}

// previousDocument() {
//   this.currentDocument--;
// tslint:disable-next-line:max-line-length
//   if(this.documentsResponse.data[this.currentDocument].document != null && this.documentsResponse.data[this.currentDocument].document != ''){
// tslint:disable-next-line:max-line-length
//     this.safeImage = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.documentsResponse.data[this.currentDocument].document);
//     this.callsService.getUserDetails(this.documentsResponse.data[this.currentDocument].email).subscribe(
//       data => {
//         this.evaluateUserData = data;
//       }
//     );
//   }
//   else {
//     this.previousDocument();
//   }
// }

nextDocument() {
  this.currentDocument++;
  if (this.currentDocument !== this.documentsResponse.data.length) {
    if (this.documentsResponse.data[this.currentDocument].document !== null &&
      this.documentsResponse.data[this.currentDocument].document !== '') {
      this.safeImage = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.documentsResponse.data[this.currentDocument].document);
      this.callsService.getUserDetails(this.documentsResponse.data[this.currentDocument].email).subscribe(
        data => {
          this.evaluateUserData = data;
        }
      );
    } else {
      this.nextDocument();
    }
  } else {
    window.alert('No more documents to review');
    this.closeDocumentModalRefreshTable();
  }
}

rejectDocument() {
  if (this.documentsResponse.data[this.currentDocument].type === 'Identity Document Front' ||
  this.documentsResponse.data[this.currentDocument].type === 'Identity Document Back') {
    this.callsService.setIdentityDocumentStatus(this.documentsResponse.data[this.currentDocument].email, 'red').subscribe(
      data => {
      this.callsService.addEvent(this.documentsResponse.data[this.currentDocument].email,
        localStorage.getItem('username'), '[IDENTITY DOCUMENT STATUS] ' +
        localStorage.getItem('username') + ' changed the status of the identity document to REJECTED').subscribe();
      this.showImage = false;
      this.nextDocument();
      this.showImage = true;
    },
    error => {
    }
    );
  } else {
    this.callsService.setProofOfResidenceStatus(this.documentsResponse.data[this.currentDocument].email, 'red').subscribe(
      data => {
        this.callsService.addEvent(this.documentsResponse.data[this.currentDocument].email, localStorage.getItem('username'), '[PROOF OF RESIDENCE STATUS] ' + localStorage.getItem('username') + ' changed the status of the proof of residence document to REJECTED').subscribe();
        this.showImage = false;
        this.nextDocument();
        this.showImage = true;
      },
      error => {}
    );
  }
}

approveDocument(imageId: any, description: any) {
  if (this.documentsResponse.data[this.currentDocument].type === 'Identity Document Front'
  || this.documentsResponse.data[this.currentDocument].type === 'Identity Document Back') {
    this.callsService.setIdentityDocumentStatus(this.documentsResponse.data[this.currentDocument].email, 'green').subscribe(
      data => {
      this.callsService.addEvent(this.documentsResponse.data[this.currentDocument].email,
        localStorage.getItem('username'), '[IDENTITY DOCUMENT STATUS] ' +
        localStorage.getItem('username') + ' changed the status of the identity document to APPROVED').subscribe();
      this.nextDocument();
    },
    error => {}
    );
  } else {
    this.callsService.setProofOfResidenceStatus(this.documentsResponse.data[this.currentDocument].email, 'green').subscribe(
      data => {
        this.callsService.addEvent(this.documentsResponse.data[this.currentDocument].email,
          localStorage.getItem('username'), '[PROOF OF RESIDENCE STATUS] ' +
          localStorage.getItem('username') + ' changed the status of the proof of residence document to APPROVED').subscribe();
        this.nextDocument();
      },
      error => {}
    );
  }
}

furtherDocument(imageId: any, description: any) {
  if (this.documentsResponse.data[this.currentDocument].type === 'Identity Document Front' ||
  this.documentsResponse.data[this.currentDocument].type === 'Identity Document Back') {
    this.callsService.setIdentityDocumentStatus(this.documentsResponse.data[this.currentDocument].email, 'yellow').subscribe(
      data => {
      this.callsService.addEvent(this.documentsResponse.data[this.currentDocument].email,
        localStorage.getItem('username'), '[IDENTITY DOCUMENT STATUS] ' +
        localStorage.getItem('username') + ' changed the status of the identity document to FURTHER RESEARCH').subscribe();
      this.nextDocument();
    },
    error => {}
    );
  } else {
    this.callsService.setProofOfResidenceStatus(this.documentsResponse.data[this.currentDocument].email, 'yellow').subscribe(
      data => {
        this.callsService.addEvent(this.documentsResponse.data[this.currentDocument].email, localStorage.getItem('username'), '[PROOF OF RESIDENCE STATUS] ' + localStorage.getItem('username') + ' changed the status of the proof of residence document to FURTHER RESEARCH').subscribe();
        this.nextDocument();
      },
      error => {}
    );
  }
}

/**
 * Function for triggering the download of correct export file
 * @param {any} exportOption - the option that the user selected
*/
export(exportOption: any) {
  switch (exportOption) {
    case 'all': { this.exportAll(); break; }
    case 'allUnchecked': { this.exportUnchecked(); break; }
    case 'notStarted': { this.exportNotStarted(); break; }
    case 'whitelist': { this.exportWhitelist(); break; }
    case 'onHold': { this.exportOnHold(); break; }
    case 'blacklist': { this.exportBlacklist(); break; }
  }
}

rowClickHandler(data) {
  this.router.navigate(['detail/' + data['email']]);
}

/**
 * Function for filtering out a user (admin in our case) so that they do not show in the table
 * @param {any} userName - user that will be filtered out
*/
removeAdmin(userName: string, userArray: any) {
  return userArray.filter((emp) => {
    if (emp.userName === userName) {
      return false;
    }
    return true;
  });
}

/**
 * Function for getting the information of all users - without files. Contains configuration of dataTables package.
*/
getAllUser() {
  // $(document).ready(function()
 }

  /**
   * Function for exporting users that are unchecked
  */
 exportUnchecked() {
    let csvData = 'ID,First Name,Last Name,Username,Email,Wallet Address,Amount,Currency\n';
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].status.mrz === null) {
        csvData += (this.users[i].id + ',' + this.users[i].firstName + ',' +
        this.users[i].lastName + ',' + this.users[i].userName + ',' + this.users[i].email
        + ',' + this.users[i].details.walletAddress + ',' + this.users[i].details.amount
        + ',' + this.users[i].details.currencyType + '\n');
      }
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'notchecked.csv';
    hiddenElement.click();
  }

  /**
   * Function for exporting users that are on the whitelist (have a risk of Low)
  */
 exportWhitelist() {
    let csvData = 'ID,First Name,Last Name,Username,Email,Wallet Address,Amount,Currency\n';
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].status.kycStatus === '1') {
        csvData += (this.users[i].id + ',' + this.users[i].firstName + ',' + this.users[i].lastName + ',' +
        this.users[i].userName + ',' + this.users[i].email + ',' + this.users[i].details.walletAddress + ','
        + this.users[i].details.amount + ',' + this.users[i].details.currencyType + '\n');
      }
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'whitelist.csv';
    hiddenElement.click();
  }

  /**
   * Function for exporting users that are on the blacklist (have a risk of High)
  */
 exportBlacklist() {
    let csvData = 'ID,First Name,Last Name,Username,Email,Wallet Address,Amount,Currency\n';
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].status.kycStatus === '3') {
        csvData += (this.users[i].id + ',' + this.users[i].firstName + ',' + this.users[i].lastName + ','
        + this.users[i].userName + ',' + this.users[i].email + ',' + this.users[i].details.walletAddress +
        ',' + this.users[i].details.amount + ',' + this.users[i].details.currencyType + '\n');
      }
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'blacklist.csv';
    hiddenElement.click();
  }

  /**
   * Function for exporting users that are on hold (have a risk of Medium)
  */
 exportOnHold() {
    let csvData = 'ID,First Name,Last Name,Username,Email,Wallet Address,Amount,Currency\n';
    for (let i = 0; i < this.users.length; i++) {
      if (this.users[i].status.kycStatus === '2') {
        csvData += (this.users[i].id + ',' + this.users[i].firstName + ',' + this.users[i].lastName +
        ',' + this.users[i].userName + ',' + this.users[i].email + ',' + this.users[i].details.walletAddress +
        ',' + this.users[i].details.amount + ',' + this.users[i].details.currencyType + '\n');
      }
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'onhold.csv';
    hiddenElement.click();
  }

  /**
   * Function for exporting users that are not started (have a status of Not Started)
  */
 exportNotStarted() {
  let csvData = 'ID,First Name,Last Name,Username,Email,Wallet Address,Amount,Currency\n';
  for (let i = 0; i < this.users.length; i++) {
    if (this.users[i].status.kycStatus === '0') {
      csvData += (this.users[i].id + ',' + this.users[i].firstName + ',' + this.users[i].lastName + ','
      + this.users[i].userName + ',' + this.users[i].email + ',' + this.users[i].details.walletAddress +
      ',' + this.users[i].details.amount + ',' + this.users[i].details.currencyType + '\n');
    }
  }

  const hiddenElement = document.createElement('a');
  hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
  hiddenElement.target = '_blank';
  hiddenElement.download = 'notstarted.csv';
  hiddenElement.click();
}

  /**
   * Function for exporting all users
  */
 exportAll() {
    let csvData = 'ID,First Name,Last Name,Username,Email,Wallet Address,Amount,Currency\n';
    for (let i = 0; i < this.users.length; i++) {
      csvData += (this.users[i].id + ',' + this.users[i].firstName + ',' + this.users[i].lastName + ','
      + this.users[i].userName + ',' + this.users[i].email + ',' + this.users[i].details.walletAddress +
      ',' + this.users[i].details.amount + ',' + this.users[i].details.currencyType + '\n');
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'users.csv';
    hiddenElement.click();
  }

  exportSelected() {
    let usersToExport = [];
    const selected = (document.getElementsByClassName('selected') as HTMLCollectionOf<Element>);

    if (selected.length > 0) {
        usersToExport = [];

        for (let i = 0; i < selected.length; i++) {
          const nodeList = Array.from(selected[i].childNodes);

          if (nodeList[2]['innerHTML'].indexOf('title') !== -1) {
            usersToExport[i] = nodeList[2]['innerHTML'].split('\'')[1];
          } else {
            usersToExport[i] = nodeList[2]['innerHTML'];
          }
        }

        this.usersToCheckNo = usersToExport.length;
        let csvData = 'First Name,Last Name,Email,Amount,Currency\n';

        for (let i = 0; i < this.users.length;   i++) {
          if (usersToExport.indexOf(this.users[i].email) !== -1) {
            csvData += (this.users[i].formData.firstName + ',' + this.users[i].formData.lastName + ','
            + this.users[i].email + ',' + this.users[i].formData.amount + ',' + this.users[i].formData.currency + '\n');
          }
        }

        const hiddenElement = document.createElement('a');
        hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
        hiddenElement.target = '_blank';
        hiddenElement.download = 'export.csv';
        hiddenElement.click();
    } else {
      window.scroll(0, 0);
      this.alertService.showError('Please select at least one user to export.');
    }
  }

  selectAll() {
    const tr = document.getElementsByTagName('tr') as HTMLCollectionOf<HTMLTableRowElement>;
    for (let i = 1; i < tr.length; i++) {
      tr[i].classList.add('selected');
    }

    this.allSelected = true;
  }

  deselectAll() {
    const tr = document.getElementsByTagName('tr') as HTMLCollectionOf<HTMLTableRowElement>;
    for (let i = 1; i < tr.length; i++) {
      tr[i].classList.remove('selected');
    }

    this.allSelected = false;
  }

  /**
   * Function for performing checks on selected users.
  */
  checkUsers() {
    this.loadingCheck = true;
    let usersToCheck = [];
    const selected = (document.getElementsByClassName('selected') as HTMLCollectionOf<Element>);
    if (selected.length > 0) {
        usersToCheck = [];

        for (let i = 0; i < selected.length; i++) {
          const nodeList = Array.from(selected[i].childNodes);

          if (nodeList[2]['innerHTML'].indexOf('title') !== -1) {
            usersToCheck[i] = nodeList[2]['innerHTML'].split('\'')[1];
          } else {
            usersToCheck[i] = nodeList[2]['innerHTML'];
          }
        }

        this.usersToCheckNo = usersToCheck.length;
        this.displayBar = true;

        this.callsService.evalList(usersToCheck).subscribe(
          data => {
            this.loadingCheck = false;
            this.displayBar = false;
            this.alertService.showSuccess('Check successful.');
            const table: any = $('.table');
            table.DataTable().ajax.reload(null, false );
            // this.getAllUserIdentityDocuments();
          },
          error => {
            this.alertService.showError('Something went wrong.');
            this.loadingCheck = false;
            this.displayBar = false;
          }
        );
    }

    this.loadingCheck = false;
  }

  /**
   * Function for deleting a user.
  */
 deleteUser() {
  const selected = (document.getElementsByClassName('selected') as HTMLCollectionOf<Element>);
  let userName = '';

  if (selected.length === 1) {
      const nodeList = Array.from(selected[0].childNodes);

      if (nodeList[2]['innerHTML'].indexOf('title') !== -1) {
        userName = nodeList[2]['innerHTML'].split('\'')[1];
      } else {
        userName = nodeList[2]['innerHTML'];
      }

      if (window.confirm('Are you sure you want to delete user ' + userName + ' ?')) {
        this.callsService.deleteUser(userName).subscribe(
          data => {
            if (data !== null) {
              this.alertService.showSuccess('User was deleted.');
              const table: any = $('.table');
              table.DataTable().ajax.reload(null, false);
              // this.ngOnInit();
            }
          },
          error => {
              this.alertService.showError('Something went wrong.');
          }
        );
      } else {
        selected.item(0).classList.remove('selected');
      }
  } else if (selected.length > 1) {
    if (window.confirm('Are you sure you want to delete these ' + selected.length + ' users?')) {
      for (let k = 0; k < selected.length; k++) {
        let username;
        const nodeList = Array.from(selected[k].childNodes);

        if (nodeList[2]['innerHTML'].indexOf('title') !== -1) {
          username = nodeList[2]['innerHTML'].split('\'')[1];
        } else {
          username = nodeList[2]['innerHTML'];
        }

        this.callsService.deleteUser(userName).subscribe(
          data => {
            if (data !== null) {
              this.alertService.showSuccess('User was deleted.');
              const table: any = $('.table');
              table.DataTable().ajax.reload(null, false );
            }
          },
          error => {
              this.alertService.showError('Something went wrong.');
          }
        );
      }
    } else {
      selected.item(0).classList.remove('selected');

      for (let j = 0; j < selected.length; j++) {
        selected.item(j).classList.remove('selected');
      }
    }
  } else {
    this.alertService.showError('Please select one or more users to delete.');
  }
}

/**
* Function used to detect whether currency has been chosen
*/
isCurrencyFilled() {
  this.currencyFilled = true;

  if (this.editUser.currencyType === 'EUR' || this.editUser.currencyType === 'USD' ) {
    this.currency = '';
  } else {
    this.currency = this.editUser.currencyType;
  }
}

/**
* Function used for detecting if tax country address is filled
*/
isTaxCountryFilled() {
  this.taxCountryFilled = true;
}

saveUser() {
  this.callsService.editUser(this.editUser)
    .subscribe(
      data => {
        this.editModal.hide();
        this.alertService.showSuccess('Changes saved successfully.');
        const table: any = $('.table');
        table.DataTable().ajax.reload(null, false );
      },
      error => {
        this.editModal.hide();
        this.alertService.showError('Something went wrong.');
      }
    );
}

screenerResults() {
    const selected = (document.getElementsByClassName('selected') as HTMLCollectionOf<Element>);

    if (selected.length > 0) {
        const usersToEvaluate = [];

        for (let i = 0; i < selected.length; i++) {
          const nodeList = Array.from(selected[i].childNodes);

          if (nodeList[2]['innerHTML'].indexOf('title') !== -1) {
            usersToEvaluate[i] = nodeList[2]['innerHTML'].split('\'')[1];
          } else {
            usersToEvaluate[i] = nodeList[2]['innerHTML'];
          }
        }
        this.dataService.setUsersForReview(usersToEvaluate);
        this.router.navigate(['/screening-results']);
    }
  }

  moveToFurther(entryId: any, email: any, direction: any) {
    if (this.currentMatch === (this.formattedMatches.length - 1)) {
      this.offset += this.limit;
      this.callsService.getProbableMatches(this.limit, this.offset).subscribe(
        data => {
          this.matchesResponse = data;
          this.matches = this.matchesResponse.data;

          if (this.matches.length === 0) {
            window.alert('There are no more matches to review.');
            this.callsService.moveMatch(email, entryId, direction).subscribe(
              () => {
                this.callsService.addEvent(email, localStorage.getItem('username'), '[SCREENING RESULT] '
                + localStorage.getItem('username') + ' marked match ' + entryId + ' for further research').subscribe(
                  () => {},
                  error => {}
                );

                this.screenerResultsModal.hide();
                const table: any = $('.table');
                table.DataTable().ajax.reload(null, false );
              },
              error => {}
            );
          } else {
            this.formattedMatches = [];

            for (let i = 0; i < this.matches.length; i++) {
              this.formattedMatches.push({'email': this.matches[i].email, 'match': this.matches[i].match});
            }

            this.currentMatch = 0;

            this.callsService.getUserDetails(this.formattedMatches[this.currentMatch].email).subscribe(
              userDetails => {
                this.singleUserResponse = userDetails;
                this.userData = this.singleUserResponse.data;
                this.matchNo = this.userData.analysisResult.probableMatches.length;

                if (this.currentUser !== this.userData.email) {
                  this.currentUser = this.userData.email;
                  this.matchCount = 1;
                } else {
                  this.matchCount++;
                }
              },
              error => {}
            );
          }
        },
        error => {}
      );
    } else {
      this.callsService.moveMatch(email, entryId, direction).subscribe(
        data => {
          this.currentMatch++;
          this.callsService.addEvent(email, localStorage.getItem('username'), '[SCREENING RESULT] ' + localStorage.getItem('username') + ' marked match ' + entryId + ' for further research').subscribe(
            () => {},
            error => {}
          );

          this.callsService.getUserDetails(this.formattedMatches[this.currentMatch].email).subscribe(
            userDetails => {
              this.singleUserResponse = userDetails;
              this.userData = this.singleUserResponse.data;
              // this.matchNo = this.userData.analysisResult.probableMatches.length;
              if (this.currentUser !== this.userData.email) {
                this.currentUser = this.userData.email;
                this.matchCount = 1;
                this.matchNo = this.userData.analysisResult.probableMatches.length;
              } else {
                this.matchCount++;
              }
            },
            error => {}
          );
        },
        error => {}
      );
    }
  }

  moveToConfirmed(entryId: any, email: any, direction: any) {
    if (this.currentMatch === (this.formattedMatches.length - 1)) {
      this.offset += this.limit;
      this.callsService.getProbableMatches(this.limit, this.offset).subscribe(
        data => {
          this.matchesResponse = data;
          this.matches = this.matchesResponse.data;

          if (this.matches.length === 0) {
            window.alert('There are no more matches to review.');

            this.callsService.moveMatch(email, entryId, direction).subscribe(
              matchData => {
                // tslint:disable-next-line:max-line-length
                this.callsService.addEvent(email, localStorage.getItem('username'), '[SCREENING RESULT] ' + localStorage.getItem('username') + ' confirmed match ' + entryId).subscribe(
                  eventData => {},
                  error => {}
                );

                this.screenerResultsModal.hide();
                const table: any = $('.table');
                table.DataTable().ajax.reload(null, false );
              },
              error => {}
            );
          } else {
            this.formattedMatches = [];

            for (let i = 0; i < this.matches.length; i++) {
              this.formattedMatches.push({ 'email': this.matches[i].email, 'match': this.matches[i].match });
            }

            this.currentMatch = 0;

            this.callsService.getUserDetails(this.formattedMatches[this.currentMatch].email).subscribe(
              userDetails => {
                this.singleUserResponse = userDetails;
                this.userData = this.singleUserResponse.data;
                this.matchNo = this.userData.analysisResult.probableMatches.length;
                if (this.currentUser !== this.userData.email) {
                  this.currentUser = this.userData.email;
                  this.matchCount = 1;
                  // this.matchNo = this.userData.analysisResult.probableMatches.length;
                } else {
                  this.matchCount++;
                }
              },
              error => {}
            );
          }
        },
        error => {}
      );
    } else {
      this.callsService.moveMatch(email, entryId, direction).subscribe(
        () => {
          this.currentMatch++;
          this.callsService.addEvent(email, localStorage.getItem('username'), '[SCREENING RESULT] ' + localStorage.getItem('username') + ' confirmed match ' + entryId).subscribe(
            () => {},
            error => {}
          );

          this.callsService.getUserDetails(this.formattedMatches[this.currentMatch].email).subscribe(
            userData => {
              this.singleUserResponse = userData;
              this.userData = this.singleUserResponse.data;
              // this.matchNo = this.userData.analysisResult.probableMatches.length;
              if (this.currentUser !== this.userData.email) {
                this.currentUser = this.userData.email;
                this.matchCount = 1;
                this.matchNo = this.userData.analysisResult.probableMatches.length;
              } else {
                this.matchCount++;
              }
            },
            error => {}
          );
        },
        error => {}
      );
    }
  }

  moveToWhitelisted(entryId: any, email: any, direction: any) {
    if (this.currentMatch === (this.formattedMatches.length - 1)) {
      this.offset += this.limit;

      this.callsService.getProbableMatches(this.limit, this.offset).subscribe(
        data => {
          this.matchesResponse = data;
          this.matches = this.matchesResponse.data;

          if (this.matches.length === 0) {
            window.alert('There are no more matches to review.');
            this.callsService.moveMatch(email, entryId, direction).subscribe(
              () => {
                this.callsService.addEvent(email, localStorage.getItem('username'), '[SCREENING RESULT] '
                + localStorage.getItem('username') + ' whitelisted ' + entryId).subscribe(
                  eventData => {},
                  error => {}
                );

                this.screenerResultsModal.hide();
                const table: any = $('.table');
                table.DataTable().ajax.reload(null, false );
              },
              error => {}
            );
          } else {
            this.formattedMatches = [];

            for (let i = 0; i < this.matches.length; i++) {
              this.formattedMatches.push({'email': this.matches[i].email, 'match': this.matches[i].match});
            }

            this.currentMatch = 0;

            this.callsService.getUserDetails(this.formattedMatches[this.currentMatch].email).subscribe(
              userDetails => {
                this.singleUserResponse = userDetails;
                this.userData = this.singleUserResponse.data;
                this.matchNo = this.userData.analysisResult.probableMatches.length;
                if (this.currentUser !== this.userData.email) {
                  this.currentUser = this.userData.email;
                  this.matchCount = 1;
                } else {
                  this.matchCount++;
                }
              },
              error => {}
            );
          }
        },
        error => {}
      );
    } else {
      this.callsService.moveMatch(email, entryId, direction).subscribe(
        () => {
          this.currentMatch++;
          this.callsService.addEvent(email, localStorage.getItem('username'), '[SCREENING RESULT] ' + localStorage.getItem('username') + ' whitelisted ' + entryId).subscribe(
            eventData => {},
            error => {}
          );

          this.callsService.getUserDetails(this.formattedMatches[this.currentMatch].email).subscribe(
            userDetails => {
              this.singleUserResponse = userDetails;
              this.userData = this.singleUserResponse.data;
              // this.matchNo = this.userData.analysisResult.probableMatches.length;

              if (this.currentUser !== this.userData.email) {
                this.currentUser = this.userData.email;
                this.matchCount = 1;
                this.matchNo = this.userData.analysisResult.probableMatches.length;
              } else {
                this.matchCount++;
              }
            },
            error => {}
          );
        },
        error => {}
      );
    }

  }

  addNote(email: any, entryId: any, note: any) {
    this.callsService.updateMatchNote(email, entryId, note).subscribe(
      () => {
        this.note = '';
        this.formattedMatches[this.currentMatch].match.note = note;

        this.callsService.getUserDetails(email).subscribe(
          userData => {
            this.singleUserResponse = userData;
            // this.matchNo = this.userData.analysisResult.probableMatches.length;
            for (let i = 0; i < this.singleUserResponse.data.analysisResult.probableMatches.length; i++) {
              if (this.singleUserResponse.data.analysisResult.probableMatches[i].entryId === entryId) {
                this.formattedMatches[this.currentMatch].match = this.singleUserResponse.data.analysisResult.probableMatches[i];
              }
            }
          }
        );
        this.callsService.addEvent(email, localStorage.getItem('username'), '[SCREENING RESULT] ' + localStorage.getItem('username') + ' added note \'' + note + '\' to ' + entryId).subscribe(
          () => {},
          error => {}
        );
      },
      error => {}
    );
  }

  accordionClick() {}

  test() {
    return true;
  }

  previousMatch() {
    this.currentMatch--;
  }

  nextMatch() {
    this.currentMatch++;
  }

  seeDetails(name: any) {
    const encoded = encodeURIComponent(name);
    const origin = window.location.origin;
    const url = origin + '/search?q=' + encoded;
    window.open(url, '_blank');
  }

  ngOnDestroy() {
    const table: any = $('.table');
    table.dataTable().fnDestroy();
  }
}

// @Component({
//   selector: 'app-modal-content',
//   template: `
//     <div class='modal-header'>
//       <h4 class='modal-title pull-left'>Select document type</h4>
//       <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
//         <span aria-hidden='true'>&times;</span>
//       </button>
//     </div>
//     <div class='modal-body'>
//       Select which type of documents you would like to evaluate:<br>
//       <div style='height: 15px;'></div>
//       <div *ngFor='let source of sources' class='source-item'>
//       <div style='margin-left: 20px'>
//         <input (change)='getSelected()' type='radio'
//                 name='sources'
//                 value='{{source.id}}'
//                 [(ngModel)]='source.selected' (ngModelChange)='sourceFilter()'/>
//         <span class='source-text'>&#160;&#160;{{source.name}}</span>
//         <div style='height: 13px;'></div>
//       </div>
//       </div>
//     </div>
//     <div class='modal-footer'>
//       <img [hidden]='!loadingEval' src={{loadingImg}} />
// tslint:disable-next-line:max-line-length
//       <button type='button' clas='btn btn-primary' style='color: white;background-color: #1F4694;border:none;padding: 10px;' (click)='evaluate()'>Evaluate</button>
//     </div>
//   `
// })

// export class GalleryModalComponent  {
// }
