import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'connectionAssessment'})
export class ConnectionAssessmentPipe implements PipeTransform {
  transform(value: any): string {
    const json = JSON.parse(value);

    value = '<b>ip_address </b>' + json['ipAddress'] + '</br>';
    value += '<b>user_agent: </b>' + json['userAgent'] + '</br>';
    value += '<b>language_setting: </b>' + json['languageSetting'] + '</br>';
    value += '<b>local_time: </b>' + json['localTime'] + '</br>';

    return value;
  }
}
