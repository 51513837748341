<div class="col-md-12">
    <div style="height: 20px"></div>
    <button class="bbtn btn-success" (click)="addNew(addTemplate)"
        style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus"
            aria-hidden="true"></i></button>
    <div style="height: 15px"></div>
    <table class="table-documents" width="100%" style="text-align: left;">
        <thead>
            <tr>
                <th>Type</th>
                <th>Allowed mime types</th>
                <th>Description</th>
                <th>Expiration</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let documentType of documentTypes">
                <td>{{documentType.documentTypeName}}</td>
                <td>{{documentType.allowableMimeTypes}}</td>
                <td>{{documentType.description}}</td>
                <td *ngIf="documentType.documentExpiration == null">NOT SET</td>
                <td *ngIf="documentType.documentExpiration != null">{{documentType.documentExpiration | secondsToDays}}
                    days</td>
                <td><button class="btn btn-warning" style="padding: 6px;" (click)="edit(editTemplate,documentType)">Edit
                        &nbsp;<i class="fa fa-pencil" aria-hidden="true"></i></button></td>
            </tr>
        </tbody>
    </table>
</div>
<ng-template #editTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Document Type</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!--<alert></alert>-->
        <label> Name: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.documentTypeName" />
        <div class="alert alert-danger" [hidden]="currentDocumentType.documentTypeName">Name is required</div>
        <div style="height: 10px;"></div>
        <label> Allowable MIME types: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-multiselect-dropdown [placeholder]="'Select types'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
        <div class="alert alert-danger" [hidden]="selectedItems.length">MIME types are required</div>
        <div style="height: 10px;"></div>
        <label> Format: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-select class="custom" [items]="formats" [(ngModel)]="currentDocumentType.docForm" style="flex-wrap: wrap;">
        </ng-select>
        <div class="alert alert-danger" [hidden]="currentDocumentType.docForm">Format is required</div>
        <div style="height: 10px;"></div>
        <label> Description: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.description" />
        <div class="alert alert-danger" [hidden]="currentDocumentType.description">Description is required</div>
        <div style="height: 10px;"></div>
        <label> Expiration: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="number" min="0" [(ngModel)]="currentDocumentType.expirationDuration" />&nbsp;&nbsp; <select
            style="color: black;height: 26px;" id="expirationUnit" class="ng-control"
            [(ngModel)]="currentDocumentType.expirationUnit">
            <option value="YEARS">YEARS</option>
            <option value="MONTHS">MONTHS</option>
            <option value="DAYS">DAYS</option>
        </select>
        <div class="alert alert-danger"
            [hidden]="currentDocumentType.expirationUnit && currentDocumentType.expirationDuration ">Expiration is
            required</div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="save()">Save</button>
    </div>
</ng-template>
<ng-template #addTemplate>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Add Document Type</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <!--<alert></alert>-->
        <label> Name: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.documentTypeName" />
        <div class="alert alert-danger" [hidden]="currentDocumentType.documentTypeName">Name is required</div>
        <div style="height: 10px;"></div>
        <label> Allowable MIME types: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-multiselect-dropdown [placeholder]="'Select types'" [settings]="dropdownSettings" [data]="dropdownList"
            [(ngModel)]="selectedItems" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)">
        </ng-multiselect-dropdown>
        <div class="alert alert-danger" [hidden]="selectedItems.length">MIME types are required</div>
        <div style="height: 10px;"></div>
        <label> Format: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <ng-select class="custom" [items]="formats" [(ngModel)]="currentDocumentType.docForm" style="flex-wrap: wrap;">
        </ng-select>
        <div class="alert alert-danger" [hidden]="currentDocumentType.docForm">Format is required</div>
        <div style="height: 10px;"></div>
        <label> Description: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="text" [(ngModel)]="currentDocumentType.description" />
        <div class="alert alert-danger" [hidden]="currentDocumentType.description">Description is required</div>
        <div style="height: 10px;"></div>
        <label> Expiration: <b class="required"> *</b></label><br>
        <div style="height: 10px;"></div>
        <input type="number" min="0" [(ngModel)]="currentDocumentType.expirationDuration" />&nbsp;&nbsp; <select
            style="color: black;height: 26px;" id="expirationUnit" class="ng-control"
            [(ngModel)]="currentDocumentType.expirationUnit">
            <option value="YEARS">YEARS</option>
            <option value="MONTHS">MONTHS</option>
            <option value="DAYS">DAYS</option>
        </select>
        <div class="alert alert-danger"
            [hidden]="currentDocumentType.expirationUnit && currentDocumentType.expirationDuration ">Expiration is
            required</div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="add()">Add</button>
    </div>
</ng-template>
