import 'hammerjs';
import 'mousetrap';
import * as networkgraph from 'highcharts/modules/networkgraph.src';
import * as organization from 'highcharts/modules/organization.src';
import * as sankey from 'highcharts/modules/sankey.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as serieslabel from 'highcharts/modules/series-label.src';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './_services/config.service';
var ɵ0 = { appearance: 'fill' }, ɵ1 = function (config) { return function () { return config.load(); }; }, ɵ2 = function () { return [
    networkgraph,
    sankey,
    organization,
    exporting,
    serieslabel,
]; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
// required for AOT compilation
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0, ɵ1, ɵ2 };
