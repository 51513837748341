<div *ngIf="!isOnMobile  && isReady">
        <form #personalForm="ngForm" class="editForm" novalidate>
                <div class="tab-pane fade in active">
                        <h4 class="head text-center">{{ "IDENTITY_DOC_PHOTO" | translate }}</h4>
                        <br />
                        <div class='row'>
                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                                        <!-- <p style="font-size: 19px;"> {{ "IDENTITY_DOC_DESC" | translate }} </p> -->
                                        <div class="row" style="font-size: 16px;"> <a *ngIf="!customOnboarding" tooltip="{{ 'WHY_TOOLTIP' | translate }}">{{"WHY" |translate}}</a>
                                                <div style="height: 15px;">
                                                </div>
                                                <div class="radio">
                                                        <label style="font-size: 15px;">
                                                                <input type="radio" #documentTypee="ngModel" required
                                                                        name="documentType" [(ngModel)]="documentType"
                                                                        value="passport"
                                                                        (change)="processType('passport')"> {{
                                                                "PASSPORT" | translate }} </label>
                                                </div>
                                                <div class="radio">
                                                        <label style="font-size: 15px;">
                                                                <input type="radio" #documentTypee="ngModel" required
                                                                        name="documentType" [(ngModel)]="documentType"
                                                                        value="id" (change)="processType('id')"> {{
                                                                "ID_CARD" | translate }} </label>
                                                </div>
                                                <div class="radio">
                                                        <label style="font-size: 15px;">
                                                                <input type="radio" #documentTypee="ngModel" required
                                                                        name="documentType" [(ngModel)]="documentType"
                                                                        value="driversLicense"
                                                                        (change)="processType('driversLicense')"> {{
                                                                "DRIVERS_LICENSE" | translate }} </label>
                                                </div>
                                        </div>
                                        <ol class="doc-ol">
                                                <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                        </ol>
                                        <div *ngIf="documentType == 'id'">
                                                <div class="row">
                                                        <div class="col-sm-12">
                                                                <!-- <div class="row">
                                                                        <div style="height: 10px"></div>
                                                                        <ol
                                                                                style="text-align: justify;font-size: 16px;padding-left: 15px;">
                                                                                <li>{{ "IDENTITY_DOC_HELPER1" |
                                                                                        translate }} </li>
                                                                                <li>{{ "IDENTITY_DOC_HELPER2" |
                                                                                        translate }} </li>
                                                                                <li>{{ "IDENTITY_DOC_HELPER3" |
                                                                                        translate }} </li>
                                                                                <li>{{ "IDENTITY_DOC_HELPER4" |
                                                                                        translate }} </li>
                                                                                <li>{{ "IDENTITY_DOC_HELPER5" |
                                                                                        translate }} </li>
                                                                        </ol>
                                                                </div>--> <button *ngIf="!isMobile" type="button"
                                                                        class=" btn btn-outline-rounded btn-info capture-button"
                                                                        (click)="manualcapture()">{{"START"| translate
                                                                        }} </button>
                                                                <div style="height: 20px; text-align: center;"> {{
                                                                        "REPEAT" | translate }}</div>
                                                                <div class="row text-center">
                                                                        <div class="col-sm-8 text-center"
                                                                                style="margin-top: 93px;padding-left: 0px;">
                                                                                <div class="overlay-id"
                                                                                        (click)="manualcapture()">
                                                                                        <img id="overlay-image"
                                                                                                src="../../../../assets/images/id.png"
                                                                                                width="90%" style="    opacity: 0.6;
                                                                                                z-index: 2;
                                                                                                position: absolute;
                                                                                                margin-top: 0px;
                                                                                                margin-left: 304px;
                                                                                                height: 238px;
                                                                                                width: 426px;" />
                                                                                        <video #idVideo id="video"
                                                                                                width="100%"
                                                                                                class="passport-video"
                                                                                                autoplay></video>
                                                                                        <!-- <div id="instruction-text"
                                                                                                style="padding-top: 120px;font-size: 16px;color: red;">
                                                                                        </div> -->
                                                                                        <div style="height: 140px;">
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div style="float: right;
                                                                        width: 200px;
                                                                        margin-right: -21px;
                                                                        margin-top: 73px;">
                                                                                <canvas #idCanvas id=" canvas"
                                                                                        width="980" height="540"
                                                                                        style="display:none;"></canvas>
                                                                                <div *ngIf="captures.length == 0">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: -278px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <!-- <img src="../../../../assets/images/id_placeholder.png" /> -->
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <!-- <img src="../../../../assets/images/id_placeholder.png" /> -->
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 1">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <img src="{{captures[0]}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" />
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <!-- <img src="../../../../assets/images/id_placeholder.png" style="padding-left: 7px;"/> -->
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 2">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block"
                                                                                                                *ngFor="let c of captures | slice:0:2">
                                                                                                                <img src="{{c}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" />
                                                                                                                <div
                                                                                                                        style="height: 12px;">
                                                                                                                </div>
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div *ngIf="isMobile"><button id="startButton"
                                                                                class="btn btn-success btn-take-photo"
                                                                                (click)="capture('id')">{{"START" |
                                                                                translate }}</button>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div *ngIf="documentType == 'driversLicense'">
                                                <div class="row">
                                                        <div class="col-sm-12">
                                                                <!-- <div class="row">
                                                                        <div style="height: 10px"></div>
                                                                        <ol
                                                                                style="text-align: justify;font-size: 16px;padding-left: 15px;">
                                                                                <li>{{ "DRIVERS_DOC_HELPER1" | translate
                                                                                        }} </li>
                                                                                <li>{{ "DRIVERS_DOC_HELPER2" | translate
                                                                                        }} </li>
                                                                                <li>{{ "DRIVERS_DOC_HELPER3" | translate
                                                                                        }} </li>
                                                                                <li>{{ "DRIVERS_DOC_HELPER4" | translate
                                                                                        }} </li>
                                                                                <li>{{ "DRIVERS_DOC_HELPER5" | translate
                                                                                        }} </li>
                                                                        </ol>
                                                                </div> -->
                                                                <button *ngIf="!isMobile" type="button"
                                                                        class=" btn btn-outline-rounded btn-info capture-button"
                                                                        (click)="manualcapture()">{{"START"| translate
                                                                        }} </button>
                                                                <div style="height: 20px; text-align: center;"> {{
                                                                        "REPEAT" | translate }} </div>
                                                                <div class="row text-center">
                                                                        <div class="col-sm-8 text-center"
                                                                                style="margin-top: 93px;padding-left: 0px;">
                                                                                <div class="overlay-id"
                                                                                        (click)="manualcapture()">
                                                                                        <img id="overlay-image"
                                                                                                src="../../../../assets/images/id.png"
                                                                                                width="90%" style="    opacity: 0.6;
                                                                                                z-index: 2;
                                                                                                position: absolute;
                                                                                                margin-top: 0px;
                                                                                                margin-left: 304px;
                                                                                                height: 238px;
                                                                                                width: 426px;" />
                                                                                        <video #driversVideo id="video"
                                                                                                width="100%"
                                                                                                class="passport-video"
                                                                                                autoplay></video>
                                                                                        <!-- <div id="instruction-text"
                                                                                                style="padding-top: 120px;font-size: 16px;color: red;">
                                                                                        </div> -->
                                                                                        <div style="height: 140px;">
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div style="float: right;
                                                                        width: 200px;
                                                                        margin-right: -21px;
                                                                        margin-top: 73px;">
                                                                                <canvas #driversCanvas id="canvas"
                                                                                        width="980" height="540"
                                                                                        style="display:none;"></canvas>
                                                                                <div *ngIf="captures.length == 0">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: -278px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <!-- <img src="../../../../assets/images/id_placeholder.png" /> -->
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <!-- <img src="../../../../assets/images/id_placeholder.png" /> -->
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 1">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <img src="{{captures[0]}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" />
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                                <!-- <img src="../../../../assets/images/id_placeholder.png" style="padding-left: 7px;"/> -->
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 2">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block"
                                                                                                                *ngFor="let c of captures | slice:0:2">
                                                                                                                <img src="{{c}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" />
                                                                                                                <div
                                                                                                                        style="height: 12px;">
                                                                                                                </div>
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div *ngIf="isMobile"><button id="startButton"
                                                                                class="btn btn-success btn-take-photo"
                                                                                (click)="capture('driversLicense')">{{"
                                                                                START" | translate }}</button>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div *ngIf="documentType == 'passport'">
                                                <div style="height: 15px"></div>
                                                <div class="row">
                                                        <div class="col-sm-12 text-center" style="padding-left: 0px;">
                                                                <img src="../../../../assets/images/instructions.png"
                                                                        width="90%" height="auto" />
                                                        </div>
                                                </div>
                                                <button *ngIf="!isMobile" type="button"
                                                        class=" btn btn-outline-rounded btn-info capture-button"
                                                        (click)="manualcapture()">{{"START"| translate }} </button>
                                                <div style="height: 20px; text-align: center;"> {{ "REPEAT" | translate
                                                        }}</div>
                                                <div class="row">
                                                        <div class="col-sm-7 text-center"
                                                                style="margin-top: 93px;padding-left: 0px;">
                                                                <div class="overlay-id" (click)="manualcapture()">
                                                                        <img id="overlay-image"
                                                                                src="../../../../assets/images/id.png"
                                                                                width="90%" style="    opacity: 0.6;
                                                                                z-index: 2;
                                                                                position: absolute;
                                                                                margin-top: 1px;
                                                                                margin-left: 305px;" />
                                                                        <video #passportVideo id="video" width="100%"
                                                                                class="passport-video" autoplay></video>
                                                                        <div style="height: 50px;"></div>
                                                                </div>
                                                        </div>
                                                        <div class="col-sm-5 text-center">
                                                                <canvas style="width: 100%;" #passportCanvas id="canvas"
                                                                        width="980" height="540"
                                                                        style="display:none;"></canvas>
                                                                <div *ngIf="!passportCaptured"
                                                                        style="margin-top: -98px">
                                                                        <!-- <img src="../../../../assets/images/passport_placeholder.png" /> -->
                                                                </div>
                                                                <div *ngIf="passportCaptured">
                                                                        <div style="height: 100px"></div>
                                                                        <img src="{{passportCapture}}" width="100%"
                                                                                style="margin-left: 128px;
                                                                        margin-top: -7px;" height="auto" />
                                                                </div>
                                                        </div>
                                                </div>
                                                <div *ngIf="isMobile"><button id="startButton" class="btn btn-success"
                                                                (click)="capture('passport')"
                                                                style="font-size: 16px;background-color: #1F4694;border:none;">{{
                                                                "START" | translate }}</button></div>
                                                <div style="height: 20px;"></div>
                                        </div>
                                        <div class="form-group text-center">
                                                <img [hidden]="!loading" src="{{loadingImg}}" width="35" />
                                        </div>
                                </div>
                        </div>
                </div>
        </form>
</div>
<div *ngIf="isOnMobile  && isReady">
        <app-mobile-document-onboarding (documentReady)="documentReady.emit(true)"></app-mobile-document-onboarding>
</div>
