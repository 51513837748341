<form #personalForm="ngForm" class="editForm" novalidate>
        <div class="tab-pane fade in active">
                <h4 class="head text-center">{{ "IDENTITY_DOC_PHOTO" | translate }}</h4>
                <br />
                <div class='row'>
                        <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8'>
                                <!-- <p style="font-size: 19px;"> {{ "IDENTITY_DOC_DESC" | translate }} </p> -->
                                <div class="row" style="font-size: 16px;"> <a tooltip="{{ 'WHY_TOOLTIP' | translate }}">{{"WHY" |translate}}</a>
                                        <div style="height: 15px;">
                                        </div>
                                        <div class="radio">
                                                <label style="font-size: 15px;">
                                                        <input type="radio" #documentTypee="ngModel" required
                                                                name="documentType" [(ngModel)]="documentType"
                                                                value="passport" (change)="processType('passport')"> {{
                                                        "PASSPORT" | translate }} </label>
                                        </div>
                                        <div class="radio">
                                                <label style="font-size: 15px;">
                                                        <input type="radio" #documentTypee="ngModel" required
                                                                name="documentType" [(ngModel)]="documentType"
                                                                value="id" (change)="processType('id')"> {{ "ID_CARD" |
                                                        translate }} </label>
                                        </div>
                                        <div class="radio">
                                                <label style="font-size: 15px;">
                                                        <input type="radio" #documentTypee="ngModel" required
                                                                name="documentType" [(ngModel)]="documentType"
                                                                value="driversLicense"
                                                                (change)="processType('driversLicense')"> {{
                                                        "DRIVERS_LICENSE" | translate }} </label>
                                        </div>
                                </div>
                                <div *ngIf="documentType == 'id'">
                                        <div class='row'>
                                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10'>
                                                        <div class="row"  style="margin: 2px;">

                                                                <div style="height: 10px"></div>
                                                                <ol>
                                                                        <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                                                </ol>
                                                                <div style="height: 30px;"></div>
                                                        </div>
                                                        <div class="row">
                                                                <button class="btn btn-success btn-take-photo"
                                                                        (click)="triggerSnapshot()">{{ "START" |
                                                                        translate }}</button>
                                                        </div>
                                                        <div *ngIf="captures.length >= 1">
                                                                <div *ngFor="let c of captures">
                                                                        <img src="{{c}}" width="100%" height="auto" />
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div *ngIf="documentType == 'driversLicense'">
                                        <div class='row'>
                                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10'>
                                                        <div class="row"  style="margin: 2px;">

                                                                <div style="height: 10px"></div>
                                                                <ol >
                                                                        <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                                                </ol>
                                                                <div style="height: 30px;"></div>
                                                        </div>
                                                        <div class="row">
                                                                <button class="btn btn-success btn-take-photo"
                                                                        (click)="triggerSnapshot()">{{ "START" |
                                                                        translate }}</button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div *ngIf="documentType == 'passport'">
                                        <div class='row'>
                                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10'>
                                                        <div class="row"  style="margin: 2px;">

                                                                <div style="height: 10px"></div>
                                                                <ol>
                                                                        <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                                        <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                                                </ol>
                                                                <div style="height: 30px;"></div>
                                                        </div>
                                                        <div class="row">
                                                                <button class="btn btn-success btn-take-photo"
                                                                        (click)="triggerSnapshot()">{{ "START" |
                                                                        translate }}</button>
                                                        </div>
                                                </div>
                                        </div>
                                </div>
                                <div class="form-group text-center">
                                        <img [hidden]="!loading" src="{{loadingImg}}" width="35" />
                                </div>
                        </div>
                </div>
        </div>
</form>