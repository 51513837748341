
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Rename Entity</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <p>Current name is: <b>{{primaryName}} {{secondaryName}}</b></p>
      <p><b><i>Please note that renaming an entity will trigger a new check against our database. Any new potential hits will be displayed in the screening results and the risk level of the entity will be adjusted accordingly.</i></b></p>
      <label> New primary name: * <input class='form-control input-md' style='border: 1px black solid; border-radius: 0px;' [(ngModel)]='newPrimary'></label><br><br>
      <label> New secondary name: * <input class='form-control input-md' style='border: 1px black solid; border-radius: 0px;' [(ngModel)]='newSecondary'></label>
    </div>
    <div class='modal-footer' style='text-align: left;'>
        <button class='btn btn-success' (click)='rename()' [disabled]='newPrimary == "" || newSecondary == ""' style='width: 20%;padding: 2px;margin-top: -3px;'>Rename</button>
    </div>
  