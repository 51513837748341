import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';

/**
* Implements password changing directive
*/
@Component({
    moduleId: module.id,
    selector: 'app-risk-analysis',
    templateUrl: 'riskAnalysisSettings.component.html',
    styleUrls: [ './riskAnalysisSettings.component.css' ]
})

export class RiskAnalysisSettingsComponent implements OnInit {

  riskAnalysisResponse: any = {};
  currentSettings: any = {};

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private kybService: KYBService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
   this.kybService.getRiskAnalysisRules().subscribe(
     data => {
       this.riskAnalysisResponse = data;
       this.currentSettings = this.riskAnalysisResponse.data;
     },
     error => {}
   );
  }

  saveSettings() {
    // place holder values - they are not beind used by backend yet but parameters are required in cal
    this.currentSettings.fourEyeRiskTolerance = 0;
    this.currentSettings.maxRiskTolerance = 0;
    this.currentSettings.regularRiskTolerance = 0;
    this.currentSettings.riskWeightFormula = '';
    this.kybService.updateRiskAnalysisRules(this.currentSettings).subscribe(
      data => {
        this.alertService.showSuccess('Settings saved successfully.');
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong.');
      }
    );
  }
}
