<div *ngIf="isFullSearch">
    <div style="height: 20px"></div>
    <table class="col-lg-12" cellpadding="10">
        <tr>
            <td style="background-color: white;color: black;text-align: center;padding: 3px;border-radius: 19px;">
                <i class="fas fa-question-circle pull-right" tooltip="The total risk is based on the AML risk and corruption risk of the country. It takes into consideration whether the person is Sanctioned or a PEP. The higher the value, the higher the risk." placement="top" style="margin-left: -50px;margin-top: 3px;"></i>
                <b>Total Risk:</b> {{fullSearchRisk?.totalRisk | number:'2.2'}}
            </td>
            <td>
                &nbsp;&nbsp;
            </td>
            <td style="background-color: white;color: black;text-align: center;padding: 3px;border-radius: 19px;">
                <i class="fas fa-question-circle pull-right" tooltip="The values of the sanction score indicate whether hits were found on any sanction lists. A value of 1 means that there are no hits, 2 means there are hits that have to be evaluated, 3 means that there is an exact hit." placement="top" style="margin-left: -50px;margin-top: 3px;margin-right: 15px;"></i>
                <b>Sanction Score:</b> {{fullSearchRisk.sanctionScore}}
            </td>
            <td>
                &nbsp;&nbsp;
            </td>
            <td style="background-color: white;color: black;text-align: center;padding: 3px;border-radius: 19px;">
                <i class="fas fa-question-circle pull-right" tooltip="The values of the PEP score indicate whether hits were found on any PEP lists. A value of 1 means that there are no hits, 2 means there are hits that have to be evaluated, 3 means that there is an exact hit." placement="bottom" style="margin-left: -50px;margin-top: 3px;"></i>
                <b>PEP Score:</b> {{fullSearchRisk.pepScore}}
            </td>
        </tr>
    </table>

    <div style="height: 30px"></div>
            <div style="height: 20px"></div>
            <div *ngIf="pep" class="pep-hits">
                <accordion>
                    <accordion-group [isOpen]="true">
                            <button class="btn btn-link btn-block clearfix" style="background-color: #cc0000;color: white;font-weight: bold;" accordion-heading>
                                <div class="icons-all">
                                        <div class="pull-right float-right" style="padding-left: 5px;"><i class="fas fa-caret-down" tooltip="Expand/Collapse" style="font-size: 20px;margin-top: 12px;"></i></div>
                                        <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_further_white.png" width="30px" style="margin-top: 12px;" class="float-right" tooltip="Add all to report as further research" (click)="addAllToReportPEP('FURTHER RESEARCH')" /></div>
                                        <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_false_white.png" width="30px" style="margin-top: 12px;" class="float-right" tooltip="Add all to report as false positive" (click)="addAllToReportPEP('FALSE POSITIVE')" /></div>
                                        <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_hit_white.png" width="30px" style="margin-top: 12px;" class="float-right" tooltip="Add all to report as hit" (click)="addAllToReportPEP('HIT')" /></div>    
                                </div>
                                    <div class="pull-left float-left"><img src="../../../../../assets/images/icons/PNG/hits.png" width="50" />&nbsp;&nbsp;&nbsp;&nbsp;PEP List Hits</div>
                            </button>
                                <div class="pep-hits-detail">
                                    <accordion  *ngFor="let hit of pepHits; let i=index">
                                                <accordion-group [isOpen]="false">
                                                    <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
                                                        <div class="pull-right float-right" style="padding-left: 5px;margin-right: -16px;"><i class="fas fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                                        <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_further.png" width="30px" class="float-right" tooltip="Add to report as further research" (click)="addToReportPEP(hit,'FURTHER RESEARCH')" /></div>
                                                        <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_false.png" width="30px" class="float-right" tooltip="Add to report as false positive" (click)="addToReportPEP(hit,'FALSE POSITIVE')" /></div>
                                                        <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_hit.png" width="30px" class="float-right" tooltip="Add to report as hit" (click)="addToReportPEP(hit,'HIT')" /></div>
                                                        <div class="pull-left float-left pep-hit-title">{{hit.entityName | truncatetext:60}}</div>
                                                    </button>
                                                    <b>List:</b> {{hit?.source}}<br>
                                                    <b>Birth date:</b> {{hit?.birthDates}} <br>
                                                    <b>Description:</b> {{hit?.description}}<br>
                                                    <b>AKA names:</b> {{hit?.akaNames | json}} <br>
                                                    <b>First seen:</b> {{hit?.firstSeen | date}}<br>
                                                    <b>Last seen:</b> {{hit?.lastSeen | date}}<br>
                                                    <div style="height: 10px"></div>
                                                    <div class="row">
                                                        <div class="col-lg-12" style="padding-left: 30px;" >
                                                            <input type="text" name="note" class="note" [(ngModel)]="hit.note" placeholder="Note" (ngModelChange)="addNotePEP(hit,hit.note)" style="width: 100%">
                                                        </div>
                                                    </div>
                                                </accordion-group>
                                    </accordion>
                                </div>
                    </accordion-group>
                </accordion>
                <div style="height: 10px"></div>
            </div>
            <div *ngIf="sanction" class="pep-hits">
                    <accordion>
                            <accordion-group [isOpen]="true">
                                    <button class="btn btn-link btn-block clearfix" style="background-color: #cc0000;color: white;font-weight: bold;" accordion-heading>
                                            <div class="icons-all">
                                                    <div class="pull-right float-right" style="padding-left: 5px;"><i class="fas fa-caret-down" tooltip="Expand/Collapse" style="font-size: 20px;margin-top: 12px;"></i></div>
                                                    <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_further_white.png" width="29px" style="margin-top: 12px;" class="float-right" tooltip="Add all to report as further research" (click)="addAllToReportSanction('FURTHER RESEARCH')" /></div>
                                                    <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_false_white.png" width="29px" style="margin-top: 12px;"  class="float-right" tooltip="Add all to report as false positive" (click)="addAllToReportSanction('FALSE POSITIVE')" /></div>
                                                    <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_hit_white.png" width="29px" style="margin-top: 12px;"  class="float-right" tooltip="Add all to report as hit" (click)="addAllToReportSanction('HIT')" /></div>    
                                            </div>
                                            <div class="pull-left float-left"><img src="../../../../../assets/images/icons/PNG/hits.png" width="50" />&nbsp;&nbsp;&nbsp;&nbsp;Sanction List Hits</div>
                                    </button>
                                    <div class="pep-hits-detail">
                                    <accordion  *ngFor="let hit of sanctionHits">
                                            <accordion-group [isOpen]="false">
                                                <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: #cc0000;font-weight: bold;" accordion-heading>
                                                    <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i class="fas fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                                    <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_further.png" width="30px" class="float-right" tooltip="Add to report as further research" (click)="addToReportSanction(hit,'FURTHER RESEARCH')" /></div>
                                                    <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_false.png" width="30px" class="float-right" tooltip="Add to report as false positive" (click)="addToReportSanction(hit,'FALSE POSITIVE')" /></div>
                                                    <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../../../assets/images/icons/PNG/add_hit.png" width="30px" class="float-right" tooltip="Add to report as hit" (click)="addToReportSanction(hit,'HIT')" /></div>
                                                    <div class="pull-left float-left">{{hit?.entityName | truncatetext:60}}</div>
                                                </button>
                                                <b>List:</b> {{hit?.source}}<br>
                                                <b>Birth date:</b> {{hit?.birthDates}} <br>
                                                <b>Description:</b> {{hit?.description}}<br>
                                                <b>AKA names:</b> {{hit?.akaNames | json}} <br>
                                                <b>First seen:</b> {{hit?.firstSeen | date}}<br>
                                                <b>Last seen:</b> {{hit?.lastSeen | date}}<br>
                                                <div style="height: 10px"></div>
                                                <div class="row">
                                                    <div class="col-lg-12" style="padding-left: 30px;">
                                                        <input type="text" name="note" class="note" [(ngModel)]="hit.note" placeholder="Note" (ngModelChange)="addNoteSanction(hit,hit.note)" style="width: 100%">
                                                    </div>
                                                </div>
                                            </accordion-group>
                                    </accordion>
                                </div>
                            </accordion-group>
                        </accordion>
                        <div style="height: 10px"></div>
            </div>
</div>
