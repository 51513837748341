<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
    <div style="height: 20px"></div>
    <button class="bbtn btn-success" (click)="displayAddUser(addTemplate)" style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button>
    <div style="height: 15px"></div>
    <table class="table-users" width="100%" style="text-align: left;">
        <thead>
            <tr>
                <th>Email</th>
                <th>System role</th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of users">
                <td>{{user.email}}</td>
                <td>{{user.role | uppercase}}</td>
                <td><button class="btn btn-danger" style="padding: 6px;" (click)="deactivate(user.identifier,user.email)">Deactivate</button></td>
                <td><button class="btn btn-warning" style="padding: 6px;" (click)="edit(editTemplate,user)">Edit role</button></td>
            </tr>
        </tbody>
    </table>  
    <div style="height: 25px;"></div>
</div>

<ng-template #editTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Edit User Role</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <label>
            User role:
        </label><br>
        <div style="height: 10px;"></div>
        <select [(ngModel)]="currentUser.userRole" name="entityType">
            <option value="viewer">VIEWER</option>
            <option value="analyst">ANALYST</option>
            <option value="admin">ADMIN</option>
        </select>
        <div style="height: 10px;"></div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="save()">Save</button>
    </div>
</ng-template>

<ng-template #addTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Add User</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <label>
            Email:
        </label><br>
        <input type="text" [(ngModel)]="currentUser.email" name="email" />
        <div style="height: 15px;"></div>
        <label>
            Password:
        </label><br>
        <input type="password" [(ngModel)]="currentUser.password" name="password"  />
        <div style="height: 15px;"></div>
        <label>
            User role:
        </label><br>
        <select [(ngModel)]="currentUser.userRole" name="role">
            <option value="viewer">VIEWER</option>
            <option value="analyst">ANALYST</option>
            <option value="admin">ADMIN</option>
        </select>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="addUser()">Add</button>
    </div>
</ng-template>