import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as $ from 'jquery';

/**
* Implements password changing directive
*/
@Component({
  moduleId: module.id,
  selector: 'app-documents-settings',
  templateUrl: 'documentsSettings.component.html',
  styleUrls: ['./documentsSettings.component.css']
})

export class DocumentsSettingsComponent implements OnInit {

  documentTypes: any = [];
  documentTypesResponse: any = {};
  dataTable: any;
  editModalRef: BsModalRef;
  addModalRef: BsModalRef;
  currentDocumentType: any = {};
  dropdownList = [
    'image/jpeg',
    'image/png',
    'application/pdf',
    'custom/form',
    'text/plain',
    'video/mpeg',
    'video/webm'
  ];
  selectedItems = [];
  formats = ['binary'];
  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    idField: 'item_id',
    textField: 'item_text',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 3,
    allowSearchFilter: true
  };

  onItemSelect(item: any) {
  }
  onSelectAll(items: any) {
  }

  constructor(
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private kybService: KYBService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.kybService.getDocumentTypes().subscribe(
      data => {
        this.documentTypesResponse = data;
        this.documentTypes = this.documentTypesResponse.data;
        this.initTable();
      },
      error => {}
    );
  }

  initTable() {
    const table: any = $('.table-documents');
    this.dataTable = table.DataTable(
      {
        'oLanguage': { 'sEmptyTable': '', 'sZeroRecords': '' },
        'orderCellsTop': true,
        'columnDefs': [
          { 'orderable': false, 'targets': 0 },
          { 'orderable': false, 'targets': 1 },
          { 'orderable': false, 'targets': 2 },
          { 'orderable': false, 'targets': 3 },
          { 'orderable': false, 'targets': 4 }
        ],
        'order': [],
        'paging': false,
        'retrieve': true,
        'lengthChange': false,
        'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
        'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
        'select': { style: 'os' }
      }
    );
  }

  edit(editTemplate: TemplateRef<any>, documentType: any) {
    this.currentDocumentType = documentType;
    this.selectedItems = documentType.allowableMimeTypes.length ? documentType.allowableMimeTypes.split(';') : [];

    if (this.currentDocumentType.documentExpiration !== null) {
      this.currentDocumentType.expirationDuration = this.secondsToDays(this.currentDocumentType.documentExpiration);
      this.currentDocumentType.expirationUnit = 'DAYS';
    }

    this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
  }

  secondsToDays(seconds) {
    const days = Math.round(seconds / 86400);
    return days;
  }

  save() {
    if (this.currentDocumentType.expirationUnit === 'YEARS') {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 31556952;
    } else if (this.currentDocumentType.expirationUnit === 'MONTHS') {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 2629746;
    } else if (this.currentDocumentType.expirationUnit === 'WEEKS') {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 604800;
    } else {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 86400;
    }

    // Check if expiration is not too far away - more than 100 years
    if (this.currentDocumentType.documentExpiration > 3155695200) {
      this.alertService.showWarning('The document expiration is too far away. Please choose a shorter expiration.');
    } else if (this.currentDocumentType.documentExpiration <= 0) {
      this.alertService.showWarning('The document expiration must be larger than 0. Please choose a different expiration.');
    } else {
      this.currentDocumentType.allowableMimeTypes = this.selectedItems.join(';');

      this.kybService.updateDocumentType(this.currentDocumentType).subscribe(
        documentType => {
          this.editModalRef.hide();

          this.alertService.showSuccess('Type edited successfully.');
          this.currentDocumentType = {};
          this.ngOnInit();
        },
        error => {
          this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
        }
      );
    }
  }

  addNew(addTemplate: TemplateRef<any>) {
    this.currentDocumentType = {};
    this.selectedItems = [];
    this.addModalRef = this.modalService.show(addTemplate, { backdrop: 'static' });
  }

  add() {
    if (this.currentDocumentType.expirationUnit === 'YEARS') {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 31556952;
    } else if (this.currentDocumentType.expirationUnit === 'MONTHS') {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 2629746;
    } else if (this.currentDocumentType.expirationUnit === 'WEEKS') {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 604800;
    } else {
      this.currentDocumentType.documentExpiration = this.currentDocumentType.expirationDuration * 86400;
    }

    // const expirationDate = new Date(this.currentDocumentType.documentExpiration);
    // this.currentDocumentType.documentExpiration = expirationDate.getTime()/1000;
    // Check if expiration is not too far away - more than 100 years
    if (this.currentDocumentType.documentExpiration > 3155695200) {
      this.alertService.showWarning('The document expiration is too far away. Please choose a shorter expiration.');
    } else if (this.currentDocumentType.documentExpiration <= 0) {
      this.alertService.showWarning('The document expiration must be larger than 0. Please choose a different expiration.');
    } else {
      this.currentDocumentType.allowableMimeTypes = this.selectedItems.join(';');
      this.kybService.createDocumentType(this.currentDocumentType).subscribe(
        documentType => {
          this.addModalRef.hide();

          this.alertService.showSuccess('Type added successfully.');
          this.currentDocumentType = {};
          this.ngOnInit();
        },
        error => {
          this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
        }
      );
    }
  }
}
