import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { config } from '../../../../assets/configuration';

/**
* Implements password changing directive
*/
@Component({
    moduleId: module.id,
    selector: 'app-password-change',
    templateUrl: 'passwordChange.component.html',
    styleUrls: [ './passwordChange.component.css' ]
})

export class PasswordChangeComponent {

  // oldPassword: string = '';
  newPassword = '';
  oldPassword = '';
  mainColor: any = config.mainColor;
  userId: any;

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private kybService: KYBService
  ) {}

  /**
  * Function used for changing the password
  */
  async changePassword(form: any) {
    this.userId = localStorage.getItem('identifier');
    this.kybService.updateUserPassword(this.userId, this.newPassword).subscribe(
      data => {
        if (confirm('Password changed successfully. Please log in with the new credentials.')) {
          this.authenticationService.logout();
          this.router.navigate(['login']);
        }
      },
      error => {
          this.alertService.showError(error);
      }
    );
  }
}
