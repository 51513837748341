import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../_services';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { config } from '../../../assets/configuration';
// import { saveAs } from 'file-saver/FileSaver';
import { FormDataService } from '../register/data/formData.service';
import {Chart} from 'angular-highcharts';
import { AddModalComponent } from '../enterprise';

/**
 * Implements the user detail page
*/
@Component({
    moduleId: module.id,
    templateUrl: 'dashboard.component.html',
    styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit {

  completenessChart: Chart;
  probableMatchesChart: Chart;
  entityTypeChart: Chart;
  assessmentResultChart: Chart;
  entityStatusChart: Chart;
  completenessPie: Chart;
  probableMatchesPie: Chart;
  entityTypePie: Chart;
  assessmentResultPie: Chart;
  dateRange: any;
  reportLogo: any = config.reportLogo;
  reportPreviewModal: BsModalRef;
  previewClicked = false;
  loadingImg: any = config.loadingImg;
  loading = false;
  loading2 = false;
  dashboardStatsResponse: any = {};
  entityCompletenessStats: any = {};
  entityStatusStats: any = {};
  entityWithProbableMatchesStats: any = {};
  entityTypesStats: any = {};
  assessmentResultStats: any = {};
  entityId = '';
  entities: any = [];
  entitiesLoading = false;
  pdfResponse: any = {};
  userResponse: any = {};
  tagsResponse: any = {};
  allTags: any = [];
  hiddenTags: any = [];
  tmpTags: any = [];
  userTags: any = [];
  displayFilters = false;

  allEntitiesResponse: any = {};

  includedTags: any = [];

  expiredDocuments: any = [];
  probableMatches: any = [];

  replaceDocumentModal: BsModalRef;

  expiredPageSize = 10;
  probablePageSize = 10;

  expiredTotalItems = 10;
  probableTotalItems = 10;

  expiredCurrentPage = 0;
  probableCurrentPage = 0;

  expiredMaxSize = 10;
  probableMaxSize = 10;

  constructor (
    private callsService: CallsService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private modalService: BsModalService,
    private formDataService: FormDataService,
    private dataService: DataService,
    private kybService: KYBService,
    private router: Router
  ) {}

  ngOnInit() {
    this.entitiesLoading = true;

    this.kybService.getCurrentUser().subscribe(
      data => {
        this.userResponse = data;

        this.kybService.getAllTags().subscribe(
          tagsData => {
            this.tagsResponse = tagsData;

            for (let i = 0; i < this.tagsResponse.data.length; i++) {
              this.allTags.push(this.tagsResponse.data[i].id);
            }

            for (let j = 0; j < this.userResponse.data.hiddenTags.length; j++) {
              this.hiddenTags.push(this.userResponse.data.hiddenTags[j].id);
            }

            for (let k = 0; k < this.allTags.length; k++) {
              if (this.hiddenTags.indexOf(this.allTags[k]) === -1) {
                this.tmpTags.push(this.allTags[k]);
              }
            }

            for (let l = 0; l < this.tagsResponse.data.length; l++) {
              if (this.tmpTags.indexOf(this.tagsResponse.data[l].id) !== -1) {
                this.userTags = [...this.userTags, { id: this.tagsResponse.data[l].id, name: this.tagsResponse.data[l].name }];
              }
            }

            // this.includedTags = this.tmpTags;
          }
        );
      },
      error => {}
    );

    this.getData(null);

    this.kybService.getExpiredDocumentsForUser(this.expiredCurrentPage, this.expiredPageSize).subscribe(
      (value: any) => {
        this.expiredDocuments = value.data.content;
        this.expiredTotalItems = value.data.totalElements;
      }
    );

    this.kybService.getProbableMatchesForUser(this.probableCurrentPage, this.probablePageSize).subscribe(
      (value: any) => {
        this.probableMatches = value.data.content;
        this.probableTotalItems = value.data.totalElements;
      }
    );
  }

  expiredPageChanged(event: any): void {
    this.kybService.getExpiredDocumentsForUser(event.page - 1, this.expiredPageSize).subscribe(
      (value: any) => this.expiredDocuments = value.data.content
    );
  }

  expiredPageSizeChanged(): void {
    this.expiredCurrentPage = 0;

    this.kybService.getExpiredDocumentsForUser(this.expiredCurrentPage, this.expiredPageSize).subscribe(
      (value: any) => this.expiredDocuments = value.data.content
    );
  }

  probablePageChanged(event: any): void {
    this.kybService.getProbableMatchesForUser(event.page - 1, this.probablePageSize).subscribe(
      (value: any) => this.probableMatches = value.data.content
    );
  }

  probablePageSizeChanged(): void {
    this.probableCurrentPage = 0;

    this.kybService.getProbableMatchesForUser(this.probableCurrentPage, this.probablePageSize).subscribe(
      (value: any) => this.probableMatches = value.data.content
    );
  }

  replaceDocument(document: any): void {
    const initialState = {
      'mode': 'replace-document',
      'title': 'Replace Document',
      'documentId': document.documentsInformation.documentId,
      'documentTypeId': document.documentsInformation.documentTypeId,
      'entityId': document.ownerInformation.entityId,
      'roleId': document.documentsInformation.roleId
    };

    this.replaceDocumentModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

    this.dataService.closeModalEvent.subscribe(
      message => {
        this.replaceDocumentModal.hide();
        this.ngOnInit();
      }
    );
  }

  toggleFilters() {
    if (this.displayFilters === true) {
      this.displayFilters = false;
    } else {
      this.displayFilters = true;
    }
  }

  tagsChanged(event) {
    this.getData(this.includedTags);
  }

  selectAllTags() {
    this.includedTags = this.tmpTags;

    this.getData(this.includedTags);
  }

  deselectAllTags() {
    this.includedTags = [];

    this.getData(this.includedTags);
  }

  getData(tags: any) {
    this.kybService.getDashboardStatistics(tags).subscribe(
      data => {
          this.dashboardStatsResponse = data;
          this.entityCompletenessStats = this.dashboardStatsResponse.data.entityCompletenessStats;
          this.entityStatusStats = this.dashboardStatsResponse.data.entityStatusStats;
          this.entityWithProbableMatchesStats = this.dashboardStatsResponse.data.entityWithProbableMatchesStats;
          this.entityTypesStats = this.dashboardStatsResponse.data.entityTypesStats;
          this.assessmentResultStats = this.dashboardStatsResponse.data.assessmentResultStats;

          this.drawCompletenessChart();
          this.drawProbableMatchesChart();
          this.drawEntityTypeChart();
          this.drawAssessmentResultChart();
          this.drawCompletenessPie();
          this.drawProbableMatchesPie();
          this.drawEntityTypePie();
          this.drawAssessmentResultPie();
      },
      error => {}
    );
  }

  prepareCompletenessPie() {
    const colors = [];
    const names = [], y = [];

    names[0] = 'Complete';
    names[1] = 'Incomplete';

    colors[0] = '#5ea138';
    colors[1] = '#D60000';

    const total = this.entityCompletenessStats.complete + this.entityCompletenessStats.incomplete;

    y[0] = Math.floor((this.entityCompletenessStats.complete / total ) * 100);
    y[1] = Math.floor((this.entityCompletenessStats.incomplete / total ) * 100);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 2; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawCompletenessPie() {
    this.completenessPie = new Chart({
      chart: {
        height: '300',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Entity completeness',
        style: {
          color: 'black',
          fontSize: '14px',
        }
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'default',
          dataLabels: {
            enabled: false
          },
          point: {
            events: {
             legendItemClick: (e) => {
                e.preventDefault();
             }
            }
          },
          showInLegend: true
        }
      },
      series: [{
        type: 'pie',
        name: '%',
        data: this.prepareCompletenessPie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareProbableMatchesPie() {
    const colors = [];
    const names = [], y = [];

    names[0] = 'No Matches';
    names[1] = 'Probable Matches';

    colors[0] = '#bdbdbd';
    colors[1] = '#FDD32B';

    const total = this.entityWithProbableMatchesStats.entitiesWithProbableMatches +
    this.entityWithProbableMatchesStats.entitiesWithoutProbableMatches;

    y[0] = Math.floor((this.entityWithProbableMatchesStats.entitiesWithoutProbableMatches / total ) * 100);
    y[1] = Math.floor((this.entityWithProbableMatchesStats.entitiesWithProbableMatches / total ) * 100);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 2; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawProbableMatchesPie() {
    this.probableMatchesPie = new Chart({
      chart: {
        height: '300',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Entities with probable matches',
        style: {
          color: 'black',
          fontSize: '14px',
        }
      },
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'default',
          dataLabels: {
            enabled: false
          },
          point: {
            events: {
             legendItemClick: (e) => {
                e.preventDefault();
             }
            }
          },
          showInLegend: true
        }
      },
      series: [{
        type: 'pie',
        name: '%',
        data: this.prepareProbableMatchesPie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareEntityTypePie() {
    const colors = [];
    const names = [], y = [];

    // names[0] = 'Geo';
    names[0] = 'Person';
    names[1] = 'Group';
    names[2] = 'Legal';
    names[3] = 'State';
    names[4] = 'Treaty';

    // colors[0] = '#3E6A3B';
    colors[0] = '#2D3945';
    colors[1] = '#E5B35F';
    colors[2] = '#E77B34';
    colors[3] = '#BDA62F';
    colors[4] = '#84463E';

    const total = this.entityTypesStats.person + this.entityTypesStats.group + this.entityTypesStats.legal
    + this.entityTypesStats.state + this.entityTypesStats.treaty;

    // y[0] = Math.floor((this.entityTypesStats.geo / total ) * 100);
    y[0] = Math.floor((this.entityTypesStats.person / total ) * 100);
    y[1] = Math.floor((this.entityTypesStats.group / total ) * 100);
    y[2] = Math.floor((this.entityTypesStats.legal / total ) * 100);
    y[3] = Math.floor((this.entityTypesStats.state / total ) * 100);
    y[4] = Math.floor((this.entityTypesStats.treaty / total ) * 100);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 5; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawEntityTypePie() {
    this.entityTypePie = new Chart({
      chart: {
        height: '300',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Entity types',
        style: {
          color: 'black',
          fontSize: '14px',
        }
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'default',
          dataLabels: {
            enabled: false
          },
          point: {
            events: {
             legendItemClick: (e) => {
                e.preventDefault();
             }
            }
          },
          showInLegend: true
        }
      },
      series: [{
        type: 'pie',
        name: '%',
        data: this.prepareEntityTypePie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareAssessmentResultPie() {
    const colors = [];
    const names = [], y = [];

    names[0] = 'Low';
    names[1] = 'Medium';
    names[2] = 'High';
    names[3] = 'Rejected';

    colors[0] = '#5ea138';
    colors[1] = '#FDD32B';
    colors[2] = '#d6860f';
    colors[3] = '#D60000';

    const total = this.assessmentResultStats.low + this.assessmentResultStats.medium +
    this.assessmentResultStats.high + this.assessmentResultStats.rejected;

    y[0] = Math.floor((this.assessmentResultStats.low / total ) * 100);
    y[1] = Math.floor((this.assessmentResultStats.medium / total ) * 100);
    y[2] = Math.floor((this.assessmentResultStats.high / total ) * 100);
    y[3] = Math.floor((this.assessmentResultStats.rejected / total ) * 100);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 4; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawAssessmentResultPie() {
    this.assessmentResultPie = new Chart ({
      chart: {
        height: '300',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Assessment results',
        style: {
          color: 'black',
          fontSize: '14px',
        }
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          allowPointSelect: false,
          cursor: 'default',
          dataLabels: {
            enabled: false
          },
          point: {
            events: {
             legendItemClick: (e) => {
                e.preventDefault();
             }
            }
          },
          showInLegend: true
        }
      },
      series: [{
        type: 'pie',
        name: '%',
        data: this.prepareAssessmentResultPie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareCompletenessChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawCompletenessChart() {
    this.completenessChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Entity completeness'
        },
        xAxis: {
            categories: ['Complete', 'Incomplete']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareCompletenessChart('Counterparties')
        }, {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareCompletenessChart('Third Parties')
        }]
    }
    );
  }

  prepareProbableMatchesChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawProbableMatchesChart() {
    this.probableMatchesChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Entities with probable matches'
        },
        xAxis: {
            categories: ['Probable matches', 'No matches']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareProbableMatchesChart('Counterparties')
        }, {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareProbableMatchesChart('Third Parties')
        }]
    }
    );
  }

  prepareAssessmentResultChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawAssessmentResultChart() {
    this.assessmentResultChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Assessment results'
        },
        xAxis: {
            categories: ['Low', 'Medium', 'High', 'Rejected']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareAssessmentResultChart('Counterparties')
        },
        {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareAssessmentResultChart('Third Parties')
        }]
    }
    );
  }

  prepareEntityTypeChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
      chartData.push(5);
      chartData.push(3);
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
      chartData.push(2);
      chartData.push(1);
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawEntityTypeChart() {
    this.entityTypeChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Entity Types'
        },
        xAxis: {
            categories: ['Geo', 'Person', 'Group', 'Legal', 'State', 'Treaty']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareEntityTypeChart('Counterparties')
        },
        {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareEntityTypeChart('Third Parties')
        }]
    }
    );
  }
}
