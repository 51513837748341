<div class="col-md-12">
        <div style="height: 20px"></div>
        <button class="btn btn-success" (click)="addNew(addTemplate)" style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button>
        <div style="height: 15px"></div>
        <table class="table-roles" width="100%" style="text-align: left;">
            <thead>
                <tr>
                    <th>Role name</th>
                    <th>Risk</th>
                    <th>Self</th>
                    <th>Allowed E1</th>
                    <th>Allowed E2</th>
                    <th>Relation Type</th>
                    <th>Required documents</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let legalRoleType of legalRoleTypes; let i = index">
                    <td>{{ legalRoleType.defaultRoleName }}</td>
                    <td>{{ legalRoleType.defaultRisk }}</td>
                    <td>{{ legalRoleType.self }}</td>
                    <td>{{ legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe }}</td>
                    <td>{{ legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe }}</td>
                    <td>{{ legalRoleType.relationType }}</td>
                    <td>
                        <div class="documents">
                            <span>{{ legalRoleType.requiredDocumentTypes | requiredDocumentsPipe }}</span>
                            <button class="btn btn-warning" (click)="editRole(editRoleTemplate, legalRoleType.id)">Edit &nbsp;<i class="fa fa-pencil" aria-hidden="true"></i></button>    
                        </div>
                    </td>
                    <td><button class="btn btn-warning" style="padding: 6px;" (click)="edit(editTemplate, legalRoleType)">Edit &nbsp;<i class="fa fa-pencil" aria-hidden="true"></i></button></td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #removeRoleTemplate>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Remove Document</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="removeModalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <span>Are you sure, this will remove the following line from the table: OrderID - {{ removeId }}</span>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success" (click)="remove()">YES IM SURE</button>
            <button class="btn btn-warning" (click)="removeModalRef.hide()">CANCEL</button>
        </div>
    </ng-template>

    <ng-template #editRoleTemplate>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Edit Role</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="editRoleModalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="requiredDocuments.length === 0" class="text-center">
                <button class="btn btn-default" (click)="addAND(addDocumentTypeTemplate)">ADD</button>
            </div>
            <table id="document-table" *ngIf="requiredDocuments.length > 0">
                <thead>
                    <tr>
                        <th>Seq.</th>
                        <th>OrderID</th>
                        <th>Document</th>
                        <th>Expiration days</th>
                        <th>AND | OR | REMOVE</th>
                    </tr>
                </thead>
                <ng-container *ngFor="let requiredDocument of requiredDocuments; let i = index">
                    <tbody>
                        <ng-container *ngIf="requiredDocument.length === 1">
                            <ng-container *ngFor="let document of requiredDocument">
                                <span *ngIf="i !== 0" class="separator">- AND -</span>
                                <tr>
                                    <td>
                                        <button *ngIf="i !== 0" (click)="upClicked(i)">
                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                        </button>
                                        <button *ngIf="i !== requiredDocuments.length - 1" (click)="downClicked(i)">
                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                    <td>{{ document.id }}</td>
                                    <td>{{ document.description }}</td>
                                    <td>{{ document.documentExpiration | secondsToDays }}</td>
                                    <td class="buttons">
                                        <div>
                                            <button class="btn btn-default" (click)="addAND(addDocumentTypeTemplate)">AND</button>
                                            <button class="btn btn-default" (click)="addOR(addDocumentTypeTemplate, document.id)">OR</button>
                                            <button class="btn btn-default" (click)="removeDocument(removeRoleTemplate, document.id)">REMOVE</button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                    <tbody>
                        <ng-container *ngIf="requiredDocument.length > 1">
                            <span *ngIf="i !== 0" class="separator">- AND -</span>
                            <tr>
                                <td>
                                    <button *ngIf="i !== 0" (click)="upClicked(i)">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="i !== requiredDocuments.length - 1" (click)="downClicked(i)">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </button>
                                </td>
                                <td>{{ requiredDocument[0].id }}</td>
                                <td>{{ requiredDocument[0].description }}</td>
                                <td>{{ requiredDocument[0].documentExpiration | secondsToDays }}</td>
                                <td class="buttons">
                                    <div>
                                        <button class="btn btn-default" (click)="addAND(addDocumentTypeTemplate)">AND</button>
                                        <button class="btn btn-default" (click)="addOR(addDocumentTypeTemplate, requiredDocument[0].id)">OR</button>
                                        <button class="btn btn-default" (click)="removeDocument(removeRoleTemplate, requiredDocument[0].id)">REMOVE</button>
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngFor="let document of requiredDocument; let i = index">
                                <span *ngIf="i !== 0" class="separator">- OR -</span>
                                <tr *ngIf="i !== 0">
                                    <td></td>
                                    <td></td>
                                    <td>{{ document.description }}</td>
                                    <td>{{ document.documentExpiration | secondsToDays }}</td>
                                    <td class="buttons">
                                        <div>
                                            <button class="btn btn-default" (click)="addAND(addDocumentTypeTemplate)">AND</button>
                                            <button class="btn btn-default" (click)="addOR(addDocumentTypeTemplate, document.id)">OR</button>
                                            <button class="btn btn-default" (click)="removeDocument(removeRoleTemplate, document.id)">REMOVE</button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </ng-container>
            </table>
        </div>
        
        <div class="modal-footer">
            <button class="btn btn-success" (click)="saveRole()">Save</button>
            <button class="btn btn-warning" (click)="editRoleModalRef.hide()">Cancel</button>
        </div>
    </ng-template>

    <ng-template #addDocumentTypeTemplate>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Add Document Type</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <ng-select [(ngModel)]="currentDocumentType" class="custom" [items]="documentTypes" bindLabel="description" bindValue="id" appendTo="body"></ng-select>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success" (click)="addDocument()">Add</button>
            <button class="btn btn-warning" (click)="addModalRef.hide()">Cancel</button>
        </div>
    </ng-template>

    <ng-template #editTemplate>
        <form [formGroup]="editLegalRoleTypeForm">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Edit Legal Role Type</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                  <!--<alert></alert>-->
                  <label>
                      Role name:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <input type="text" formControlName="defaultRoleName">
                  <div style="height: 10px;"></div>
                  <label>
                      Default risk:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <input type="number" min="0" formControlName="defaultRisk">
                  <div style="height: 10px;"></div>
                  <label>
                      Self:
                  </label>&nbsp;<input type="checkbox" formControlName="self">
                  <div style="height: 10px;"></div>
                  <label>
                      Relation type:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <select formControlName="relationType" name="relationType">
                      <option value="NONE">NONE</option>
                      <option value="OWNER">OWNER</option>
                      <option value="CONTROL">CONTROL</option>
                      <option value="OWNER_AND_CONTROL">OWNER AND CONTROL</option>
                      <option value="GEO">GEO</option>
                  </select>
                  <div style="height: 10px;"></div>
                  <label>
                      Allowable entity 1 types:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <ng-select
                      [items]="entityTypes"
                      [multiple]="true"
                      bindLabel="legalPersonType"
                      [selectableGroup]="false"
                      [closeOnSelect]="false"
                      bindValue="id"
                      [class.self]="editLegalRoleTypeForm.get('self').value"
                      formControlName="allowableE1EntityTypes">
                      <ng-template ng-header-tmp>
                          <div>
                              <button class="btn btn-link" (click)="selectAllE1('edit')">Select All</button>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                      </ng-template>
                  </ng-select>
                  <div style="height: 10px;"></div>
                  <ng-container *ngIf="!editLegalRoleTypeForm.get('self').value">
                      <label>
                          Allowable entity 2 types:
                      </label><br>
                      <div style="height: 10px;"></div>
                      <ng-select
                          [items]="entityTypes"
                          [multiple]="true"
                          bindLabel="legalPersonType"
                          [selectableGroup]="false"
                          [closeOnSelect]="false"
                          bindValue="id"
                          formControlName="allowableE2EntityTypes">
                          <ng-template ng-header-tmp>
                              <div>
                                  <button class="btn btn-link" (click)="selectAllE2('edit')">Select All</button>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                          </ng-template>
                      </ng-select>
                      <div style="height: 10px;"></div>
                  </ng-container>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-success" type="submit" [disabled]="!editLegalRoleTypeForm.valid" (click)="save()">Save</button>
              </div>
        </form>
    </ng-template>

    <ng-template #addTemplate>
        <form [formGroup]="addLegalRoleTypeForm">
            <div class="modal-header">
              <h4 class="modal-title pull-left">Add Legal Role Type</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <!--<alert></alert>-->
                <label>
                    Role name:
                </label><br>
                <div style="height: 10px;"></div>
                <input type="text" formControlName="defaultRoleName" (focusout)="changeRoleName()">
                <div class="alert alert-danger" [hidden]="isUnique">The role name must be unique.</div>
                <div style="height: 10px;"></div>
                <label>
                    Default risk:
                </label><br>
                <div style="height: 10px;"></div>
                <input type="number" min="0" formControlName="defaultRisk">
                <div style="height: 10px;"></div>
                <label>
                    Relation type:
                </label><br>
                <div style="height: 10px;"></div>
                <select formControlName="relationType" name="relationType">
                    <option value="NONE">NONE</option>
                    <option value="OWNER">OWNER</option>
                    <option value="CONTROL">CONTROL</option>
                    <option value="OWNER_AND_CONTROL">OWNER AND CONTROL</option>
                    <option value="GEO">GEO</option>
                </select>
                <div style="height: 10px;"></div>
                <label>
                    Allowable entity 1 types:
                </label><br>
                <div style="height: 10px;"></div>
                <ng-select
                    [items]="entityTypes"
                    [multiple]="true"
                    bindLabel="legalPersonType"
                    [selectableGroup]="false"
                    [closeOnSelect]="false"
                    bindValue="id"
                    formControlName="allowableE1EntityTypes">
                    <ng-template ng-header-tmp>
                        <div>
                            <button class="btn btn-link" (click)="selectAllE1('add')">Select All</button>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                    </ng-template>
                </ng-select>
                <div style="height: 10px;"></div>
                <ng-container *ngIf="!currentRoleType.self">
                    <label>
                        Allowable entity 2 types:
                    </label><br>
                    <div style="height: 10px;"></div>
                    <ng-select
                        [items]="entityTypes"
                        [multiple]="true"
                        bindLabel="legalPersonType"
                        [selectableGroup]="false"
                        [closeOnSelect]="false"
                        bindValue="id"
                        formControlName="allowableE2EntityTypes">
                        <ng-template ng-header-tmp>
                            <div>
                                <button class="btn btn-link" (click)="selectAllE2('add')">Select All</button>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                        </ng-template>
                    </ng-select>
                    <div style="height: 10px;"></div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success" type="submit" [disabled]="!addLegalRoleTypeForm.valid || !isUnique" (click)="add()">Add</button>
            </div>
        </form>
    </ng-template>