import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { config } from '../../../../assets/configuration';
import * as FileSaver from 'file-saver';
import { Chart } from 'angular-highcharts';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

/**
* Implements password changing directive
*/
@Component({
    moduleId: module.id,
    selector: 'app-screening-report',
    templateUrl: 'screeningReport.component.html',
    styleUrls: [ './screeningReport.component.css' ]
})

export class ScreeningReportComponent implements OnInit {

    allEntitiesResponse: any = {};
    entities: any = [];
    entitiesLoading = false;
    entityResponse: any = {};
    riskResponse: any = {};
    rolesResponse: any = {};
    documentContentResponse: any = {};
    basicInformation: any = {};
    pdfResponse: any = {};
    loading2 = false;
    loading = false;
    eventsForEntityResponse: any = {};
    reportLogo: any = config.reportLogo;
    entityId = '';
    loadingImg: any = config.loadingImg;
    reportPreviewModal: BsModalRef;
    pieChart: Chart;
    displayFilters = false;
    userResponse: any = {};
    tagsResponse: any = {};
    tmpTags: any = [];
    allTags: any = [];
    hiddenTags: any = [];
    userTags: any = [];
    includedTags: any = [];
    excludedTags: any = [];

    htmlInput = '';
    timestampFrom: any;
    timestampTo: any;
    groupOption = '';
    events: any = {};
    analysts: any = [];
    dossiers: any = [];
    analystsUnique: any = [];
    dossiersUnique: any = [];
    eventsByAnalyst: any = {};
    eventsByDossier: any = {};
    prettyTimestampFrom: any;
    prettyTimestampTo: any;
    counter = 0;
    eventsResponse: any = {};
    entityIds: any = [];
    dateRange: any;
    previewClicked = false;
    analyst: any;
    dossier: any;
    statisticsResponse: any = {};
    disabledButtons = true;

    constructor (
        private router: Router,
        private callsService: CallsService,
        private alertService: AlertService,
        private kybService: KYBService,
        private authenticationService: AuthenticationService,
        private modalService: BsModalService
    ) {}

    ngOnInit() {
      this.kybService.getAllEntities({'entityStatus': 'Counterparty'}).subscribe(
          data => {
              this.allEntitiesResponse = data;
              this.entities = this.allEntitiesResponse.data;
              this.entitiesLoading = false;
          },
          error => {}
          );

      this.kybService.getCurrentUser().subscribe(
        data => {
          this.userResponse = data;
          this.kybService.getAllTags().subscribe(
            tags => {
              this.tagsResponse = tags;

              for (let i = 0; i < this.tagsResponse.data.length; i++) {
                this.allTags.push(this.tagsResponse.data[i].id);
              }

              for (let j = 0; j < this.userResponse.data.hiddenTags.length; j++) {
                this.hiddenTags.push(this.userResponse.data.hiddenTags[j].id);
              }

              for (let k = 0; k < this.allTags.length; k++) {
                if (this.hiddenTags.indexOf(this.allTags[k]) === -1) {
                  this.tmpTags.push(this.allTags[k]);
                }
              }

              for (let l = 0; l < this.tagsResponse.data.length; l++) {
                if (this.tmpTags.indexOf(this.tagsResponse.data[l].id) !== -1) {
                  this.userTags = [...this.userTags, {id: this.tagsResponse.data[l].id, name: this.tagsResponse.data[l].name}];
                }
              }
              // this.includedTags = this.tmpTags;
              this.excludedTags = this.hiddenTags;
            }
          );
        },
        error => {
        }
      );
    }

    tagsChanged(event) {
      this.datesChanged(this.dateRange);
    }

    selectAllTags() {
      this.statisticsResponse = {};
      this.includedTags = this.tmpTags;
      this.datesChanged(this.dateRange);
    }

    deselectAllTags() {
      this.statisticsResponse = {};
      this.includedTags = [];
      this.datesChanged(this.dateRange);
    }

    preparePie(pieData: any) {
      const colors = [];
      const names = [], y = [];

      names[0] = 'Whitelisted matches';
      names[1] = 'Probable matches';
      names[2] = 'Confirmed matches';
      names[3] = 'Marked for further research';

      colors[0] = '#5ea138';
      colors[1] = '#FDD32B';
      colors[2] = '#D60000';
      colors[3] = '#d6860f';

      const total = pieData.whiteListedMatchesCount + pieData.probableMatchesCount + pieData.confirmedMatchesCount
      + pieData.toFurtherResearchMatchesCount;

      y[0] = Math.floor((pieData.whiteListedMatchesCount / total ) * 100);
      y[1] = Math.floor((pieData.probableMatchesCount / total ) * 100);
      y[2] = Math.floor((pieData.confirmedMatchesCount / total ) * 100);
      y[3] = Math.floor((pieData.toFurtherResearchMatchesCount / total ) * 100);

      // Putting the data in the correct format for highcharts - JSON
      const pieDataResult = [];

      for (let j = 0; j < 4; j++ ) {
        pieDataResult.push({'name': names[j], 'color': colors[j], 'y': y[j]});
      }

      return pieDataResult;
    }

    drawPieChart(pieData: any) {
      // tslint:disable-next-line:max-line-length
      const title = 'Matches distribution (' + pieData.from.split('T')[0] + ' ' + pieData.from.split('T')[1].replace('Z', '') + ' - ' + pieData.to.split('T')[0] + ' ' + pieData.to.split('T')[1].replace('Z', '').split('.')[0] + ')';

      this.pieChart = new Chart({
          chart: {
            height: '300',
            type: 'pie'
          },
          credits: {
            enabled: false
          },
          title: {
            text: title,
            style: {
              color: 'black',
              fontSize: '14px',
            }
          },
          colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
          plotOptions: {
            pie: {
              allowPointSelect: false,
              cursor: 'default',
              dataLabels: {
                enabled: false
              },
              point: {
                events: {
                  legendItemClick: function(e) {
                      e.preventDefault();
                  }
                }
            },
              showInLegend: true
            }
          },
          series: [{
            type: 'pie',
            name: '%',
            data: this.preparePie(pieData)
        }],
        exporting: {
          enabled: true
        }
        });

        const svg = document.querySelector('svg') as SVGSVGElement;

        if (svg !== null) {
          const svgSize = svg.getBoundingClientRect();
          const xml = new XMLSerializer().serializeToString(svg);
          const svg64 = btoa(unescape(encodeURIComponent(xml)));
          const b64Start = 'data:image/svg+xml;base64,';
          let image64 = b64Start + svg64;
          const canvas = document.createElement('canvas');

          canvas.width = svgSize.width;
          canvas.height = svgSize.height;

          const ctx = canvas.getContext('2d');
          const img = document.createElement('img');

          img.setAttribute('src', 'data:image/svg+xml;base64,' + svg64);
          img.setAttribute('alt', 'pieChart');
          img.onload = () => {
              ctx.drawImage(img, 0, 0);
              image64 = canvas.toDataURL('image/png');
              sessionStorage.setItem('pieChart', image64);
          };
        }
    }

    datesChanged(value: any): void {
        if (this.pieChart !== undefined) {
          this.pieChart.destroy();
        }

        this.disabledButtons = true;
        this.dateRange = value;
        this.loading = true;
        this.timestampFrom = new Date(this.dateRange[0]);
        this.prettyTimestampFrom = this.timestampConverter(this.dateRange[0]).toString().split(' ');
        this.timestampFrom.setHours(0, 0, 0, 0);

        const tzoffset = this.timestampFrom.getTimezoneOffset() * 60000; // offset in milliseconds
        const localISOTimeFrom = (new Date(this.timestampFrom - tzoffset)).toISOString().slice(0, -1);

        this.timestampFrom = localISOTimeFrom + 'Z';
        this.timestampTo = new Date(this.dateRange[1]);
        this.prettyTimestampTo = this.timestampConverter(this.dateRange[1]).toString().split(' ');
        this.timestampTo.setHours(23, 59, 59, 999);

        const tzoffsetTo = this.timestampTo.getTimezoneOffset() * 60000; // offset in milliseconds
        const localISOTimeTo = (new Date(this.timestampTo - tzoffsetTo)).toISOString().slice(0, -1);

        this.timestampTo = localISOTimeTo + 'Z';

        this.kybService.getMatchStatsBetweenDates(this.timestampFrom, this.timestampTo, this.includedTags, this.excludedTags).subscribe(
          data => {
            this.statisticsResponse = data;

            for (let j = 0; j < this.statisticsResponse.data.matches.length; j++) {
              for (let k = 0; k < this.statisticsResponse.data.matches[j].confirmedMatches.length; k++) {
                if (this.statisticsResponse.data.matches[j].confirmedMatches[k].matchType === 'MEDIA') {
                  // tslint:disable-next-line:max-line-length
                  this.statisticsResponse.data.matches[j].confirmedMatches[k].identifier = this.statisticsResponse.data.matches[j].confirmedMatches[k].mediaHeadline;
                } else if (this.statisticsResponse.data.matches[j].confirmedMatches[k].matchType === 'PEP_OR_SANCTION') {
                  if (this.statisticsResponse.data.matches[j].confirmedMatches[k].entityName === null) {
                    this.statisticsResponse.data.matches[j].confirmedMatches[k].identifier = 'Name is missing.';
                  } else {
                    this.statisticsResponse.data.matches[j].confirmedMatches[k].identifier =
                    this.statisticsResponse.data.matches[j].confirmedMatches[k].entityName;
                  }
                } else { this.statisticsResponse.data.matches[j].confirmedMatches[k].identifier =
                  this.statisticsResponse.data.matches[j].confirmedMatches[k].seHeadline; }
              }
              for (let l = 0; l < this.statisticsResponse.data.matches[j].toFurtherResearchMatches.length; l++) {
                if (this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].matchType === 'MEDIA') {
                  this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].identifier =
                  this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].mediaHeadline;
                } else if (this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].matchType === 'PEP_OR_SANCTION') {
                  if (this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].entityName === null) {
                    this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].identifier = 'Name is missing.';
                  } else {
                    this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].identifier =
                    this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].entityName;
                  }
                } else { this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].identifier
                  = this.statisticsResponse.data.matches[j].toFurtherResearchMatches[l].seHeadline; }

              }
              for (let m = 0; m < this.statisticsResponse.data.matches[j].probableMatches.length; m++) {
                if (this.statisticsResponse.data.matches[j].probableMatches[m].matchType === 'MEDIA') {
                  this.statisticsResponse.data.matches[j].probableMatches[m].identifier =
                  this.statisticsResponse.data.matches[j].probableMatches[m].mediaHeadline;
                } else if (this.statisticsResponse.data.matches[j].probableMatches[m].matchType === 'PEP_OR_SANCTION') {
                  if (this.statisticsResponse.data.matches[j].probableMatches[m].entityName === null) {
                    this.statisticsResponse.data.matches[j].probableMatches[m].identifier = 'Name is missing.';
                  } else {
                    this.statisticsResponse.data.matches[j].probableMatches[m].identifier =
                    this.statisticsResponse.data.matches[j].probableMatches[m].entityName;
                  }
                } else { this.statisticsResponse.data.matches[j].probableMatches[m].identifier =
                  this.statisticsResponse.data.matches[j].probableMatches[m].seHeadline; }

              }
              for (let n = 0; n < this.statisticsResponse.data.matches[j].whiteListedMatches.length; n++) {
                if (this.statisticsResponse.data.matches[j].whiteListedMatches[n].matchType === 'MEDIA') {
                  this.statisticsResponse.data.matches[j].whiteListedMatches[n].identifier =
                  this.statisticsResponse.data.matches[j].whiteListedMatches[n].mediaHeadline;
                } else if (this.statisticsResponse.data.matches[j].whiteListedMatches[n].matchType === 'PEP_OR_SANCTION') {
                  if (this.statisticsResponse.data.matches[j].whiteListedMatches[n].entityName == null) {
                    this.statisticsResponse.data.matches[j].whiteListedMatches[n].identifier = 'Name is missing.';
                  } else {
                    this.statisticsResponse.data.matches[j].whiteListedMatches[n].identifier =
                    this.statisticsResponse.data.matches[j].whiteListedMatches[n].entityName;
                  }
                } else { this.statisticsResponse.data.matches[j].whiteListedMatches[n].identifier =
                  this.statisticsResponse.data.matches[j].whiteListedMatches[n].seHeadline; }
              }
            }

            this.disabledButtons = false;
            this.loading = false;
            this.drawPieChart(this.statisticsResponse.data);
          },
          error => {}
        );
    }

    toggleFilters() {
      if (this.displayFilters === true) {
        this.displayFilters = false;
      } else {
        this.displayFilters = true;
      }
    }

    exportPDF() {
      this.generateReport();
    }

    generateReport() {
      this.drawPieChart(this.statisticsResponse.data);

      const doc = new jsPDF();

      doc.addImage(this.reportLogo, 20, 20, 42, 21);
      doc.setFontSize(12);
      doc.text('THIS REPORT WAS BUILT BY ' + localStorage.getItem('username').toUpperCase(), 90, 28);
      doc.text('DATE/TIME ' + new Date().toLocaleString(), 90, 35);
      doc.setFontSize(12);
      doc.text('Matches report ' + '(' + this.statisticsResponse.data.from.split('T')[0] + ' '
      + this.statisticsResponse.data.from.split('T')[1].replace('Z', '') + ' - ' + this.statisticsResponse.data.to.split('T')[0] + ' '
      + this.statisticsResponse.data.to.split('T')[1].replace('Z', '').split('.')[0] + ')', 45, 60);
      autoTable(doc,
        {
          headStyles: { cellWidth: 45 },
          head: [[ 'PROBABLE MATCHES', 'CONFIRMED MATCHES', 'REJECTED MATCHES', 'MARKED FOR FURTHER RESEARCH' ]],
          body: [[
            this.statisticsResponse.data.probableMatchesCount,
            this.statisticsResponse.data.confirmedMatchesCount,
            this.statisticsResponse.data.whiteListedMatchesCount,
            this.statisticsResponse.data.toFurtherResearchMatchesCount
          ]],
          startY: 70
        });

      let body = [];
      let head = [];

      for (let i = 0; i < this.statisticsResponse.data.matches.length; i++) {
        body = [];
        head = [];

        head.push([this.statisticsResponse.data.matches[i].entity.name, 'Last Update', 'Updated By']);

        this.statisticsResponse.data.matches[i].confirmedMatches.forEach(match => {
          const confirmedMatch = '[' + match.riskType + '] ' + match.identifier;
          body.push([confirmedMatch, match.lastStatusTimestamp, match.lastStatusChangedBy]);
        });

        this.statisticsResponse.data.matches[i].toFurtherResearchMatches.forEach(match => {
          const toFurtherResearchMatch = '[' + match.riskType + '] ' + match.identifier;
          body.push([toFurtherResearchMatch, match.lastStatusTimestamp, match.lastStatusChangedBy]);
        });

        this.statisticsResponse.data.matches[i].probableMatches.forEach(match => {
          const probableMatch = '[' + match.riskType + '] ' + match.identifier;
          body.push([probableMatch, match.lastStatusTimestamp, match.lastStatusChangedBy]);
        });

        this.statisticsResponse.data.matches[i].whiteListedMatches.forEach(match => {
          const whiteListedMatch = '[' + match.riskType + '] ' + match.identifier;
          body.push([whiteListedMatch, match.lastStatusTimestamp, match.lastStatusChangedBy]);
        });

        autoTable(doc,
          {
            headStyles: { cellWidth: 60 },
            head: head,
            body: body
          });
      }

      doc.save('report.pdf');
    }

    exportCSV() {
      let csvData = 'Date range,Probable matches,Confirmed matches,Rejected matches,Marked for further research\n';
      csvData += this.statisticsResponse.data.from.split('T')[0] + ' ' + this.statisticsResponse.data.from.split('T')[1].replace('Z', '') + ' - ' + this.statisticsResponse.data.to.split('T')[0] + ' ' + this.statisticsResponse.data.to.split('T')[1].replace('Z', '').split('.')[0] + ',' + this.statisticsResponse.data.probableMatchesCount + ',' + this.statisticsResponse.data.confirmedMatchesCount + ',' + this.statisticsResponse.data.whiteListedMatchesCount + ',' + this.statisticsResponse.data.toFurtherResearchMatchesCount + '\n';

      for (let j = 0; j < this.statisticsResponse.data.matches.length; j++) {
        // tslint:disable-next-line:max-line-length
        csvData += this.statisticsResponse.data.matches[j].entity.name + ',' + this.statisticsResponse.data.matches[j].probableMatches.length + ',' + this.statisticsResponse.data.matches[j].confirmedMatches.length + ',' + this.statisticsResponse.data.matches[j].whiteListedMatches.length + ',' + this.statisticsResponse.data.matches[j].toFurtherResearchMatches.length + '\n';
      }

      const hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'match_report.csv';
      hiddenElement.click();
    }

    onlyUnique(value, index, self) {
      return self.indexOf(value) === index;
    }

    generatePDF(htmlInput: any) {
      this.counter++;

      if (this.counter === this.analysts.length || this.counter === this.entityIds.length) {
        htmlInput += '</body></html>';

        if (this.previewClicked === true) {
          const div = (document.getElementById('report-content') as HTMLElement);
          div.innerHTML = htmlInput;
          this.previewClicked = false;
          this.counter = 0;
          this.analysts = [];
          this.dossiers = [];
          this.htmlInput = '';
          this.loading = false;
        } else {
          this.callsService.getPdfReport(htmlInput).subscribe(
            data => {
              this.pdfResponse = data;
              const binaryImg = atob(this.pdfResponse.data);
              const length = binaryImg.length;
              const arrayBuffer = new ArrayBuffer(length);
              const uintArray = new Uint8Array(arrayBuffer);

              for (let i = 0; i < length; i++) {
                uintArray[i] = binaryImg.charCodeAt(i);
              }

              const file = new Blob([uintArray], {type: 'application/pdf'});
              // var pdf = URL.createObjectURL(file);
              FileSaver.saveAs(file, 'event-log-report.pdf');
              this.counter = 0;
              this.analysts = [];
              this.dossiers = [];
              this.htmlInput = '';
              this.loading = false;
            },
            error => {
              // window.open(pdf);
            }
          );
        }
      }
    }

    displayReportPreviewModal(template: TemplateRef<any>) {
      this.previewClicked = true;
      this.reportPreviewModal = this.modalService.show(template, Object.assign({}, { class: 'modal-xl'}));
      this.generateReport();
    }

    closeModal() {
      this.reportPreviewModal.hide();
    }

    timestampConverter(timestamp: any) {
      const date = new Date(timestamp);
      return date.toUTCString();
    }
}
