<div class="col-md-12">
  <div style="height: 40px"></div>
  <p class="text-center" style="text-decoration: underline;font-size: 20pt;">Risk Screening Reports</p>
  <p style="text-align: center;font-size: 15px;">Here you can generate a report on what type of matches were detected in
    a certain time period. Start by selecting a from and to date. </p>
  <div class="table-responsive" style="overflow-x: hidden;">
    <div class="row">
      <!-- <div class="col-sm-6" style="text-align: center;"> 
            <div style="height: 20px"></div>
          <button class="btn btn-primary" style="background-color: #355B89;border-color: #355B89;padding: 8px;" (click)="overallReport()">Generate Overall Report</button>
        </div> -->
      <div class="col-sm-6">
        <div style="height: 40px"></div>
        <p>Select dates:</p>
        <div style="height: 20px"></div>
        <input type="text" [(ngModel)]="dateRange" placeholder="Select dates..." class="form-control input-md"
          placement="bottom"
          [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', showWeekNumbers: false}"
          bsDaterangepicker (bsValueChange)="datesChanged($event)" required>
      </div>
      <div class="col-sm-6">
        <!--             <div style="height: 40px"></div>
            <p>Select entity:</p><br>
            <ng-select [(ngModel)]="entityId" class="custom" [items]="entities" [loading]="entitiesLoading"  bindLabel="name" bindValue="id" appendTo="body"></ng-select>
            <div style="height: 50px;"></div> -->
      </div>
    </div>
    <div class="row" *ngIf="displayFilters">
      <div style="height: 40px"></div>
      <div class="col-sm-6">
        <p>Select the tag(s) that you would like to see:</p>
        <ng-select class="custom-screening" [items]="userTags" [multiple]="true" bindLabel="name"
          [closeOnSelect]="false" bindValue="id" [clearable]="false" (change)="tagsChanged($event)"
          [(ngModel)]="includedTags">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-sm-1" style="margin-left: -20px;">
        <div style="height: 50px;"></div>
        <button (click)="selectAllTags()"
          style="background-color:#355B89;border-color: #355B89;padding: 3px;font-size: 12px;"
          class="btn btn-success">Select all</button>
      </div>
      <div class="col-sm-1" style="margin-left: -20px;">
        <div style="height: 50px;"></div>
        <button (click)="deselectAllTags()"
          style="background-color:#355B89;border-color: #355B89;padding: 3px;font-size: 12px;"
          class="btn btn-success">Clear all</button>
      </div>
      <div style="height: 20px"></div>
    </div>
    <div style="height: 40px"></div>
    <button class="btn btn-primary" (click)="exportCSV()" [disabled]="disabledButtons">Export
      CSV</button>&nbsp;&nbsp;<button class="btn btn-primary" (click)="exportPDF()" [disabled]="disabledButtons">Export
      PDF</button>&nbsp;&nbsp;<button class="btn btn-primary" (click)="toggleFilters()"
      [disabled]="disabledButtons">Filters&nbsp;<i class="fa fa-filter" aria-hidden="true"></i>
    </button>
    <div style="height: 20px"></div>
    <p *ngIf="!disabledButtons">Summary</p>
    <div style="height: 20px"></div>
    <div class="row">
      <div class="col-sm-12" style="margin-left: -20px;">
        <table id="entity-table" class="table" style="color: black;width: 100%;margin-left: 20px;"
          *ngIf="!disabledButtons">
          <thead>
            <tr>
              <th>Date range</th>
              <th>Probable matches</th>
              <th>Confirmed matches</th>
              <th>Rejected matches</th>
              <th>Marked for further research</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ statisticsResponse?.data?.from | date:'dd/MM/yyyy, h:mm:ss a z' }} - {{
                statisticsResponse?.data?.to | date:'dd/MM/yyyy, h:mm:ss a z' }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.probableMatchesCount }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.confirmedMatchesCount }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.whiteListedMatchesCount }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.toFurtherResearchMatchesCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="height: 40px"></div>
    <div class="row">
      <div class="col-sm-12">
        <div [chart]="pieChart"></div>
      </div>
    </div>
    <div style="height: 40px"></div>
  </div>
  <div style="height: 20px"></div>
  <p *ngIf="!disabledButtons">Detailed Report</p>
  <div style="height: 20px"></div>
  <div class="row">
    <div class="col-sm-12" style="margin-left: -20px;">
      <table *ngFor="let entity of statisticsResponse?.data?.matches" class="table"
        style="color: black;width: 100%;margin-left: 20px;">
        <thead>
          <td class="col-md-4" style="padding: 10px;">{{entity.entity.name}}</td>
          <td class="col-md-4" style="padding: 10px;">Last Update</td>
          <td class="col-md-4" style="padding: 10px;">Updated By</td>
        </thead>
        <tr style="background-color: #cc0000;color: white;" *ngFor="let confirmedMatch of entity.confirmedMatches">
          <td style="padding: 10px;">[{{confirmedMatch.riskType}}] {{confirmedMatch.identifier}}</td>
          <td style="padding: 10px;">{{confirmedMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px;">{{confirmedMatch.lastStatusChangedBy}}</td>
        </tr>
        <tr style="background-color: #d6860f;color: white;"
          *ngFor="let toFurtherResearchMatch of entity.toFurtherResearchMatches">
          <td style="padding: 10px;">[{{toFurtherResearchMatch.riskType}}] {{toFurtherResearchMatch.identifier}}</td>
          <td style="padding: 10px;">{{toFurtherResearchMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px;">{{toFurtherResearchMatch.lastStatusChangedBy}}</td>
        </tr>
        <tr style="background-color: #daaa0e;color: white;" *ngFor="let probableMatch of entity.probableMatches">
          <td style="padding: 10px;">[{{probableMatch.riskType}}] {{probableMatch.identifier}}</td>
          <td style="padding: 10px;">{{probableMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px;">{{probableMatch.lastStatusChangedBy}}</td>
        </tr>
        <tr style="background-color: #1a8331;color: white;" *ngFor="let whitelistedMatch of entity.whiteListedMatches">
          <td style="padding: 10px;">[{{whitelistedMatch.riskType}}] {{whitelistedMatch.identifier}}</td>
          <td style="padding: 10px;">{{whitelistedMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px;">{{whitelistedMatch.lastStatusChangedBy}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div style="height: 40px"></div>
</div>
