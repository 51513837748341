<form #disclaimersForm="ngForm" class="editForm" novalidate>
    <div class="form-horizontal">
        <div class="tab-pane fade in active">
            <h4 class="head text-center">{{"DISCLAIMERS" | translate}}</h4>
            <br />
            <div class='row'>
                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10'>
                    <!-- <div class="form-group text-center space-10">
                        <button type="button" class="btn btn-outline-rounded btn-default" (click)="checkAll()"> <span
                                style="margin-right:10px;" class="glyphicon glyphicon-ok"></span>{{ "CHECK_ALL" |
                            translate}}</button>
                    </div> -->
                    <label>{{ "DISC_DESC" | translate }}</label>
                    <ol>
                        <li>{{ "DISC_FIN_1" | translate}}</li>
                        <li>{{ "DISC_FIN_2" | translate}}</li>
                        <li>{{ "DISC_FIN_3" | translate}}</li>
                    </ol>
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input id="onOwnBehalf" (change)="checkDisclaimers()" type="checkbox"
                                    #onOwnBehalf="ngModel" required name="onOwnBehalf"
                                    [(ngModel)]="disclaimers.onOwnBehalf" value="onOwnBehalf">
                                <ng-container *ngIf="!customOnboarding"><div [innerHTML]="disclaimerWithLinks"></div></ng-container>
                                <ng-container *ngIf="customOnboarding"> {{ "DISCLAIMERS_MAIN" | translate}}
                                </ng-container>
                            </label>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input  id="nonUs" (change)="checkDisclaimers()" type="checkbox" #nonUs="ngModel" required name="nonUs"
                                    [(ngModel)]="disclaimers.nonUs" value="nonUs"> {{"DICSLAIMER2" | translate}}
                            </label>
                        </div>
                    </div> -->
                    <!-- <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input id="fullAndFactual" (change)="checkDisclaimers()" type="checkbox" #fullAndFactual="ngModel" required
                                    name="fullAndFactual" [(ngModel)]="disclaimers.fullAndFactual"
                                    value="fullAndFactual">{{"DICSLAIMER3" | translate}} </label>
                        </div>
                    </div> -->
                    <!-- <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input id="exclusionStatement" type="checkbox" #exclusionStatement="ngModel" required
                                    name="exclusionStatement" [(ngModel)]="disclaimers.exclusionStatement"
                                    value="exclusionStatement"> {{"DICSLAIMER4" | translate}} </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input id="nonFATF" type="checkbox" #nonFATF="ngModel" required name="nonFATF"
                                    [(ngModel)]="disclaimers.nonFATF" value="nonFATF"> {{"DICSLAIMER5" | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="checkbox">
                            <label>
                                <input id="acceptanceOfRiskDisclaimer" type="checkbox"
                                    #acceptanceOfRiskDisclaimer="ngModel" required name="acceptanceOfRiskDisclaimer"
                                    [(ngModel)]="disclaimers.acceptanceOfRiskDisclaimer"
                                    value="acceptanceOfRiskDisclaimer"> {{"DICSLAIMER6" | translate}} </label>
                        </div>
                    </div> -->
                    <!-- <div style="height: 10px"></div>
                        <div class="form-group text-center space-10">
                            <re-captcha (resolved)="resolved($event)" siteKey="{{captchaKey}}" required></re-captcha>
                        </div> -->
                </div>
            </div>
        </div>
        <div class="form-group text-center space-20">
            <!-- <button type="button" class="btn btn-outline-rounded btn-default" (click)="goToPrevious(disclaimersForm)">
                <span style="margin-right:10px;" class="glyphicon glyphicon-arrow-left"></span> Previous</button>
            <button *ngIf="!thirdParty" type="button" class="btn btn-outline-rounded btn-warning"
                [disabled]="!disclaimersForm.valid" style="background-color: #355B89;border: 1.5px solid #355B89;"
                (click)="submit(disclaimersForm)"> Finish <span style="margin-left:10px;"
                    class="glyphicon glyphicon-arrow-right"></span></button>
            <button *ngIf="thirdParty" type="button" class="btn btn-outline-rounded btn-warning"
                [disabled]="!disclaimersForm.valid" style="background-color: #355B89;border: 1.5px solid #355B89;"
                (click)="goToNext(disclaimersForm)"> Next <span style="margin-left:10px;"
                    class="glyphicon glyphicon-arrow-right"></span></button> -->
        </div>
    </div>
</form>
