<h3>Two-Factor Authentication - Setup</h3>

<div *ngIf="isLoading" class="spinner-border" role="status"></div>


<div  *ngIf="!isLoading && !tfaEnabled && qrInfo!=''">


    <div >
            <div *ngIf="showError" class="alert alert-danger" role="alert">
                {{errorMessage}}
            </div>
            <div class="box">
                <p>Scan QR:</p>
                <img [src]="'data:image/jpg;base64,'+qrInfo" />         
            </div>
            <div class="form-group" >
                <label for="twoFactorCode" >Use a one-time password authenticator on your mobile device or computer to enable two-factor authentication (2FA).</label>
                <div class="fieldset">
                    <h4 class="legend">Recovery codes</h4>
                    <ul>
                      <li  *ngFor="let code of recoveryCodes">{{code}}</li>
    
                    </ul>
                </div>
                <p>Enter verification code :</p>
                <input type="number" id="mfaCode" [(ngModel)]="mfaCode" class="form-control" />
                </div>
                <br>
               
                <div class="mb-3 row">
                    <div class="row-md-1">

                        <button type="submit" class="btn btn-info" (click)="enableTfa()">Submit</button>
                    </div>
                </div>
    </div>

</div>
<div  *ngIf="tfaEnabled">
    <p class="alert alert-danger">Two-factor authentication has been enabled</p>
    <div  >
        <div class="box">
            <button class="btn btn-info" (click)="disableTfa()">Disable Two-Factor Authentication(admin only)</button>
        </div>
    </div>
</div>
