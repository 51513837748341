import { Component, OnInit, TemplateRef, Input, OnChanges } from '@angular/core';
import { AlertService, KYBService } from '../../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as $ from 'jquery';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { Link } from '../../../../_models/link.model';

/**
* Implements password changing directive
*/
@Component({
  moduleId: module.id,
  selector: 'app-legal-persons-settings',
  templateUrl: 'legalPersonsSettings.component.html',
  styleUrls: ['./legalPersonsSettings.component.css']
})

export class LegalPersonsSettingsComponent implements OnInit, OnChanges {

  @Input() requiredLink: RequiredLink;
  requiredLinkForm: FormGroup;

  legalPersonTypes: any = [];
  entityTypesResponse: any = {};
  dataTable: any;
  editModalRef: BsModalRef;
  addModalRef: BsModalRef;
  currentPersonType: any = {};
  entityTypeList: any = [];
  legalPersonTypeList: any = [];
  roleTypesResponse: any = {};
  roleTypes: any = [];

  legalPersonTypesResponse: any = {};

  get links(): FormArray {
    return this.requiredLinkForm.get('links') as FormArray;
  }

  constructor(
    private alertService: AlertService,
    private kybService: KYBService,
    private modalService: BsModalService,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.requiredLinkForm = this.formBuilder.group({
      'links': this.formBuilder.array([])
    });
  }

  ngOnChanges() {
    this.rebuildForm();
  }

  rebuildForm() {
    this.requiredLinkForm.reset({});
    this.setStudents(this.requiredLink.links);
  }

  setStudents(students: Link[]) {
    const studentsFA = this.formBuilder.group(students);

    this.requiredLinkForm.setControl('links', studentsFA);
  }

  addStudent() {
    this.links.push(this.formBuilder.group(new Link(null, null, null)));
  }

  deleteStudent(student) {
    this.links.controls.forEach((link, index, object) => {
      if (link.value === student) {
        object.splice(index, 1);
      }
    });

    this.links.value.forEach((link, index, object) => {
      if (link === student) {
        object.splice(index, 1);
      }
    });

    this.currentPersonType.requiredRoles.requiredE1Roles.forEach((role, index, object) => {
      if (role.roleType.id === student.roleTypeId) {
        object.splice(index, 1);
      }
    });
  }

  addDefinedStudent(requiredE1Roles) {
    for (let i = 0; i < requiredE1Roles.length; i++) {
      if (requiredE1Roles[i].roleType.self === true) {
        requiredE1Roles.splice(i, 1);
      }
    }
    this.requiredLinkForm = this.formBuilder.group({
      'links': this.formBuilder.array([])
    });

    requiredE1Roles.forEach(role => {
      this.links.push(this.formBuilder.group(new Link(role.operator, role.requiredValue, role.roleType.id)));
    });
  }

  ngOnInit() {
    this.kybService.getEntityTypes().subscribe(
      data => {
        this.entityTypesResponse = data;
        this.legalPersonTypes = this.entityTypesResponse.data;

        for (let i = 0; i < this.legalPersonTypes.length; i++) {
          if (this.legalPersonTypes[i].eligibleForMediaScreening === true && this.legalPersonTypes[i].excludedFromScreening === false) {
            this.legalPersonTypes[i].screeningOption = 'Full screening';
          } else if (this.legalPersonTypes[i].eligibleForMediaScreening === false &&
            this.legalPersonTypes[i].excludedFromScreening === false) {
            this.legalPersonTypes[i].screeningOption = 'PEP and Sanctions only';
          } else {
            this.legalPersonTypes[i].screeningOption = 'No screening';
          }

          this.entityTypeList.push(this.legalPersonTypes[i].entityType);

          if (this.legalPersonTypes[i].requiredRoles !== null) {
            for (let j = 0; j < this.legalPersonTypes[i].requiredRoles.requiredE1Roles.length; j++) {
              if (this.legalPersonTypes[i].requiredRoles.requiredE1Roles[j].roleType.self === true) {
                this.legalPersonTypes[i].requiredRoles.requiredE1Roles.splice(j, 1);
              }
            }
          }
        }

        this.entityTypeList.push('STATE');
        this.entityTypeList.push('TREATY');
        this.entityTypeList.push('GEO');
        this.entityTypeList.push('GROUP');
        this.entityTypeList = this.entityTypeList.filter((elem, index, self) => {
          return index === self.indexOf(elem);
        });

        this.initTable();
      },
      error => {}
    );

    this.kybService.getRoleTypes().subscribe(
      data => {
        this.roleTypesResponse = data;
        this.roleTypes = this.roleTypesResponse.data;
        this.roleTypes = this.roleTypes.filter(x => x.self !== true);
      },
      error => {
      }
    );
  }

  initTable() {
    const table: any = $('.table');

    this.dataTable = table.DataTable(
      {
        'oLanguage': { 'sEmptyTable': 'No legal person types found.' },
        'orderCellsTop': true,
        'columnDefs': [
          { 'orderable': false, 'targets': 0 },
          { 'orderable': false, 'targets': 1 },
          { 'orderable': false, 'targets': 2 },
          { 'orderable': false, 'targets': 3 },
          { 'orderable': false, 'targets': 4 }
        ],
        'order': [],
        'paging': false,
        'retrieve': true,
        'lengthChange': false,
        'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
        'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
        'select': { style: 'os' }
      }
    );

    if (this.legalPersonTypes.length > 0) {
      const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
      empty[0].setAttribute('style', 'display: none;');
    }
  }

  edit(editTemplate: TemplateRef<any>, legalPersonType: any) {
    this.kybService.getRoleTypes().subscribe(
      data => {
        this.roleTypesResponse = data;
        this.roleTypes = this.roleTypesResponse.data;
        this.roleTypes = this.roleTypes.filter(x => x.self !== true);
      },
      error => {
      }
    );

    this.currentPersonType = legalPersonType;

    if (this.currentPersonType && this.currentPersonType.requiredRoles) {
      this.addDefinedStudent(this.currentPersonType.requiredRoles.requiredE1Roles);
    }

    if (this.currentPersonType.eligibleForMediaScreening === true && this.currentPersonType.excludedFromScreening === false) {
      this.currentPersonType.screeningOption = 'fullScreening';
    } else if (this.currentPersonType.eligibleForMediaScreening === false && this.currentPersonType.excludedFromScreening === false) {
      this.currentPersonType.screeningOption = 'pepAndSanctionsOnly';
    } else {
      this.currentPersonType.screeningOption = 'noScreening';
    }

    this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
  }

  save() {
    if (this.currentPersonType.screeningOption === 'fullScreening') {
      this.currentPersonType.eligibleForMediaScreening = true;
      this.currentPersonType.excludedFromScreening = false;
    } else if (this.currentPersonType.screeningOption === 'pepAndSanctionsOnly') {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = false;
    } else {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = true;
    }

    this.currentPersonType.entityType = this.currentPersonType.entityType.toUpperCase();
    this.currentPersonType.requiredRolesRequest = {};
    this.currentPersonType.requiredRolesRequest.requiredRoles = this.requiredLinkForm.value.links;
    this.currentPersonType.requiredRolesRequest.id = this.currentPersonType.requiredRoles.id;

    // Legal person type is old version of legal form and needs to be remapped while sending
    this.currentPersonType.legalForm = this.currentPersonType.legalPersonType;

    this.kybService.updateEntityType(this.currentPersonType).subscribe(
      data => {
        this.editModalRef.hide();

        this.alertService.showSuccess('Type edited successfully.');
        this.currentPersonType = {};
        this.ngOnInit();

      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );
  }

  saveEdit() {
    this.save();
  }

  addNew(addTemplate: TemplateRef<any>) {
    this.currentPersonType = {};
    this.links.clear();
    this.kybService.getRoleTypes().subscribe(
      data => {
        this.roleTypesResponse = data;
        this.roleTypes = this.roleTypesResponse.data;
        this.roleTypes = this.roleTypes.filter(x => x.self !== true);
      },
      error => {
      }
    );
    this.addModalRef = this.modalService.show(addTemplate, { backdrop: 'static' });
  }

  add() {
    if (this.currentPersonType.screeningOption === 'fullScreening') {
      this.currentPersonType.eligibleForMediaScreening = true;
      this.currentPersonType.excludedFromScreening = false;
    } else if (this.currentPersonType.screeningOption === 'pepAndSanctionsOnly') {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = false;
    } else {
      this.currentPersonType.eligibleForMediaScreening = false;
      this.currentPersonType.excludedFromScreening = true;
    }

    this.currentPersonType.entityDetailForm = '';
    this.currentPersonType.entityType = this.currentPersonType.entityType.toUpperCase();
    // this.currentPersonType.legalPersonType = this.currentPersonType.legalPersonType.toUpperCase();
    this.currentPersonType.requiredRolesRequest = {};
    this.currentPersonType.requiredRolesRequest.requiredRoles = this.requiredLinkForm.value.links;

    this.kybService.createEntityType(this.currentPersonType).subscribe(
      data => {
        this.addModalRef.hide();
        this.alertService.showSuccess('Type added successfully.');
        this.currentPersonType = {};
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
      }
    );
  }
}

export class RequiredLink {
  public links: Link[];
}
