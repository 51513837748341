<div class="col-md-12">
    <div style="height: 20px"></div>
    <!-- <button class="bbtn btn-success" (click)="addNew(addTemplate)" style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button> -->
    <h3 class="text-center">Risk Analysis Settings</h3><br>
    <div style="height: 10px;"></div>
    <span style="font-size: 16px;">Current settings:</span>
        <div style="height: 5px;"></div>
        <ul>
            <!-- <li><b>Low risk threshold:</b> {{currentSettings?.lowRiskThreshold}}</li> -->
            <li><b>Medium risk threshold:</b> {{currentSettings?.mediumRiskThreshold}}</li>
            <li><b>High risk threshold:</b> {{currentSettings?.highRiskThreshold}}</li>
            <li><b>Unacceptable risk threshold:</b> {{currentSettings?.unacceptableRiskThreshold}}</li>
        </ul>
    <!-- <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="confidence"><b>Low risk threshold:</b> {{currentSettings?.lowRiskThreshold}}</label><br>
        <br>
        <input type="number" style="color: black;" id="lowRiskThreshold" class="ng-control" name="lowRiskThreshold" [(ngModel)]="currentSettings.lowRiskThreshold" value="lowRiskThreshold" #lowRiskThreshold>
        <div class="alert alert-danger" *ngIf="currentSettings.lowRiskThreshold < 0 || currentSettings.lowRiskThreshold > 9999 || currentSettings.lowRiskThreshold == ''">Please enter a value between 0 and 9999.</div>
    </div> -->
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="confidence"><b>Medium risk threshold:</b> {{currentSettings?.mediumRiskThreshold}}</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="mediumRiskThreshold" class="ng-control" #mediumRiskThreshold name="mediumRiskThreshold" [(ngModel)]="currentSettings.mediumRiskThreshold" value="mediumRiskThreshold">
        <div class="alert alert-danger" *ngIf="currentSettings.mediumRiskThreshold < 0 || currentSettings.mediumRiskThreshold > 9999 || currentSettings.mediumRiskThreshold == ''">Please enter a value between 0 and 9999.</div>

    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="confidence"><b>High risk threshold:</b> {{currentSettings?.highRiskThreshold}}</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="highRiskThreshold" class="ng-control" #highRiskThreshold name="highRiskThreshold" [(ngModel)]="currentSettings.highRiskThreshold" value="highRiskThreshold">
        <div class="alert alert-danger" *ngIf="currentSettings.highRiskThreshold < 0 || currentSettings.highRiskThreshold > 9999 || currentSettings.highRiskThreshold == ''">Please enter a value between 0 and 9999.</div>

    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
        <label class="control-label" for="confidence"><b>Unacceptable risk threshold:</b> {{currentSettings?.unacceptableRiskThreshold}}</label><br>
        <br>
        <input type="number" min="0" style="color: black;" id="unacceptableRiskThreshold" #unacceptableRiskThreshold class="ng-control" name="unacceptableRiskThreshold" [(ngModel)]="currentSettings.unacceptableRiskThreshold" value="unacceptableRiskThreshold">
        <div class="alert alert-danger" *ngIf="currentSettings.unacceptableRiskThreshold < 0 || currentSettings.unacceptableRiskThreshold > 9999 || currentSettings.unacceptableRiskThreshold == ''">Please enter a value between 0 and 9999.</div>

    </div>
    <div style="height: 10px;"></div>
    <div class="form-group">
    <button type="button" class="btn btn-primary" (click)="saveSettings()" [disabled]="currentSettings.lowRiskThreshold < 0 || currentSettings.lowRiskThreshold > 9999 || currentSettings.mediumRiskThreshold < 0 || currentSettings.mediumRiskThreshold > 9999 || currentSettings.highRiskThreshold < 0 || currentSettings.highRiskThreshold > 9999 || currentSettings.unacceptableRiskThreshold < 0 || currentSettings.unacceptableRiskThreshold > 9999 || currentSettings.lowRiskThreshold == '' || currentSettings.mediumRiskThreshold == '' || currentSettings.highRiskThreshold == '' || currentSettings.unacceptableRiskThreshold == ''"> Save Settings </button>
    </div>
</div>

