import { Injectable, InjectionToken, Inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { AuthenticationService, AlertService } from '../app/_services';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private authenticationService: AuthenticationService,
    private alertService: AlertService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      timeout(this.defaultTimeout),
      catchError(err => {
        if (err instanceof TimeoutError) {
          console.error('Timeout has occurred', request.url);
          return throwError(err);
        }

        if (err.status === 403) {
          // auto logout if 403 response returned from api

          // this.authenticationService.logout();
          // location.reload(true);
        } else if (err.status === 422) {
          let errorString = '';

          // Get errors
          if (err.error.errors.length > 0) {
            for (let i = 0; i < err.error.errors.length; i++) {
              errorString += err.error.errors[i].message + '. ';
            }
          }

          // Get field errors
          if (err.error.fieldErrors.length > 0) {
            for (let j = 0; j < err.error.fieldErrors.length; j++) {
              errorString += err.error.fieldErrors[j].message + '. ';
            }
          } else if (err.status === 404) {
            if (!err.url.includes('/api/v3/auth/onboard/')) {
              this.alertService.showError('Something went wrong. Please try again (Error code: ' + err.status + ')');
            }
          }
        }

        // const error = err.error.message || err.statusText;
        return throwError(err);
      })
    );
  }
}
