/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./documentEditor.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./documentEditor.component";
import * as i5 from "../../_services/kyb.service";
import * as i6 from "../../_services/alert.service";
import * as i7 from "../../_services/data.service";
var styles_DocumentEditorComponent = [i0.styles];
var RenderType_DocumentEditorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentEditorComponent, data: {} });
export { RenderType_DocumentEditorComponent as RenderType_DocumentEditorComponent };
function View_DocumentEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ":\u00A0\u00A0\u00A0"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_DocumentEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["style", "width: 60%"]], [[8, "type", 0], [8, "value", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var currVal_9 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.type; var currVal_1 = _v.parent.context.$implicit.value; var currVal_2 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 5).ngClassValid; var currVal_7 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_DocumentEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "textarea", [["style", "width: 60%"]], [[8, "value", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.FormControlName, [[3, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR], [2, i2.ɵangular_packages_forms_forms_q]], { name: [0, "name"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.FormControlName]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null)], function (_ck, _v) { var currVal_8 = _v.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.value; var currVal_1 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 5).ngClassValid; var currVal_6 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_DocumentEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "inner"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentEditorComponent_2)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DocumentEditorComponent_3)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpad(5, 7), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentEditorComponent_4)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.label !== ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 5, 0, "text", "password", "email", "number", "search", "tel", "url").includes(_v.context.$implicit.type); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_v.context.$implicit.type === "textarea"); _ck(_v, 7, 0, currVal_2); }, null); }
function View_DocumentEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save Document"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.myForm.valid || _co.loading); _ck(_v, 0, 0, currVal_0); }); }
function View_DocumentEditorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-success"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Save Geo Entity"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.myForm.valid || _co.loading); _ck(_v, 0, 0, currVal_0); }); }
export function View_DocumentEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [[":xmlns:height", "http://www.w3.org/1999/xhtml"], ["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, { ngSubmit: "ngSubmit" }), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentEditorComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["style", "height: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentEditorComponent_5)), i1.ɵdid(10, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentEditorComponent_6)), i1.ɵdid(12, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.myForm; _ck(_v, 3, 0, currVal_7); var currVal_8 = ((_co.formData == null) ? null : _co.formData.controls); _ck(_v, 7, 0, currVal_8); var currVal_9 = (_co.mode === "edit-document"); _ck(_v, 10, 0, currVal_9); var currVal_10 = (_co.mode === "edit-geo-entity"); _ck(_v, 12, 0, currVal_10); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DocumentEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-document-editor", [], null, null, null, View_DocumentEditorComponent_0, RenderType_DocumentEditorComponent)), i1.ɵdid(1, 114688, null, 0, i4.DocumentEditorComponent, [i5.KYBService, i6.AlertService, i2.FormBuilder, i7.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentEditorComponentNgFactory = i1.ɵccf("app-document-editor", i4.DocumentEditorComponent, View_DocumentEditorComponent_Host_0, { jsonFormData: "jsonFormData", documentId: "documentId", roleId: "roleId", entityId: "entityId", e1Id: "e1Id", e2Id: "e2Id", isGeoEntity: "isGeoEntity", mode: "mode", entityTypeId: "entityTypeId", primaryName: "primaryName", secondaryName: "secondaryName" }, {}, []);
export { DocumentEditorComponentNgFactory as DocumentEditorComponentNgFactory };
