import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { config } from '../../../../assets/configuration';
/**
* Implements password changing directive
*/
var PasswordChangeComponent = /** @class */ (function () {
    function PasswordChangeComponent(router, callsService, alertService, authenticationService, kybService) {
        this.router = router;
        this.callsService = callsService;
        this.alertService = alertService;
        this.authenticationService = authenticationService;
        this.kybService = kybService;
        // oldPassword: string = '';
        this.newPassword = '';
        this.oldPassword = '';
        this.mainColor = config.mainColor;
    }
    /**
    * Function used for changing the password
    */
    PasswordChangeComponent.prototype.changePassword = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.userId = localStorage.getItem('identifier');
                this.kybService.updateUserPassword(this.userId, this.newPassword).subscribe(function (data) {
                    if (confirm('Password changed successfully. Please log in with the new credentials.')) {
                        _this.authenticationService.logout();
                        _this.router.navigate(['login']);
                    }
                }, function (error) {
                    _this.alertService.showError(error);
                });
                return [2 /*return*/];
            });
        });
    };
    return PasswordChangeComponent;
}());
export { PasswordChangeComponent };
