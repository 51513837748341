import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'sanctionListPipe'})
export class SanctionListPipe implements PipeTransform {
  sourcesList: any = [];

  transform(value: string): string {
    this.sourcesList = [
        {
          'source': 'usa_ofac_sdn',
          'displayName': 'OFAC Specially Designated Nationals List',
          'shortDisplayName' : 'OFAC SDNs'
        },
        {
          'source': 'boa_executives',
          'displayName': 'KYC3 High Profile Executive List',
          'shortDisplayName' : 'High Profile Execs'
        },
        {
          'source': 'un_1988',
          'displayName': 'United Nations 1988 Sanction List',
          'shortDisplayName' : 'UN 1988 Sanction'
        },
        {
          'source': 'un_1267',
          'displayName': 'United Nations 1267 Sanction List',
          'shortDisplayName' : 'UN 1267 Sanction'
        },
        {
          'source': 'gbr_hmtreasury_sanctions',
          'displayName': `Her Majesty's Treasury Sanction List`,
          'shortDisplayName' : 'UK Sanctions'
        },
        {
          'source': 'eu_sanctions',
          'displayName': 'EU Global Sanction List',
          'shortDisplayName' : 'EU Sanctions'
        },
        {
          'source': 'che_seco',
          'displayName': 'SECO Sanction List',
          'shortDisplayName' : 'SECO Sanctions'
        },
        {
          'source': 'cia_leaders',
          'displayName': 'CIA Political Party Leadership',
          'shortDisplayName' : 'CIA Political Leaders'
        },
        {
          'source': 'cia_chiefs_of_state',
          'displayName': 'CIA Chiefs of State and Cabinet Members',
          'shortDisplayName' : 'CIA Governments'
        },
        {
          'source': 'kyc3_pep',
          'displayName': 'KYC3 Politically Exposed Persons List 2013',
          'shortDisplayName' : 'KYC3 2013 PEP'
        },
        {
          'source': 'intinterpol',
          'displayName': 'Interpol Wanted List',
          'shortDisplayName' : 'Interpol Wanted'
        },
        {
          'source': 'ukgovfsa',
          'displayName': 'FCA Unauthorised Firms List',
          'shortDisplayName' : 'FCA Unauthorised Firms'
        },
        {
          'source': 'govfbi',
          'displayName': 'FBI Wanted List',
          'shortDisplayName' : 'FBI Wanted'
        },
        {
          'source': 'orgafdb',
          'displayName': 'African Development Bank Santions',
          'shortDisplayName' : 'African DB Sanctions'
        },
        {
          'source': 'orgadblnadbg4',
          'displayName': 'Asian Development Bank Sanctions',
          'shortDisplayName' : 'Asian DB Sanctions'
        },
        {
          'source': 'orgworldbankweb',
          'displayName': 'World Bank List of Ineligible Individuals',
          'shortDisplayName' : 'World Bank Sanctions'
        },
        {
          'source': 'comebrd',
          'displayName': 'EBRD Ineligible Entities',
          'shortDisplayName' : 'EBRD Ineligible Entities'
        },
        {
          'source': 'orgiadb',
          'displayName': 'Inter-American Development Bank Sanctions',
          'shortDisplayName' : 'IADB Sanctions'
        },
        {
          'source': 'nlopenkvk',
          'displayName': 'Netherlands company list',
          'shortDisplayName' : 'NL companies'
        },
        {
          'source': 'govdea',
          'displayName': 'Drug Enforcement Administration Wanted List',
          'shortDisplayName' : 'DEA Wanted'
        },
        {
          'source': 'govuspispostalinspectors',
          'displayName': 'US Postal Inspection Service',
          'shortDisplayName' : 'USPIS Wanted'
        },
        {
          'source': 'govoccapps',
          'displayName': 'OCC/OTS Enforcement Orders',
          'shortDisplayName' : 'OCC/OTS Enforcement'
        },
        {
          'source': 'ukgovcompanyhouse',
          'displayName': 'UK Company House',
          'shortDisplayName' : 'UK Company House'
        },
        {
          'source': 'ukgovcompanyhouse_crextract',
          'displayName': 'UK Company House',
          'shortDisplayName' : 'UK Company House'
        },
        {
          'source': 'lux_memorialc',
          'displayName': 'Luxembourg Memorial C',
          'shortDisplayName' : 'LUX Memorial C'
        },
        {
          'source': 'edgar',
          'displayName': 'Securities and Exchange Commission Filling',
          'shortDisplayName' : 'SEC Filling'
        },
        {
          'source': 'govdocbisecl',
          'displayName': 'US Government Consolidated Screening',
          'shortDisplayName' : 'US GOV Screening'
        },
        {
          'source': 'wikidata',
          'displayName': 'KYC3 Politically Exposed Persons List',
          'shortDisplayName' : 'KYC3 PEP'
        },
        {
          'source': 'aus_dfat',
          'displayName': 'Australian Consolidated Sanctions',
          'shortDisplayName' : 'AU Sanctions'
        },
        {
          'source': 'usa_ofac_con',
          'displayName': 'US Treasury Consolidated Sanctions',
          'shortDisplayName' : 'US Treasury Sanctions'
        },
        {
          'source': 'cncomchinadignitaries',
          'displayName': 'KYC3 China PEP List',
          'shortDisplayName' : 'KYC3 China PEP'
        },
        {
          'source': 'nlopenkvk',
          'displayName': 'Netherlands Company Registry',
          'shortDisplayName': 'NL Company Registry'
        },
        {
          'source': 'frbodacc',
          'displayName': 'French Company Announcements',
          'shortDisplayName' : 'FR Company List'
        },
        {
          'source': 'cyberwatcher',
          'displayName': 'KYC3 News List',
          'shortDisplayName' : 'KYC3 News'
        },
        {
          'source': 'kyc3_super_entities',
          'displayName': 'KYC3 Entities List',
          'shortDisplayName' : 'KYC3 Entities'
        },
        {
          'source': 'eueumostwanted',
          'displayName': 'EU Most Wanted List',
          'shortDisplayName' : 'EU Wanted'
        }
    ];

    if (value !== null) {
        const source = value.split(':')[0];

        if (source === 'wikidata') {
            return 'None';
        } else {
            for (let i = 0; i < this.sourcesList.length; i++) {
                if (this.sourcesList[i].source === source) {
                    return this.sourcesList[i].displayName;
                }
            }
        }
    } else {
        return 'None';
    }
  }
}
