import { OnInit, TemplateRef, OnChanges } from '@angular/core';
import { AlertService, KYBService } from '../../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import * as $ from 'jquery';
import { FormBuilder } from '@angular/forms';
import { Link } from '../../../../_models/link.model';
/**
* Implements password changing directive
*/
var LegalPersonsSettingsComponent = /** @class */ (function () {
    function LegalPersonsSettingsComponent(alertService, kybService, modalService, formBuilder) {
        this.alertService = alertService;
        this.kybService = kybService;
        this.modalService = modalService;
        this.formBuilder = formBuilder;
        this.legalPersonTypes = [];
        this.entityTypesResponse = {};
        this.currentPersonType = {};
        this.entityTypeList = [];
        this.legalPersonTypeList = [];
        this.roleTypesResponse = {};
        this.roleTypes = [];
        this.legalPersonTypesResponse = {};
        this.createForm();
    }
    Object.defineProperty(LegalPersonsSettingsComponent.prototype, "links", {
        get: function () {
            return this.requiredLinkForm.get('links');
        },
        enumerable: true,
        configurable: true
    });
    LegalPersonsSettingsComponent.prototype.createForm = function () {
        this.requiredLinkForm = this.formBuilder.group({
            'links': this.formBuilder.array([])
        });
    };
    LegalPersonsSettingsComponent.prototype.ngOnChanges = function () {
        this.rebuildForm();
    };
    LegalPersonsSettingsComponent.prototype.rebuildForm = function () {
        this.requiredLinkForm.reset({});
        this.setStudents(this.requiredLink.links);
    };
    LegalPersonsSettingsComponent.prototype.setStudents = function (students) {
        var studentsFA = this.formBuilder.group(students);
        this.requiredLinkForm.setControl('links', studentsFA);
    };
    LegalPersonsSettingsComponent.prototype.addStudent = function () {
        this.links.push(this.formBuilder.group(new Link(null, null, null)));
    };
    LegalPersonsSettingsComponent.prototype.deleteStudent = function (student) {
        this.links.controls.forEach(function (link, index, object) {
            if (link.value === student) {
                object.splice(index, 1);
            }
        });
        this.links.value.forEach(function (link, index, object) {
            if (link === student) {
                object.splice(index, 1);
            }
        });
        this.currentPersonType.requiredRoles.requiredE1Roles.forEach(function (role, index, object) {
            if (role.roleType.id === student.roleTypeId) {
                object.splice(index, 1);
            }
        });
    };
    LegalPersonsSettingsComponent.prototype.addDefinedStudent = function (requiredE1Roles) {
        var _this = this;
        for (var i = 0; i < requiredE1Roles.length; i++) {
            if (requiredE1Roles[i].roleType.self === true) {
                requiredE1Roles.splice(i, 1);
            }
        }
        this.requiredLinkForm = this.formBuilder.group({
            'links': this.formBuilder.array([])
        });
        requiredE1Roles.forEach(function (role) {
            _this.links.push(_this.formBuilder.group(new Link(role.operator, role.requiredValue, role.roleType.id)));
        });
    };
    LegalPersonsSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.kybService.getEntityTypes().subscribe(function (data) {
            _this.entityTypesResponse = data;
            _this.legalPersonTypes = _this.entityTypesResponse.data;
            for (var i = 0; i < _this.legalPersonTypes.length; i++) {
                if (_this.legalPersonTypes[i].eligibleForMediaScreening === true && _this.legalPersonTypes[i].excludedFromScreening === false) {
                    _this.legalPersonTypes[i].screeningOption = 'Full screening';
                }
                else if (_this.legalPersonTypes[i].eligibleForMediaScreening === false &&
                    _this.legalPersonTypes[i].excludedFromScreening === false) {
                    _this.legalPersonTypes[i].screeningOption = 'PEP and Sanctions only';
                }
                else {
                    _this.legalPersonTypes[i].screeningOption = 'No screening';
                }
                _this.entityTypeList.push(_this.legalPersonTypes[i].entityType);
                if (_this.legalPersonTypes[i].requiredRoles !== null) {
                    for (var j = 0; j < _this.legalPersonTypes[i].requiredRoles.requiredE1Roles.length; j++) {
                        if (_this.legalPersonTypes[i].requiredRoles.requiredE1Roles[j].roleType.self === true) {
                            _this.legalPersonTypes[i].requiredRoles.requiredE1Roles.splice(j, 1);
                        }
                    }
                }
            }
            _this.entityTypeList.push('STATE');
            _this.entityTypeList.push('TREATY');
            _this.entityTypeList.push('GEO');
            _this.entityTypeList.push('GROUP');
            _this.entityTypeList = _this.entityTypeList.filter(function (elem, index, self) {
                return index === self.indexOf(elem);
            });
            _this.initTable();
        }, function (error) { });
        this.kybService.getRoleTypes().subscribe(function (data) {
            _this.roleTypesResponse = data;
            _this.roleTypes = _this.roleTypesResponse.data;
            _this.roleTypes = _this.roleTypes.filter(function (x) { return x.self !== true; });
        }, function (error) {
        });
    };
    LegalPersonsSettingsComponent.prototype.initTable = function () {
        var table = $('.table');
        this.dataTable = table.DataTable({
            'oLanguage': { 'sEmptyTable': 'No legal person types found.' },
            'orderCellsTop': true,
            'columnDefs': [
                { 'orderable': false, 'targets': 0 },
                { 'orderable': false, 'targets': 1 },
                { 'orderable': false, 'targets': 2 },
                { 'orderable': false, 'targets': 3 },
                { 'orderable': false, 'targets': 4 }
            ],
            'order': [],
            'paging': false,
            'retrieve': true,
            'lengthChange': false,
            'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
            'dom': "<'top'l<'clear'>>rt<'bottom'<'clear'>>",
            'select': { style: 'os' }
        });
        if (this.legalPersonTypes.length > 0) {
            var empty = (document.getElementsByClassName('dataTables_empty'));
            empty[0].setAttribute('style', 'display: none;');
        }
    };
    LegalPersonsSettingsComponent.prototype.edit = function (editTemplate, legalPersonType) {
        var _this = this;
        this.kybService.getRoleTypes().subscribe(function (data) {
            _this.roleTypesResponse = data;
            _this.roleTypes = _this.roleTypesResponse.data;
            _this.roleTypes = _this.roleTypes.filter(function (x) { return x.self !== true; });
        }, function (error) {
        });
        this.currentPersonType = legalPersonType;
        if (this.currentPersonType && this.currentPersonType.requiredRoles) {
            this.addDefinedStudent(this.currentPersonType.requiredRoles.requiredE1Roles);
        }
        if (this.currentPersonType.eligibleForMediaScreening === true && this.currentPersonType.excludedFromScreening === false) {
            this.currentPersonType.screeningOption = 'fullScreening';
        }
        else if (this.currentPersonType.eligibleForMediaScreening === false && this.currentPersonType.excludedFromScreening === false) {
            this.currentPersonType.screeningOption = 'pepAndSanctionsOnly';
        }
        else {
            this.currentPersonType.screeningOption = 'noScreening';
        }
        this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
    };
    LegalPersonsSettingsComponent.prototype.save = function () {
        var _this = this;
        if (this.currentPersonType.screeningOption === 'fullScreening') {
            this.currentPersonType.eligibleForMediaScreening = true;
            this.currentPersonType.excludedFromScreening = false;
        }
        else if (this.currentPersonType.screeningOption === 'pepAndSanctionsOnly') {
            this.currentPersonType.eligibleForMediaScreening = false;
            this.currentPersonType.excludedFromScreening = false;
        }
        else {
            this.currentPersonType.eligibleForMediaScreening = false;
            this.currentPersonType.excludedFromScreening = true;
        }
        this.currentPersonType.entityType = this.currentPersonType.entityType.toUpperCase();
        this.currentPersonType.requiredRolesRequest = {};
        this.currentPersonType.requiredRolesRequest.requiredRoles = this.requiredLinkForm.value.links;
        this.currentPersonType.requiredRolesRequest.id = this.currentPersonType.requiredRoles.id;
        // Legal person type is old version of legal form and needs to be remapped while sending
        this.currentPersonType.legalForm = this.currentPersonType.legalPersonType;
        this.kybService.updateEntityType(this.currentPersonType).subscribe(function (data) {
            _this.editModalRef.hide();
            _this.alertService.showSuccess('Type edited successfully.');
            _this.currentPersonType = {};
            _this.ngOnInit();
        }, function (error) {
            _this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
        });
    };
    LegalPersonsSettingsComponent.prototype.saveEdit = function () {
        this.save();
    };
    LegalPersonsSettingsComponent.prototype.addNew = function (addTemplate) {
        var _this = this;
        this.currentPersonType = {};
        this.links.clear();
        this.kybService.getRoleTypes().subscribe(function (data) {
            _this.roleTypesResponse = data;
            _this.roleTypes = _this.roleTypesResponse.data;
            _this.roleTypes = _this.roleTypes.filter(function (x) { return x.self !== true; });
        }, function (error) {
        });
        this.addModalRef = this.modalService.show(addTemplate, { backdrop: 'static' });
    };
    LegalPersonsSettingsComponent.prototype.add = function () {
        var _this = this;
        if (this.currentPersonType.screeningOption === 'fullScreening') {
            this.currentPersonType.eligibleForMediaScreening = true;
            this.currentPersonType.excludedFromScreening = false;
        }
        else if (this.currentPersonType.screeningOption === 'pepAndSanctionsOnly') {
            this.currentPersonType.eligibleForMediaScreening = false;
            this.currentPersonType.excludedFromScreening = false;
        }
        else {
            this.currentPersonType.eligibleForMediaScreening = false;
            this.currentPersonType.excludedFromScreening = true;
        }
        this.currentPersonType.entityDetailForm = '';
        this.currentPersonType.entityType = this.currentPersonType.entityType.toUpperCase();
        // this.currentPersonType.legalPersonType = this.currentPersonType.legalPersonType.toUpperCase();
        this.currentPersonType.requiredRolesRequest = {};
        this.currentPersonType.requiredRolesRequest.requiredRoles = this.requiredLinkForm.value.links;
        this.kybService.createEntityType(this.currentPersonType).subscribe(function (data) {
            _this.addModalRef.hide();
            _this.alertService.showSuccess('Type added successfully.');
            _this.currentPersonType = {};
            _this.ngOnInit();
        }, function (error) {
            _this.alertService.showError('Something went wrong. Make sure all the required fields are filled in.');
        });
    };
    return LegalPersonsSettingsComponent;
}());
export { LegalPersonsSettingsComponent };
var RequiredLink = /** @class */ (function () {
    function RequiredLink() {
    }
    return RequiredLink;
}());
export { RequiredLink };
