<section>
  <div *ngIf="isveryfing" class="verify-loader">
    <div class="lds-ripple">
      <div></div>
      <div></div>
    </div> <br />
    <p>Please wait</p>
  </div>
  <div *ngIf="documents.length && !isveryfing" class="documents-wrapper">
    <div class="document-list">
      <p>Please provide the following information:</p>
      <table class="table">
        <thead>
          <tr>
            <td>Name</td>
            <td>Comment</td>
            <td>Action</td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let e of documents">
            <td> {{getEntity(e.entityId)}} </td>
            <td> {{e.doc?.description}} </td>
            <td>
              <form>
                <label> Select file: <b class="required">*</b></label>
                <input class="form-control input-md" type="file"
                  (change)="processDocument($event.target.files,'file1')">
                <button class="submit document" (click)="submitFile(e.entityId, e.role.roleId, e.docId)">Submit
                  document</button>
              </form>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="!documents.length" class="no-document-wrapper">
    <p>There are no more documents to be added</p>
  </div>
</section>
