<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
   <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
    <div class="inner" *ngFor="let control of formData?.controls">
      <label *ngIf="control.label !== ''">{{ control.label }}:&nbsp;&nbsp;&nbsp;</label>
      <input
        *ngIf="
          [
            'text',
            'password',
            'email',
            'number',
            'search',
            'tel',
            'url'
          ].includes(control.type)
        "
        [type]="control.type"
        [formControlName]="control.name"
        [value]="control.value"
        style="width: 60%"
      />
      <textarea
        *ngIf="control.type === 'textarea'"
        [formControlName]="control.name"
        [value]="control.value"
        style="width: 60%"
      ></textarea>
    </div>
    <div style="height: 10px;"></div>
    <button *ngIf="mode === 'edit-document'" class="btn btn-success" type="submit" [disabled]="!myForm.valid || loading">Save Document</button>
    <button *ngIf="mode === 'edit-geo-entity'" class="btn btn-success" type="submit" [disabled]="!myForm.valid || loading">Save Geo Entity</button>
  </form>
</div>

