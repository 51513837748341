import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { config } from '../../../../assets/configuration';

/**
* Implements password changing directive
*/
@Component({
    moduleId: module.id,
    selector: 'app-pep-sanction-screener',
    templateUrl: 'pepSanctionScreenerSettings.component.html',
    styleUrls: [ './pepSanctionScreenerSettings.component.css' ]
})

export class PepSanctionScreenerSettingsComponent implements OnInit {

  oldPassword = '';
  newPassword = '';
  mainColor: any = config.mainColor;
  periodicity: any;
  weekend: any = false;
  maxAgeOfRecordToBeConsideredValidInMonths: any = 0;
  nationalityMatch: any = false;
  massScreening: any = false;
  confidence: any = 0.0;
  sensitivity: any = 0.0;
  screenerConfig: any = {};
  mediaConfig: any = {};
  msinclusiveness: any;
  seinclusiveness: any;
  tags: any = [];

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private kybService: KYBService
  ) {}

  ngOnInit() {
    this.kybService.getRemoteBatch().subscribe(
      data => {
        this.screenerConfig = data;
      },
      error => {}
    );

    this.kybService.getMediaScreeningSetings().subscribe(
      data => {
        this.mediaConfig = data;
      },
      error => {
      }
    );
  }

  saveSettings() {
    this.maxAgeOfRecordToBeConsideredValidInMonths = 24;
    this.mediaConfig.mediaThreshold = 0;
    // this.kybService.updateMediaScreeningSettings(this.mediaConfig).subscribe(
    //   data => {
      // tslint:disable-next-line:max-line-length
      this.kybService.remoteBatch(this.periodicity, this.weekend, this.maxAgeOfRecordToBeConsideredValidInMonths, this.confidence, this.massScreening, this.screenerConfig.stopWords).subscribe(
        data => {
          this.alertService.showSuccess('Settings saved successfully.');
          this.ngOnInit();
          this.kybService.addScreeningPlan().subscribe(
            screeningPlan => {
              this.kybService.addScreeningJob().subscribe(
                screeningJob => {
                },
                error => {
                  this.alertService.showError('Something went wrong while creating screening job.');
                }
              );
            },
            error => {
              this.alertService.showError('Something went wrong while creating screening plan.');
            }
          );
        },
        error => {
          error.error.fieldErrors.forEach(fieldError => {
            if (fieldError.field === 'confidenceThreshold') {
              this.alertService.showError('Threshold value should be greater than or equal to 70.');
            } else {
              this.alertService.showError('Something went wrong.');
            }
          });
        }
      );
      // },
      // error => {

      // }
    // );
  }
}
