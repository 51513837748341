/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./documentManager.component";
import * as i5 from "../../../_services/calls.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../_services/alert.service";
import * as i8 from "../../../_services/data.service";
import * as i9 from "../../../_services/kyb.service";
import * as i10 from "./documentManager.service";
var styles_DocumentManagerComponent = [i0.styles];
var RenderType_DocumentManagerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DocumentManagerComponent, data: {} });
export { RenderType_DocumentManagerComponent as RenderType_DocumentManagerComponent };
function View_DocumentManagerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "verify-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "lds-ripple"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please wait"]))], null, null); }
function View_DocumentManagerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 12, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 8).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(8, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(10, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 3, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Select file: "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "b", [["class", "required"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["*"])), (_l()(), i1.ɵeld(15, 0, null, null, 0, "input", [["class", "form-control input-md"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processDocument($event.target.files, "file1") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "button", [["class", "submit document"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitFile(_v.context.$implicit.entityId, _v.context.$implicit.role.roleId, _v.context.$implicit.docId) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Submit document"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getEntity(_v.context.$implicit.entityId); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.$implicit.doc == null) ? null : _v.context.$implicit.doc.description); _ck(_v, 4, 0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_3 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_5 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_6 = i1.ɵnov(_v, 10).ngClassValid; var currVal_7 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_8 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_DocumentManagerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "documents-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "div", [["class", "document-list"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please provide the following information:"])), (_l()(), i1.ɵeld(4, 0, null, null, 11, "table", [["class", "table"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Name"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Comment"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Action"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentManagerComponent_3)), i1.ɵdid(15, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.documents; _ck(_v, 15, 0, currVal_0); }, null); }
function View_DocumentManagerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "no-document-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["There are no more documents to be added"]))], null, null); }
export function View_DocumentManagerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "section", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentManagerComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentManagerComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DocumentManagerComponent_4)), i1.ɵdid(6, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isveryfing; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.documents.length && !_co.isveryfing); _ck(_v, 4, 0, currVal_1); var currVal_2 = !_co.documents.length; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_DocumentManagerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "document-manager", [], null, null, null, View_DocumentManagerComponent_0, RenderType_DocumentManagerComponent)), i1.ɵdid(1, 114688, null, 0, i4.DocumentManagerComponent, [i5.CallsService, i6.ActivatedRoute, i7.AlertService, i8.DataService, i9.KYBService, i6.Router, i10.DocumentManagerService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DocumentManagerComponentNgFactory = i1.ɵccf("document-manager", i4.DocumentManagerComponent, View_DocumentManagerComponent_Host_0, {}, {}, []);
export { DocumentManagerComponentNgFactory as DocumentManagerComponentNgFactory };
