<div class="col-md-12">
    <div style="height: 20px"></div>
    <!-- <button class="bbtn btn-success" (click)="addNew(addTemplate)" style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button> -->
    <h3 class="text-center">Screening Risk Settings</h3><br>
    <div style="height: 15px"></div>
    <table class="table" width="100%" style="text-align: left;">
        <thead>
            <tr>
                <th>Risk Type</th>
                <th>Risk Factor</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let screeningRisk of screeningRisks">
                <td>{{screeningRisk.riskType}}</td>
                <td>{{screeningRisk.riskFactor}}</td>
                <td><button class="btn btn-warning" style="padding: 6px;" (click)="edit(editTemplate,screeningRisk)">Edit &nbsp;<i class="fa fa-pencil" aria-hidden="true"></i></button></td>
            </tr>
        </tbody>
    </table>  
</div>

<ng-template #editTemplate>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Adjust Risk Factor</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        <label>
            Risk Factor:
        </label><br>
        <div style="height: 10px;"></div>
        <input type="number" min="0" [(ngModel)]="currentTopic.riskFactor" />
        <div style="height: 10px;"></div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-success" (click)="save()">Save</button>
    </div>
</ng-template>


<!-- <ng-template #addTemplate>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Add Legal Person Type</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <label>
                Type:
            </label><br>
            <div style="height: 10px;"></div>
            <select [(ngModel)]="currentPersonType.entityType" name="entityType">
                <option value=""></option>
                <option *ngFor="let entityType of entityTypeList" [value]="entityType">
                    {{entityType}}
                </option>
            </select>
            <div style="height: 10px;"></div>
            <label>
                Legal Form:
            </label><br>
            <div style="height: 10px;"></div>
            <select [(ngModel)]="currentPersonType.legalPersonType" name="entityType">
                <option value=""></option>
                <option *ngFor="let legalPersonType of legalPersonTypeList" [value]="legalPersonType">
                    {{legalPersonType}}
                </option>
            </select>
            <div style="height: 10px;"></div>
            <label>
                Required Links:
            </label><br>
            <div style="height: 10px;"></div>
            <input type="text" [(ngModel)]="currentPersonType.autoLinkedEntities" />
            <div style="height: 10px;"></div>
            <label>
                Media Screening:
            </label><br>
            <div style="height: 10px;"></div>
            <input type="checkbox" [(ngModel)]="currentPersonType.eligibleForMediaScreening" />
            <div style="height: 10px;"></div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success" (click)="add()">Add</button>
        </div>
</ng-template> -->