<div class="modal-header">
    <span style="color: white;" (click)="cancel()" class="glyphicon glyphicon-arrow-left"></span>
</div>
<div class="modal-body">
    <div *ngIf="permissionDenied">
        <h4 style="color: white; text-align: center;">{{textfromerror}}</h4>
    </div>

    <div *ngIf="!permissionDenied">

    <div class="words" style="display: none;"></div>
    <div  class="overlay-desc" style="color: black; position: relative;">
        <video class="videoRecord" #videoElement autoplay muted playsinline style="transform: rotateY(180deg);
-webkit-transform:rotateY(180deg); /* Safari and Chrome */
-moz-transform:rotateY(180deg); /* Firefox */"></video>

    </div>
        
    </div>
<!-- <button  type="button" *ngIf="isPlaying && !isVideoRecording" class="button" (click)="recordVideo()"></button> -->
<div style="text-align: center;position: relative; font-size: 16px;color: white;" *ngIf="isVideoRecording">{{"VIDEO_RECORDING" | translate}} <b class="text-danger Blink">&#11044;</b>
</div>
</div>