import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'phoneAssessment'})
export class PhoneAssessmentPipe implements PipeTransform {
  transform(value: any): string {
    const json = JSON.parse(value);

    value = '<b>country_code: </b>' + json['country_code'] + '</br>';
    value += '<b>country_name: </b>' + json['country_name'] + '</br>';
    value += '<b>international_format_number: </b>' + json['international_format_number'] + '</br>';
    value += '<b>lookup_outcome_message: </b>' + json['lookup_outcome_message'] + '</br>';
    value += '<b>national_format_number: </b>' + json['national_format_number'] + '</br>';
    value += '<b>reachable: </b>' + json['reachable'] + '</br>';
    value += '<b>status_message: </b>' + json['status_message'] + '</br>';
    value += '<b>valid_number: </b>' + json['valid_number'] + '</br>';

    return value;
  }
}
