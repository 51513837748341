<form #personalForm="ngForm" class="editForm" novalidate>
    <div class="tab-pane fade in active">
        <div class='row'>
            <div class='col-sm-12'> I am creating a <select [(ngModel)]="entityTypeGeneral"
                    (change)="generalTypeChanged()" name="entityTypeGeneral">
                    <option value="PERSON">person</option>
                    <option value="LEGAL">legal</option>
                    <option value="GROUP">group</option>
                    <option value="STATE">state</option>
                    <option value="TREATY">treaty</option>
                    <!-- <option value="GEO">geo</option> -->
                </select>
                <br>
                <br>
                <ng-container *ngIf="entityTypeGeneral === '' || entityTypeGeneral === 'PERSON' || entityTypeGeneral === 'GROUP' || entityTypeGeneral === 'STATE' || entityTypeGeneral === 'TREATY' || entityTypeGeneral === 'GEO'">
                    <div *ngIf="entityTypeGeneral == '' || entityTypeGeneral == 'PERSON' || entityTypeGeneral == 'GEO'">
                        <label>First Name: <b class="required">*</b></label>
                        <br>
                        <input name="primaryName" placeholder="First name" type="text" [(ngModel)]="primaryName">
                        <div class="alert alert-danger" [hidden]="primaryName">First Name is required.</div>
                        <br>
                        <br *ngIf="primaryName">
                    </div>
                    <div *ngIf="entityTypeGeneral =='GROUP' || entityTypeGeneral == 'STATE' || entityTypeGeneral == 'TREATY'">
                        <label>Name: <b class="required">*</b></label>
                        <br>
                        <input name="primaryName" placeholder="Name" type="text" [(ngModel)]="primaryName">
                        <div class="alert alert-danger" [hidden]="primaryName">Name is required.</div>
                        <br>
                        <br *ngIf="primaryName">
                    </div>

                    <label *ngIf="enabledSecoundaryName">Second Name: <b class="required">*</b></label>
                    <br *ngIf="enabledSecoundaryName">
                    <input *ngIf="enabledSecoundaryName" name="secondaryName" placeholder="Last name" type="text" [(ngModel)]="secondaryName">
                    <div *ngIf="enabledSecoundaryName" class="alert alert-danger" [hidden]="secondaryName">Second Name is required.</div>
                    <br *ngIf="enabledSecoundaryName">
                </ng-container>

                <ng-container *ngIf="entityTypeGeneral == 'LEGAL'">
                    <label>Company Name: <b class="required">*</b></label>
                    <br>
                    <input name="primaryName" placeholder="Company name" type="text" [(ngModel)]="primaryName">
                    <div class="alert alert-danger" [hidden]="primaryName">Company Name is required.</div>
                    <br *ngIf="primaryName">
                    <br>
                    <label>Company Abbreviations: <b class="required">*</b></label>
                    <br>
                    <input name="secondaryName" placeholder="Company abbreviations" type="text" [(ngModel)]="secondaryName">
                    <div class="alert alert-danger" [hidden]="secondaryName">Company Abbreviations is required.</div>
                    <br>
                </ng-container>
                
                <br /> with the type <select [(ngModel)]="entityType" (change)="typeChanged()" name="entityType">
                    <option *ngFor="let entityType of filteredEntityTypes" [value]="entityType.id">
                        {{entityType.legalPersonType}} </option>
                </select>
                <div *ngIf="entityTypeGeneral == 'PERSON' && (addEntityPhoneNumber || addEntityEmail )">
                    <br>
                    <ng-container *ngIf="addEntityPhoneNumber">
                        <label> Phone number: <b class="required">*</b> </label><br><input type="text" placeholder="Phone number" [(ngModel)]="personPhone" name="personPhone">&#160;&#160; 
                        <div class="alert alert-danger" [hidden]="personPhone">Phone number is required.</div>
                    </ng-container>
                    <div style="height: 10px;"></div>
                    <ng-container *ngIf="addEntityEmail">
                        <label> Email: <b class="required">*</b> </label><br><input type="text" placeholder="Email" [(ngModel)]="personEmail" name="personEmail">&#160;&#160; 
                        <div class="alert alert-danger" [hidden]="personEmail">Email is required.</div>
                    </ng-container>
                    <!-- <label> Date of birth (DD/MM/YYYY): <input class="form-control input-md"
                        #datepickerYMD="bsDatepicker" bsDatepicker [(ngModel)]="dateOfBirth"
                        [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
                  </label>&#160;&#160; <label> Address: <input class="form-control input-md"
                        type="text" [style.width]="'421px'" [(ngModel)]="address">
                  </label>&#160;&#160; <div style="height: 10px;"></div>
                  <label> Nationality: 
                     <ng-select id="nationality" name="nationality"
                        style="border: 1px solid #ccc;border-radius: 0px;width: 110%" [items]="nationalities"
                        #nationalityy="ngModel" [searchable]="true" [multiple]="true" [(ngModel)]="nationality"
                        appendTo="body" bindLabel="label" bindValue="value" placeholder="Start typing a nationality..."
                        (change)=isNationalityFilled()>
                     </ng-select>
                  </label>&#160;&#160; <div style="height: 10px;"></div>
                  <label> Email: <input class="form-control input-md" type="text"
                        [(ngModel)]="personEmail"></label>&#160;&#160; -->
                        <div style="height: 20px;"></div>
                </div>
                <div style="height: 10px;"></div> The entity has the following tags: <ng-select
                    class="custom" [items]="tags" [multiple]="true" [addTag]="addTagFn" (add)="createTag($event)"
                    bindLabel="name" [hideSelected]="true" [clearable]="false" (change)="tagsChanged($event)"
                    [(ngModel)]="selectedTags" appendTo="body" [ngModelOptions]="{standalone: true}">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                            [ngModelOptions]="{standalone: true}" /> {{item.name}}
                    </ng-template>
                </ng-select>
                <!-- <div class="row">-->
                <app-person-form *ngIf="isPerson" [events]="eventsSubject.asObservable()"></app-person-form>
                <app-company-form *ngIf="isCompany" [events]="eventsSubject.asObservable()"></app-company-form>
                <!--</div>-->
                <!--<div style="height: 20px;"></div> -->
                <!-- <div class="row">
                    <button *ngIf="isCompany" (click)="addRole()">Add roles for this entity</button>
                    <button>Delegate</button>
                </div> -->
                <div style="height: 30px"></div>
                <button [disabled]="(entityTypeGeneral === 'PERSON' && (!primaryName || !secondaryName || !personPhone || !personEmail)) || (entityTypeGeneral !== 'PERSON' && (!primaryName ||  ( !secondaryName && enabledSecoundaryName )))" class="btn btn-primary" style="background-color: #355B89;border-color: #355B89"
                    (click)="saveEntity()">Save Entity</button>
            </div>
        </div>
    </div>
</form>
