import { OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { CallsService, KYBService, DataService } from '../../../../_services';
import * as $ from 'jquery';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormDataService } from '../../data/formData.service';
import { config } from '../../../../../assets/configuration';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoRecordingService } from '../../../../_services/video-recording.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
/**
 * Implements the login page
*/
var MobileVideoOnboardingRecordingComponent = /** @class */ (function () {
    function MobileVideoOnboardingRecordingComponent(translate, options, bsModalRef, modalService, deviceDetectorService, ref, videoRecordingService, sanitizer, kybService, callsService, dataService, formDataService) {
        var _this = this;
        this.translate = translate;
        this.options = options;
        this.bsModalRef = bsModalRef;
        this.modalService = modalService;
        this.deviceDetectorService = deviceDetectorService;
        this.ref = ref;
        this.videoRecordingService = videoRecordingService;
        this.sanitizer = sanitizer;
        this.kybService = kybService;
        this.callsService = callsService;
        this.dataService = dataService;
        this.formDataService = formDataService;
        this.event = new EventEmitter();
        this.textfromerror = '';
        this.title = 'Video Recording';
        this.wordsResponse = {};
        this.words = [];
        this.mimeTypeForSaving = '';
        this.loading = false;
        this.email = '';
        this.loadingImg = config.loadingImg;
        this.CAMERA_MIC_ACCESS_BLOCKED = '';
        // isPlaying = false;
        this.displayControls = true;
        this.isAudioRecording = false;
        this.isVideoRecording = false;
        this.permissionDenied = false;
        this.videoConfIOS = {
            video: {
                facingMode: 'user',
                height: {
                    min: window.innerWidth * 0.8 <= 240 ? window.innerWidth * 0.8 : 240,
                    ideal: window.innerWidth * 0.8 <= 240 ? window.innerWidth * 0.8 : 240,
                    max: window.innerWidth * 0.8 <= 240 ? window.innerWidth * 0.8 : 240
                }
            },
            audio: true,
            frameRate: {
                min: 15,
                ideal: 60,
                max: 120
            }
        };
        this.videoConfAndroid = {
            video: {
                facingMode: 'user',
                height: {
                    ideal: 623,
                }
            },
            audio: true,
            frameRate: {
                min: 15,
                ideal: 60,
                max: 120
            }
        };
        this.videoConf = {
            video: {
                facingMode: 'user',
                height: {
                    ideal: 623
                }
            },
            audio: true,
            frameRate: {
                min: 15,
                ideal: 60,
                max: 120
            }
        };
        this.createEntityResponse = {};
        this.roleResponse = {};
        this.getRoleResponse = {};
        this.additionalDocumentIds = [];
        this.requiredDocumentIds = [];
        this.requiredDocumentTypes = [];
        this.documentIds = [];
        this.videoAnalysisResponse = {};
        this.documentData = {};
        this.documentResponse = {};
        this.recordedBlobs = [];
        // window.alert('Inner width is: ' + window.innerWidth + 'Inner height is: ' + window.innerHeight);
        // window.alert('Screen width is: ' + window.screen.width + 'Screen height is: ' + window.screen.height);
        // window.alert('Screen availWidth is: ' + window.screen.availWidth + 'Screen availHeight is: ' + window.screen.availHeight);
        if (this.ios) {
            this.videoConf = this.videoConfIOS;
        }
        this.videoRecordingService.recordingFailed().subscribe(function (stream) {
            _this.isVideoRecording = false;
            if (stream === 'Permission denied') {
                _this.permissionDenied = true;
            }
            else {
                _this.permissionDenied = true;
                _this.textfromerror = stream;
            }
            _this.ref.detectChanges();
        });
        this.videoRecordingService.getRecordedTime().subscribe(function (time) {
            _this.videoRecordedTime = time;
            _this.ref.detectChanges();
        });
        this.videoRecordingService.getStream().subscribe(function (stream) {
            _this.videoStream = stream;
            _this.ref.detectChanges();
        });
        this.videoRecordingService.getRecordedBlob().subscribe(function (data) {
            _this.videoBlob = data.blob;
            _this.videoName = data.title;
            _this.videoBlobUrl = window.URL.createObjectURL(data.blob);
            var x = options.initialState;
            _this.words = x.words;
            _this.ref.detectChanges();
            _this.event.emit({ data: data });
            _this.bsModalRef.hide();
        });
    }
    Object.defineProperty(MobileVideoOnboardingRecordingComponent.prototype, "ios", {
        get: function () { return !!this.agent.match(/(iPhone)|(iPod)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingRecordingComponent.prototype, "agent", {
        get: function () { return navigator.userAgent || navigator.vendor; },
        enumerable: true,
        configurable: true
    });
    MobileVideoOnboardingRecordingComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.ios) {
            this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_IOS');
        }
        else {
            this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_ANDROID');
        }
        // this.start();
        // TODO: Disable Finish button
        this.dataService.stack.subscribe(function (message) { return _this.currentStack = message; });
        this.dataService.currentDocumentIds.subscribe(function (message) { return _this.documentIds = message; });
        this.email = localStorage.getItem('registrationEmail');
        this.personal = this.formDataService.getPersonal();
        this.selfRoleId = this.dataService.onboardingEntitySelfRoleId.getValue();
        this.onboardingEntityId = this.dataService.onboardingEntityId.getValue();
        var self = this;
        setTimeout(function () {
            self.startVideo();
        }, 1000);
    };
    MobileVideoOnboardingRecordingComponent.prototype.cancel = function () {
        this.bsModalRef.hide();
    };
    MobileVideoOnboardingRecordingComponent.prototype.recordVideo = function () {
        this.isVideoRecording = true;
        var self = this;
        var textOverlay = document.getElementById('overlay-text');
        if (this.isVideoRecording) {
            /*   setTimeout(() => {
                $('.overlay-desc').append('<div id='ovals' class='oval' style='position: absolute;z-index: 3;'></div>');
                // new Audio('../../../../assets/beep33.wav').play();
      
                textOverlay.innerHTML = '';
              }, 1000); */
            textOverlay.setAttribute('style', 'z-index: 2;position: absolute;color: white;');
            setTimeout(function () {
                textOverlay.innerHTML = '3';
            }, 1000);
            setTimeout(function () {
                textOverlay.innerHTML = '2';
            }, 2000);
            setTimeout(function () {
                textOverlay.innerHTML = '1';
            }, 3000);
            setTimeout(function () {
                var beep = new Audio('../../../../assets/beep33.wav');
                beep.volume = 0.2;
                beep.play();
                textOverlay.innerHTML = '';
                // textOverlay.innerHTML = self.translate.instant('GO');
            }, 4000);
            setTimeout(function () {
                textOverlay.innerHTML = '';
                document.getElementById('ovals').remove();
                textOverlay.setAttribute('style', 'z-index: 2;position: absolute;color: white;');
                self.videoRecordingService.record();
            }, 5000);
            setTimeout(function () {
                textOverlay.innerHTML = "<span style='font-weight: bold; font-size: 25px;'>" + self.words[0].toUpperCase() + '</span>';
            }, 6000);
            setTimeout(function () {
                textOverlay.innerHTML = '';
            }, 8500);
            setTimeout(function () {
                textOverlay.innerHTML = "<span style='font-weight: bold; font-size: 25px;'>" + self.words[1].toUpperCase() + '</span>';
            }, 9000);
            setTimeout(function () {
                textOverlay.innerHTML = '';
            }, 11500);
            setTimeout(function () {
                textOverlay.innerHTML = "<span style='font-weight: bold; font-size: 25px;'>" + self.words[2].toUpperCase() + '</span>';
                // self.snapshot()
            }, 12000);
            setTimeout(function () {
                textOverlay.innerHTML = '';
            }, 14500);
            setTimeout(function () {
                textOverlay.innerHTML = "<span style='font-weight: bold; font-size: 25px;'>" + self.words[3].toUpperCase() + '</span>';
            }, 15000);
            setTimeout(function () {
                textOverlay.innerHTML = '';
            }, 17500);
            setTimeout(function () {
                textOverlay.innerHTML = "<span style='font-weight: bold; font-size: 25px;'>" + self.words[4].toUpperCase() + '</span>';
            }, 18000);
            setTimeout(function () {
                textOverlay.innerHTML = '';
            }, 20500);
            setTimeout(function () {
                self.stopVideoRecording();
            }, 22500);
            setTimeout(function () {
                // tslint:disable-next-line:max-line-length
                $('.overlay-desc').append("<div id='thank-you' style='position: absolute;z-index: 3;color: white;font-size: 24px;'>" + self.translate.instant('THANK_YOU') + '!<br>');
            }, 23500);
        }
    };
    MobileVideoOnboardingRecordingComponent.prototype.startVideo = function () {
        var _this = this;
        this.video = this.videoElement.nativeElement;
        this.videoRecordingService.startRecording(this.videoConf)
            .then(function (stream) {
            // this.video.src = window.URL.createObjectURL(stream);
            _this.video.srcObject = stream;
            var self = _this;
            _this.video.play();
            _this.video.muted = true;
            setTimeout(function () {
                $('.overlay-desc').append("<div id='overlay-text' style='z-index: 2;position: absolute;color: white;width: 50%;text-align: center;'></div>");
                var textOverlay = document.getElementById('overlay-text');
                textOverlay.setAttribute('style', 'position: absolute;color: white;font-size: 12px;line-height: 1;font-weight: bold; text-align: center;width: 80%');
                $('.overlay-desc').append("<div id='ovals' class='oval' style='position: absolute;z-index: 3;'></div>");
                // self.isPlaying = true;
                if (!self.permissionDenied) {
                    self.recordVideo();
                }
            }, 500);
        }).catch(function (err0r) {
        });
    };
    MobileVideoOnboardingRecordingComponent.prototype.stopVideoRecording = function () {
        if (this.isVideoRecording) {
            this.videoRecordingService.stopRecording();
            this.isVideoRecording = false;
            this.video.controls = true;
        }
    };
    MobileVideoOnboardingRecordingComponent.prototype.checkWordLength = function (word) {
        if (word.length > 7) {
            return true;
        }
        return false;
    };
    MobileVideoOnboardingRecordingComponent.prototype.handleError = function (error) {
        if (error.name === 'ConstraintNotSatisfiedError') {
            var v = true;
            this.errorMsg('The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.', error);
        }
        else if (error.name === 'PermissionDeniedError') {
            this.errorMsg('Permissions have not been granted to use your camera and ' +
                'microphone, you need to allow the page access to your devices in ' +
                'order for the demo to work.', error);
        }
        this.errorMsg("getUserMedia error: " + error.name, error);
    };
    MobileVideoOnboardingRecordingComponent.prototype.errorMsg = function (msg, error) {
        // const errorElement = document.querySelector('#errorMsg');
        // errorElement.innerHTML += `<p>${msg}</p>`;
        console.error(error);
        console.error(msg);
        if (typeof error !== 'undefined') {
            console.error(error);
        }
    };
    return MobileVideoOnboardingRecordingComponent;
}());
export { MobileVideoOnboardingRecordingComponent };
