<div class="col-md-12">
    <tabset type="pills" #staticTabs class="main-topic-tabs">
        <tab heading="Legal Persons">
            <div class="row">
                <app-legal-persons-settings *ngIf="staticTabs.tabs[0]?.active"></app-legal-persons-settings>
            </div>
        </tab>
        <tab heading="Legal Roles">
            <div class="row">
                <app-legal-roles-settings *ngIf="staticTabs.tabs[1]?.active"></app-legal-roles-settings>
            </div>
        </tab>
        <!-- <tab heading="Legal Person Types">
            <div class="row">
                <app-legal-person-types-settings></app-legal-person-types-settings>
            </div>
        </tab> -->
        <tab heading="Documents">
            <div class="row">
                <app-documents-settings *ngIf="staticTabs.tabs[2]?.active"></app-documents-settings>
            </div>
        </tab>
        <tab heading="GEO Entities">
            <div class="row">
                <app-geo-entities-settings *ngIf="staticTabs.tabs[3]?.active"></app-geo-entities-settings>
            </div>
        </tab>
    </tabset>
</div>
