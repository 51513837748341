/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./piechart.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./piechart.component";
import * as i3 from "../../../../_services/webData.service";
var styles_PiechartComponent = [i0.styles];
var RenderType_PiechartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PiechartComponent, data: {} });
export { RenderType_PiechartComponent as RenderType_PiechartComponent };
export function View_PiechartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { pieChart: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["pieChart", 1]], null, 0, "div", [], null, null, null, null, null))], null, null); }
export function View_PiechartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "piechart", [], null, null, null, View_PiechartComponent_0, RenderType_PiechartComponent)), i1.ɵdid(1, 245760, null, 0, i2.PiechartComponent, [i3.WebDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PiechartComponentNgFactory = i1.ɵccf("piechart", i2.PiechartComponent, View_PiechartComponent_Host_0, { events: "events" }, {}, []);
export { PiechartComponentNgFactory as PiechartComponentNgFactory };
