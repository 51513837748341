<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
    <form [formGroup]="myForm" (ngSubmit)="onSubmit()">
        <div class="row">
            <div class="inner">
                <label>Primary name:</label>
                <input style="width: 55%;" formControlName="primaryName">
            </div>
            <div class="inner">
                <label>Secondary name:</label>
                <input style="width: 55%;" formControlName="secondaryName">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType === 'PERSON'" class="inner">
                <label>Phone:</label>
                <input style="width: 55%;" formControlName="phoneNumber">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType === 'PERSON'" class="inner">
                <label>Email:</label>
                <input style="width: 55%;" formControlName="email">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType === 'PERSON' && this.basicInformation && this.basicInformation.dateOfBirth" class="inner">
                <label> Date of birth (DD/MM/YYYY): <b class="required">*</b></label>
                <input style="width: 55%;" class="form-control input-md"
                    #datepickerYMD="bsDatepicker" bsDatepicker formControlName="dateOfBirth"
                    [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType === 'PERSON' && this.basicInformation && this.basicInformation.nationality" class="inner">
                <label>Nationality:</label>
                <ng-select style="width: 55%;" formControlName="nationality" [items]="nationalities" appendTo="body"
                    [searchable]="true" [multiple]="true" bindLabel="label" bindValue="value"
                    placeholder="Start typing a nationality...">
                 </ng-select>
            </div>
            <div *ngIf="entityResponseData.entityType.entityType === 'PERSON' && this.basicInformation && this.basicInformation.address" class="inner">
                <label>Address:</label>
                <input style="width: 55%;" formControlName="address">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType !== 'PERSON' && this.basicInformation && this.basicInformation.address" class="inner">
                <label>Address:</label>
                <input style="width: 55%;" formControlName="address">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType !== 'PERSON' && this.basicInformation && this.basicInformation.contactEmail" class="inner">
                <label>Contact Email:</label>
                <input style="width: 55%;" formControlName="contactEmail">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType !== 'PERSON' && this.basicInformation && this.basicInformation.contactName" class="inner">
                <label>Contact Name:</label>
                <input style="width: 55%;" formControlName="contactName">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType !== 'PERSON' && this.basicInformation && this.basicInformation.contactPhone" class="inner">
                <label>Contact Phone:</label>
                <input style="width: 55%;" formControlName="contactPhone">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType !== 'PERSON' && this.basicInformation && this.basicInformation.taxCountry" class="inner">
                <label>Tax Country</label>
                <input style="width: 55%;" formControlName="taxCountry">
            </div>
            <div *ngIf="entityResponseData.entityType.entityType !== 'PERSON' && this.basicInformation && this.basicInformation.vat" class="inner">
                <label>VAT:</label>
                <input style="width: 55%;" formControlName="vat">
            </div>
        </div>
        <div style="height: 10px;"></div>
        <button class="btn btn-success" type="submit" [disabled]="!myForm.valid">Save Information</button>
    </form>
 </div>