import { Component, OnInit } from '@angular/core';
import { CallsService } from '../../_services';
import { config } from '../../../assets/configuration';

/**
* Implements the Settings page
*/
@Component({
  moduleId: module.id,
  templateUrl: 'settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {

  oldPassword = '';
  newPassword = '';
  selectedItem = '';
  response: any = {};
  // passwordDisplay: any = true;
  createAdminDisplay = false;
  emailServerDisplay = false;
  emailTemplateDisplay = false;
  screenerSettingsDisplay = false;
  userManagementDisplay = true;
  legalConfigurationDisplay = false;
  pepSanctionScreenerDisplay = false;
  mediaScreenerDisplay = false;
  searchEngineScreenerDisplay = false;
  screeningRiskSettingsDisplay = false;
  riskAnalysisDisplay = false;
  brandingSettings = false;
  tagManagementDisplay = false;

  mainColor: any = config.mainColor;

  constructor(
    private callsService: CallsService
  ) {}

  ngOnInit() {}

  /**
  * Function for menu navigation
  * @param {String} selectedItem - selected item
  */
  menuClick(selectedItem: string): void {
    this.createAdminDisplay = selectedItem === 'createAdmin';
    this.emailServerDisplay = selectedItem === 'emailServer';
    this.emailTemplateDisplay = selectedItem === 'emailTemplate';
    this.screenerSettingsDisplay = selectedItem === 'screenerSettingsDisplay';
    this.userManagementDisplay = selectedItem === 'userManagement';
    this.tagManagementDisplay = selectedItem === 'tagManagement';
    this.legalConfigurationDisplay = selectedItem === 'legalConfiguration';
    this.mediaScreenerDisplay = selectedItem === 'mediaScreener';
    this.pepSanctionScreenerDisplay = selectedItem === 'pepSanctionScreener';
    this.searchEngineScreenerDisplay = selectedItem === 'searchEngineScreener';
    this.screeningRiskSettingsDisplay = selectedItem === 'screeningRiskSettings';
    this.riskAnalysisDisplay = selectedItem === 'riskAnalysis';
    this.brandingSettings = selectedItem === 'brandingSettings';
    this.emailServerDisplay = selectedItem === 'emailServerSettings';
  }
}
