<div class="col-md-11" style="margin-left: 50px;" xmlns:height="http://www.w3.org/1999/xhtml">
   <div class="row">
      <div class="col-md-12" *ngIf="notFound == false">
            <button class="btn btn-primary" *ngIf="!expanded" (click)="expandAll()">Expand All&#160;<i class="fa fa-plus" aria-hidden="true" style="font-size: 12px;"></i></button>
            <button id="collapse-button" class="btn btn-primary" *ngIf="expanded" (click)="collapseAll()">Collapse All&#160;<i class="fa fa-minus" aria-hidden="true" style="font-size: 12px;"></i></button>
            <div style="height: 20px;"></div>
            <div *ngIf="results?.probableMatches?.length > 0">
                  <accordion>
                     <accordion-group>
                        <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                           <div class="pull-left float-left" style="color: #daaa0e;">PROBABLE MATCHES</div>
                           <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>

                        </button>
                        <div style="height: 20px;"></div>
                  <div class="pep-hits-detail">
                     <accordion  *ngIf="probableMatches.media.length > 0">
                        <accordion-group>
                           <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                              <div class="pull-left float-left" style="color: #daaa0e;">MEDIA</div>
                              <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                              <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{probableMatches.media.length}}</div>

                           </button>
                        <accordion  *ngFor="let match of  probableMatches.media; index as i">
                           <accordion-group [isOpen]="true">
                              <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                                       <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                       <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_further.png" width="20px" class="float-right" tooltip="Move to Further Research Matches" (click)="moveToFurther(match.entryId,'FURTHER_RESEARCH')" /></div>
                                       <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')" /></div>  
                                       <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')" /></div>                                     
                                       <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-title">{{match?.entity?.entityName}}</div> -->
                                       <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                       <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                       <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                       <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                       <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityName | shorterText}}</div>
                                       <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                       <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                       <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-title" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0]}}</div>
                                       <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-title" style="color: #cc0000"></div> -->
                                       <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title"></div> -->
                              </button>
                              <span *ngIf="match?.note != null && match?.note != ''" class="note">
                              {{match.note}}
                              </span>
                              <div style="height: 10px;"></div>
                              <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv == false || match?.entityRiskDescriptor?.isRefinitiv == null ">
                                 <ul>
                                    <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                                    <li *ngIf="match?.entryId">
                                       <b>Entry Id:</b> {{match?.entryId}}
                                    </li>

                                    <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">
                                       <b>Description:</b> {{match?.entityRiskDescriptor?.descriptions[0]}}
                                    </li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                    
                                    <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                    

                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                    <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                    <li>
                                       <b>Refinitiv:</b> no
                                    </li>
                                    <li *ngIf="match?.timestamp">
                                       <b>Time Stamp:</b> {{match?.timestamp}}
                                    </li>
                                    
                                    <div style="height: 10px;"></div>
                                    <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'probable-match-note-' + i }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,i,'PROBABLE')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                 </ul>
                              </span>
                              <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                                 <ul>

                                    <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                                    <li *ngIf="match?.entryId">
                                       <b>Entry Id:</b> {{match?.entryId}}
                                    </li>
                                    
                                    <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                                      <li>
                                          <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                      </li>
                                    </span>

                                    <span>
                                       <li *ngIf="match?.entityRiskDescriptor?.links.length > 0">
                                       <span>
                                          <b>Links:</b>
                                          <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                             <li *ngFor="let url of (urls | orgLinksPipe)">
                                                <a href="{{ url }}" target="_blank">{{ url }}</a>
                                             </li> 
                                          </ul>
                                       </span>
                                       </li>
                                    </span>

                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                    
                                    <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                    

                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                    <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                    
                                    <li>
                                       <b>Refinitiv:</b> yes
                                    </li>
                                    <li  *ngIf="match?.entityRiskDescriptor?.type">
                                       <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.subType">
                                       <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                       <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                       <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.dob">
                                       <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                       <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                       <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                       <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                       <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.title">
                                       <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.position">
                                       <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.age">
                                       <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                       <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                       <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                       <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                       <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.sex">
                                       <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                    </li>
                                    
                                    <li *ngIf="match?.timestamp">
                                       <b>Time Stamp:</b> {{match?.timestamp}}
                                    </li>
                                    <div style="height: 10px;"></div>
                                    <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'probable-match-note-' + i }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,i,'PROBABLE')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                 </ul>
                              </span>
                              
                             
                              <div style="height: 10px"></div>
                           </accordion-group>
                        </accordion>
                     </accordion-group>
                  </accordion>
                  <accordion  *ngIf="probableMatches.pep_or_sanction.length > 0">
                     <accordion-group>
                        <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                           <div class="pull-left float-left" style="color: #daaa0e;">PEP OR SANCTION</div>
                           <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                           <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{probableMatches.pep_or_sanction.length}}</div>

                        </button>
                     <accordion  *ngFor="let match of  probableMatches.pep_or_sanction; index as i">
                        <accordion-group [isOpen]="true">
                           <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                                    <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                    <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_further.png" width="20px" class="float-right" tooltip="Move to Further Research Matches" (click)="moveToFurther(match.entryId,'FURTHER_RESEARCH')" /></div>
                                    <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')" /></div>  
                                    <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')" /></div>                                     
                                    <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-title">{{match?.entity?.entityName}}</div> -->
                                    <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                    <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityName | shorterText}}</div>
                                    <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                    <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                    <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-title" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0]}}</div>
                                    <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-title" style="color: #cc0000"></div> -->
                                    <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title"></div> -->
                           </button>
                           <span *ngIf="match?.note != null && match?.note != ''" class="note">
                           {{match.note}}
                           </span>
                           <div style="height: 10px;"></div>
                           <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv == false || match?.entityRiskDescriptor?.isRefinitiv == null ">
                              <ul>
                                 <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                                 <li *ngIf="match?.entryId">
                                    <b>Entry Id:</b> {{match?.entryId}}
                                 </li>

                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">
                                    <b>Description:</b> {{match?.entityRiskDescriptor?.descriptions[0]}}
                                 </li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                 
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                 

                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                 <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                 <li>
                                    <b>Refinitiv:</b> no
                                 </li>
                                 <li *ngIf="match?.timestamp">
                                    <b>Time Stamp:</b> {{match?.timestamp}}
                                 </li>
                                 
                                 <div style="height: 10px;"></div>
                                 <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'probable-match-note-' + i }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,i,'PROBABLE')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                              </ul>
                           </span>
                           <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                              <ul>

                                 <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                                 <li *ngIf="match?.entryId">
                                    <b>Entry Id:</b> {{match?.entryId}}
                                 </li>
                                 
                                 <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                                   <li>
                                       <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                   </li>
                                 </span>

                                 <span>
                                    <li *ngIf="match?.entityRiskDescriptor?.links.length > 0">
                                    <span>
                                       <b>Links:</b>
                                       <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                          <li *ngFor="let url of (urls | orgLinksPipe)">
                                             <a href="{{ url }}" target="_blank">{{ url }}</a>
                                          </li> 
                                       </ul>
                                    </span>
                                    </li>
                                 </span>

                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                 
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                 

                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                 <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                 
                                 <li>
                                    <b>Refinitiv:</b> yes
                                 </li>
                                 <li  *ngIf="match?.entityRiskDescriptor?.type">
                                    <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.subType">
                                    <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                    <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                    <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.dob">
                                    <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                    <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                    <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                    <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                    <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.title">
                                    <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.position">
                                    <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.age">
                                    <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                    <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                    <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                    <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                    <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.sex">
                                    <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                 </li>
                                 
                                 <li *ngIf="match?.timestamp">
                                    <b>Time Stamp:</b> {{match?.timestamp}}
                                 </li>
                                 <div style="height: 10px;"></div>
                                 <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'probable-match-note-' + i }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,i,'PROBABLE')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                              </ul>
                           </span>
                           
                          
                           <div style="height: 10px"></div>
                        </accordion-group>
                     </accordion>
                  </accordion-group>
               </accordion>
               <accordion  *ngIf="probableMatches.search_engine.length > 0">
                  <accordion-group>
                     <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                        <div class="pull-left float-left" style="color: #daaa0e;">SEARCH ENGINE</div>
                        <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                        <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{probableMatches.search_engine.length}}</div>

                     </button>
                  <accordion  *ngFor="let match of  probableMatches.search_engine; index as i">
                     <accordion-group [isOpen]="true">
                        <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                                 <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                 <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_further.png" width="20px" class="float-right" tooltip="Move to Further Research Matches" (click)="moveToFurther(match.entryId,'FURTHER_RESEARCH')" /></div>
                                 <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')" /></div>  
                                 <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')" /></div>                                     
                                 <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-title">{{match?.entity?.entityName}}</div> -->
                                 <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                 <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                 <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                 <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                 <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityName | shorterText}}</div>
                                 <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                 <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                 <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-title" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0]}}</div>
                                 <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-title" style="color: #cc0000"></div> -->
                                 <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title"></div> -->
                        </button>
                        <span *ngIf="match?.note != null && match?.note != ''" class="note">
                        {{match.note}}
                        </span>
                        <div style="height: 10px;"></div>
                        <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv == false || match?.entityRiskDescriptor?.isRefinitiv == null ">
                           <ul>
                              <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                              <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                              <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                              <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                              <li *ngIf="match?.entryId">
                                 <b>Entry Id:</b> {{match?.entryId}}
                              </li>

                              <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">
                                 <b>Description:</b> {{match?.entityRiskDescriptor?.descriptions[0]}}
                              </li>
                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                              
                              <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                              

                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                              <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                              <li>
                                 <b>Refinitiv:</b> no
                              </li>
                              <li *ngIf="match?.timestamp">
                                 <b>Time Stamp:</b> {{match?.timestamp}}
                              </li>
                              
                              <div style="height: 10px;"></div>
                              <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'probable-match-note-' + i }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,i,'PROBABLE')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                           </ul>
                        </span>
                        <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                           <ul>

                              <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                              <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                              <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                              <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                              <li *ngIf="match?.entryId">
                                 <b>Entry Id:</b> {{match?.entryId}}
                              </li>
                              
                              <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                                <li>
                                    <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                </li>
                              </span>

                              <span>
                                 <li *ngIf="match?.entityRiskDescriptor?.links.length > 0">
                                 <span>
                                    <b>Links:</b>
                                    <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                       <li *ngFor="let url of (urls | orgLinksPipe)">
                                          <a href="{{ url }}" target="_blank">{{ url }}</a>
                                       </li> 
                                    </ul>
                                 </span>
                                 </li>
                              </span>

                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                              
                              <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                              

                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                              <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                              <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                              
                              <li>
                                 <b>Refinitiv:</b> yes
                              </li>
                              <li  *ngIf="match?.entityRiskDescriptor?.type">
                                 <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.subType">
                                 <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                 <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                 <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.dob">
                                 <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                 <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                 <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                 <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                 <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.title">
                                 <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.position">
                                 <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.age">
                                 <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                 <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                 <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                 <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                 <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                              </li>
                              <li *ngIf="match?.entityRiskDescriptor?.sex">
                                 <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                              </li>
                              
                              <li *ngIf="match?.timestamp">
                                 <b>Time Stamp:</b> {{match?.timestamp}}
                              </li>
                              <div style="height: 10px;"></div>
                              <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'probable-match-note-' + i }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,i,'PROBABLE')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                           </ul>
                        </span>
                        
                       
                        <div style="height: 10px"></div>
                     </accordion-group>
                  </accordion>
               </accordion-group>
            </accordion>
                     </div>

      </accordion-group>
   </accordion>
                  <div style="height: 20px;"></div>
            </div>
            <div *ngIf="results?.confirmedMatches?.length > 0">

               <div style="height: 20px;"></div>
               <accordion>
                  <accordion-group>
                     <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
                        <div class="pull-left float-left" style="color: #cc0000;">CONFIRMED MATCHES</div>
                        <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>

                     </button>
               <div class="pep-hits-detail">
                  <accordion  *ngIf="confirmedMatches.media.length > 0">
                     <accordion-group>
                        <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
                           <div class="pull-left float-left" style="color: #cc0000;">MEDIA</div>
                           <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                           <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{confirmedMatches.media.length}}</div>

                        </button>
                     <accordion  *ngFor="let match of  confirmedMatches.media; index as j">
                        <accordion-group [isOpen]="true">
                                 <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
                                          <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                          <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_further.png" width="20px" class="float-right" tooltip="Move to Further Research Matches" (click)="moveToFurther(match.entryId,'FURTHER_RESEARCH')"/></div>
                                          <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>                                       
                                          <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-title">{{match?.entity?.entityName}}</div> -->
                                          <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                          <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                          <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                          <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                          <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #cc0000">{{match?.entityName | shorterText}}</div>
                                          <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #cc0000;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                          <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #cc0000;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                          <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-title" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0]}}</div>
                                          <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-title" style="color: #cc0000"></div> -->
                                          <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title"></div> -->
                                 </button>
                                 <span *ngIf="match?.note != null && match?.note != ''" class="note">
                                 {{match.note}}
                                 </span>
                                 <div style="height: 10px;"></div>
                                 <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                                    <ul>
                                       <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                       <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                       <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                       <li>
                                          <b>Refinitiv:</b> no
                                       </li>
                                       <div style="height: 10px;"></div>
                                       <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'confirmed-match-note-' + j }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,j,'CONFIRMED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                    </ul>
                                 </span>
                                 <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                                    <ul>
   
                                       <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
   
                                       <li *ngIf="match?.entryId">
                                          <b>Entry Id:</b> {{match?.entryId}}
                                       </li>
                                       
                                       <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                                         <li>
                                             <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                         </li>
                                         
                                       </span>
                                       <span>
                                          <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                                          <span>
                                             <b>Links:</b>
                                             <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                                <li *ngFor="let url of (urls | orgLinksPipe)">
                                                   <a href="{{ url }}" target="_blank">{{ url }}</a>
                                                </li> 
                                             </ul>
                                          </span>
                                          </li>
                                       </span>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                       
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                       
   
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                       <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                       
                                       <li>
                                          <b>Refinitiv:</b> yes
                                       </li>
                                       <li  *ngIf="match?.entityRiskDescriptor?.type">
                                          <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.subType">
                                          <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                          <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                          <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.dob">
                                          <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                          <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                          <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                          <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                          <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.title">
                                          <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.position">
                                          <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.age">
                                          <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                          <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                          <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                          <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                          <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.sex">
                                          <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                       </li>
                                       
                                       <li *ngIf="match?.timestamp">
                                          <b>Time Stamp:</b> {{match?.timestamp}}
                                       </li>
                                       <div style="height: 10px;"></div>
                                       <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'confirmed-match-note-' + j }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,j,'CONFIRMED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                    </ul>
                                 </span>
                                 <div style="height: 10px"></div>
                        </accordion-group>
                  </accordion>
               </accordion-group>
            </accordion>
            <accordion  *ngIf="confirmedMatches.pep_or_sanction.length > 0">
               <accordion-group>
                  <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
                     <div class="pull-left float-left" style="color: #cc0000;">PEP OR SANCTION</div>
                     <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                     <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{confirmedMatches.pep_or_sanction.length}}</div>

                  </button>
               <accordion  *ngFor="let match of  confirmedMatches.pep_or_sanction; index as j">
                  <accordion-group [isOpen]="true">
                           <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
                                    <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                    <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_further.png" width="20px" class="float-right" tooltip="Move to Further Research Matches" (click)="moveToFurther(match.entryId,'FURTHER_RESEARCH')"/></div>
                                    <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>                                       
                                    <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-title">{{match?.entity?.entityName}}</div> -->
                                    <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                    <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #cc0000">{{match?.entityName | shorterText}}</div>
                                    <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #cc0000;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                    <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #cc0000;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                    <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-title" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0]}}</div>
                                    <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-title" style="color: #cc0000"></div> -->
                                    <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title"></div> -->
                           </button>
                           <span *ngIf="match?.note != null && match?.note != ''" class="note">
                           {{match.note}}
                           </span>
                           <div style="height: 10px;"></div>
                           <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                              <ul>
                                 <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                 <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                 <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                 <li>
                                    <b>Refinitiv:</b> no
                                 </li>
                                 <div style="height: 10px;"></div>
                                 <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'confirmed-match-note-' + j }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,j,'CONFIRMED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                              </ul>
                           </span>
                           <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                              <ul>

                                 <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                                 <li *ngIf="match?.entryId">
                                    <b>Entry Id:</b> {{match?.entryId}}
                                 </li>
                                 
                                 <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                                   <li>
                                       <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                   </li>
                                   
                                 </span>
                                 <span>
                                    <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                                    <span>
                                       <b>Links:</b>
                                       <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                          <li *ngFor="let url of (urls | orgLinksPipe)">
                                             <a href="{{ url }}" target="_blank">{{ url }}</a>
                                          </li> 
                                       </ul>
                                    </span>
                                    </li>
                                 </span>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                 
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                 

                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                 <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                 
                                 <li>
                                    <b>Refinitiv:</b> yes
                                 </li>
                                 <li  *ngIf="match?.entityRiskDescriptor?.type">
                                    <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.subType">
                                    <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                    <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                    <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.dob">
                                    <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                    <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                    <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                    <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                    <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.title">
                                    <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.position">
                                    <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.age">
                                    <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                    <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                    <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                    <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                    <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.sex">
                                    <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                 </li>
                                 
                                 <li *ngIf="match?.timestamp">
                                    <b>Time Stamp:</b> {{match?.timestamp}}
                                 </li>
                                 <div style="height: 10px;"></div>
                                 <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'confirmed-match-note-' + j }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,j,'CONFIRMED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                              </ul>
                           </span>
                           <div style="height: 10px"></div>
                  </accordion-group>
            </accordion>
         </accordion-group>
      </accordion>
      <accordion  *ngIf="confirmedMatches.search_engine.length > 0">
         <accordion-group>
            <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
               <div class="pull-left float-left" style="color: #cc0000;">MEDIA</div>
               <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
               <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{confirmedMatches.search_engine.length}}</div>

            </button>
         <accordion  *ngFor="let match of  confirmedMatches.search_engine; index as j">
            <accordion-group [isOpen]="true">
                     <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #cc0000;color: white;font-weight: bold;" accordion-heading>
                              <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                              <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_further.png" width="20px" class="float-right" tooltip="Move to Further Research Matches" (click)="moveToFurther(match.entryId,'FURTHER_RESEARCH')"/></div>
                              <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>                                       
                              <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-title">{{match?.entity?.entityName}}</div> -->
                              <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                              <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                              <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #cc0000;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                              <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                              <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #cc0000">{{match?.entityName | shorterText}}</div>
                              <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #cc0000;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                              <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #cc0000;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                              <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-title" style="color: #cc0000">{{match?.entityRiskDescriptor?.names[0]}}</div>
                              <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-title" style="color: #cc0000"></div> -->
                              <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title"></div> -->
                     </button>
                     <span *ngIf="match?.note != null && match?.note != ''" class="note">
                     {{match.note}}
                     </span>
                     <div style="height: 10px;"></div>
                     <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                        <ul>
                           <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                           <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                           <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                           <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                           <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                           <li>
                              <b>Refinitiv:</b> no
                           </li>
                           <div style="height: 10px;"></div>
                           <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'confirmed-match-note-' + j }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,j,'CONFIRMED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                        </ul>
                     </span>
                     <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                        <ul>

                           <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                           <li *ngIf="match?.entryId">
                              <b>Entry Id:</b> {{match?.entryId}}
                           </li>
                           
                           <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                             <li>
                                 <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                             </li>
                             
                           </span>
                           <span>
                              <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                              <span>
                                 <b>Links:</b>
                                 <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                    <li *ngFor="let url of (urls | orgLinksPipe)">
                                       <a href="{{ url }}" target="_blank">{{ url }}</a>
                                    </li> 
                                 </ul>
                              </span>
                              </li>
                           </span>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                           
                           <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                           

                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                           <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                           
                           <li>
                              <b>Refinitiv:</b> yes
                           </li>
                           <li  *ngIf="match?.entityRiskDescriptor?.type">
                              <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.subType">
                              <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                              <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                              <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.dob">
                              <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                              <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                              <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                              <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                              <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.title">
                              <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.position">
                              <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.age">
                              <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                              <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.ssn">
                              <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                              <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.deceased">
                              <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.sex">
                              <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                           </li>
                           
                           <li *ngIf="match?.timestamp">
                              <b>Time Stamp:</b> {{match?.timestamp}}
                           </li>
                           <div style="height: 10px;"></div>
                           <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'confirmed-match-note-' + j }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,j,'CONFIRMED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                        </ul>
                     </span>
                     <div style="height: 10px"></div>
            </accordion-group>
      </accordion>
   </accordion-group>
</accordion>
               </div>

            </accordion-group>
         </accordion>
         <div style="height: 20px;"></div>
            </div>
            <div *ngIf="results?.furtherResearchMatches?.length > 0">
               <div style="height: 20px;"></div>
               <accordion >
                  <accordion-group>
                     <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                        <div class="pull-left float-left" style="color: #daaa0e;">FURTHER RESEARCH NEEDED</div>
                        <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>

                     </button>
               <div class="pep-hits-detail">
                  <accordion  *ngIf="furtherResearchMatches.media.length > 0">

                  <accordion-group>
                     <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                        <div class="pull-left float-left" style="color: #daaa0e;">MEDIA</div>
                        <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                        <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{furtherResearchMatches.media.length}}</div>

                     </button>
                  <accordion  *ngFor="let match of  furtherResearchMatches.media; index as k">
                           <accordion-group [isOpen]="true">
                                    <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                                             <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                             <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>
                                             <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')"/></div>
                                             <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-probable">{{match?.entity?.entityName}}</div> -->
                                             <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                             <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                             <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                             <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                             <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityName | shorterText}}</div>
                                             <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                             <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                             <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0]}}</div> -->
                                             <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e"></div> -->
                                             <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title-probable"></div> -->
                                    </button>
                                    <span *ngIf="match.note != null && match.note != ''" class="note">
                                       {{match?.note}}
                                    </span>
                                    <div style="height: 10px;"></div>
                                    <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                                       <ul>
                                          <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                          <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                          <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                          <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                                          <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                          <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                          <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                          <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                           <li>
                                          <b>Refinitiv:</b> yes
                                       </li>
                                          <div style="height: 10px;"></div>
                                          <input class="note-input" id="{{ 'further-research-match-note-' + k }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,k,'FURTHER RESEARCH')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                       </ul>
                                    </span>
                                    <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                                       <ul>
   
                                          <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                          <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                          <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                          <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
      
                                          <li *ngIf="match?.entryId">
                                             <b>Entry Id:</b> {{match?.entryId}}
                                          </li>
                                          
                                          <span *ngIf="match?.entityRiskDescriptor?.descriptions.length==2">
                                            <li>
                                                <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                            </li>
                                            
                                          </span>
                                          <span>
                                             <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                                             <span>
                                                <b>Links:</b>
                                                <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                                   <li *ngFor="let url of (urls | orgLinksPipe)">
                                                      <a href="{{ url }}" target="_blank">{{ url }}</a>
                                                   </li> 
                                                </ul>
                                             </span>
                                             </li>
                                          </span>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                          
                                          <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                          
      
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                          <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                          <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                          
                                          <li>
                                             <b>Refinitiv:</b> yes
                                          </li>
                                          <li  *ngIf="match?.entityRiskDescriptor?.type">
                                             <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.subType">
                                             <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                             <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                             <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.dob">
                                             <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                             <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                             <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                             <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                             <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.title">
                                             <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.position">
                                             <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.age">
                                             <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                             <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                             <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                             <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                             <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                          </li>
                                          <li *ngIf="match?.entityRiskDescriptor?.sex">
                                             <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                          </li>
                                          
                                          <li *ngIf="match?.timestamp">
                                             <b>Time Stamp:</b> {{match?.timestamp}}
                                          </li>
                                          <div style="height: 10px;"></div>
                                          <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'further-research-match-note-' + k }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,k,'FURTHER RESEARCH')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                       </ul>
                                    </span>
                                    <div style="height: 10px"></div>
                           </accordion-group>
                     </accordion>
                  </accordion-group>
               </accordion>
               <accordion  *ngIf="furtherResearchMatches.pep_or_sanction.length > 0">
                  <accordion-group>
                  <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                     <div class="pull-left float-left" style="color: #daaa0e;">PEP OR SANCTION</div>
                     <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                     <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{furtherResearchMatches.pep_or_sanction.length}}</div>

                  </button>
               <accordion  *ngFor="let match of  furtherResearchMatches.pep_or_sanction; index as k">
                        <accordion-group [isOpen]="true">
                                 <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                                          <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                          <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>
                                          <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')"/></div>
                                          <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-probable">{{match?.entity?.entityName}}</div> -->
                                          <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                          <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                          <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                          <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                          <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityName | shorterText}}</div>
                                          <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                          <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                          <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0]}}</div> -->
                                          <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e"></div> -->
                                          <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title-probable"></div> -->
                                 </button>
                                 <span *ngIf="match.note != null && match.note != ''" class="note">
                                    {{match?.note}}
                                 </span>
                                 <div style="height: 10px;"></div>
                                 <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                                    <ul>
                                       <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                       <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                       <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                        <li>
                                       <b>Refinitiv:</b> yes
                                    </li>
                                       <div style="height: 10px;"></div>
                                       <input class="note-input" id="{{ 'further-research-match-note-' + k }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,k,'FURTHER RESEARCH')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                    </ul>
                                 </span>
                                 <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                                    <ul>

                                       <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
   
                                       <li *ngIf="match?.entryId">
                                          <b>Entry Id:</b> {{match?.entryId}}
                                       </li>
                                       
                                       <span *ngIf="match?.entityRiskDescriptor?.descriptions.length==2">
                                         <li>
                                             <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                         </li>
                                         
                                       </span>
                                       <span>
                                          <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                                          <span>
                                             <b>Links:</b>
                                             <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                                <li *ngFor="let url of (urls | orgLinksPipe)">
                                                   <a href="{{ url }}" target="_blank">{{ url }}</a>
                                                </li> 
                                             </ul>
                                          </span>
                                          </li>
                                       </span>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                       
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                       
   
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                       <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                       
                                       <li>
                                          <b>Refinitiv:</b> yes
                                       </li>
                                       <li  *ngIf="match?.entityRiskDescriptor?.type">
                                          <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.subType">
                                          <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                          <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                          <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.dob">
                                          <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                          <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                          <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                          <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                          <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.title">
                                          <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.position">
                                          <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.age">
                                          <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                          <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                          <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                          <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                          <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.sex">
                                          <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                       </li>
                                       
                                       <li *ngIf="match?.timestamp">
                                          <b>Time Stamp:</b> {{match?.timestamp}}
                                       </li>
                                       <div style="height: 10px;"></div>
                                       <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'further-research-match-note-' + k }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,k,'FURTHER RESEARCH')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                    </ul>
                                 </span>
                                 <div style="height: 10px"></div>
                        </accordion-group>
                  </accordion>
               </accordion-group>
            </accordion>
            <accordion  *ngIf="furtherResearchMatches.search_engine.length > 0">
               <accordion-group>
               <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                  <div class="pull-left float-left" style="color: #daaa0e;">SEARCH ENGINE</div>
                  <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                  <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{furtherResearchMatches.search_engine.length}}</div>

               </button>
            <accordion  *ngFor="let match of  furtherResearchMatches.search_engine; index as k">
                     <accordion-group [isOpen]="true">
                              <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                                       <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                       <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>
                                       <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')"/></div>
                                       <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-probable">{{match?.entity?.entityName}}</div> -->
                                       <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                       <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                       <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #daaa0e;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                       <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                       <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityName | shorterText}}</div>
                                       <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                       <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #daaa0e;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                       <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{match?.entityRiskDescriptor?.names[0]}}</div> -->
                                       <!-- <div *ngIf="match?.entityRiskDescriptor?.entityName == null && match?.entityRiskDescriptor?.names.length == 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e"></div> -->
                                       <!-- <div *ngIf="match?.entity?.names == null" class="pull-left float-left pep-hit-title-probable"></div> -->
                              </button>
                              <span *ngIf="match.note != null && match.note != ''" class="note">
                                 {{match?.note}}
                              </span>
                              <div style="height: 10px;"></div>
                              <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                                 <ul>
                                    <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                                    <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                    <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                    <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                    <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                     <li>
                                    <b>Refinitiv:</b> yes
                                 </li>
                                    <div style="height: 10px;"></div>
                                    <input class="note-input" id="{{ 'further-research-match-note-' + k }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,k,'FURTHER RESEARCH')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                 </ul>
                              </span>
                              <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                                 <ul>

                                    <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                    <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                                    <li *ngIf="match?.entryId">
                                       <b>Entry Id:</b> {{match?.entryId}}
                                    </li>
                                    
                                    <span *ngIf="match?.entityRiskDescriptor?.descriptions.length==2">
                                      <li>
                                          <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                      </li>
                                      
                                    </span>
                                    <span>
                                       <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                                       <span>
                                          <b>Links:</b>
                                          <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                             <li *ngFor="let url of (urls | orgLinksPipe)">
                                                <a href="{{ url }}" target="_blank">{{ url }}</a>
                                             </li> 
                                          </ul>
                                       </span>
                                       </li>
                                    </span>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                    
                                    <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                    

                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                    <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                    <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                    
                                    <li>
                                       <b>Refinitiv:</b> yes
                                    </li>
                                    <li  *ngIf="match?.entityRiskDescriptor?.type">
                                       <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.subType">
                                       <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                       <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                       <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.dob">
                                       <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                       <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                       <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                       <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                       <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.title">
                                       <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.position">
                                       <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.age">
                                       <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                       <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                       <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                       <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                       <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                    </li>
                                    <li *ngIf="match?.entityRiskDescriptor?.sex">
                                       <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                    </li>
                                    
                                    <li *ngIf="match?.timestamp">
                                       <b>Time Stamp:</b> {{match?.timestamp}}
                                    </li>
                                    <div style="height: 10px;"></div>
                                    <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'further-research-match-note-' + k }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,k,'FURTHER RESEARCH')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                 </ul>
                              </span>
                              <div style="height: 10px"></div>
                     </accordion-group>
               </accordion>
            </accordion-group>
         </accordion>
            </div>

         </accordion-group>
      </accordion>
               <div style="height: 20px;"></div>
            </div>
            <div *ngIf="results?.whitelistedMatches?.length > 0">
               <h4></h4>
               <accordion>
                  <accordion-group>
                     <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #1a8331;color: white;font-weight: bold;" accordion-heading>
               <h4></h4>
                        <div class="pull-left float-left" style="color: #1a8331;">REJECTED MATCHES</div>
                        <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>

                     </button>
               <div class="pep-hits-detail">
                  <accordion  *ngIf="whitelistedMatches.media.length > 0">
                     <accordion-group>
                        <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" accordion-heading>
                           <div class="pull-left float-left" style="color: #1a8331;">MEDIA</div>
                           <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                           <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{whitelistedMatches.media.length}}</div>

                        </button>
                     <accordion  *ngFor="let match of  whitelistedMatches.media; index as l">
                        <accordion-group [isOpen]="false">
                                 <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #1a8331;color: white;font-weight: bold;" accordion-heading>
                                    <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                                    <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>
                                    <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')"/></div>
                                    <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-probable">{{match?.entity?.entityName}}</div> -->
                                    <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                                    <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #1a8331">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                                    <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #1a8331">{{match?.entityName | shorterText}}</div>
                                    <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #1a8331;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                                    <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #1a8331;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                                 </button>
                                 <span *ngIf="match?.note != null && match?.note != ''" class="note">
                                   {{match.note}}
                                 </span>
                                 <div style="height: 10px;"></div>
                                 <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                                    <ul>
                                       <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                       <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                       <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                       <li>
                                          <b>Refinitiv:</b> no
                                       </li>
                                       <div style="height: 10px;"></div>
                                       <input class="note-input" id="{{ 'rejected-match-note-' + l }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,l,'REJECTED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                    </ul>
                                 </span>
                                 <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                                    <ul>
   
                                       <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                       <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
   
                                       <li *ngIf="match?.entryId">
                                          <b>Entry Id:</b> {{match?.entryId}}
                                       </li>
                                       
                                       <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                                         <li>
                                             <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                         </li>
                                         
                                       </span>
                                       <span>
                                          <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                                          <span>
                                             <b>Links:</b>
                                             <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                                <li *ngFor="let url of (urls | orgLinksPipe)">
                                                   <a href="{{ url }}" target="_blank">{{ url }}</a>
                                                </li> 
                                             </ul>
                                          </span>
                                          </li>
                                       </span>
                                      
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                       
                                       <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                       
   
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                       <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                       <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                       
                                       <li>
                                          <b>Refinitiv:</b> yes
                                       </li>
                                       <li  *ngIf="match?.entityRiskDescriptor?.type">
                                          <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.subType">
                                          <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                          <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                          <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.dob">
                                          <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                          <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                          <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                          <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                          <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.title">
                                          <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.position">
                                          <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.age">
                                          <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                          <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                          <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                          <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                          <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                       </li>
                                       <li *ngIf="match?.entityRiskDescriptor?.sex">
                                          <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                       </li>
                                       
                                       <li *ngIf="match?.timestamp">
                                          <b>Time Stamp:</b> {{match?.timestamp}}
                                       </li>
                                       <div style="height: 10px;"></div>
                                       <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'rejected-match-note-' + l }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,l,'REJECTED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                                    </ul>
                                 </span>
                                 <div style="height: 10px"></div>
                        </accordion-group>
                  </accordion>
               </accordion-group>
            </accordion>
            <accordion  *ngIf="whitelistedMatches.pep_or_sanction.length > 0">
               <accordion-group>
                  <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #1a8331;color: white;font-weight: bold;" accordion-heading>
                     <div class="pull-left float-left" style="color: #1a8331;">PEP OR SANCTION</div>
                     <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                     <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{whitelistedMatches.pep_or_sanction.length}}</div>

                  </button>
               <accordion  *ngFor="let match of  whitelistedMatches.pep_or_sanction; index as l">
                  <accordion-group [isOpen]="false">
                           <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #1a8331;color: white;font-weight: bold;" accordion-heading>
                              <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                              <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>
                              <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')"/></div>
                              <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-probable">{{match?.entity?.entityName}}</div> -->
                              <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                              <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                              <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                              <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #1a8331">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                              <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #1a8331">{{match?.entityName | shorterText}}</div>
                              <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #1a8331;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                              <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #1a8331;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                           </button>
                           <span *ngIf="match?.note != null && match?.note != ''" class="note">
                             {{match.note}}
                           </span>
                           <div style="height: 10px;"></div>
                           <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                              <ul>
                                 <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                 <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                 <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                 <li>
                                    <b>Refinitiv:</b> no
                                 </li>
                                 <div style="height: 10px;"></div>
                                 <input class="note-input" id="{{ 'rejected-match-note-' + l }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,l,'REJECTED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                              </ul>
                           </span>
                           <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                              <ul>

                                 <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                                 <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                                 <li *ngIf="match?.entryId">
                                    <b>Entry Id:</b> {{match?.entryId}}
                                 </li>
                                 
                                 <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                                   <li>
                                       <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                                   </li>
                                   
                                 </span>
                                 <span>
                                    <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                                    <span>
                                       <b>Links:</b>
                                       <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                          <li *ngFor="let url of (urls | orgLinksPipe)">
                                             <a href="{{ url }}" target="_blank">{{ url }}</a>
                                          </li> 
                                       </ul>
                                    </span>
                                    </li>
                                 </span>
                                
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                                 
                                 <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                                 

                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                                 <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                                 <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                                 
                                 <li>
                                    <b>Refinitiv:</b> yes
                                 </li>
                                 <li  *ngIf="match?.entityRiskDescriptor?.type">
                                    <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.subType">
                                    <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                                    <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                                    <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.dob">
                                    <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                                    <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                                    <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                                    <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                                    <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.title">
                                    <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.position">
                                    <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.age">
                                    <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                                    <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.ssn">
                                    <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                                    <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.deceased">
                                    <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                                 </li>
                                 <li *ngIf="match?.entityRiskDescriptor?.sex">
                                    <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                                 </li>
                                 
                                 <li *ngIf="match?.timestamp">
                                    <b>Time Stamp:</b> {{match?.timestamp}}
                                 </li>
                                 <div style="height: 10px;"></div>
                                 <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'rejected-match-note-' + l }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,l,'REJECTED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                              </ul>
                           </span>
                           <div style="height: 10px"></div>
                  </accordion-group>
            </accordion>
         </accordion-group>
      </accordion>
      <accordion  *ngIf="whitelistedMatches.search_engine.length > 0">
         <accordion-group>
            <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #1a8331;color: white;font-weight: bold;" accordion-heading>
               <div class="pull-left float-left" style="color: #1a8331;">SEARCH ENGINE</div>
               <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
               <div class="pull-right float-right" style="color: #000000;padding-left: 5px;">Count:{{whitelistedMatches.search_engine.length}}</div>

            </button>
         <accordion  *ngFor="let match of  whitelistedMatches.search_engine; index as l">
            <accordion-group [isOpen]="false">
                     <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #1a8331;color: white;font-weight: bold;" accordion-heading>
                        <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div>
                        <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Rejected Matches" (click)="moveToWhitelisted(match.entryId,'WHITE_LISTED')"/></div>
                        <div class="pull-right float-right" style="padding-left: 5px;"><img *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(match.entryId,'CONFIRMED')"/></div>
                        <!-- <div *ngIf="match?.entity?.entityName" class="pull-left float-left pep-hit-probable">{{match?.entity?.entityName}}</div> -->
                        <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left"><i class="fa fa-newspaper-o" aria-hidden="true" title='News Article' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                        <div *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null" class="pull-left float-left"><i class="fa fa-user" aria-hidden="true" title='PEP/Sanction hit' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                        <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left"><i class="fa fa-search" aria-hidden="true" title='Search Engine hit' style="color: #1a8331;font-size: 20px;"></i>&nbsp;&nbsp;</div>
                        <div *ngIf="match?.entityName == null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #1a8331">{{match?.entityRiskDescriptor?.names[0] | shorterText}}</div>
                        <div *ngIf="match?.entityName != null && (match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null)" class="pull-left float-left pep-hit-probable" style="color: #1a8331">{{match?.entityName | shorterText}}</div>
                        <div *ngIf="match?.matchType == 'MEDIA'" class="pull-left float-left" style="color: #1a8331;">[{{match.riskType}}] <a href="{{match.mediaRiskDescriptor.url}}" target="_blank"> {{match.mediaRiskDescriptor.headline | shorterText}}</a></div>
                        <div *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="pull-left float-left" style="color: #1a8331;">[{{match.riskType}}] <a href="{{match.searchEngineRiskDescriptor.url}}" target="_blank"> {{match.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                     </button>
                     <span *ngIf="match?.note != null && match?.note != ''" class="note">
                       {{match.note}}
                     </span>
                     <div style="height: 10px;"></div>
                     <span *ngIf="!match?.entityRiskDescriptor?.isRefinitiv">
                        <ul>
                           <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>
                           <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entityRiskDescriptor?.descriptions != null && match?.entityRiskDescriptor?.descriptions.length > 0">{{match?.entityRiskDescriptor?.descriptions[0]}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                           <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                           <!-- <li><b>ID:</b> {{match?.entity?.id}}</li> -->
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                           <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                           <li>
                              <b>Refinitiv:</b> no
                           </li>
                           <div style="height: 10px;"></div>
                           <input class="note-input" id="{{ 'rejected-match-note-' + l }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,l,'REJECTED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                        </ul>
                     </span>
                     <span *ngIf="match?.entityRiskDescriptor?.isRefinitiv">
                        <ul>

                           <li *ngIf="match?.matchType == 'MEDIA' && match?.entityRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.mediaRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'MEDIA'"><b>Topic:</b> {{match?.mediaRiskDescriptor?.topicLabel}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE' && match?.searchEngineRiskDescriptor?.publicationDate != null"><b>Publication date:</b> {{match?.searchEngineRiskDescriptor?.publicationDate | dateParser}}</li>
                           <li *ngIf="match?.matchType == 'SEARCH_ENGINE'">{{match?.searchEngineRiskDescriptor?.text}}</li>

                           <li *ngIf="match?.entryId">
                              <b>Entry Id:</b> {{match?.entryId}}
                           </li>
                           
                           <span *ngIf=" match?.entityRiskDescriptor?.descriptions.length==2">
                             <li>
                                 <b>Description:</b>{{match?.entityRiskDescriptor?.descriptions[1]}}
                             </li>
                             
                           </span>
                           <span>
                              <li *ngIf="match?.entityRiskDescriptor?.links.length>0">
                              <span>
                                 <b>Links:</b>
                                 <ul *ngFor="let urls of match?.entityRiskDescriptor?.links; index as i">
                                    <li *ngFor="let url of (urls | orgLinksPipe)">
                                       <a href="{{ url }}" target="_blank">{{ url }}</a>
                                    </li> 
                                 </ul>
                              </span>
                              </li>
                           </span>
                          
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>First seen:</b> {{match?.entityRiskDescriptor?.firstSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Last seen:</b> {{match?.entityRiskDescriptor?.lastSeen | date}}</li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>AKA Names:</b> {{match?.entityRiskDescriptor?.names}}</li>
                           
                           <li *ngIf="(match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null) && match?.entity?.birthDates && match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{match?.entityRiskDescriptor?.birthDates}}</li>
                           

                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>PEP:</b> <span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == true">Yes</span><span *ngIf="match?.entityRiskDescriptor?.politicallyExposedPerson == false">No</span></li>
                           <li *ngIf="match?.matchType == 'PEP_OR_SANCTION' || match?.matchType == null"><b>Sanction List:</b> {{match?.entryId | sanctionListPipe}}</li>
                           <li><b>Score:</b> <ng-container *ngIf="match?.score != 0"> {{match?.score}}</ng-container><ng-container *ngIf="match?.score == 0">Not calculated</ng-container></li>
                           
                           <li>
                              <b>Refinitiv:</b> yes
                           </li>
                           <li  *ngIf="match?.entityRiskDescriptor?.type">
                              <b>Type:</b> {{match?.entityRiskDescriptor?.type}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.subType">
                              <b>Subtype:</b> {{match?.entityRiskDescriptor?.subType}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.alternativeSpellings.length>0">
                              <b>Alternative Spellings:</b> {{match?.entityRiskDescriptor?.alternativeSpellings}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.addresses.length>0">
                              <b>Addresses:</b> {{match?.entityRiskDescriptor?.addresses}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.dob">
                              <b>Dob:</b> {{match?.entityRiskDescriptor?.dob}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.birthPlaces.length>0">
                              <b>Birth Places:</b> {{match?.entityRiskDescriptor?.birthPlaces}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.affiliations.length>0">
                              <b>Affiliations:</b> {{match?.entityRiskDescriptor?.affiliations}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.primaryName">
                              <b>Primary Name:</b> {{match?.entityRiskDescriptor?.primaryName}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.secondaryName">
                              <b>Secondary Name:</b> {{match?.entityRiskDescriptor?.secondaryName}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.title">
                              <b>Title:</b> {{match?.entityRiskDescriptor?.title}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.position">
                              <b>Position:</b> {{match?.entityRiskDescriptor?.position}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.age">
                              <b>Age:</b> {{match?.entityRiskDescriptor?.age}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.passports.length>0">
                              <b>Passports:</b> {{match?.entityRiskDescriptor?.passports}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.ssn">
                              <b>SSN:</b> {{match?.entityRiskDescriptor?.ssn}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.identificationNumbers.length>0">
                              <b>Identification Numbers:</b> {{match?.entityRiskDescriptor?.identificationNumbers}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.deceased">
                              <b>Deceased:</b> {{match?.entityRiskDescriptor?.deceased}}
                           </li>
                           <li *ngIf="match?.entityRiskDescriptor?.sex">
                              <b>Sex:</b> {{match?.entityRiskDescriptor?.sex}}
                           </li>
                           
                           <li *ngIf="match?.timestamp">
                              <b>Time Stamp:</b> {{match?.timestamp}}
                           </li>
                           <div style="height: 10px;"></div>
                           <input *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="note-input" id="{{ 'rejected-match-note-' + l }}" type="text"  placeholder="Enter note here..."><button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" style="margin-left: -40px;" (click)="addNote(match.entryId,l,'REJECTED')">Save note</button>&#160;&#160;&#160;<button *ngIf="match?.matchType == 'PEP_OR_SANCTION'" class="btn btn-primary" (click)="seeDetails(match.entityName)">See details</button><button *ngIf="match?.matchType == 'MEDIA'" class="btn btn-primary" (click)="seeDetailsUrl(match.mediaRiskDescriptor.url)">See details</button><button *ngIf="match?.matchType == 'SEARCH_ENGINE'" class="btn btn-primary" (click)="seeDetailsUrl(match.searchEngineRiskDescriptor.url)">See details</button>
                        </ul>
                     </span>
                     <div style="height: 10px"></div>
            </accordion-group>
      </accordion>
   </accordion-group>
</accordion>
            </div>

         </accordion-group>
      </accordion>
               <div style="height: 20px;"></div>
            </div>
      </div>
   </div>
   <div class="row" *ngIf="notFound == true">
      <div class="col-md-12" style="text-align: center;">
         No results to show. Either this entity was not part of a screening yet or no matches were found.
      </div>
   </div>
</div>