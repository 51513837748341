<div class="col-lg-12 box" xmlns:height="http://www.w3.org/1999/xhtml">
  <div class="row">
    <!--<alert></alert>-->
    <div style="height: 10px;"></div>
    <!-- <p>You are adding a role for the entity {{primaryName}} {{secondaryName}}</p><br> -->
    <ng-select style="width: 100%;" [(ngModel)]="e1Instance" class="custom" [items]="entities"
      [loading]="entitiesLoading" bindLabel="name" bindValue="id" appendTo="body" (change)="e1InstanceChanged($event)">
      <ng-template ng-option-tmp let-item="item">
        <div [title]="item.name">{{item.name}}</div>
      </ng-template>
    </ng-select>
    <div class="alert alert-danger" [hidden]="e1Instance">This field is required</div> is <ng-select
      style="width: 100%;" [(ngModel)]="roleType" [items]="roleTypes" [loading]="roleTypesLoading"
      bindLabel="defaultRoleName" [dropdownPosition]="'bottom'" bindValue="id" appendTo="body" (change)="roleTypeChanged()"></ng-select>
    <div class="alert alert-danger" [hidden]="roleType">This field is required</div> <div *ngIf="!showGEOentities">of <ng-select style="width: 100%;"
      [(ngModel)]="e2Instance" class="custom" [items]="newEntities" [loading]="entitiesLoading" bindLabel="name"
      bindValue="id" appendTo="body"></ng-select></div>
      <div *ngIf="showGEOentities">
      <div style="height: 25px;"></div>
      <ng-select style="width: 100%;"
      [(ngModel)]="e2Instance" class="custom" [items]="GEOentities" [loading]="entitiesLoading" bindLabel="primaryName"
      bindValue="id" appendTo="body"></ng-select>
      </div>
    <div class="alert alert-danger" [hidden]="e2Instance">This field is required</div>
    <div style="height: 25px;"></div>
  </div>
  <div style="height: 20px;"></div>
  <div class="row">
    <button class="btn btn-primary" (click)="addRole()" style="background-color: #355B89;border-color: #355B89">Add
      role</button>
  </div>
</div>

<!-- <div class="col-lg-12 box" xmlns:height="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div style="height: 10px;"></div>
    <label>First Entity: <b class="required">*</b></label>
    <br>
    <ng-select *ngIf="e1Entities.length === 0" style="width: 100%;" [(ngModel)]="e1Instance" class="custom" [items]="entities"
      [loading]="entitiesLoading" bindLabel="name" bindValue="id" appendTo="body">
      <!-- <ng-template ng-option-tmp let-item="item">
        <div [title]="item.name">{{item.name}}</div>
      </ng-template>
    </ng-select>
    <ng-select disabled *ngIf="e1Entities.length > 0" style="width: 100%;" [(ngModel)]="e1Instance" class="custom" [items]="e1Entities"
      [loading]="entitiesLoading" bindLabel="name" bindValue="id" appendTo="body">
    </ng-select>
    <div class="alert alert-danger" [hidden]="e1Instance">First Entity is required.</div>
    <br *ngIf="e1Instance">
    <label>is: <b class="required">*</b></label>
    <br>
    <ng-select style="width: 100%;" [(ngModel)]="roleType" [items]="roleTypes" [loading]="roleTypesLoading"
      bindLabel="defaultRoleName" [dropdownPosition]="'bottom'" bindValue="id" appendTo="body" (change)="changeRoleType()">
    </ng-select>
    <div class="alert alert-danger" [hidden]="roleType">Role Type is required.</div>
    <br *ngIf="roleType">
    <label>of: <b class="required">*</b></label>
    <br>
    <ng-select [disabled]="e2Entities.length === 0" style="width: 100%;" [(ngModel)]="e2Instance"
      class="custom" [items]="e2Entities" [loading]="entitiesLoading" bindLabel="name" bindValue="id" appendTo="body">
    </ng-select>
    <div class="alert alert-danger" [hidden]="e2Instance || e2Entities.length === 0">Second Entity is required.</div>
    <div class="alert alert-danger" [hidden]="e2Entities.length > 0">Please choose the Role Type.</div>
    <div style="height: 25px;"></div>
  </div>
  <div style="height: 20px;"></div>
  <div class="row">
    <button class="btn btn-primary" [disabled]="!e1Instance || !roleType || !e2Instance" (click)="addRole()" style="background-color: #355B89;border-color: #355B89">Add Role</button>
  </div>
</div>
 -->