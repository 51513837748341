import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { AlertService, KYBService } from '../../_services';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
    moduleId: module.id,
    selector: 'app-matches',
    templateUrl: 'matches.component.html',
    styleUrls: ['./matches.component.css']
})

export class MatchesComponent implements OnInit {

  @Output() moveEvent = new EventEmitter<any>();
  @Input() entityId: any;

  error: string;

  model: any = {};
  batchFileFilled = false;
  batchFileName: string;
  filenameBatchFile: string;
  batchFile: File = null;
  csv = '';
  loading = false;
  temparray: any;
  currentUser = 0;
  userObject: any = {};
  row: any;
  results: any = {};
  probableMatches: any = {media: [], pep_or_sanction: [], search_engine: [] };
  confirmedMatches: any = {media: [], pep_or_sanction: [], search_engine: [] };
  furtherResearchMatches: any = {media: [], pep_or_sanction: [], search_engine: [] };
  whitelistedMatches: any = {media: [], pep_or_sanction: [], search_engine: [] };
  screenTimestamp: any;
  userDetails: any = {};
  log = '';
  whitelistedEntries: any = [];
  whitelistedEntriesDisplay: any = {};
  filteredProbableMatches: any = [];
  filteredConfirmedMatches: any = [];
  filteredFurtherResearchMatches: any = [];
  riskSetting = '';
  users: any = {};
  usersForReview: any;
  resultsToReview: any = [];
  response: any = {};
  expanded = true;
  note = '';
  whitelistedIds: any = [];
  notFound = false;
  matchesResponse: any = {};

  userPermissions: any = [];

  constructor(
    private alertService: AlertService,
    private kybService: KYBService
  ) {}

  ngOnInit() {

    this.kybService.getRiskAnalysisForEntity(this.entityId).subscribe(
      data => {
        this.matchesResponse = data;
        this.probableMatches = {media: [], pep_or_sanction: [], search_engine: [] };
        this.confirmedMatches = {media: [], pep_or_sanction: [], search_engine: [] };
        this.furtherResearchMatches = {media: [], pep_or_sanction: [], search_engine: [] };
        this.whitelistedMatches = {media: [], pep_or_sanction: [], search_engine: [] };
        this.results = this.matchesResponse.data;
        this.results.whitelistedMatches.forEach(element => {
          if (element.matchType === 'MEDIA') {
          this.whitelistedMatches.media.push(element);
          }
          if (element.matchType === 'PEP_OR_SANCTION') {
          this.whitelistedMatches.pep_or_sanction.push(element);
          }
          if (element.matchType === 'SEARCH_ENGINE') {
          this.whitelistedMatches.search_engine.push(element);
          }
        });
        this.results.furtherResearchMatches.forEach(element => {
          if (element.matchType === 'MEDIA') {
          this.furtherResearchMatches.media.push(element);
          }
          if (element.matchType === 'PEP_OR_SANCTION') {
          this.furtherResearchMatches.pep_or_sanction.push(element);
          }
          if (element.matchType === 'SEARCH_ENGINE') {
          this.furtherResearchMatches.search_engine.push(element);
          }
        });
        this.results.confirmedMatches.forEach(element => {
          if (element.matchType === 'MEDIA') {
          this.confirmedMatches.media.push(element);
          }
          if (element.matchType === 'PEP_OR_SANCTION') {
          this.confirmedMatches.pep_or_sanction.push(element);
          }
          if (element.matchType === 'SEARCH_ENGINE') {
          this.confirmedMatches.search_engine.push(element);
          }
        });
        this.results.probableMatches.forEach(element => {
          if (element.matchType === 'MEDIA') {
          this.probableMatches.media.push(element);
          }
          if (element.matchType === 'PEP_OR_SANCTION') {
          this.probableMatches.pep_or_sanction.push(element);
          }
          if (element.matchType === 'SEARCH_ENGINE') {
          this.probableMatches.search_engine.push(element);
          }
        });
        if (this.results.probableMatches.length === 0 && this.results.furtherResearchMatches === 0
          && this.results.confirmedMatches.length === 0 && this.results.whitelistedMatches.length === 0) {
          this.notFound = true;
        } else {
          this.notFound = false;
        }

        setTimeout(() => {
          this.expanded = false;

          if (document.getElementById('collapse-button') !== null) {
            document.getElementById('collapse-button').click();
          }
        }, 5000);
      }, error => {}
    );

    this.kybService.getUserPermissions().subscribe(
      value => this.userPermissions = value.data.permissions
    );
  }

  convertUrl(url: string) {}

  expandAll() {
    this.expanded = true;
    const accordionGroup = (document.getElementsByTagName('accordion-group'));
    const buttons = (document.getElementsByClassName('fa fa-caret-down')) as HTMLCollectionOf<HTMLButtonElement>;

    for (let i = 0; i < buttons.length; i++) {
      if (!accordionGroup[i].classList.contains('panel-open')) {
        buttons[i].click();
      }
    }
  }

  collapseAll() {
    this.expanded = false;
    const accordionGroup = (document.getElementsByTagName('accordion-group'));
    const buttons = (document.getElementsByClassName('fa fa-caret-down')) as HTMLCollectionOf<HTMLButtonElement>;

    for (let i = 0; i < buttons.length; i++) {
      if (accordionGroup[i].classList.contains('panel-open')) {
        buttons[i].click();
      }
    }
  }

  moveToFurther(entryId: any, newMatchStatus: any) {
    this.kybService.moveMatch(this.entityId, entryId, newMatchStatus).subscribe(
      data => {
        this.moveEvent.emit();
        this.ngOnInit();
      }, error => {
        this.alertService.showError(error.error.errors[0].message);
      }
    );
  }

  moveToConfirmed(entryId: any, newMatchStatus: any) {
    this.kybService.moveMatch(this.entityId, entryId, newMatchStatus).subscribe(
      data => {
        this.moveEvent.emit();
        this.ngOnInit();
      }, error => {
        this.alertService.showError(error.error.errors[0].message);
      }
    );
  }

  moveToWhitelisted(entryId: any, newMatchStatus: any) {
    this.kybService.moveMatch(this.entityId, entryId, newMatchStatus).subscribe(
      data => {
        this.moveEvent.emit();
        this.ngOnInit();
      }, error => {
        this.alertService.showError(error.error.errors[0].message);
      }
    );
  }

  addNote(entryId: any, index: any, type: any) {
    let note = '';

    if (type === 'PROBABLE') {
      const inputField = (document.getElementById('probable-match-note-' + index) as HTMLInputElement);
      note = inputField.value;
    } else if (type === 'CONFIRMED') {
      const inputField = (document.getElementById('confirmed-match-note-' + index) as HTMLInputElement);
      note = inputField.value;
    } else if (type === 'FURTHER RESEARCH') {
      const inputField = (document.getElementById('further-research-match-note-' + index) as HTMLInputElement);
      note = inputField.value;
    } else {
      const inputField = (document.getElementById('rejected-match-note-' + index) as HTMLInputElement);
      note = inputField.value;
    }
    this.kybService.updateMatchNote(this.entityId, entryId, note).subscribe(
      data => {
        this.note = '';
        this.ngOnInit();
      }, error => {}
    );
  }

  seeDetails(name: any) {
    const encoded = encodeURIComponent(name);
    const origin = window.location.origin;
    const url = origin + '/search?q=' + encoded;
    window.open(url, '_blank');
  }

  seeDetailsUrl(url: any) {
    window.open(url, '_blank');
  }
}
