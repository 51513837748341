import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../_services';
import { config } from '../../../assets/configuration';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import 'datatables.net-select';
import 'datatables.net-buttons';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Entity, EntityUpdate } from '../../_models/kyb';
import { ConfigService } from '../../_services/config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>{{title}}</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='close()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body' style='min-height: 300px; overflow:hidden'>
      <div style='height: 20px'></div>
      <add-role-simple *ngIf='addRole' [entityId]='entityId' [primaryName]='primaryName' [secondaryName]='secondaryName' [entities]='entities' [roleTypeId]='roleTypeId'></add-role-simple>
      <add-entity-simple *ngIf='addEntity' ></add-entity-simple>
      <add-document *ngIf='addDocument' [documentId]='documentId' [roles]='roles' [entityId]='entityId' [forRoleId]='roleId' [documentTypeId]='documentTypeId'></add-document>
      <replace-document *ngIf='replaceDocument' [roles]='roles' [entityId]='entityId' [documentId]='documentId' [forRoleId]='roleId' [documentTypeId]='documentTypeId' [validTo]='validTo'></replace-document>
      <app-document-editor *ngIf='editDocument' [mode]='mode' [documentId]='documentId' [entityId]='entityId' [roleId]='roleId' [e1Id]='e1Id' [e2Id]='e2Id' [isGeoEntity]='geoEntityFlag' [entityTypeId]='entityTypeId' [primaryName]='primaryName' [secondaryName]='secondaryName'></app-document-editor>
      <app-edit-basic-information *ngIf="editInformation" [basicInformation]="basicInformation" [documentData]="documentData" [entityResponseData]="entityResponseData"></app-edit-basic-information>
      <div style='height: 100px'></div>
    </div>
    <div class='modal-footer'>
    </div>
  `
})

export class AddModalComponent implements OnInit {
  id: any;
  mode: any;
  primaryName: any;
  title: any;
  secondaryName: any;
  entities: any;
  addRole = false;
  addEntity = false;
  addDocument = false;
  replaceDocument = false;
  editDocument = false;
  editInformation = false;
  roles: any;
  entityId: any;
  roleId: any;
  documentTypeId: any;
  entityTypeId: any;
  roleTypeId: any;
  documentId: any;
  e1Id: any;
  e2Id: any;
  geoEntityFlag = false;
  basicInformation: any;
  documentData: any;
  entityResponseData: any;

  constructor(private kybService: KYBService, private dataService: DataService) {}

  ngOnInit() {
    if (this.mode === 'add-role') {
      this.addRole = true;
    }

    if (this.mode === 'add-entity') {
      this.addEntity = true;
    }

    if (this.mode === 'add-document') {
      this.addDocument = true;
    }

    if (this.mode === 'replace-document') {
      this.replaceDocument = true;
    }

    if (this.mode === 'edit-document') {
      this.editDocument = true;
    }

    if (this.mode === 'edit-information') {
      this.editInformation = true;
    }
  }

  close() {
    this.dataService.triggerCloseModalEvent(1);
  }
}

/**
 * Implements the enterprise page
*/
@Component({
  moduleId: module.id,
  templateUrl: 'enterprise.component.html',
  styleUrls: ['./enterprise.component.css']
})

export class EnterpriseComponent implements OnInit {

  loadingImg = 'data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==';
  userName = '';
  response: any = {};
  dataTable: any;
  allUsers: any[];
  allUserstmp: any = [];
  mainColor: string = config.mainColor;
  isOpen = false;
  showImage = true;
  addModal: BsModalRef = null;
  testEntity: Entity;
  currentStack: any = {};
  pageSize = 30;
  currentPage = 0;
  sortingOption = null;
  searchQuery = null;
  loading = false;
  isEmpty = false;
  numberOfElements = 0;
  totalItems = 0;
  entityStatus: any = null;
  userResponse: any = {};
  tagsResponse: any = {};
  tmpTags: any = [];
  allTags: any = [];
  hiddenTags: any = [];
  userTags: any = [];
  includedTags: any = [];
  excludedTags: any = [];
  displayFilters = false;
  users: any[] = [];
  owner = '';
  entityStatus2E = '';

  isAdmin = false;

  entitiesResponse: any = {};
  riskResponse: any = {};
  roleResponse: any = {};
  entities: any = [];
  inclTagIds: string[] = [];
  exclTagIds: string[] = [];

  page: number;

  displayAddRole = true;
  displayStructureBuilder = true;
  displayCompleteColumn = true;
  displayOverallStatusColumn = true;

  userPermissions: any = [];

  entityStatus2EList = [
    'APPLICANT', 'IN_REVIEW', 'ACCEPTED', 'NON_COMPLIANT', 'FROZEN', 'CLOSED', 'ARCHIVED'
  ];

  constructor(
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private dataService: DataService,
    private kybService: KYBService,
    private chRef: ChangeDetectorRef,
    private modalService: BsModalService,
    private domSanitizer: DomSanitizer,
    private configService: ConfigService,
    private translate: TranslateService
  ) {
    this.dataService.refreshEvent.subscribe(message => this.showAll());

    if (configService.get('displayAddRole') !== undefined) {
      this.displayAddRole = configService.get('displayAddRole');
    }

    if (configService.get('displayStructureBuilder') !== undefined) {
      this.displayStructureBuilder = configService.get('displayStructureBuilder');
    }

    if (configService.get('displayCompleteColumn') !== undefined) {
      this.displayCompleteColumn = configService.get('displayCompleteColumn');
    }

    if (configService.get('displayOverallStatusColumn') !== undefined) {
      this.displayOverallStatusColumn = configService.get('displayOverallStatusColumn');
    }
  }

  ngOnInit() {
    // this.ngOnDestroy();
    // this.dataService.setCurrentEntityId('');
    this.allTags = [];
    this.hiddenTags = [];
    this.tmpTags = [];
    this.userTags = [];
    this.includedTags = [];
    this.excludedTags = [];
    this.entities = [];
    this.sortingOption = 'id,desc';

    this.kybService.getAllUsers().subscribe(
      data => {
        for (let l = 0; l < data.data.length; l++) {
          this.users.push({name: data.data[l].email});
        }
      },
      error => {
      }
    );

    this.kybService.getCurrentUser().subscribe(
      data => {
        this.userResponse = data;

        if (this.userResponse.data.role === 'admin') {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }

        this.kybService.getAllTags().subscribe(
          tagsData => {
            this.tagsResponse = tagsData;

            for (let i = 0; i < this.tagsResponse.data.length; i++) {
              this.allTags.push(this.tagsResponse.data[i].id);
            }

            for (let j = 0; j < this.userResponse.data.hiddenTags.length; j++) {
              this.hiddenTags.push(this.userResponse.data.hiddenTags[j].id);
            }

            for (let k = 0; k < this.allTags.length; k++) {
              if (this.hiddenTags.indexOf(this.allTags[k]) === -1) {
                this.tmpTags.push(this.allTags[k]);
              }
            }

            for (let l = 0; l < this.tagsResponse.data.length; l++) {
              if (this.tmpTags.indexOf(this.tagsResponse.data[l].id) !== -1) {
                this.userTags = [...this.userTags, { id: this.tagsResponse.data[l].id, name: this.tagsResponse.data[l].name }];
              }
            }

            this.excludedTags = this.hiddenTags;
            // this.includedTags = this.tmpTags;
          }
        );
      },
      error => {
      }
    );

    this.currentStack = {};
    this.currentStack.entities = [];
    this.currentStack.roles = [];
    this.currentStack.documents = [];
    this.dataService.setCurrentStack(this.currentStack);
    this.userName = localStorage.getItem('username');
    // default sorting is ascending by id
    $('#id-asc-sorticon').css('opacity', '1.0');

    this.kybService.getUserPermissions().subscribe(
      value => {
        this.userPermissions = value.data.permissions;
      }
    );
  }

  filterOwner(event) {
    if (event !== undefined) {
      this.currentPage = 0;
      this.entityStatus = null;
      sessionStorage.setItem('selectedOwner', this.owner);
      this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
        this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
    } else {
      this.resetOwner();
    }
  }
  filterEntityStatus2E(status) {
    if (status !== undefined) {
      this.currentPage = 0;
      this.entityStatus = null;

      sessionStorage.setItem('entityStatus2E', this.entityStatus2E);
      this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
        this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
    } else {
      this.resetEntityStatus2E();
    }
  }

  resetOwner() {
    this.currentPage = 0;
    this.entityStatus = null;
    this.owner = '';

    sessionStorage.removeItem('selectedOwner');
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, null, this.entityStatus2E);
  }
  resetEntityStatus2E() {
    this.currentPage = 0;
    this.entityStatus = null;
    this.owner = '';
    sessionStorage.removeItem('entityStatus2E');
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, null);
  }
  toggleFilters() {
    if (this.displayFilters === true) {
      this.displayFilters = false;
    } else {
      this.displayFilters = true;
    }
  }

  tagAdded(_event) {
    this.entities = [];
    this.currentPage = 0;
    this.entityStatus = null;
    sessionStorage.setItem('includedTags', this.includedTags);
    sessionStorage.setItem('excludedTags', this.excludedTags);
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  tagRemoved(_event) {
    this.entities = [];
    this.currentPage = 0;
    this.entityStatus = null;
    sessionStorage.setItem('includedTags', this.includedTags);
    sessionStorage.setItem('excludedTags', this.excludedTags);
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  compareFn(item, selected) {
    return item.id === selected.id;
  }

  selectAllTags() {
    this.entities = [];
    this.includedTags = this.tmpTags;
    this.currentPage = 0;
    this.entityStatus = null;
    sessionStorage.setItem('includedTags', this.includedTags);
    sessionStorage.setItem('excludedTags', this.excludedTags);
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  deselectAllTags() {
    this.entities = [];
    this.includedTags = [];
    this.entityStatus = null;
    this.currentPage = 0;
    sessionStorage.setItem('includedTags', this.includedTags);
    sessionStorage.setItem('excludedTags', this.excludedTags);
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  showAll() {
    this.currentPage = 0;
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  addRole() {
    const initialState = {
      'mode': 'add-role',
      'title': 'Add role'
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });
    this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        // this.showAll();
      }
    );
  }

  structureBuilder() {
    this.router.navigate(['/structure-builder']);
  }

  addEntity() {
    const initialState = {
      'mode': 'add-entity',
      'title': 'Add Entity'
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });
    this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        this.ngOnInit();
        this.showAll();
      }
    );
  }

  addDocument() {
    const initialState = {
      'mode': 'add-document',
      'title': 'Add Document',
      'entityId': ''
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });
    this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        // this.showAll();
      }
    );
  }
  getAllEntities(size: any, page: any, sort: any, searchQuery: any, _includedTags: any,
    _excludedTags: any, owner: string , entityStatus2E: string) {
    // Close all role details
    $(`tr[class*='role-detail-table-row']`).remove();
    this.loading = true;
    this.entities = [];

    if (sessionStorage.getItem('pageSize') !== null) {
      size = sessionStorage.getItem('pageSize');
      this.pageSize = size;
    }

    if (sessionStorage.getItem('selectedOwner') !== null) {
      owner = sessionStorage.getItem('selectedOwner');
      this.owner = owner;
    }
    if (sessionStorage.getItem('entityStatus2E') != null) {
      entityStatus2E = sessionStorage.getItem('entityStatus2E');
      this.entityStatus2E = entityStatus2E;
    }

    if (sessionStorage.getItem('sortingOption') !== null) {
      sort = sessionStorage.getItem('sortingOption');
      $(`i[id*='-sorticon']`).css('opacity', '0.4');
      $('#' + sort.split(',')[0] + '-' + sort.split(',')[1] + '-sorticon').css('opacity', '1.0');
    }

    if (sessionStorage.getItem('includedTags') !== null) {
      this.inclTagIds = sessionStorage.getItem('includedTags').split(',');

      for (let i = 0; i < this.userTags.length; i++) {
        if (this.inclTagIds.indexOf(this.userTags[i].id.toString()) !== -1) {
          this.includedTags.push(this.userTags[i]);
        }
      }
    } else {
      this.inclTagIds = this.includedTags;
    }

    if (sessionStorage.getItem('excludedTags') !== null) {
      this.exclTagIds = sessionStorage.getItem('excludedTags').split(',');

      for (let j = 0; j < this.userTags.length; j++) {
        if (this.exclTagIds.indexOf(this.userTags[j].id.toString()) !== -1) {
          this.excludedTags.push(this.userTags[j]);
        }
      }
    } else {
      this.exclTagIds = this.excludedTags;
    }

    this.kybService.getAllEntitiesPaged(size, page, sort, searchQuery, this.inclTagIds,
      this.exclTagIds, null, null, owner, null, entityStatus2E).subscribe(
      data => {
        this.entities = [];
        this.entitiesResponse = data;
        this.entities = this.entitiesResponse.data.content;
        for (let i = 0; i < this.entities.length; i++) {
          if (this.entities[i].customOverallRisk !== null && this.entities[i].customOverallRisk !== 0) {
            this.entities[i].overallRisk = this.entities[i].customOverallRisk;
          } else {
            this.entities[i].overallRisk = this.entities[i].combinedOverallRisk;
          }
          // Cover Findomestic USE CASE
          if (this.displayOverallStatusColumn === false && this.entities[i].entityStatus2E === 'APPLICANT') {
            this.entities[i].overallRisk = 'UNEVALUATED';
          }
        }
        this.totalItems = this.entitiesResponse.data.totalElements;
        this.numberOfElements = this.entitiesResponse.data.numberOfElements;

        if (this.totalItems === 0 || this.numberOfElements === 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }
      },
      error => {}
    );
  }

  pageChanged(event: any) {
    this.getAllEntities(this.pageSize, event.page - 1, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  clearTable() {
    this.entities = [];
  }

  getRoles(entityId: any, index: any, primaryName: any, secondaryName: any, entityType: any) {
    const expandButton = $('.main-rows').eq(index).children('td').get(0) as HTMLButtonElement;
    if (expandButton.innerHTML.indexOf('fa-plus-square') === -1) {
      expandButton.children.item(0).classList.remove('fa-minus-square');
      expandButton.children.item(0).classList.add('fa-plus-square');
      expandButton.children.item(0).setAttribute('style', 'color: green');
      $('.role-detail-table-row-' + index).remove();
    } else {
      expandButton.children.item(0).classList.add('disabled-button');

      this.kybService.getRolesForEntity(entityId).subscribe(
        data => {
          this.roleResponse = data;
          expandButton.children.item(0).classList.remove('disabled-button');
          expandButton.children.item(0).classList.remove('fa-plus-square');
          expandButton.children.item(0).classList.add('fa-minus-square');
          expandButton.children.item(0).setAttribute('style', 'color: red');
          const roleTableString = this.getRoleTableString(this.roleResponse.data, primaryName + ' ' + secondaryName, entityType, index);
          $('.main-rows').eq(index).after(roleTableString);
        },
        error => {
        }
      );
    }
  }

  sort(column: any, direction: any) {
    this.currentPage = 0;
    this.sortingOption = column + ',' + direction;
    $(`i[id*='-sorticon']`).css('opacity', '0.4');
    $('#' + column + '-' + direction + '-sorticon').css('opacity', '1.0');

    sessionStorage.setItem('sortingOption', this.sortingOption);
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  pageSizeChanged() {
    this.currentPage = 0;
    sessionStorage.setItem('pageSize', this.pageSize.toString());
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  search() {
    this.currentPage = 0;
    this.getAllEntities(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery,
      this.includedTags, this.excludedTags, this.owner, this.entityStatus2E);
  }

  getDetailLink(entityId: any) {
    const url = window.location.href.split('/enterprise');
    return url[0] + '/detail/' + entityId;
  }

  getRoleTableString(data: any, forEntity: any, entityType: any, index: any) {
    let htmlString = '<tr class="role-detail-table-row-' + index + '" style="text-align: center;"><td style="display: none"><b>ID</b></td><td><b>Role</b></td><td><b>Entity</b></td><td><b>Document Status</b></td><td><b>Complete</b></td><td><b>Type</b></td></tr>';
    for (let i = 0; i < data.length; i++) {
      let entityTypeColor = '';
      let iconString = '';
      // we no longer distinguish color based on entityStatus - replaced with tags
      entityTypeColor = 'black';
      let completenessIcon = '';
      if (data[i].completeness === 'COMPLETE') {
        completenessIcon = '<i title="COMPLETE" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>';
      } else {
        completenessIcon = '<i title="INCOMPLETE" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>';
      }
      if (data[i].documentsStatus === 'UNEVALUATED') {
        if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance === null) {
          iconString = 'fa fa-male';
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;border-bottom: none;"><td style="display: none">' + data[i].e1Id
            + '</td><td>' + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance === null && data[i].e2Instance.entityType.entityType === 'PERSON') {
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
          data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
          '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance === null) {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
          data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
          '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="'
            + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="'
            + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e2Instance.entityType.entityType === 'GEO') {
          iconString = 'fa fa-globe';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
          data[i].roleName + '</td><td><u>' + data[i].e2Instance.name +
          '</u></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Geo Entity" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
          data[i].roleName + '</td><td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
          '</u></a></td><td><i title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString
          + '" aria-hidden="true"></i></td></tr>';
        }
      } else if (data[i].documentsStatus === 'ACCEPTED') {
        if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance === null) {
          iconString = 'fa fa-male';
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;border-bottom: none;"><td style="display: none">' + data[i].e1Id +
            '</td><td>' + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance === null && data[i].e2Instance.entityType.entityType === 'PERSON') {
          iconString = 'fa fa-male';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
          data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
          '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          iconString = 'fa fa-male';
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance === null) {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
          data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
          '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="'
            + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e2Instance.entityType.entityType === 'GEO') {
          iconString = 'fa fa-globe';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
          data[i].roleName + '</td><td><u>' + data[i].e2Instance.name +
          '</u></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Geo Entity" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
          data[i].roleName + '</td><td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
          '</u></a></td><td><i title="Accepted" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        }
      } else if (data[i].documentsStatus === 'ESCALATED') {
        if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance === null) {
          iconString = 'fa fa-male';
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;border-bottom: none;"><td style="display: none">' + data[i].e1Id
            + '</td><td>' + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance === null && data[i].e2Instance.entityType.entityType === 'PERSON') {
          iconString = 'fa fa-male';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
          data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
          '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          iconString = 'fa fa-male';
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="'
            + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance === null) {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
          + data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name + '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>' + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:' + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString + '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name + '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>' + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:' + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString + '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e2Instance.entityType.entityType === 'GEO') {
          iconString = 'fa fa-globe';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
          data[i].roleName + '</td><td><u>' + data[i].e2Instance.name +
          '</u></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Geo Entity" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
          + data[i].roleName + '</td><td><a class="third-party-detail-link" style="color:black" href="'
          + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
          '</u></a></td><td><i title="Escalated" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        }
      } else {
        if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance === null) {
          iconString = 'fa fa-male';
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;border-bottom: none;"><td style="display: none">' + data[i].e1Id +
            '</td><td>' + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="'
            + this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance === null && data[i].e2Instance.entityType.entityType === 'PERSON') {
          iconString = 'fa fa-male';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>'
          + data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
          '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          iconString = 'fa fa-male';
          if (forEntity === data[i].e1Instance.name) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance === null) {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
          data[i].roleName + '</td>';
          htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
          '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>' +
          completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            iconString = 'fa fa-male';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:' +
            data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            iconString = 'fa fa-building';
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'LEGAL' && data[i].e2Instance.entityType.entityType === 'LEGAL') {
          iconString = 'fa fa-building';
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index + '" style="text-align: center;"><td style="display: none">'
            + data[i].e1Id + '</td><td>' + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e1Instance.entityType.entityType === 'PERSON' && data[i].e2Instance.entityType.entityType === 'PERSON') {
          iconString = 'fa fa-male';
          if (forEntity.trim() === data[i].e1Instance.name.trim()) {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>'
            + data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="'
            + this.getDetailLink(data[i].e2Id) + '" target="_blank"><u>' + data[i].e2Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          } else {
            htmlString += '<tr class="role-detail-table-row-' + index +
            '" style="text-align: center;"><td style="display: none">' + data[i].e1Id + '</td><td>' +
            data[i].roleName + '</td>';
            htmlString += '<td><a class="third-party-detail-link" style="color:black" href="' +
            this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
            '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
            + completenessIcon + '</td><td style="text-align: center"><i title="Physical Person" style="color:'
            + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
            '" aria-hidden="true"></i></td></tr>';
          }
        } else if (data[i].e2Instance.entityType.entityType === 'GEO') {
          iconString = 'fa fa-globe';
          htmlString += '<tr class="role-detail-table-row-' + index +
          '" style="text-align: center;"><td style="display: none">' + data[i].e2Id + '</td><td>'
          + data[i].roleName + '</td><td><u>' + data[i].e2Instance.name +
          '</u></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Geo Entity" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString +
          '" aria-hidden="true"></i></td></tr>';
        } else {
          iconString = 'fa fa-building';
          htmlString += '<tr class="role-detail-table-row-' + index
          + '" style="text-align: center;"><td style="display: none">'
          + data[i].e1Id + '</td><td>' + data[i].roleName +
          '</td><td><a class="third-party-detail-link" style="color:black" href="' +
          this.getDetailLink(data[i].e1Id) + '" target="_blank"><u>' + data[i].e1Instance.name +
          '</u></a></td><td><i title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i></td><td>'
          + completenessIcon + '</td><td style="text-align: center"><i title="Moral Person" style="color:'
          + data[i].entityTypeColor + ';font-size: 22px;" class="' + iconString + '" aria-hidden="true"></i></td></tr>';
        }
      }
    }

    forEntity = '';

    return htmlString;
  }

  goToDetail() {
    const button = document.getElementById('navigation-button') as HTMLButtonElement;
    const id = button.innerText;
    this.router.navigate(['detail/' + id]);
  }

  updateArchived(entityId: any) {

    let entityResponse: any;
    this.kybService.getEntity(entityId).subscribe(
      data => {
        entityResponse = data;

        const selectedTags = entityResponse.data.tags;
        const tagIds = [];

        for (let k = 0; k < selectedTags.length; k++) {
          tagIds.push(selectedTags[k].id);
        }

        const updatedEntity = new EntityUpdate();

        updatedEntity.delegateEmail = 'admin@admin.com';
        updatedEntity.entityId = entityId;
        updatedEntity.ownerId = entityResponse.data.owner.identifier;
        updatedEntity.entityStatus2E = 'ARCHIVED';
        updatedEntity.entityTypeId = entityResponse.data.entityType.id;
        updatedEntity.primaryName = entityResponse.data.primaryName;
        updatedEntity.secondaryName = entityResponse.data.secondaryName;
        updatedEntity.tagIds = tagIds;
        this.kybService.updateEntity(updatedEntity).subscribe(
          _result => {
            this.alertService.showSuccess('Entity status successfully set.');
            this.showAll();      },
          error => {
          }
        );
      }
    );
  }
}
