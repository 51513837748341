import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { config } from '../../../../assets/configuration';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { Chart } from 'angular-highcharts';
import * as svg from 'save-svg-as-png';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'app-entity-report',
    templateUrl: 'entityReport.component.html',
    styleUrls: ['./entityReport.component.css']
})

export class EntityReportComponent implements OnInit {

  allEntitiesResponse: any = {};
  entities: any = [];
  entitiesLoading = false;
  entityResponse: any = {};
  rolesResponse: any = {};
  documentContentResponse: any = {};
  basicInformation: any = {};
  pdfResponse: any = {};
  loading2 = false;
  eventsForEntityResponse: any = {};
  reportLogo: any = config.reportLogo;
  entityId = '';
  loadingImg: any = config.loadingImg;
  timestampFrom: any = null;
  timestampTo: any = null;
  testFile: any;

  controlChartString: any;

  tags: any = [];
  entityName = '';

  relationResponse: any = {};
  controlResponse: any = {};
  UBOResponse: any = {};
  exclamationImage = '../../../assets/images/exclamation.png';
  chart: Chart;
  chart2: Chart;
  controlChart: Chart;
  uboChart: Chart;
  emptyUBO = false;
  emptyControl = false;
  entityType: any;
  relationshipGraphFile: File;
  controlChartFile: File;
  uboChartFile: File;

  relationshipGraphData: any = [];
  relationshipGraphString: any;

  controls: any = [];
  controlData: any = [];
  controlNodes: any = [];

  owners: any = [];
  uboData: any = [];
  uboNodes: any = [];
  entityLevel = 0;
  titles: any = [];

  uboLevels: any = [];
  index: any;
  uboChartString: any;

  counter = 0;

  exportResponse: any;

  constructor(
      private router: Router,
      private callsService: CallsService,
      private alertService: AlertService,
      private kybService: KYBService,
      private authenticationService: AuthenticationService,
      private modalService: BsModalService
  ) {}

  ngOnInit() {
      this.testFile = '../../../../assets/images/exclamation.png';
      this.timestampTo = new Date();
      this.timestampFrom = new Date();

      this.timestampFrom.setDate(this.timestampFrom.getDate() - 7);

      this.timestampFrom = this.timestampFrom.toISOString();
      this.timestampTo = this.timestampTo.toISOString();

      this.kybService.getAllEntities({}).subscribe(
          data => {
              this.allEntitiesResponse = data;
              this.entities = this.allEntitiesResponse.data;
              this.entitiesLoading = false;
          },
          error => { }
      );
  }

  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  prettyTimestamp(timestamp: any) {
      return formatDate(timestamp, 'dd/MM/yyyy, h:mm:ss a z', 'en');
  }

  clientDossierForEntity(entityId: any) {
    this.kybService.downloadClientDossier(entityId).subscribe(data => {
      if (data) {
        this.blobToFile(data, 'text/xml', 'KYC3_Client_Dossier.zip');
      }
    });
  }

  reportForEntity(entityId: any) {
      this.entityId = entityId;

      for (let i = 0; i < this.entities.length; i++) {
          if (this.entities[i].id === this.entityId) {
              this.entityName = this.entities[i].name;
          }
      }

      this.tags = [];
      this.loading2 = true;

      this.getGraphs();
  }

  getGraphs() {
      this.relationResponse = {};

      this.kybService.getRelationships(this.entityId).subscribe(
        data => {
          this.relationResponse = data;
          if (this.relationResponse.data.roles.length === 0) {
              this.relationshipGraphString = null;
              this.export();
          } else {
              this.drawRelationshipGraph(this.relationResponse.data.roles);
              // this.prepareOrganizationalChart(this.relationResponse.data.roles, []);
          }
        },
        error => {}
      );

      this.kybService.getUBOGraph(this.entityId, 'control').subscribe(
        data => {
          this.controlResponse = data;
          if (this.controlResponse.data.controls.length === 0 && this.controlResponse.data.owners.length === 0) {
              this.controlChartString = null;
          } else {
              this.drawControlChart(this.controlResponse.data);
          }
        },
        error => {}
      );

      this.kybService.getUBOGraph(this.entityId, 'owner').subscribe(
        data => {
          this.UBOResponse = data;

          if (this.UBOResponse.data.owners.length === 0 && this.UBOResponse.data.controls.length === 0) {
              this.uboChartString = null;
          } else {
              this.drawUBOChart(this.UBOResponse.data);
          }
        },
        error => {}
      );
  }

  drawRelationshipGraph(relationship: any) {
    this.entityType = 'legal';
    this.relationshipGraphData = [];
    let height = '';

    if (this.entityType !== 'legal') {
      height = '50%';
    } else {
      height = '100%';
    }

    if (relationship.length === 0) {
      this.relationshipGraphData.push({ 'from': this.entityName, 'to': this.entityName });
    } else {
      for (let i = 0; i < relationship.length; i++) {
        // tslint:disable-next-line:max-line-length
        this.relationshipGraphData.push({ 'from': relationship[i].entity1.entityName + ' ' + relationship[i].entity1.secondaryName, 'to': relationship[i].entity2.entityName + ' ' + relationship[i].entity2.secondaryName, 'name': relationship[i].roleName });
      }
    }

    this.chart = new Chart({
      chart: {
        type: 'networkgraph',
        height: height
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        networkgraph: {
          marker: {
            radius: 20,
            fillColor: '#68799c'
          },
          keys: ['from', 'to'],
          layoutAlgorithm: {
            enableSimulation: false,
            linkLength: 40,
            friction: -0.9
          },
          lineWidth: 4
        }
      },
      series: [{
        type: 'networkgraph',
        dataLabels: {
          enabled: true,
          linkTextPath: {
            attributes: {
              dy: 12
            }
          },
          linkFormat: 'is {point.name} of',
          // {point.fromNode.name}
        },
        id: 'lang-tree',
        color: '#68799c',
        data: this.relationshipGraphData,
      }]
      ,
      exporting: {
        enabled: false
      }
    });

    const parentThis = this;

    setTimeout(() => {
        parentThis.export();
    }, 3000);
  }

  prepareControlData(startEntity: any, entities: any) {
    for (let i = 0; i < entities.length; i++) {
      this.controls.push({ from: startEntity, to: entities[i].entityName });

      this.controlNodes.push({ id: entities[i].entityName, title: entities[i].entityName,
        name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName + '-' +
        entities[i].entityType, detailId: entities[i].id });

      if (entities[i].entities.length > 0) {
        this.prepareControlData(entities[i].entityName, entities[i].entities);
      }
    }

    return this.controls;
  }

  drawControlChart(controlData: any) {
    if (this.controlChart !== undefined) {
      this.controlChart.destroy();
    }

    if (controlData.controls.length === 0) {
      this.emptyControl = true;
      this.controlData = [];
      this.controlNodes = [];
      this.controls = [];
    } else {
      this.emptyControl = false;
      this.controlData = [];
      this.controlNodes = [];
      this.controls = [];
      this.controlData = this.prepareControlData(controlData.entity.entityName, controlData.controls);
      this.controlNodes.push({ id: controlData.entity.entityName, title: controlData.entity.entityName,
        name: 'FOCUS Company', detailId: controlData.entity.id });
    }

    this.controlChartString = JSON.stringify(
        {
            chart: {
              inverted: true,
              style: {
                cursor: 'pointer'
              }
            },
            title: {
                text: ''
            },
            series: [{
              type: 'organization',
              name: controlData.entity.entityName,
              keys: ['from', 'to'],
              data: this.controlData,
              levels: [{
                level: 0,
                color: 'silver',
                dataLabels: {
                  color: 'white',
                },
              }, {
                level: 1,
                color: 'green',
                dataLabels: {
                  color: 'white'
                },
              }, {
                level: 2,
                color: 'green',
                dataLabels: {
                  color: 'white'
                }
              }, {
                level: 3,
                color: 'green',
                dataLabels: {
                  color: 'white'
                }
              }, {
                level: 4,
                color: 'green',
                dataLabels: {
                  color: 'white'
                }
              }],
              nodes: this.controlNodes,
              events: {
              },
              colorByPoint: false,
              color: '#007ad0',
              dataLabels: {
                color: 'white'
              },
              borderColor: 'white',
              nodeWidth: 65
            }],
            exporting: {
              enabled: true,
              allowHTML: true,
              sourceWidth: 800
            },
            credits: {
              enabled: false
            },
          }
    );
  }

  prepareUBOData(startEntity: any, entities: any) {
    this.entityLevel++;

    for (let i = 0; i < entities.length; i++) {
      let cyclesWith = '';

      this.owners.push({ from: entities[i].entityName, to: startEntity });

      if (entities[i].cycles.length > 0) {
        for (let j = 0; j < entities[i].cycles.length; j++) {
          cyclesWith += entities[i].cycles[j].name + ',';
        }
      }

      if (cyclesWith !== '') {
        for (let z = 0; z < this.uboNodes.length; z++) {
          if (this.uboNodes[z].title === entities[i].entityName) {
            this.uboNodes[z].color = '#cc0000';
            this.uboNodes[z].cycles = cyclesWith.replace(/,([^,]*)$/, '$1');
            this.uboNodes[z].image = this.exclamationImage;
          }
        }

        if (this.titles.indexOf(entities[i].entityName) === -1) {
          this.titles.push(entities[i].entityName);

          this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, name: 'E' +
          (entities[i].distance + 1) + '-' + entities[i].roleName + '-' + entities[i].entityType, detailId:
          entities[i].id, color: '#cc0000', cycles: cyclesWith.replace(/,([^,]*)$/, '$1'),
          image: this.exclamationImage, layout: 'hanging' });
        }
      } else {
        if (this.titles.indexOf(entities[i].entityName) === -1) {
          this.titles.push(entities[i].entityName);

          if (entities[i].distance > 1) {
            this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, name: 'E' +
            (entities[i].distance + 1) + '-' + entities[i].roleName + '-' + entities[i].entityType, detailId:
            entities[i].id, cycles: '', layout: 'hanging' });
          } else {
            this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, name: 'E' +
            (entities[i].distance + 1) + '-' + entities[i].roleName + '-' + entities[i].entityType, detailId:
            entities[i].id, cycles: '' });
          }
        }
      }

      if (entities[i].entities.length > 0) {
        this.prepareUBOData(entities[i].entityName, entities[i].entities);
      }
    }

    return this.owners;
  }

  drawUBOChart(UBOdata: any) {
    this.titles = [];

    if (this.uboChart !== undefined) {
      this.uboChart.destroy();
    }

    if (UBOdata.owners.length === 0) {
      this.emptyUBO = true;
      this.uboData = [];
      this.uboNodes = [];
      this.uboLevels = [];
      this.entityLevel = 0;
      this.owners = [];
    } else {
      this.emptyUBO = false;
      this.uboData = [];
      this.uboNodes = [];
      this.uboLevels = [];
      this.entityLevel = 0;
      this.owners = [];
      this.uboData = this.prepareUBOData(UBOdata.entity.entityName, UBOdata.owners);

      // Check this for the colors
      for (let i = 0; i < this.entityLevel; i++) {
        this.uboLevels.push({ level: i, color: 'blue', dataLabels: { color: 'white' } });
      }

      this.uboNodes.push({ id: UBOdata.entity.entityName, title: UBOdata.entity.entityName,
        name: 'FOCUS Company', detailId: UBOdata.entity.id, color: 'silver' });

      let title = '';

      for (let x = 0; x < this.uboNodes.length; x++) {
        if (this.uboNodes[x].name === 'FOCUS Company') {
          title = this.uboNodes[x].title;
          this.index = x;
        }
      }

      for (let y = 0; y < this.uboNodes.length; y++) {
        if (this.uboNodes[y].title === title && this.uboNodes[y].name !== 'FOCUS Company') {
          this.uboNodes[this.index].color = '#cc0000';
          this.uboNodes[this.index].cycles = this.uboNodes[y].cycles;
          this.uboNodes[this.index].image = this.uboNodes[y].image;
          this.uboNodes.splice(y, 1);
        }
      }

      let cycleEntities = [];

      for (let p = 0; p < this.uboNodes.length; p++) {
        if (this.uboNodes[p].name === 'FOCUS Company') {
          this.index = p;
        }

        if (this.uboNodes[p].cycles !== '') {
          if (this.uboNodes[p].cycles !== undefined) {
            cycleEntities = this.uboNodes[p].cycles.split(',');
          }
        }
      }

      for (let q = 0; q < this.uboData.length; q++) {
        if (this.uboData[q].from === this.uboNodes[this.index].title && cycleEntities.indexOf(this.uboData[q].to) !== -1) {
          this.uboData.splice(q, 1);
        }
      }

      this.uboChartString = JSON.stringify({
        chart: {
          inverted: true
        },
        title: {
          text: ''
        },
        series: [{
          type: 'organization',
          name: UBOdata.entity.entityName,
          keys: ['from', 'to'],
          data: this.uboData,
          levels: this.uboLevels,
          nodes: this.uboNodes,
          events: {
          },
          colorByPoint: false,
          color: '#007ad0',
          dataLabels: {
            color: 'white'
          },
          borderColor: 'white'
        }],
        exporting: {
          enabled: true,
          allowHTML: true,
          sourceWidth: 800
        },
        credits: {
          enabled: false
        },
      });
    }
  }

  prepareOrganizationalChart(relationship: any, inputData: any) {
    if (relationship.length === 0) {
      inputData.push([this.entityName, this.entityName]);
    } else {
      this.counter += relationship.length;

      for (let i = 0; i < relationship.length; i++) {
        if (relationship[i].documentStatus === 'UNEVALUATED') {
          inputData.push({ 'from': relationship[i].entity1.entityName + ' ' + relationship[i].entity1.secondaryName, 'to':
          relationship[i].entity2.entityName + ' ' + relationship[i].entity2.secondaryName, 'name': relationship[i].roleName,
          'color': '#bdbdbd' });
        } else if (relationship[i].documentStatus === 'ACCEPTED') {
          inputData.push({ 'from': relationship[i].entity1.entityName + ' ' + relationship[i].entity1.secondaryName,
          'to': relationship[i].entity2.entityName + ' ' + relationship[i].entity2.secondaryName, 'name': relationship[i].roleName,
          'color': '#72C245' });
        } else if (relationship[i].documentStatus === 'ESCALATED') {
          inputData.push({ 'from': relationship[i].entity1.entityName + ' ' + relationship[i].entity1.secondaryName,
          'to': relationship[i].entity2.entityName + ' ' + relationship[i].entity2.secondaryName, 'name': relationship[i].roleName,
          'color': '#FDD32B' });
        } else {
          inputData.push({ 'from': relationship[i].entity1.entityName + ' ' + relationship[i].entity1.secondaryName,
          'to': relationship[i].entity2.entityName + ' ' + relationship[i].entity2.secondaryName, 'name':
          relationship[i].roleName, 'color': '#D60000' });
        }

        if (relationship[i].entity1.roles.length > 0) {
          this.prepareOrganizationalChart(relationship[i].entity1.roles, inputData);
        }

        if (relationship[i].entity2.roles.length > 0) {
          this.prepareOrganizationalChart(relationship[i].entity2.roles, inputData);
        }
      }
    }

    this.drawOrganizationalChart(inputData);
  }

  drawOrganizationalChart(inputData: any) {
    this.chart2 = new Chart({
      chart: {
        inverted: true,
        type: 'organization',
      },
      plotOptions: {
        organization: {
          dataLabels: {
            enabled: true
          },
        }
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: [{
        type: undefined,
        keys: ['from', 'to', 'name', 'color'],
        label: {
          enabled: true
        },
        data: inputData,
        colorByPoint: false,
        color: '#007ad0',
        dataLabels: {
          enabled: true,
          color: 'white',
          fontSize: '8px'
        },
        borderColor: 'white',
        nodeWidth: 65
      }]
    }
    );
  }

  export() {
    if (this.relationResponse.data.roles.length > 0) {
        svg.svgAsPngUri(document.getElementsByClassName('highcharts-root')[0], {}, (uri) => {
            this.relationshipGraphString = uri.split('data:image/png;base64,')[1];

            this.kybService.getPdfReport2(this.entityId, localStorage.getItem('username').toUpperCase(),
            this.relationshipGraphString, this.controlChartString, this.uboChartString).subscribe(
                data => {
                    this.pdfResponse = data;
                    const binaryImg = atob(this.pdfResponse.data.reportData);
                    const length = binaryImg.length;
                    const arrayBuffer = new ArrayBuffer(length);
                    const uintArray = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < length; i++) {
                        uintArray[i] = binaryImg.charCodeAt(i);
                    }

                    const file = new Blob([uintArray], { type: 'application/pdf' });

                    FileSaver.saveAs(file, 'entity-risk-report-' + this.pdfResponse.data.reportName + '.pdf');

                    this.loading2 = false;
                    this.entityId = '';
                },
                error => {
                    this.loading2 = false;
                }
            );
        });
    } else {
        this.kybService.getPdfReport2(this.entityId, localStorage.getItem('username').toUpperCase(),
        this.relationshipGraphString, this.controlChartString, this.uboChartString).subscribe(
            data => {
                this.pdfResponse = data;
                const binaryImg = atob(this.pdfResponse.data.reportData);
                const length = binaryImg.length;
                const arrayBuffer = new ArrayBuffer(length);
                const uintArray = new Uint8Array(arrayBuffer);

                for (let i = 0; i < length; i++) {
                    uintArray[i] = binaryImg.charCodeAt(i);
                }

                const file = new Blob([uintArray], { type: 'application/pdf' });

                FileSaver.saveAs(file, 'entity-risk-report-' + this.pdfResponse.data.reportName + '.pdf');

                this.loading2 = false;
                this.entityId = '';
            },
            error => {
                this.loading2 = false;
            }
        );
    }
  }
}
