
  <div class='modal-header'>
  <h4 class='modal-title pull-left'>Evaluate document</h4>
  <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
  <span aria-hidden='true'>&times;</span>
  </button>
</div>
<div class='modal-body'>
  <button class='btn btn-success' (click)='accept("ACCEPTED")'>Accept</button>&nbsp;&nbsp;
  <button class='btn btn-warning' (click)='escalate("ESCALATED")'>Escalate</button>&nbsp;&nbsp;
  <button class='btn btn-danger' (click)='reject("REJECTED")'>Reject</button>&nbsp;&nbsp;
  <button class='btn btn-primary' *ngIf='displayPrevious' (click)='goToPrevious()'>Previous</button>&nbsp;&nbsp;
  <button class='btn btn-primary' *ngIf='displayNext' (click)='goToNext()'>Next</button>
  <div style='height: 15px;'></div>
  Document type: <b>{{documents[currentDocument]?.name}}</b>
  <div style='height: 15px;'></div>
  Current document status is:
  <ng-container [ngSwitch] = 'currentStatus'>
     <span *ngSwitchCase='"UNEVALUATED"' style='color: gray;'><b>UNEVALUATED</b></span>
     <span *ngSwitchCase='"ACCEPTED"' style='color: #5cb85c;'><b>ACCEPTED</b></span>
     <span *ngSwitchCase='"ESCALATED"' style='color: #f0ad4e;'><b>ESCALATED</b></span>
     <span *ngSwitchCase='"REJECTED"' style='color: #d9534f;'><b>REJECTED</b></span>
  </ng-container>
  <br>
  <div style='height: 15px;'></div>
  <div *ngIf='showContextualData && documents[currentDocument].contextualData'>
    Word list:&nbsp;&nbsp;<b>{{documents[currentDocument]?.contextualData}}</b>
    <div style='height: 15px;'></div>
  </div>
  Comment:&nbsp;<input type='text' [(ngModel)]='comment' style='width: 100%' />
  <div style='height: 15px;'></div>
  <img *ngIf='showImage && !multiplePictures' id='safePicture' [src]='safePicture' style='width: 75%; height: auto'/>
  <div *ngIf='multiplePictures && showImage'>
     <img id='safeIdFront' [src]='safeIdFront' style='width: 75%; height: auto'/>
     <div style='height: 15px;'></div>
     <img id='safeIdBack' [src]='safeIdBack' style='width: 75%; height: auto'/>
  </div>
  <video style='display:none;' id='selfie-video' width='100%' controls></video>
  <button *ngIf='showPdf' class='btn btn-primary' (click)='viewFullPdf()'>Open Full PDF</button>
  <div style='height: 5px;'></div>
  <pdf-viewer *ngIf='showPdf' [src]='pdfSrc'
  [render-text]='true'
  [show-all]='false'
  [original-size]='false'
  [autoresize]='true'
  style='display: block;'></pdf-viewer>
  <span><i>{{message}}</i></span>
  <div style='height: 15px;'></div>
  <table *ngIf='showJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 65%'>
     <tr *ngFor='let item of textContent | keyvalue' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>{{item.key}}</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{item.value}}</td>
     </tr>
  </table>
  <table *ngIf='showPhoneJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 65%'>
     <tr style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>assessmentType</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.assessmentType}}</td>
     </tr>
     <tr *ngIf='riskAssessmentType != "CONNECTION_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>description</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.description}}</td>
      </tr>
      <tr *ngIf='riskAssessmentType == "GEOLOCATION"' style='padding-left: 10px;'>
      <td style='padding: 5px;border: 1px solid #cacaca'><b>assessment</b></td>
      <td style='padding: 5px;border: 1px solid #cacaca' [innerHTML]='textContent.assessment | geoAssessment'></td>
    </tr>
      <tr *ngIf='riskAssessmentType == "PHONE_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>assessment</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca' [innerHTML]='textContent.assessment | phoneAssessment'></td>
      </tr>
      <tr *ngIf='riskAssessmentType == "CONNECTION_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>assessment</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca' [innerHTML]='textContent.assessment | connectionAssessment'></td>
      </tr>
      <tr *ngIf='riskAssessmentType == "PHONE_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskLevel</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskLevel}}</td>
      </tr>
      <tr *ngIf='riskAssessmentType == "VIDEO_LIVENESS_VERIFICATION" || riskAssessmentType == "VIDEO_FACE_MATCH"' style='padding-left: 10px;'>
      <td style='padding: 5px;border: 1px solid #cacaca'><b>riskStatus</b></td>
      <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskStatus}}</td>
      </tr>
      <tr *ngIf='riskAssessmentType == "VIDEO_LIVENESS_VERIFICATION" || riskAssessmentType == "VIDEO_FACE_MATCH"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskType</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskType}}</td>
      </tr>
  </table>
</div>
<div class='modal-footer'>
  <button type='button' class='btn btn-default' (click)='bsModalRef.hide()'>Close</button>
</div>
  