import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as $ from 'jquery';

/**
* Implements password changing directive
*/
@Component({
    moduleId: module.id,
    selector: 'app-screening-risk-settings',
    templateUrl: 'screeningRiskSettings.component.html',
    styleUrls: [ './screeningRiskSettings.component.css' ]
})

export class ScreeningRiskSettingsComponent implements OnInit {

  dataTable: any;
  editModalRef: BsModalRef;
  // addModalRef: BsModalRef;
  currentTopic: any = {};
  screeningRiskResponse: any = {};
  screeningRisks: any = [];

  screeningRisk: any = {};

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private kybService: KYBService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.kybService.listScreeningRisk().subscribe(
      data => {
        this.screeningRiskResponse = data;
        this.screeningRisks = this.screeningRiskResponse;
      },
      error => {}
    );
  }

  initTable() {
    const table: any = $('.table');
    this.dataTable = table.DataTable(
        {
            'oLanguage': { 'sEmptyTable': 'No legal person types found.' },
            'orderCellsTop': true,
            'columnDefs': [
                { 'orderable': false, 'targets': 3 }
            ],
            'order': [],
            'paging': false,
            'retrieve': true,
            'lengthChange': false,
            'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
            'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
            'select': { style: 'os' }
        }
    );

    if (this.screeningRisk.length > 0) {
      const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
      empty[0].setAttribute('style', 'display: none;');
    }
  }

  edit(editTemplate: TemplateRef<any>, legalPersonType: any) {
    this.currentTopic = legalPersonType;
    this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
  }

  save() {
    this.screeningRisk = {};
    this.screeningRisk.id = this.currentTopic.id;
    this.screeningRisk.riskFactor = this.currentTopic.riskFactor;
    this.screeningRisk.topicLabel = this.currentTopic.topicLabel;
    this.screeningRisk.riskType = this.currentTopic.riskType;
    this.kybService.saveScreeningRisk(this.screeningRisk).subscribe(
      data => {
        this.editModalRef.hide();
        this.alertService.showSuccess('Risk edited successfully.');
        this.currentTopic = {};
        this.ngOnInit();

      },
      error => {}
    );
  }

}
