<div class="col-lg-12 box" xmlns:height="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4"></div>
    <div class="col-lg-4" style="color:black;text-align: right; padding-right: 40px">Logged in as <span [style.color]="mainColor" style="font-size: 16px">{{userName}}</span></div>
  </div>
  <div style="height: 20px"></div>
  <p class="text-center" style="color: black;font-family: 'Open Sans', sans-serif;">Entity List -  <span class="text-primary" [style.color]="mainColor">{{companyName}}</span></p> 
  <div style="height: 20px"></div>
  <progressbar *ngIf="displayBar" [value]="100" style="background-color: #286090;" [striped]="true" [animate]="true">Performing {{usersToCheckNo}} Check(s)...</progressbar>
  <div style="height: 20px"></div>    
      <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;margin-left: -140px;"> 
        <div style="height: 40px"></div>         
        <table class="table">
            <thead>
              <tr>
                <th>Name</th>
                <!-- <th>PEP</th>
                <th>Sanction</th>
                <th>Entity Status</th>
                <th>Dossier Status</th>
                <th>Dossier Risk Level</th>
                <th>Roles</th>-->
                <th>Complete</th>
                <th>Created</th>
                <th>Detail</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let entity of entities">
                <td>{{entity.primaryName}} {{entity.secondaryName}}</td>
                <!-- <td [ngSwitch] = "entity?.pepScore">
                  <i *ngSwitchCase="'0'" title="Not Started" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i>
                  <i *ngSwitchCase="'1'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                  <i *ngSwitchCase="'2'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                  <i *ngSwitchCase="'3'" title="High Risk" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                </td>
                <td [ngSwitch] = "entity?.sanctionScore">
                  <i *ngSwitchCase="'0'" title="Not Started" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i>
                  <i *ngSwitchCase="'1'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                  <i *ngSwitchCase="'2'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                  <i *ngSwitchCase="'3'" title="High Risk" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                </td>
                <td>{{entity?.entityStatus}}</td>
                <td>
                  <ng-container *ngIf="entity?.dossierStatus != null">
                  <select style="color: black;" id="dossierStatus" class="ng-control" name="dossierStatus" [(ngModel)]="entity.dossierStatus" value="{{entity.dossierStatus}}" #dossierStatus="ngModel" (change)="setDossierStatus(entity.id,entity.dossierStatus)">
                  <option value="applicant">Applicant</option>
                  <option value="inreview">InReview</option>
                  <option value="accepted">Accepted</option>
                  <option value="noncompliant">NonCompliant</option>
                  <option value="frozen">Frozen</option>
                  <option value="closed">Closed</option>
                  </select>
                </ng-container>
                </td>
                <td>Low</td>
                <td [ngSwitch] = "entity?.entityType" style="font-size: 22px;">
                  <i *ngSwitchCase="'Physical Person'"   title="Physical Person" style="color:black" class="fa fa-male" aria-hidden="true"></i>
                  <i *ngSwitchCase="'Moral Person'" title="Moral Person" style="color: black" class="fa fa-building" aria-hidden="true"></i>
                </td> -->
                <td></td>
                <td>Wed Oct 16th, 16:58</td>
                <td><a style="color: black" [routerLink]="['/detail', entity.id]"><u>Detail</u></a></td>
                <td><button>Edit</button></td>
            </tr>
            </tbody>
          </table>  
    </div> 
    <div style="height: 10px"></div>
    <ng-template #exportTemplate>
      <div class="modal-header">
        <p class="modal-title pull-left"><b>CSV Export</b></p>
        <button type="button" class="close pull-right" aria-label="Close" (click)="exportModal.hide()">
          <span aria-hidden="true" style="color: gray; font-size: 35px">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
          <div class="form-group">
            <label for="exportOption" style="color: black">Select an export option:</label><br />
              <select id="exportOption" class="ng-control" name="exportOption" [(ngModel)]="exportOption" placeholder="Select export option" required>
                      <option value="all" selected="selected">Export all users</option>
                      <option value="allUnchecked">Export all unchecked users (checks haven't been run)</option>
                      <option value="notStarted">Export all users with no KYC status (have been checked but not reviewed)</option>
                      <option value="whitelist">Export users on whitelist (low KYC status)</option>
                      <option value="onHold">Export users on hold (medium KYC status)</option>
                      <option value="blacklist">Export users on blacklist (high KYC status)</option>
              </select>
      </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="export(exportOption)">Export</button>
      </div>
    </ng-template>
    <form #editForm="ngForm" class="editForm" novalidate>
      <ng-template #editTemplate>
        <div class="modal-header">
          <p class="modal-title pull-left"><b>Edit User</b></p>
          <button type="button" class="close pull-right" aria-label="Close" (click)="editModal.hide()">
            <span aria-hidden="true" style="color: gray; font-size: 35px">&times;</span>
          </button>
        </div>
        <div class="modal-body">
                <div class="form-group">
                    <label class="control-label" for="firstName"><b>First Name:</b></label>  
                    <input class="form-control input-md" name="firstName" #firstname="ngModel" type="text" value="{{model?.data?.formData?.firstName}}" maxlength="65" [(ngModel)]="editUser.firstName" required>  
                    <div class="alert alert-danger" *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)">First name is required</div> 
                </div>
                <div class="form-group">
                    <label class="control-label" for="lastName"><b>Last Name:</b></label>  
                    <input class="form-control input-md" name="lastName" #lastname="ngModel" type="text" value="{{model?.data?.formData?.lastName}}" maxlength="65" [(ngModel)]="editUser.lastName" required>
                    <div class="alert alert-danger" [hidden]="lastname.valid">Last name is required</div> 
                </div>
                <div class="form-group">
                    <label class="control-label" for="email"><b>Email:</b></label>  
                    <input class="form-control input-md" name="email" #email="ngModel" type="text" value="{{model?.data?.email}}" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" maxlength="100" [(ngModel)]="editUser.email" required>
                    <div class="alert alert-danger" [hidden]="email.valid">Email is required and must be valid</div> 
                </div>
                <div class="form-group">
                    <label class="control-label" for="dateOfBirth"><b>Date of birth:</b> (DD/MM/YYYY)</label>  
                    <input class="form-control input-md" name="dateOfBirth" #dateofbirth="ngModel" type="text" value="{{model?.data?.formData?.dateOfBirth}}" [(ngModel)]="editUser.dateOfBirth" maxlength="10" required>
                    <div class="alert alert-danger" [hidden]="dateofbirth.valid">Date of birth is required</div> 
                </div>
                <div class="form-group">
                    <label class="control-label" for="nationality"><b>Nationality:</b></label>  
                    <input class="form-control input-md" name="nationality" #nationality="ngModel" type="text" value="{{model?.data?.formData?.nationality}}" [(ngModel)]="editUser.nationality" maxlength="255" required>
                    <div class="alert alert-danger" [hidden]="nationality.valid">Nationality is required</div> 
                </div>
                <div class="form-group">
                    <label class="control-label" for="address"><b>Address:</b></label>  
                    <input class="form-control input-md" name="address" #address="ngModel" type="text" value="{{model?.data?.formData?.address}}" [(ngModel)]="editUser.address" required>
                    <div class="alert alert-danger" [hidden]="address.valid">Address is required</div> 
                </div>
                <div class="form-group">
                    <label class="control-label" for="taxCountry"><b>Tax Country:</b></label>  
                    <ng-select [searchable]="true" id="taxCountry" class="ng-control" placeholder="{{model?.data?.formData?.taxCountry}}" name="taxCountry" [(ngModel)]="editUser.taxCountry" #taxCountry (change)=isTaxCountryFilled() required>
                            <ng-option value="AF">Afghanistan</ng-option>
                            <ng-option value="AL">Albania</ng-option>
                            <ng-option value="DZ">Algeria</ng-option>
                            <ng-option value="AS">American Samoa</ng-option>
                            <ng-option value="AD">Andorra</ng-option>
                            <ng-option value="AO">Angola</ng-option>
                            <ng-option value="AI">Anguilla</ng-option>
                            <ng-option value="AQ">Antarctica</ng-option>
                            <ng-option value="AG">Antigua and Barbuda</ng-option>
                            <ng-option value="AR">Argentina</ng-option>
                            <ng-option value="AM">Armenia</ng-option>
                            <ng-option value="AW">Aruba</ng-option>
                            <ng-option value="AU">Australia</ng-option>
                            <ng-option value="AT">Austria</ng-option>
                            <ng-option value="AZ">Azerbaijan</ng-option>
                            <ng-option value="BS">Bahamas</ng-option>
                            <ng-option value="BH">Bahrain</ng-option>
                            <ng-option value="BD">Bangladesh</ng-option>
                            <ng-option value="BB">Barbados</ng-option>
                            <ng-option value="BY">Belarus</ng-option>
                            <ng-option value="BE">Belgium</ng-option>
                            <ng-option value="BZ">Belize</ng-option>
                            <ng-option value="BJ">Benin</ng-option>
                            <ng-option value="BM">Bermuda</ng-option>
                            <ng-option value="BT">Bhutan</ng-option>
                            <ng-option value="BO">Bolivia</ng-option>
                            <ng-option value="BQ">Bonaire, Sint Eustatius and Saba</ng-option>
                            <ng-option value="BA">Bosnia and Herzegovina</ng-option>
                            <ng-option value="BW">Botswana</ng-option>
                            <ng-option value="BV">Bouvet Island</ng-option>
                            <ng-option value="BR">Brazil</ng-option>
                            <ng-option value="IO">British Indian Ocean Territory</ng-option>
                            <ng-option value="BN">Brunei Darussalam</ng-option>
                            <ng-option value="BG">Bulgaria</ng-option>
                            <ng-option value="BF">Burkina Faso</ng-option>
                            <ng-option value="BI">Burundi</ng-option>
                            <ng-option value="KH">Cambodia</ng-option>
                            <ng-option value="CM">Cameroon</ng-option>
                            <ng-option value="CA">Canada</ng-option>
                            <ng-option value="CV">Cape Verde</ng-option>
                            <ng-option value="KY">Cayman Islands</ng-option>
                            <ng-option value="CF">Central African Republic</ng-option>
                            <ng-option value="TD">Chad</ng-option>
                            <ng-option value="CL">Chile</ng-option>
                            <ng-option value="CN">China</ng-option>
                            <ng-option value="CX">Christmas Island</ng-option>
                            <ng-option value="CC">Cocos (Keeling) Islands</ng-option>
                            <ng-option value="CO">Colombia</ng-option>
                            <ng-option value="KM">Comoros</ng-option>
                            <ng-option value="CG">Congo</ng-option>
                            <ng-option value="CD">Congo, the Democratic Republic of the</ng-option>
                            <ng-option value="CK">Cook Islands</ng-option>
                            <ng-option value="CR">Costa Rica</ng-option>
                            <ng-option value="CI">Côte d'Ivoire</ng-option>
                            <ng-option value="HR">Croatia</ng-option>
                            <ng-option value="CU">Cuba</ng-option>
                            <ng-option value="CW">Curaçao</ng-option>
                            <ng-option value="CY">Cyprus</ng-option>
                            <ng-option value="CZ">Czech Republic</ng-option>
                            <ng-option value="DK">Denmark</ng-option>
                            <ng-option value="DJ">Djibouti</ng-option>
                            <ng-option value="DM">Dominica</ng-option>
                            <ng-option value="DO">Dominican Republic</ng-option>
                            <ng-option value="EC">Ecuador</ng-option>
                            <ng-option value="EG">Egypt</ng-option>
                            <ng-option value="SV">El Salvador</ng-option>
                            <ng-option value="GQ">Equatorial Guinea</ng-option>
                            <ng-option value="ER">Eritrea</ng-option>
                            <ng-option value="EE">Estonia</ng-option>
                            <ng-option value="ET">Ethiopia</ng-option>
                            <ng-option value="FK">Falkland Islands (Malvinas)</ng-option>
                            <ng-option value="FO">Faroe Islands</ng-option>
                            <ng-option value="FJ">Fiji</ng-option>
                            <ng-option value="FI">Finland</ng-option>
                            <ng-option value="FR">France</ng-option>
                            <ng-option value="GF">French Guiana</ng-option>
                            <ng-option value="PF">French Polynesia</ng-option>
                            <ng-option value="TF">French Southern Territories</ng-option>
                            <ng-option value="GA">Gabon</ng-option>
                            <ng-option value="GM">Gambia</ng-option>
                            <ng-option value="GE">Georgia</ng-option>
                            <ng-option value="DE">Germany</ng-option>
                            <ng-option value="GH">Ghana</ng-option>
                            <ng-option value="GI">Gibraltar</ng-option>
                            <ng-option value="EL">Greece</ng-option>
                            <ng-option value="GL">Greenland</ng-option>
                            <ng-option value="GD">Grenada</ng-option>
                            <ng-option value="GP">Guadeloupe</ng-option>
                            <ng-option value="GU">Guam</ng-option>
                            <ng-option value="GT">Guatemala</ng-option>
                            <ng-option value="GG">Guernsey</ng-option>
                            <ng-option value="GN">Guinea</ng-option>
                            <ng-option value="GW">Guinea-Bissau</ng-option>
                            <ng-option value="GY">Guyana</ng-option>
                            <ng-option value="HT">Haiti</ng-option>
                            <ng-option value="HM">Heard Island and McDonald Islands</ng-option>
                            <ng-option value="VA">Holy See (Vatican City State)</ng-option>
                            <ng-option value="HN">Honduras</ng-option>
                            <ng-option value="HK">Hong Kong</ng-option>
                            <ng-option value="HU">Hungary</ng-option>
                            <ng-option value="IS">Iceland</ng-option>
                            <ng-option value="IN">India</ng-option>
                            <ng-option value="ID">Indonesia</ng-option>
                            <ng-option value="IR">Iran, Islamic Republic of</ng-option>
                            <ng-option value="IQ">Iraq</ng-option>
                            <ng-option value="IE">Ireland</ng-option>
                            <ng-option value="IM">Isle of Man</ng-option>
                            <ng-option value="IL">Israel</ng-option>
                            <ng-option value="IT">Italy</ng-option>
                            <ng-option value="JM">Jamaica</ng-option>
                            <ng-option value="JP">Japan</ng-option>
                            <ng-option value="JE">Jersey</ng-option>
                            <ng-option value="JO">Jordan</ng-option>
                            <ng-option value="KZ">Kazakhstan</ng-option>
                            <ng-option value="KE">Kenya</ng-option>
                            <ng-option value="KI">Kiribati</ng-option>
                            <ng-option value="KP">Korea, Democratic People's Republic of</ng-option>
                            <ng-option value="KR">Korea, Republic of</ng-option>
                            <ng-option value="KW">Kuwait</ng-option>
                            <ng-option value="KG">Kyrgyzstan</ng-option>
                            <ng-option value="LA">Lao People's Democratic Republic</ng-option>
                            <ng-option value="LV">Latvia</ng-option>
                            <ng-option value="LB">Lebanon</ng-option>
                            <ng-option value="LS">Lesotho</ng-option>
                            <ng-option value="LR">Liberia</ng-option>
                            <ng-option value="LY">Libya</ng-option>
                            <ng-option value="LI">Liechtenstein</ng-option>
                            <ng-option value="LT">Lithuania</ng-option>
                            <ng-option value="LU">Luxembourg</ng-option>
                            <ng-option value="MO">Macao</ng-option>
                            <ng-option value="MK">North Macedonia</ng-option>
                            <ng-option value="MG">Madagascar</ng-option>
                            <ng-option value="MW">Malawi</ng-option>
                            <ng-option value="MY">Malaysia</ng-option>
                            <ng-option value="MV">Maldives</ng-option>
                            <ng-option value="ML">Mali</ng-option>
                            <ng-option value="MT">Malta</ng-option>
                            <ng-option value="MH">Marshall Islands</ng-option>
                            <ng-option value="MQ">Martinique</ng-option>
                            <ng-option value="MR">Mauritania</ng-option>
                            <ng-option value="MU">Mauritius</ng-option>
                            <ng-option value="YT">Mayotte</ng-option>
                            <ng-option value="MX">Mexico</ng-option>
                            <ng-option value="FM">Micronesia, Federated States of</ng-option>
                            <ng-option value="MD">Moldova, Republic of</ng-option>
                            <ng-option value="MC">Monaco</ng-option>
                            <ng-option value="MN">Mongolia</ng-option>
                            <ng-option value="ME">Montenegro</ng-option>
                            <ng-option value="MS">Montserrat</ng-option>
                            <ng-option value="MA">Morocco</ng-option>
                            <ng-option value="MZ">Mozambique</ng-option>
                            <ng-option value="MM">Myanmar</ng-option>
                            <ng-option value="NA">Namibia</ng-option>
                            <ng-option value="NR">Nauru</ng-option>
                            <ng-option value="NP">Nepal</ng-option>
                            <ng-option value="NL">Netherlands</ng-option>
                            <ng-option value="NC">New Caledonia</ng-option>
                            <ng-option value="NZ">New Zealand</ng-option>
                            <ng-option value="NI">Nicaragua</ng-option>
                            <ng-option value="NE">Niger</ng-option>
                            <ng-option value="NG">Nigeria</ng-option>
                            <ng-option value="NU">Niue</ng-option>
                            <ng-option value="NF">Norfolk Island</ng-option>
                            <ng-option value="MP">Northern Mariana Islands</ng-option>
                            <ng-option value="NO">Norway</ng-option>
                            <ng-option value="OM">Oman</ng-option>
                            <ng-option value="PK">Pakistan</ng-option>
                            <ng-option value="PW">Palau</ng-option>
                            <ng-option value="PS">Palestinian Territory, Occupied</ng-option>
                            <ng-option value="PA">Panama</ng-option>
                            <ng-option value="PG">Papua New Guinea</ng-option>
                            <ng-option value="PY">Paraguay</ng-option>
                            <ng-option value="PE">Peru</ng-option>
                            <ng-option value="PH">Philippines</ng-option>
                            <ng-option value="PN">Pitcairn</ng-option>
                            <ng-option value="PL">Poland</ng-option>
                            <ng-option value="PT">Portugal</ng-option>
                            <ng-option value="PR">Puerto Rico</ng-option>
                            <ng-option value="QA">Qatar</ng-option>
                            <ng-option value="RE">Réunion</ng-option>
                            <ng-option value="RO">Romania</ng-option>
                            <ng-option value="RU">Russian Federation</ng-option>
                            <ng-option value="RW">Rwanda</ng-option>
                            <ng-option value="BL">Saint Barthélemy</ng-option>
                            <ng-option value="SH">Saint Helena, Ascension and Tristan da Cunha</ng-option>
                            <ng-option value="KN">Saint Kitts and Nevis</ng-option>
                            <ng-option value="LC">Saint Lucia</ng-option>
                            <ng-option value="MF">Saint Martin (French part)</ng-option>
                            <ng-option value="PM">Saint Pierre and Miquelon</ng-option>
                            <ng-option value="VC">Saint Vincent and the Grenadines</ng-option>
                            <ng-option value="WS">Samoa</ng-option>
                            <ng-option value="SM">San Marino</ng-option>
                            <ng-option value="ST">Sao Tome and Principe</ng-option>
                            <ng-option value="SA">Saudi Arabia</ng-option>
                            <ng-option value="SN">Senegal</ng-option>
                            <ng-option value="RS">Serbia</ng-option>
                            <ng-option value="SC">Seychelles</ng-option>
                            <ng-option value="SL">Sierra Leone</ng-option>
                            <ng-option value="SG">Singapore</ng-option>
                            <ng-option value="SX">Sint Maarten (Dutch part)</ng-option>
                            <ng-option value="SK">Slovakia</ng-option>
                            <ng-option value="SI">Slovenia</ng-option>
                            <ng-option value="SB">Solomon Islands</ng-option>
                            <ng-option value="SO">Somalia</ng-option>
                            <ng-option value="ZA">South Africa</ng-option>
                            <ng-option value="GS">South Georgia and the South Sandwich Islands</ng-option>
                            <ng-option value="SS">South Sudan</ng-option>
                            <ng-option value="ES">Spain</ng-option>
                            <ng-option value="LK">Sri Lanka</ng-option>
                            <ng-option value="SD">Sudan</ng-option>
                            <ng-option value="SR">Suriname</ng-option>
                            <ng-option value="SJ">Svalbard and Jan Mayen</ng-option>
                            <ng-option value="SZ">Swaziland</ng-option>
                            <ng-option value="SE">Sweden</ng-option>
                            <ng-option value="CH">Switzerland</ng-option>
                            <ng-option value="SY">Syrian Arab Republic</ng-option>
                            <ng-option value="TW">Taiwan, Province of China</ng-option>
                            <ng-option value="TJ">Tajikistan</ng-option>
                            <ng-option value="TZ">Tanzania, United Republic of</ng-option>
                            <ng-option value="TH">Thailand</ng-option>
                            <ng-option value="TL">Timor-Leste</ng-option>
                            <ng-option value="TG">Togo</ng-option>
                            <ng-option value="TK">Tokelau</ng-option>
                            <ng-option value="TO">Tonga</ng-option>
                            <ng-option value="TT">Trinidad and Tobago</ng-option>
                            <ng-option value="TN">Tunisia</ng-option>
                            <ng-option value="TR">Turkey</ng-option>
                            <ng-option value="TM">Turkmenistan</ng-option>
                            <ng-option value="TC">Turks and Caicos Islands</ng-option>
                            <ng-option value="TV">Tuvalu</ng-option>
                            <ng-option value="UG">Uganda</ng-option>
                            <ng-option value="UA">Ukraine</ng-option>
                            <ng-option value="AE">United Arab Emirates</ng-option>
                            <ng-option value="UK">United Kingdom</ng-option>
                            <ng-option value="US">United States</ng-option>
                            <ng-option value="UM">United States Minor Outlying Islands</ng-option>
                            <ng-option value="UY">Uruguay</ng-option>
                            <ng-option value="UZ">Uzbekistan</ng-option>
                            <ng-option value="VU">Vanuatu</ng-option>
                            <ng-option value="VE">Venezuela, Bolivarian Republic of</ng-option>
                            <ng-option value="VN">Viet Nam</ng-option>
                            <ng-option value="VG">Virgin Islands, British</ng-option>
                            <ng-option value="VI">Virgin Islands, U.S.</ng-option>
                            <ng-option value="WF">Wallis and Futuna</ng-option>
                            <ng-option value="EH">Western Sahara</ng-option>
                            <ng-option value="YE">Yemen</ng-option>
                            <ng-option value="ZM">Zambia</ng-option>
                            <ng-option value="ZW">Zimbabwe</ng-option>
                          </ng-select>
                    <div class="alert alert-danger" [hidden]="taxCountryFilled">Tax country is required</div>
                </div>  
                <div class="form-group">
                    <label class="control-label" for="amount"><b>Amount:</b></label>  
                    <input class="form-control input-md" name="amount" #amount="ngModel" type="text" value="{{model?.data?.formData?.amount}}" [(ngModel)]="editUser.amount" pattern="^[0-9]+(\.[0-9]{1,10})?$" required>
                    <div class="alert alert-danger" [hidden]="amount.valid">Amount is required</div> 
                </div>
                <div class="form-group">
                    <label class="control-label" for="currencyType"><b>Currency:</b></label>  
                    <ng-select [searchable]="true" id="currencyType" class="ng-control" placeholder="{{model?.data?.formData?.currencyType}}" name="currencyType" [(ngModel)]="editUser.currencyType" (change)="isCurrencyFilled()" required>
                            <ng-option value="BTC">BTC</ng-option>
                            <ng-option value="ETH">ETH</ng-option>
                            <ng-option value="EUR">EUR</ng-option>
                            <ng-option value="USD">USD</ng-option>
                    </ng-select>
                    <div class="alert alert-danger" [hidden]="currencyFilled">Currency is required</div>
                </div>
                <div class="form-group" [ngSwitch] = "editUser.currencyType">
                        <div *ngSwitchCase="'BTC'">
                            <label class="control-label" for="walletAddress"><b>BTC Wallet Address:</b></label>  
                            <input class="form-control input-md" #walletAddress="ngModel" value="{{model?.data?.formData?.walletAddress}}" required pattern="^[13][a-km-zA-HJ-NP-Z0-9]{26,33}$" id="walletAddress" name="walletAddress" type="text" maxlength="256" placeholder="BTC wallet address" [(ngModel)]="editUser.walletAddress">
                            <div class="alert alert-danger" [hidden]="walletAddress.valid">BTC Wallet address is required and must be valid</div>
                        </div>
                        <div *ngSwitchCase="'ETH'">
                            <label class="control-label" for="walletAddress"><b>ETH Wallet Address:</b></label>  
                            <input class="form-control input-md" #walletAddress="ngModel" value="{{model?.data?.formData?.walletAddress}}" required pattern="^0x[a-fA-F0-9]{40}$" id="walletAddress" name="walletAddress" type="text" maxlength="256" placeholder="ETH wallet address" [(ngModel)]="editUser.walletAddress">
                            <div class="alert alert-danger" [hidden]="walletAddress.valid">ETH Wallet address is required and must be valid</div>
                        </div>
                </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" (click)="saveUser()" [disabled]="!editForm.valid">Save</button><button class="btn btn-danger" (click)="editModal.hide()">Cancel</button>
        </div>
      </ng-template>
    </form>
    <div class="row">
      <div class="col-lg-12" style="padding-left: 0px;margin-left: -210px;">
          <ul class="list-unstyled list-inline text-center" style="width: 106%;">
              <!-- <li class="list-inline-item">
                  <button style="padding: 8px" class="btn btn-primary" (click)="exportAll()" title="Export all users">Export all users</button>
              </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="exportUnchecked()" title="Export users that have not been checked">Export unchecked users</button>
              </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="exportNotStarted()" title="Export users with undetermined KYC status">Export users with no status</button>
              </li>
              <li class="list-inline-item">
                  <button style="padding: 8px" class="btn btn-primary" (click)="exportWhitelist()" title="Export users with 'low' KYC status">Export whitelist</button>
              </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="exportOnHold()" title="Export users with 'medium' KYC status">Export users on hold</button>
              </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="exportBlacklist()" title="Export users with 'high' KYC status">Export blacklist</button>
            </li> -->
            <li class="list-inline-item" *ngIf="!allSelected">
              <button style="padding: 8px" class="btn btn-primary" (click)="selectAll()" title="Select all">Select all</button>
            </li>
            <li class="list-inline-item" *ngIf="allSelected">
              <button style="padding: 8px" class="btn btn-primary" (click)="deselectAll()" title="Select all">Deselect all</button>
            </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="exportSelected()" title="Export selected"><i class="fa fa-file"></i>&#160;&#160;Export selected</button>
              </li>
              <!-- <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="displayExport(exportTemplate)" title="Export"><i class="fa fa-file" aria-hidden="true"></i>&#160;&#160;Export options</button>
              </li> -->
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="deleteUser()" title="Delete user"><i class="fa fa-trash"></i>&#160;&#160;Delete selected</button>
              </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="getUserInfo()" title="Edit user"><i class="fa fa-pencil"></i>&#160;&#160;Edit selected</button>
              </li>
              <li class="list-inline-item">
                  <button style="padding: 8px" class="btn btn-primary" (click)="checkUsers()" title="Run KYC checks for selected users"><i class="fa fa-check" aria-hidden="true"></i>&#160;&#160;Check selected</button>
              </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="displayEvaluateDocumentsModal(evaluateDocumentsTemplate)" title="Evaluate documents"><i class="fa fa-balance-scale" aria-hidden="true"></i>&#160;&#160;Evaluate documents</button>
              </li>
              <li class="list-inline-item">
                <button style="padding: 8px" class="btn btn-primary" (click)="displayScreenerModal(screenerResultsTemplate)" title="See screener results"><i class="fa fa-eye" aria-hidden="true"></i>&#160;&#160;Screener results</button>
              </li>
            </ul>
      </div>
    </div>
    <div style="height: 40px"></div>
    <div class="row">
      <div class="col-lg-12">
      </div>
    </div>
    <div style="height: 40px"></div>
    <ng-template #screenerResultsTemplate>
      <div class="modal-header">
        <p class="modal-title pull-left">Screening Results</p>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalRefreshTable()">
          <span aria-hidden="true" style="color: gray; font-size: 35px">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-5">
            Email / Username: <b>{{userData?.email}}</b><br>
            First name: <b>{{userData?.formData?.firstName}}</b><br>
            Last name: <b>{{userData?.formData?.lastName}}</b><br>
          </div>
          <div class="col-md-5">
              Date of Birth: <b>{{userData?.formData?.dateOfBirth}}</b><br>
              Nationality: <b>{{userData?.formData?.taxCountry}}</b><br>
              Address: <b>{{userData?.formData?.address}}</b>
          </div>
          <div class="col-md-1"></div>
        </div>
        <div class="row">
          <div class="col-md-1">
            <!-- <div style="height: 220px;"></div> -->
            <!-- <i *ngIf="currentMatch != 0" class="fa fa-arrow-left" style="font-size: 18px;" aria-hidden="true" (click)="previousMatch()"></i> -->
          </div>
          <div class="col-md-10">
              <div style="height: 20px;"></div>
              <h4>PROBABLE MATCHES</h4> Match <b>{{matchCount}}</b> of <b>{{matchNo}}</b> for this user
              <div style="height: 20px;"></div>
              <div class="pep-hits-detail">
                    <table width="100%" style="border: 1px solid whitesmoke">
                      <tr>
                        <td width="100%">
                            <button class="btn btn-link btn-block clearfix" style="background-color: none;border-bottom: 3px solid #daaa0e;color: white;font-weight: bold;" (click)="accordionClick()" accordion-heading>
                                <!-- <div class="pull-right float-right" style="padding-left: 5px;margin-right: -15px;"><i id="expand" class="fa fa-caret-down" tooltip="Expand/Collapse" style="font-size: 18px;color:darkgrey;"></i></div> -->
                                <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../assets/images/icons/add_further.png" width="20px" class="float-right" tooltip="Move to Further Research Matches" (click)="moveToFurther(formattedMatches[currentMatch]?.match?.entryId,userData?.email,'PROBABLE_MATCH_TO_FURTHER_RESEARCH_MATCH')" /></div>
                                <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../assets/images/icons/add_false.png" width="20px" class="float-right" tooltip="Move to Whitelisted Matches" (click)="moveToWhitelisted(formattedMatches[currentMatch]?.match?.entryId,userData?.email,'PROBABLE_MATCH_TO_WHITELISTED_MATCH')" /></div>
                                <div class="pull-right float-right" style="padding-left: 5px;"><img src="../../../assets/images/icons/add_hit.png" width="20px" class="float-right" tooltip="Move to Confirmed Matches" (click)="moveToConfirmed(formattedMatches[currentMatch]?.match?.entryId,userData?.email,'PROBABLE_MATCH_TO_CONFIRMED_MATCH')" /></div>
                                <div *ngIf="formattedMatches[currentMatch]?.match?.entity?.entityName != null" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{formattedMatches[currentMatch]?.match?.entity?.entityName}}</div>
                                <div *ngIf="formattedMatches[currentMatch]?.match?.entity?.entityName == null && formattedMatches[currentMatch]?.match?.entity?.names.length > 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e">{{formattedMatches[currentMatch]?.match?.entity?.names[0]}}</div>
                                <div *ngIf="formattedMatches[currentMatch]?.match?.entity?.entityName == null && formattedMatches[currentMatch]?.match?.entity?.names.length == 0" class="pull-left float-left pep-hit-probable" style="color: #daaa0e"></div>
                            </button>
                        </td>
                      </tr>
                      <tr>
                        <td style="color: black;width: 100%" width="100%">
                            <div style="height: 10px;"></div>
                            <span *ngIf="formattedMatches[currentMatch]?.match?.note != null && formattedMatches[currentMatch]?.match?.note != ''" class="note">
                                {{formattedMatches[currentMatch]?.match?.note}}
                             </span>
                             <div style="height: 10px;"></div>
                             <ul>
                                <li *ngIf="formattedMatches[currentMatch]?.match?.entity?.description[0]">{{formattedMatches[currentMatch]?.match?.entity?.description[0]}}</li>
                                <li><b>First seen:</b> {{formattedMatches[currentMatch]?.match?.entity?.firstSeen | date}}</li>
                                <li><b>Last seen:</b> {{formattedMatches[currentMatch]?.match?.entity?.lastSeen | date}}</li>
                                <li><b>AKA Names:</b> {{formattedMatches[currentMatch]?.match?.entity?.names}}</li>
                                <li *ngIf="formattedMatches[currentMatch]?.match?.entity?.birthDates && formattedMatches[currentMatch]?.match?.entity?.birthDates.length > 0"><b>Birth date:</b> {{formattedMatches[currentMatch]?.match?.entity?.birthDates}}</li>
                                <li><b>PEP:</b> <span *ngIf="formattedMatches[currentMatch]?.match?.entity?.pep == true">Yes</span><span *ngIf="formattedMatches[currentMatch]?.match?.entity?.pep == false">No</span></li>
                                <li><b>Sanction List:</b> {{formattedMatches[currentMatch]?.match?.entryId | sanctionListPipe}}</li>
                                <li><b>Score:</b> <ng-container *ngIf="formattedMatches[currentMatch]?.match?.score != 0">{{formattedMatches[currentMatch]?.match?.score}}</ng-container><ng-container *ngIf="formattedMatches[currentMatch]?.match?.score == 0">Not calculated</ng-container></li>
                                <div style="height: 10px;"></div>
                                <input class="note-input col-md-10" id="note" name="note" type="text" [(ngModel)]="note" placeholder="Enter note here..."><br>
                                <div style="height: 5px;"></div><br>
                                <button class="btn btn-primary" style="margin-left: -15px;" (click)="addNote(userData?.email,formattedMatches[currentMatch]?.match?.entryId,note)">Save note</button>&#160;&#160;&#160;<button class="btn btn-primary" (click)="seeDetails(formattedMatches[currentMatch]?.match?.entity?.entityName)">See details</button>
                             </ul>
                              <div style="height: 10px"></div>
                        </td>
                      </tr>
                    </table>
                    <div *ngIf="errorMessage != ''" style="color: crimson;">
                      {{errorMessage}}
                    </div>
              </div>
          </div>
          <div class="col-md-1">
            <!-- <div style="height: 220px;"></div> -->
            <!-- <i class="fa fa-arrow-right" style="font-size: 18px;" aria-hidden="true" (click)="nextMatch()"></i> -->
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger" (click)="closeModalRefreshTable()">Close</button>
      </div>
    </ng-template>
    <ng-template #evaluateDocumentsTemplate>
        <div class="modal-header">
          <p class="modal-title pull-left">Document Evaluator</p>
          <button type="button" class="close pull-right" aria-label="Close" (click)="closeDocumentModalRefreshTable()">
            <span aria-hidden="true" style="color: gray; font-size: 35px">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div style="height: 20px;"></div>
            <h4 style="padding-left: 90px;">INFORMATION PROVIDED BY USER</h4>
            <div style="height: 20px;"></div>
            <div class="col-md-1"></div>
            <div class="col-md-5">
              Email / Username: <b>{{evaluateUserData?.data?.email}}</b><br>
              First name: <b>{{evaluateUserData?.data?.formData?.firstName}}</b><br>
              Last name: <b>{{evaluateUserData?.data?.formData?.lastName}}</b><br>
            </div>
            <div class="col-md-5">
                Date of Birth: <b>{{evaluateUserData?.data?.formData?.dateOfBirth}}</b><br>
                Nationality: <b>{{evaluateUserData?.data?.formData?.taxCountry}}</b><br>
                Address: <b>{{evaluateUserData?.data?.formData?.address}}</b>
            </div>
            <div class="col-md-1"></div>
          </div>
          <div class="row">
            <div class="col-md-1">
              <!-- <div style="height: 220px;"></div>
              <i *ngIf="currentDocument != 0" class="fa fa-arrow-left" style="font-size: 18px;" aria-hidden="true" (click)="previousDocument()"></i> -->
            </div>
            <div class="col-md-10">
                <div style="height: 20px;"></div>
                <h4>DOCUMENT PREVIEW</h4>
                <div style="height: 20px;"></div>
                <p style="font-size: 15px; text-align: center;">{{documentsResponse?.data[this.currentDocument]?.type}}</p>
                <div style="height: 10px;"></div>
                <div style="text-align: center;">
                    <i class="fa fa-check green" style="font-size: 38px;" title="Approve" aria-hidden="true" (click)="approveDocument()"></i>&#160;&#160;
                    <i class="fa fa-arrow-up yellow" style="font-size: 38px;" title="Further research" aria-hidden="true" (click)="furtherDocument()"></i>&#160;&#160;
                    <i class="fa fa-ban crimson" style="font-size: 38px;" aria-hidden="true" title="Reject" (click)="rejectDocument()"></i>
                </div>
                <br>
                <div class="image-container">
                  <ngx-image-zoom [thumbImage]="safeImage" [fullImage]="safeImage" [magnification]="1" [zoomMode]="'hover'" [enableLens]="true" [enableScrollZoom]="false" [circularLens]="true" [lensWidth]="100" [lensHeight]="100"></ngx-image-zoom> 
                </div>
            </div>
            <div class="col-md-1">
              <!-- <div style="height: 220px;"></div> 
              <i class="fa fa-arrow-right" style="font-size: 18px;" aria-hidden="true" (click)="nextDocument()"></i> -->
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-danger" (click)="closeDocumentModalRefreshTable()">Close</button>
        </div>
      </ng-template>
    <!-- <div class="row">
      <div class="col-lg-3">
        <div #pieChart></div> 
      </div>
      <div class="col-lg-6">
        <div #barChart></div>
      </div>
      <div class="col-lg-3">
        <div #anotherChart></div> 
      </div>
    </div> -->
    <!-- <div style="height: 40px"></div>
    <div class="row">
      <div class="col-lg-12">
        <div #progressChart></div> 
      </div>
    </div> -->
</div>

