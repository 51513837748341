import { Component, OnInit } from '@angular/core';
import { AlertService, AuthenticationService, KYBService } from '../../../../_services';
import * as $ from 'jquery';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
  moduleId: module.id,
  selector: 'app-setup-2fa',
  templateUrl: 'setup2FA.component.html',
  styleUrls: ['./setup2FA.component.css']
})

export class Setup2FAComponent implements OnInit {

  constructor( public alertService: AlertService, public authService: AuthenticationService, public kycservice: KYBService) { }

url: any;
  isLoading = false;
  tfaEnabled = false;
  qrInfo = "";
recoveryCodes: any = [];
  private returnUrl = "";
  mfaCode: any;
  showError = false;
  errorMessage = "";
  userPermissions: any = [];

  ngOnInit(): void {

    const email = localStorage.getItem('username') || '';
    this.kycservice.getCurrentUser().subscribe((res: any) => {
      if (!res.data.mfaEnabled) {

        this.authService.getTfaImage(encodeURIComponent(email)).subscribe((response: any) => {
          this.qrInfo = response.data.mfaBody;
          this.recoveryCodes = response.data.recoveryCodes;
          this.saveTextFile(this.recoveryCodes);

        });
      } else {
        this.tfaEnabled = true;
      }
    });


  }


  saveTextFile(data) {
    const blob = new Blob([data], { type: 'text/csv' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.setAttribute('download', 'recoverycodes.txt');
    document.body.appendChild(downloadLink);
    downloadLink.click();
}
  disableTfa() {
    const userId = localStorage.getItem('identifier');

    this.authService.disableTfa()
      .subscribe(response => {
        this.tfaEnabled = false;
        this.authService.logout();

        this.isLoading = false;
      }
      );
  }

  enableTfa() {
    const userId = localStorage.getItem('identifier');
    const email = localStorage.getItem('username') || '';

    this.authService.postTfaSetup(this.mfaCode)
      .subscribe(resp => {
        if (resp.status = "MFA has been enabled") {
          this.tfaEnabled = true;
          this.isLoading = false;
          this.authService.logout();

        }},
        error => {

          this.alertService.showError('Wrong mfa');

        });


  }



}
