import { OnInit, OnChanges } from '@angular/core';
import { config } from '../../../assets/configuration';
import { CallsService, KYBService } from '../../_services';
import { FormBuilder } from '@angular/forms';
import { RequiredDoc } from '../../_models/requiredDoc.model';
var PackageManagerComponent = /** @class */ (function () {
    function PackageManagerComponent(callsService, kybService, formBuilder) {
        this.callsService = callsService;
        this.kybService = kybService;
        this.formBuilder = formBuilder;
        this.onboardingEntity = {};
        this.representativeEntity = {};
        this.package = {};
        this.createPackageResponse = {};
        this.onboardingUrlResponse = {};
        this.documentTypeResponse = {};
        this.documentTypes = [];
        this.mainColor = config.mainColor;
        this.loading = false;
        this.createForm();
    }
    Object.defineProperty(PackageManagerComponent.prototype, "links", {
        get: function () {
            return this.requiredLinkForm.get('links');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PackageManagerComponent.prototype, "representativeLinks", {
        get: function () {
            return this.representativeRequiredDocsForm.get('representativeLinks');
        },
        enumerable: true,
        configurable: true
    });
    PackageManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.kybService.getDocumentTypes().subscribe(function (data) {
            _this.documentTypeResponse = data;
            _this.documentTypes = _this.documentTypeResponse.data;
        }, function (error) { return console.log(error); });
    };
    PackageManagerComponent.prototype.createPackage = function () {
        var _this = this;
        this.loading = true;
        this.onboardingEntity.ownerEmail = localStorage.getItem("username");
        this.onboardingEntity.entityType = "Counterparty";
        this.onboardingEntity.externalEntityId = Math.floor(Math.random() * 10000);
        this.onboardingEntity.secondaryName = "";
        this.representativeEntity.ownerEmail = localStorage.getItem("username");
        this.representativeEntity.secondaryName = "";
        this.representativeEntity.phoneNumber = "+111";
        this.representativeEntity.entityType = "Representative";
        this.representativeEntity.externalEntityId = Math.floor(Math.random() * 10000);
        this.onboardingEntity.roles = [
            {
                "e1externalId": this.onboardingEntity.externalEntityId,
                "e2externalId": "",
                "roleType": "Identity:Counterparty",
                "requestedDocs": this.requiredLinkForm.value.links
            },
            {
                "e1externalId": this.onboardingEntity.externalEntityId,
                "e2externalId": this.representativeEntity.externalEntityId,
                "roleType": "Representative",
                "requestedDocs": this.representativeRequiredDocsForm.value.representativeLinks
            }
        ];
        this.package.onboardingEntities = [];
        this.package.onboardingEntities.push(this.onboardingEntity);
        this.package.representativeEntity = this.representativeEntity;
        console.log(this.package);
        this.callsService.createPackage(this.package).subscribe(function (data) {
            _this.createPackageResponse = data;
            _this.callsService.getOnboardingUrl(_this.createPackageResponse.packageId).subscribe(function (onboardData) {
                _this.onboardingUrlResponse = onboardData;
                window.alert(_this.onboardingUrlResponse.url);
                _this.loading = false;
            }, function (error) {
                _this.loading = false;
            });
        }, function (error) { return console.log(error); });
    };
    PackageManagerComponent.prototype.createForm = function () {
        this.requiredLinkForm = this.formBuilder.group({
            'links': this.formBuilder.array([])
        });
        this.representativeRequiredDocsForm = this.formBuilder.group({
            'representativeLinks': this.formBuilder.array([])
        });
    };
    PackageManagerComponent.prototype.ngOnChanges = function () {
        this.rebuildForm();
    };
    PackageManagerComponent.prototype.rebuildForm = function () {
        this.requiredLinkForm.reset({});
        this.setDocuments(this.requiredLink.links);
        this.representativeRequiredDocsForm.reset({});
        this.setRepresentativeDocuments(this.representativeRequiredLink.links);
    };
    PackageManagerComponent.prototype.setDocuments = function (requiredDocuments) {
        var requiredDocumentsFA = this.formBuilder.group(requiredDocuments);
        this.requiredLinkForm.setControl('links', requiredDocumentsFA);
    };
    PackageManagerComponent.prototype.setRepresentativeDocuments = function (requiredRepresentativeDocuments) {
        var requiredRepresentativeDocumentsFA = this.formBuilder.group(requiredRepresentativeDocuments);
        this.representativeRequiredDocsForm.setControl('links', requiredRepresentativeDocumentsFA);
    };
    PackageManagerComponent.prototype.addDocument = function () {
        this.links.push(this.formBuilder.group(new RequiredDoc(null, null, null)));
    };
    PackageManagerComponent.prototype.addRepresentativeDocument = function () {
        this.representativeLinks.push(this.formBuilder.group(new RequiredDoc(null, null, null)));
    };
    PackageManagerComponent.prototype.deleteDocument = function (requiredDocument) {
        this.links.controls.forEach(function (link, index, object) {
            if (link.value === requiredDocument) {
                object.splice(index, 1);
            }
        });
        this.links.value.forEach(function (link, index, object) {
            if (link === requiredDocument) {
                object.splice(index, 1);
            }
        });
    };
    PackageManagerComponent.prototype.deleteRepresentativeDocument = function (requiredRepresentativeDocument) {
        this.representativeLinks.controls.forEach(function (link, index, object) {
            if (link.value === requiredRepresentativeDocument) {
                object.splice(index, 1);
            }
        });
        this.representativeLinks.value.forEach(function (link, index, object) {
            if (link === requiredRepresentativeDocument) {
                object.splice(index, 1);
            }
        });
    };
    return PackageManagerComponent;
}());
export { PackageManagerComponent };
var RequiredLink = /** @class */ (function () {
    function RequiredLink() {
    }
    return RequiredLink;
}());
export { RequiredLink };
