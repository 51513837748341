<div class="col-md-12">
  <div style="height: 40px"></div>
    <p class="text-center" style="text-decoration: underline;font-size: 20pt;">Entity Dossier Reports</p>
    <p style="text-align: center;font-size: 15px;">
      Here you can generate a PDF report for an entity which contains comprehensive information about the different risk levels. It includes information on any potential PEP/Sanction hits as well as an overview of roles connected to it, their completeness and documents status.
    </p>
    <div class="table-responsive" style="overflow-x: hidden;">
      <div class="row">
        <div class="col-sm-12"> 
            <div style="height: 40px"></div>
            <p>Select entity:</p>
            <div style="height: 20px"></div><br>
            <ng-select [(ngModel)]="entityId" class="custom" [items]="entities" [loading]="entitiesLoading"  bindLabel="name" bindValue="id" appendTo="body"></ng-select>
            <div style="height: 50px;"></div>
            <button class="btn btn-primary" style="background-color: #355B89;border-color: #355B89;padding: 8px;" (click)="reportForEntity(entityId)" [disabled]="entityId == '' || loading2">Generate Report for Entity</button>
            <button class="btn btn-primary" style="background-color: #355B89;border-color: #355B89;margin-left: 5px;padding: 8px;" (click)="clientDossierForEntity(entityId)" [disabled]="entityId == '' || loading2">Download Client Dossier</button>
            <img *ngIf="loading2" [src]="loadingImg" style="width: 20px;"/>
        </div>
      </div>
    </div>
    <div style="height: 40px"></div>
    <div>
      <div [chart]="chart" style="display: none;"></div>
<!--       <div [chart]="controlChart" style="display: none;"></div>
      <div id="chart2" [chart]="uboChart" style="display: none;"></div> -->
    </div>
    <!-- <button (click)="export()">Export</button> -->
</div>