import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'shorterText'})
export class ShorterTextPipe implements PipeTransform {
  transform(value: any): string {
    let result = '';

    if (value.length > 60) {
      result = value.substring(0, 50) + '...';

      return result;
    } else {
      return value;
    }
  }
}
