<form #passwordForm="ngForm" class="editForm" novalidate>
        <h3 class="text-center">Change Password</h3><br>
        <div class="form-group">
            <label class="control-label" for="oldPassword"><b>Old Password:</b></label>  
            <input class="form-control input-md" #oldPasswordd="ngModel" required id="oldPassword" name="oldPassword" type="password" placeholder="Old Password" [(ngModel)]="oldPassword">   
            <div class="alert alert-danger" [hidden]="oldPasswordd.valid">Old Password is required</div>
        </div>
        <div class="form-group">
            <label class="control-label" for="newPassword"><b>New Password:</b></label>  
            <input class="form-control input-md" #newPasswordd="ngModel" required id="newPassword" name="newPassword" type="password" placeholder="New Password" [(ngModel)]="newPassword">
            <div class="alert alert-danger" [hidden]="newPasswordd.valid">New Password is required</div>
        </div>

        <div class="form-group">
        <button type="button" class="btn btn-primary" [disabled]="!passwordForm.valid" [style.background-color]="mainColor" (click)="changePassword(passwordForm)"> Change Password </button>
        </div>
</form>