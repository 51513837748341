
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Comments History</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <div *ngIf='comments.length > 0'>
        <div *ngFor='let comment of comments'><b>[{{comment.createdAt | date:'dd/MM/yyyy, h:mm:ss a z'}}] {{comment.createdByEmail}}:</b> '{{comment.comment}}'<br></div>
      </div>
      <div *ngIf='comments.length == 0'>
        <span *ngFor=''>Nothing to show.</span>
      </div>
    </div>
    <div class='modal-footer' style='text-align: left;'>
        <input type='text' [(ngModel)]='newComment' style='width: 70%'>&nbsp;&nbsp;
        <button class='btn btn-success' (click)='save()' style='width: 20%;padding: 2px;margin-top: -3px;'>Add comment</button>
    </div>
  