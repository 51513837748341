<form #passwordForm="ngForm" class="editForm" novalidate>
  <h3 class="text-center">Search Engine Screener Settings</h3>
  <br />
  <div style="height: 10px;"></div>
  <!-- <span style="font-size: 14px;"
    ><b>Current setting:</b>
    {{ currentConfig?.searchEngineScreeningInclusiveness }}</span
  >
  <div style="height: 10px;"></div>
  <div class="form-group">
    <label class="control-label" for="searchEngineScreeningInclusiveness"
      ><b>Search Engine Screening Inclusiveness:</b>&nbsp;</label
    >
    <select
      style="color: black;"
      id="searchEngineScreeningInclusiveness"
      class="ng-control"
      name="searchEngineScreeningInclusiveness"
      [(ngModel)]="currentConfig.searchEngineScreeningInclusiveness"
      value="searchEngineScreeningInclusiveness"
      #seinclusivenesss="ngModel"
    >
      <option value="NO_SCREENING">No search engine screening</option>
      <option value="COUNTERPARTIES_ONLY">Counterparties Only</option>
      <option value="COUNTERPARTIES_AND_THIRD_PARTIES"
        >Counterparties and Third Parties</option
      >
    </select>
  </div> -->
<!--   <div style="height: 10px;"></div>
  <div class="form-group">
    <button
      type="button"
      class="btn btn-primary"
      style="background-color:#355B89;border-color: #355B89;"
      [disabled]="!passwordForm.valid"
      (click)="saveSettings()"
    >
      Save Settings
    </button>
  </div> -->
  <div style="height: 10px;"></div>
  <button
    class="bbtn btn-success"
    (click)="addNew(addTemplate)"
    style="background-color:#355B89;border-color: #355B89;"
  >
    Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i>
  </button>
  <div style="height: 10px;"></div>
  <table class="table-search-engine" width="150%" style="text-align: left;">
    <thead>
      <tr>
        <th>Search engine</th>
        <th>Keywords</th>
        <th>Risk Type</th>
        <th>Max. hits</th>
        <th>Affected Entity Types</th>
        <th></th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="searchQueries.length > 0">
      <tr *ngFor="let searchQuery of searchQueries">
        <td>{{ searchQuery?.searchEngineType }}</td>
        <td>{{ searchQuery?.queryKeywords | json }}</td>
        <td>{{ searchQuery?.screeningRisk?.riskType }}</td>
        <td>{{ searchQuery?.maxHits }}</td>
        <td>
          <ul style="list-style: none;">
            <li *ngFor="let entityType of searchQuery.affectedEntityTypes">
              {{ entityType?.legalForm }}
            </li>
          </ul>
        </td>
        <td>
          <i
            class="fa fa-pencil"
            style="color: #f0ad4e;font-size: 18px;"
            aria-hidden="true"
            title="Edit"
            (click)="edit(editTemplate, searchQuery)"
          ></i>
        </td>
        <td>
          <i
            class="fa fa-trash"
            style="color: #d9534f;font-size: 18px;"
            aria-hidden="true"
            title="Delete"
            (click)="delete(searchQuery.id)"
          ></i>
        </td>
      </tr>
    </tbody>
  </table>
</form>
<ng-template #editTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Edit Search Query</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="editModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label> Search engine: </label><br />
    <div style="height: 10px;"></div>
    <select
      style="color: black;"
      id="searchEngineType"
      class="ng-control"
      name="searchEngineType"
      [(ngModel)]="currentSearchQuery.searchEngineType"
      value="currentSearchQuery.searchEngineType"
    >
      <option value="GOOGLE">Google</option>
    </select>
    <div style="height: 10px;"></div>
    <label> Query keywords (press Enter after each word to confirm): </label
    ><br />
    <div style="height: 10px;"></div>
    <ngx-tags-input
      class="form-control input-lg input-grow"
      (onTagsChanged)="keywordsChanged($event)"
      placeholder="Start typing keywords..."
      [(ngModel)]="currentSearchQuery.keywords"
      name="keywords"
    ></ngx-tags-input>
    <div style="height: 10px;"></div>
    <label> Risk type: </label><br />
    <div style="height: 10px;"></div>
    <select
      style="color: black;"
      id="screeningRisk"
      class="ng-control"
      name="screeningRisk"
      [(ngModel)]="currentSearchQuery.screeningRisk"
      value="currentSearchQuery.screeningRisk"
    >
      <option *ngFor="let risk of riskTypes" value="{{ risk.id }}">{{
        risk.riskType
      }}</option>
    </select>
    <div style="height: 10px;"></div>
    <label> Maximum hits (enter a number between 1 and 1000): </label><br />
    <div style="height: 10px;"></div>
    <input
      type="number"
      [(ngModel)]="currentSearchQuery.maxHits"
      step="10"
      min="1"
      max="1000"
    />
    <div style="height: 10px;"></div>
    <label> Affected Entity Types: </label><br />
    <div style="height: 10px;"></div>
    <ng-select
      class="custom"
      [items]="entityTypes"
      [multiple]="true"
      bindLabel="legalPersonType"
      [selectableGroup]="false"
      [closeOnSelect]="false"
      bindValue="id"
      [ngModel]="currentSearchQuery?.affectedEntityTypes"
      (ngModelChange)="currentSearchQuery.affectedEntityTypes = $event"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
        />
        {{ item.legalPersonType }}
      </ng-template>
    </ng-select>
    <div style="height: 10px;"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="save()">Save</button>
  </div>
</ng-template>

<ng-template #addTemplate>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Add Search Query</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="addModalRef.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <label> Search engine: </label><br />
    <div style="height: 10px;"></div>
    <select
      style="color: black;"
      id="searchEngineType"
      class="ng-control"
      name="searchEngineType"
      [(ngModel)]="currentSearchQuery.searchEngineType"
      value="currentSearchQuery.searchEngineType"
    >
      <option value="GOOGLE">Google</option>
    </select>
    <div style="height: 10px;"></div>
    <label> Query keywords (press Enter after each word to confirm): </label
    ><br />
    <div style="height: 10px;"></div>
    <ngx-tags-input
      class="form-control input-grow"
      (onTagsChanged)="keywordsChanged($event)"
      placeholder="Start typing keywords..."
      [(ngModel)]="currentSearchQuery.keywords"
      name="keywords"
    ></ngx-tags-input>
    <div style="height: 10px;"></div>
    <label> Risk type: </label><br />
    <div style="height: 10px;"></div>
    <select
      style="color: black;"
      id="screeningRisk"
      class="ng-control"
      name="screeningRisk"
      [(ngModel)]="currentSearchQuery.screeningRisk"
      value="currentSearchQuery.screeningRisk"
    >
      <option *ngFor="let risk of riskTypes" value="{{ risk.id }}">{{
        risk.riskType
      }}</option>
    </select>
    <div style="height: 10px;"></div>
    <label> Maximum hits (enter a number between 1 and 1000): </label><br />
    <div style="height: 10px;"></div>
    <input
      type="number"
      [(ngModel)]="currentSearchQuery.maxHits"
      step="10"
      min="1"
      max="1000"
    />
    <div style="height: 10px;"></div>
    <label> Affected Entity Types: </label><br />
    <div style="height: 10px;"></div>
    <ng-select
      class="custom"
      [items]="entityTypes"
      [multiple]="true"
      bindLabel="legalPersonType"
      [selectableGroup]="false"
      [closeOnSelect]="false"
      bindValue="id"
      [ngModel]="currentSearchQuery?.affectedEntityTypes"
      (ngModelChange)="currentSearchQuery.affectedEntityTypes = $event"
    >
      <ng-template
        ng-option-tmp
        let-item="item"
        let-item$="item$"
        let-index="index"
      >
        <input
          id="item-{{ index }}"
          type="checkbox"
          [ngModel]="item$.selected"
        />
        {{ item.legalPersonType }}
      </ng-template>
    </ng-select>
    <div style="height: 10px;"></div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="add()">Add</button>
  </div>
</ng-template>
<!-- searchEngineType - required, only "GOOGLE" is available now
queryKeywords - required, must be an empty array if there're no keywords
maxHits - required, inclusive range 1-1000
screeningRiskId - optional, expecting existing screningRisk ID
affectedEntityTypes - required must be an empty array or array of existing entity type IDs -->
