<!-- main header -->
<title>Welcome to {{ companyName }}</title>
<section [ngClass]="{'mobile-version': isOnMobile, 'desktop-version': !isOnMobile}">
    <!-- <dropdown-basic class="selectnav" id="selectnav1"></dropdown-basic> -->
    <div class="menu">
        <nav class=" navbar navbar-inverse navbar-static-top">
            <div class="container">
                <div class="navbar-header">
                    <a class="navbar-brand" href="{{web}}"><img class="mainLogo" *ngIf="logoPath" src="{{logoPath}}"
                            alt="logo">
                    </a>
                </div>
                <div id="navbar" class="navbar-collapse collapse">
                    <ul class="nav navbar-nav navbar-right">
                        <!-- <li><a [routerLink]="['/account']" *ngIf="isLoggedIn()">Account</a></li> -->
                        <!-- <li><a [routerLink]="['/login']" *ngIf="!isLoggedIn()">LOGIN</a></li> -->
                        <!-- <li><a [routerLink]="['/register']" *ngIf="!isLoggedIn()">REGISTER</a></li> -->
                        <!-- <li><a [routerLink]="['/tokens']" *ngIf="!isLoggedIn()">Token Sale</a></li> -->
                        <li><a [routerLink]="['/dashboard']"
                                *ngIf="(isLoggedIn() || loggedIn) && (userPermissions.indexOf('DashboardAccess') !== -1)">DASHBOARD</a>
                        </li>
                        <li><a [routerLink]="['/enterprise']"
                                *ngIf="(isLoggedIn() || loggedIn) && (userPermissions.indexOf('EntityListAccess') !== -1)">ENTITY
                                LIST</a></li>      
                        <li><a [routerLink]="['/screening']"
                                *ngIf="(isLoggedIn() || loggedIn) && (userPermissions.indexOf('ScreeningAccess') !== -1)">SCREENING</a>
                        </li>
                        <li><a [routerLink]="['/reporting']"
                                *ngIf="(isLoggedIn() || loggedIn) && (userPermissions.indexOf('ReportsAccess') !== -1)">REPORTING</a>
                        </li>
                        <li *ngIf="displayResearchTab"><a [routerLink]="['/research']"
                                *ngIf="(isLoggedIn() || loggedIn) && (userPermissions.indexOf('ResearchAccess') !== -1)">RESEARCH</a>
                        </li>
                        <!-- <li><a [routerLink]="['/document-checker']" *ngIf="isLoggedIn() && admin">DOCUMENT CHECKER</a></li> -->
                        <!-- <li><a [routerLink]="['/batch']" *ngIf="isLoggedIn() && admin">BATCH SCREENER</a></li> -->
                        <!-- <li><a [routerLink]="['/user-loader']" *ngIf="(isLoggedIn() || loggedIn) && admin">UPLOADER</a></li> -->
                        <!-- <li><a [routerLink]="['/screening-results']" *ngIf="isLoggedIn() && admin">SCREENING RESULTS</a></li> -->
                        <li *ngIf="(isLoggedIn() || loggedIn)" class='nav-item' dropdown>
                            <a class='nav-link dropdown-toggle' role='button' dropdownToggle><i
                                    class="fa fa-user-circle" style="font-size: 25px;margin-top: -3px;"
                                    aria-hidden="true"></i></a>
                            <ul class='dropdown-menu'>
                                <li><a class='dropdown-item' [routerLink]="['/my-account']"
                                        *ngIf="userPermissions.indexOf('AccountSettingsAccess') !== -1">MY ACCOUNT</a></li>
                                <li><a class='dropdown-item' [routerLink]="['/settings']"
                                        *ngIf="(isLoggedIn() || loggedIn) && userPermissions.indexOf('SettingsAccess') !== -1">SETTINGS</a>
                                </li>
                                <li><a class='dropdown-item' (click)="showAbout()">ABOUT</a></li>
                                <li><a class='dropdown-item' [routerLink]="['/login']">LOGOUT</a></li>
                            </ul>
                        </li>
                        <!-- <li><a [routerLink]="['/settings']" *ngIf="isLoggedIn() && admin">SETTINGS</a></li> -->
                        <li><a [routerLink]="['/login']" *ngIf="!isLoggedIn() || !loggedIn">LOGIN</a></li>
                        <!-- <li><a [routerLink]="['/register']" *ngIf="!isLoggedIn() || !loggedIn">REGISTER</a></li> -->
                        <!-- <li><a [routerLink]="['/login']" *ngIf="isLoggedIn()">LOGOUT</a></li> -->
                    </ul>
                </div>
                <!--/.nav-collapse -->
            </div>
            <!--/.container-fluid -->
        </nav>
    </div>
    <!-- main app container -->
    <div class="jumbotron">
        <div class="container">
            <div class="col-lg-12">
                <!--<alert></alert>-->
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
    <div class="hideOnMobile" style="height: 50px;background-color: white;"></div>
    <!--Footer-->
    <footer *ngIf="!isOnMobile" class="page-footer font-small stylish-color-dark pt-4 mt-4">
        <!--Footer Links-->
        <div class="container text-center text-md-left">
            <div class="row">
                <div class="col-md-6">
                    <div class="text-left">
                        <a href="{{web}}"><img *ngIf="logoPath" src="{{logoPath}}" alt="logo" height="auto"
                                width="120"></a><br>
                        <div style="height: 5px;"></div>
                        <p *ngIf="showFooterText" style="text-align: justify">{{footerText}}</p>
                    </div>
                </div>
                <div class="col-md-2">
                </div>
                <div class="col-md-4">
                    <div class="text-left">
                        <h5 class="text-uppercase mb-4 mt-3 font-weight-bold">{{"CONTACT_US" | translate }}</h5>
                    </div>
                    <div class="text-left">
                        <ul class="list-unstyled">
                            <li>
                                <b>{{"SUPPORT" | translate}}: </b> <a href="mailto:{{supportEmail}}"
                                    [style.color]="mainColor">{{supportEmail}}</a>
                            </li>
                            <li>
                                <b>{{"WEB" | translate}}: </b> <a href="{{web}}" [style.color]="mainColor">{{web}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!--/.Footer Links-->
        <div style="height: 20px;background-color: white;"></div>
        <!--Copyright-->
        <div class="footer-copyright py-3 text-center">
            <p>All dates/times diplayed in UTC</p> Copyright © 2023 KYC3 sarl - Peer Mountain Enterprise Risk Management
            Server, 3.3.16
        </div>
        <!--/.Copyright-->
        <div style="height: 20px;background-color: white;"></div>
        <go-top-button class="text-center" [speed]="95" [animate]="true" [styles]="{
        'border-radius': '0px',
        'color': 'white',
        'background-color': 'black',
        'right': '5px',
        'line-height': '20px',
        'top': '80%'
    }"><i class="fa fa-arrow-up text-center" style="padding-left: 4px;"></i></go-top-button>
    </footer>
    <footer *ngIf="isOnMobile">
        <div class="footer-mobile">
            <a href="{{web}}">
                <img src="assets/images/logo.png" alt="KYC3" style="width: 80px; padding-right: 10px;" /> | <img
                    *ngIf="logoPath" src="{{logoPath}}" alt="logo" height="auto" width="120"></a>
        </div>
        <div class="footer-mobile">
            <div class="clearfix"></div>
            <p><small>©2023 KYC3 sarl. ERMS build 3.3.16</small></p>
        </div>
    </footer>
</section>
<!--/.Footer-->
