import { OnInit, ChangeDetectorRef } from '@angular/core';
import { AlertService, CallsService, KYBService, DataService } from '../../../../_services';
import { Document } from '../../../../_models/kyb';
import { NgForm } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormDataService } from '../../data/formData.service';
import { config } from '../../../../../assets/configuration';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoRecordingService } from '../../../../_services/video-recording.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MobileVideoOnboardingRecordingComponent } from '../mobile-video-onboarding-recording/mobile-video-onboarding-recording.component';
import { TranslateService } from '@ngx-translate/core';
/**
 * Implements the login page
*/
var MobileVideoOnboardingComponent = /** @class */ (function () {
    function MobileVideoOnboardingComponent(translate, modalService, deviceDetectorService, ref, videoRecordingService, sanitizer, kybService, callsService, dataService, formDataService, alertService) {
        this.translate = translate;
        this.modalService = modalService;
        this.deviceDetectorService = deviceDetectorService;
        this.ref = ref;
        this.videoRecordingService = videoRecordingService;
        this.sanitizer = sanitizer;
        this.kybService = kybService;
        this.callsService = callsService;
        this.dataService = dataService;
        this.formDataService = formDataService;
        this.alertService = alertService;
        this.customOnboarding = false;
        this.firstConnection = true;
        this.title = 'Video Recording';
        this.wordsResponse = {};
        this.words = [];
        this.mimeTypeForSaving = '';
        this.loading = false;
        this.email = '';
        this.loadingImg = config.loadingImg;
        this.videoStarted = false;
        this.showRetry = false;
        this.videoConf = { video: { facingMode: 'user', width: 320 }, audio: true };
        this.recordedBlobs = [];
        this.videoResponse = {};
        this.createEntityResponse = {};
        this.roleResponse = {};
        this.getRoleResponse = {};
        this.additionalDocumentIds = [];
        this.requiredDocumentIds = [];
        this.requiredDocumentTypes = [];
        this.documentIds = [];
        this.videoAnalysisResponse = {};
        this.documentData = {};
        this.documentResponse = {};
    }
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "agent", {
        get: function () { return navigator.userAgent || navigator.vendor; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "chrome", {
        get: function () { return !!this.agent.toLowerCase().match(/(chrome)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "safari", {
        get: function () { return !!this.agent.toLowerCase().match(/(safari)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "firefox", {
        get: function () { return !!this.agent.toLowerCase().match(/(firefox)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "firefoxIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(fxios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "chromeIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(crios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "operaIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(opios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "samsungAndroid", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(samsungbrowser)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "android", {
        get: function () { return !!this.agent.match(/(android)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileVideoOnboardingComponent.prototype, "ios", {
        get: function () { return !!this.agent.match(/(iPhone)|(iPod)/i); },
        enumerable: true,
        configurable: true
    });
    MobileVideoOnboardingComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dataService.firstConnection.subscribe(function (message) {
            _this.firstConnection = message;
        });
        // this.start();
        // TODO: Disable Finish button
        this.dataService.stack.subscribe(function (message) { return _this.currentStack = message; });
        this.dataService.currentDocumentIds.subscribe(function (message) { return _this.documentIds = message; });
        this.email = localStorage.getItem('registrationEmail');
        this.personal = this.formDataService.getPersonal();
        this.selfRoleId = this.dataService.onboardingEntitySelfRoleId.getValue();
        this.onboardingEntityId = this.dataService.onboardingEntityId.getValue();
        this.document = new Document();
        this.document.docTypeId = 3;
        this.document.mimeType = 'video/webm';
        this.document.retainTo = '2026-01-01T11:52:05.381';
        this.document.validTo = '2026-01-01T11:52:05.381';
        this.document.totalFiles = 1;
        this.subscribeToStepChanges();
    };
    MobileVideoOnboardingComponent.prototype.subscribeToStepChanges = function () {
        var _this = this;
        this.formDataService.currentRegisterState.subscribe(function (message) {
            if (message === 4) {
                _this.goToNext(_this.personalForm);
            }
        });
    };
    MobileVideoOnboardingComponent.prototype.startVideo = function (ref) {
        var _this = this;
        /*  if (this.ios ) {
           this.document.mimeType = 'video/mp4';
         }
         else
         {
           this.document.mimeType = 'video/webm';
         } */
        if (this.firstConnection) {
            this.kybService.createDocument(this.document, this.onboardingEntityId, this.selfRoleId, this.onboardingEntityId, null).subscribe(function (videoResponse) {
                _this.videoResponse = videoResponse;
                _this.documentId = _this.videoResponse.data.documentId;
                _this.fileId = _this.videoResponse.data.fileIds[0];
                _this.words = JSON.parse(_this.videoResponse.data.contextualData);
                var initialState = { words: _this.words };
                _this.captureImageModalRef = _this.modalService.show(MobileVideoOnboardingRecordingComponent, { initialState: initialState, backdrop: 'static' });
                _this.captureImageModalRef.content.event.subscribe(function (content) {
                    _this.videoBlob = content.data.blob;
                    _this.videoName = content.data.title;
                    _this.videoBlobUrl = window.URL.createObjectURL(content.data.blob);
                    _this.goToNext(null);
                });
            }, function (error) {
                _this.alertService.showError(_this.translate.instant('CONNECTION_VIDEO_START_ERROR'));
            });
        }
        else {
            this.kybService.getConfirmationWords(5).subscribe(function (data) {
                _this.words = data.data;
                var initialState = { words: _this.words };
                _this.captureImageModalRef = _this.modalService.show(MobileVideoOnboardingRecordingComponent, { initialState: initialState, backdrop: 'static' });
                _this.captureImageModalRef.content.event.subscribe(function (content) {
                    _this.videoBlob = content.data.blob;
                    _this.videoName = content.data.title;
                    _this.videoBlobUrl = window.URL.createObjectURL(content.data.blob);
                    _this.goToNext(null);
                });
            }, function (error) { });
        }
    };
    MobileVideoOnboardingComponent.prototype.goToNext = function (form) {
        var _this = this;
        this.showRetry = false;
        // window.alert('Go to next called from mobile');
        this.selfRoleId = this.dataService.onboardingEntitySelfRoleId.getValue();
        this.onboardingEntityId = this.dataService.onboardingEntityId.getValue();
        var blobUrl = this.videoBlobUrl;
        this.loading = true;
        this.callsService.getSelfieBlob(blobUrl).subscribe(function (data) {
            if (!data) {
                return false;
            }
            // if (this.ios ) {
            // this.videoSelfie = new File([data], 'selfieVideo', { type: 'video/mp4', lastModified: Date.now() });
            // }
            // else
            // {
            _this.videoSelfie = new File([data], 'selfieVideo', { type: 'video/webm', lastModified: Date.now() });
            // }
            if (!_this.videoSelfie) {
                return false;
            }
            if (_this.firstConnection) {
                _this.kybService.saveFile(_this.videoSelfie, _this.documentId, _this.fileId).subscribe(function (file) {
                    _this.loading = false;
                    _this.formDataService.setfinishOnboarding(true);
                }, function (error) {
                    _this.loading = false;
                    _this.alertService.showError(_this.translate.instant('CONNECTION_VIDEO_UPLOAD_ERROR'));
                    _this.showRetry = true;
                });
            }
            else {
                _this.getVideoAnalysis();
            }
        }, function (error) { });
    };
    MobileVideoOnboardingComponent.prototype.getVideoAnalysis = function () {
        var _this = this;
        this.kybService.getVideoAuth(this.videoSelfie, this.words).subscribe(function (data) {
            _this.videoAnalysisResponse = data;
            if (_this.videoAnalysisResponse.data.faceRec.faceMatch !== true ||
                _this.videoAnalysisResponse.data.faceRec.humanFace !== true) {
                _this.videoSelfie = undefined;
                _this.alertService.showError('User video auth failed');
                _this.loading = false;
                setTimeout(function () {
                    // window.location.reload();
                }, 1000);
            }
            else {
                _this.loading = false;
                _this.formDataService.setfinishOnboarding(true);
                _this.ref.detectChanges();
            }
        }, function (error) {
        });
    };
    MobileVideoOnboardingComponent.prototype.blobToFile = function (theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    };
    MobileVideoOnboardingComponent.prototype.handleError = function (error) {
        if (error.name === 'ConstraintNotSatisfiedError') {
            var v = true;
            this.errorMsg('The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.', error);
        }
        else if (error.name === 'PermissionDeniedError') {
            this.errorMsg('Permissions have not been granted to use your camera and ' +
                'microphone, you need to allow the page access to your devices in ' +
                'order for the demo to work.', error);
        }
        this.errorMsg("getUserMedia error: " + error.name, error);
    };
    MobileVideoOnboardingComponent.prototype.errorMsg = function (msg, error) {
        // const errorElement = document.querySelector('#errorMsg');
        // errorElement.innerHTML += `<p>${msg}</p>`;
        console.error(error);
        console.error(msg);
        if (typeof error !== 'undefined') {
            console.error(error);
        }
    };
    return MobileVideoOnboardingComponent;
}());
export { MobileVideoOnboardingComponent };
