import { Component, OnInit } from '@angular/core';

/**
* Implements the Error 404 page
*/
@Component({
  template: `<h2>Error 404</h2> <p>This page does not exist</p>`,
})

export class Error404Component { }
