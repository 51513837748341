import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { AppComponent, ShowAboutComponent } from './app.component';
import { routing } from './app-routing.module';
import { AuthGuard, EnterpriseGuard, UsernameGuard } from './_guards';
import {
  AlertService,
  AuthenticationService,
  CallsService,
  DataService,
  KYBService
} from './_services';
import { LoginComponent } from './user_management/login';
import { AzureOrGoogleModalComponent, RegisterComponent } from './user_management/register';
import { AccountComponent } from './user_management/account';
import {
  EnterpriseComponent,
  AddModalComponent,
} from './user_management/enterprise';
import { BatchComponent } from './user_management/batch';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import {
  DetailComponent,
  ViewDocumentComponent,
  ShowCommentsComponent,
  RenameEntityComponent,
  ManualTotalRiskAdjustmentComponent,
  ManualPEPRiskAdjustmentComponent,
  RemoveRiskAdjustmentComponent
} from './user_management/detail';
import { SettingsComponent } from './user_management/settings';
import { DataTablesModule } from 'angular-datatables';
import { PersonalComponent } from './user_management/register/personal/personal.component';
import { OtherComponent } from './user_management/register/other/other.component';
import { DocumentsComponent } from './user_management/register/documents/documents.component';
import { DisclaimersComponent } from './user_management/register/disclaimers/disclaimers.component';
import { NavbarComponent } from './user_management/register/navbar/navbar.component';
import { PasswordChangeComponent } from './user_management/settings/passwordChange/passwordChange.component';
import { CreateAdminComponent } from './user_management/settings/createAdmin/createAdmin.component';
import { FormDataService } from './user_management/register/data/formData.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { EmailServerComponent } from './user_management/settings/emailServer/emailServer.component';
import { EmailTemplateComponent } from './user_management/settings/emailTemplate/emailTemplate.component';
import { VideoComponent } from './user_management/register/video/video.component';
import { IdentityDocumentComponent } from './user_management/register/identityDocument/identityDocument.component';
import 'hammerjs';
import 'mousetrap';
import { DocumentCheckerComponent } from './user_management/document-checker/documentChecker.component';
import { UserReviewComponent } from './user_management/user-review/userReview.component';
import { ShortNavbarComponent } from './user_management/register/short-navbar/shortNavbar.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { GoTopButtonModule } from 'ng2-go-top-button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatchesComponent } from './user_management/matches/matches.component';
import { SanctionListPipe } from './_pipes/sanction-list.pipe';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { DashboardComponent } from './user_management/dashboard/dashboard.component';
import { AddDossierComponent } from './user_management/register/addDossier/addDossier.component';
import { PersonFormComponent } from './user_management/register/addDossier/addEntity/personForm/personForm.component';
import { CompanyFormComponent } from './user_management/register/addDossier/addEntity/companyForm/companyForm.component';
import { AddEntityComponent } from './user_management/register/addDossier/addEntity/addEntity.component';
// tslint:disable-next-line:max-line-length
import { AddDocumentComponent } from './user_management/register/addDossier/addEntity/companyForm/addRole/addDocument/addDocument.component';
import { NonAdminComponent } from './user_management/nonadmin/nonAdmin.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as networkgraph from 'highcharts/modules/networkgraph.src';
import * as organization from 'highcharts/modules/organization.src';
import * as sankey from 'highcharts/modules/sankey.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as serieslabel from 'highcharts/modules/series-label.src';
import { AddRoleSimpleComponent } from './user_management/enterprise/addRoleSimple/addRoleSimple.component';
import { AddEntitySimpleComponent } from './user_management/enterprise/addEntitySimple/addEntitySimple.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { UserManagementComponent } from './user_management/settings/userManagement/userManagement.component';
import { UserUploaderComponent } from './user_management/settings/userManagement/userUploader/userUploader.component';
import { ManageUsersComponent } from './user_management/settings/userManagement/manageUsers/manageUsers.component';
import { BulkOwnershipComponent } from './user_management/settings/userManagement/bulkOwnership/bulkOwnership.component';
import { LegalConfigurationComponent } from './user_management/settings/legalConfiguration/legalConfiguration.component';
import { MyAccountComponent } from './user_management/myAccount/myAccount.component';
import {
  ScreeningComponent,
  ScreenerResultsModalComponent,
} from './user_management/screening/screening.component';
import { LegalPersonsSettingsComponent } from './user_management/settings/legalConfiguration/legalPersonsSettings/legalPersonsSettings.component';
import { BrandingSettingsComponent } from './user_management/settings/legalConfiguration/brandingSettings/brandingSettings.component';
import { LegalPersonTypesSettingsComponent } from './user_management/settings/legalConfiguration/legalPersonTypesSettings/legalPersonTypesSettings.component';
import { LegalRolesSettingsComponent } from './user_management/settings/legalConfiguration/legalRolesSettings/legalRolesSettings.component';
import { DocumentsSettingsComponent } from './user_management/settings/legalConfiguration/documentsSettings/documentsSettings.component';
import { RequiredDocumentsPipe } from './requiredDocuments.pipe';
import { AllowedEntitiesPipe } from './allowedEntities.pipe';
import { SplitMimeTypePipe } from './splitMimeType.pipe';
import { SplitTimestampPipe } from './splitTimestamp.pipe';
import { ShorterTextPipe } from './shorterText.pipe';
import { DecodeOwnerPipe } from './decodeOwner.pipe';
import { MobileVideoOnboardingComponent } from './user_management/register/video/mobile-video-onboarding/mobile-video-onboarding.component';
import { DateParserPipe } from './dateParser.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ScreeningRiskSettingsComponent } from './user_management/settings/screeningRiskSettings/screeningRiskSettings.component';
import { NgxTagsInputModule } from 'ngx-tags-input';
import { MediaScreenerSettingsComponent } from './user_management/settings/mediaScreenerSettings/mediaScreenerSettings.component';
import { SearchEngineScreenerSettingsComponent } from './user_management/settings/searchEngineScreenerSettings/searchEngineScreenerSettings.component';
import { PepSanctionScreenerSettingsComponent } from './user_management/settings/pepSanctionScreenerSettings/pepSanctionScreenerSettings.component';
import { ReportingComponent } from './user_management/reporting/reporting.component';
import { PrettyTimestampPipe } from './prettyTimestamp.pipe';
import { PrettyEventNamePipe } from './prettyEventName.pipe';

import { ErrorInterceptor, DEFAULT_TIMEOUT } from './error.interceptor';
import { RiskAnalysisSettingsComponent } from './user_management/settings/riskAnalysisSettings/riskAnalysisSettings.component';
import { ReplaceDocumentComponent } from './user_management/register/addDossier/addEntity/companyForm/replaceDocument/addDocument/replaceDocument.component';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { BrandingModule } from './branding/branding.module';
import { SettingModule } from './setting/setting.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { EntityReportComponent } from './user_management/reporting/entityReport/entityReport.component';
import { ActivityReportComponent } from './user_management/reporting/activityReport/activityReport.component';
import { ScreeningReportComponent } from './user_management/reporting/screeningReport/screeningReport.component';
import { DateParserRemoveZPipe } from './dateParserRemoveZ.pipe';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ManageUserRolesComponent } from './user_management/settings/userManagement/manageUserRoles/manageUserRoles.component';
import { UserGroupGuard } from './_guards/userGroup.guard';
import { HiddenTagsComponent } from './user_management/hiddenTags/hiddenTags.component';
import { TagManagementComponent } from './user_management/settings/tagManagement/tagManagement.component';
import { UnixToDateTimestampPipe } from './unixToDateTimestamp.pipe';
import { ToastrModule } from 'ngx-toastr';
import { GeoEntitiesSettingsComponent } from './user_management/settings/legalConfiguration/geoEntitiesSettings/geoEntitiesSettings.component';
import { GeoRoleNamePipe } from './geoRoleName.pipe';
import { SecondsToDaysPipe } from './secondsToDays.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MediumTextPipe } from './mediumText.pipe';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VerificationService } from './user_management/register/verification.service';
import { MobileDocumentOnboardingComponent } from './user_management/register/identityDocument/mobile-document-onboarding/mobile-document-onboarding.component';
import { DocumentEditorComponent } from './user_management/document-editor/documentEditor.component';
import { PhoneAssessmentPipe } from './phoneAssessment.pipe';
import { QRCodeModule } from 'angular2-qrcode';
import { WebcamModule } from 'ngx-webcam';
import { VideoRecordingService } from './_services/video-recording.service';
import { ConnectionAssessmentPipe } from './connectionAssessment.pipe';
import { Error404Component } from './error404.component';
import { MobileDocumentOnboardingImageCaptureComponent } from './user_management/register/identityDocument/mobile-document-onboarding-image-capture/mobile-document-onboarding-image-capture.component';
import { MobileVideoOnboardingRecordingComponent } from './user_management/register/video/mobile-video-onboarding-recording/mobile-video-onboarding-recording.component';
import { ConfigService } from './_services/config.service';
import { RepresentativeComponent } from './user_management/register/representative/representative.component';
import { DocumentManagerService } from './user_management/register/documentManager/documentManager.service';
import { DocumentManagerComponent } from './user_management/register/documentManager/documentManager.component';
import { OrgLinksPipe } from './orgLinks.pipe';
import { MenuComponent } from './user_management/menu/menu.component';
import { EntityDetailComponent, SearchComponent } from './user_management/menu/search';
import { WebDataService } from './_services/webData.service';
import { SearchService } from './_services/search.service';
import { SharedService } from './_services/shared.service';
import { FilterPipe } from './filter.pipe';
import { WebCallsService } from './_services/webCalls.service';
import { CreditAlertComponent } from './user_management/menu/creditAlert/creditAlert.component';
import { TruncateTextPipe } from './truncate-text.pipe';
import { ParseSourcePipe } from './parseSource.pipe';
import { FullReportComponent } from './user_management/menu/fullReport';
import { NewSearchComponent } from './user_management/menu/fullReport/newSearch/newSearch.component';
import { RiskComponent } from './user_management/menu/fullReport/risk';
import { RiskAssessmentComponent } from './user_management/menu/fullReport/risk-assessment/risk-assessment.component';
import { RiskLevelComponent } from './user_management/menu/fullReport/risk-level/risk-level.component';
import { CommentComponent } from './user_management/menu/fullReport/comment/comment.component';
import { FullSearchRiskComponent } from './user_management/menu/fullReport/fullSearchRisk';
import { ResultsComponent } from './user_management/menu/fullReport/results';
import { InformationComponent } from './user_management/menu/fullReport/information';
import { ControlsComponent, LoadModalComponent, SaveModalComponent } from './user_management/menu/fullReport/controls';
import { ReplaceSpacePipe } from './replaceSpace.pipe';
import { ReportComponent } from './user_management/menu/fullReport/report';
import { ReportPreviewComponent } from './user_management/menu/fullReport/report-preview';
import { PiechartComponent } from './user_management/menu/fullReport/piechart';
import { ReportResultsComponent } from './user_management/menu/fullReport/report-results';
import { PrettyStatePipe } from './prettyState.pipe';
import { EditBasicInformationComponent } from './user_management/enterprise/editBasicInformation/editBasicInformation.component';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { MatSliderModule } from '@angular/material/slider';
import { GeoAssessmentPipe } from './geoAssessment.pipe';
import { WebAlertService } from './_services/webAlert.service';
import { StepPageWrapperComponent } from './user_management/register/step-page-wrapper/step-page-wrapper.component';
import { Setup2FAComponent } from './user_management/settings/userManagement/setup2FA/setup2FA.component';
import { PackageManagerComponent } from './user_management/package-manager/packageManager.component';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    routing,
    BsDropdownModule.forRoot(),
    NgSelectModule,
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    DataTablesModule,
    ModalModule.forRoot(),
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    BsDatepickerModule.forRoot(),
    AccordionModule.forRoot(),
    GoTopButtonModule,
    BrowserAnimationsModule,
    NgxImageZoomModule.forRoot(),
    ReactiveFormsModule,
    ChartModule,
    TabsModule.forRoot(),
    PdfViewerModule,
    NgxOrgChartModule,
    NgxTagsInputModule,
    NgxCsvParserModule,
    BrandingModule,
    MatSliderModule,
    SettingModule,
    ColorPickerModule,
    PaginationModule.forRoot(),
    MatStepperModule,
    MatFormFieldModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      progressAnimation: 'decreasing',
      preventDuplicates: true,
      progressBar: false,
      closeButton: true,
      enableHtml: true
    }),
    QRCodeModule,
    WebcamModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    AccountComponent,
    EnterpriseComponent,
    DetailComponent,
    BatchComponent,
    NavbarComponent,
    PersonalComponent,
    RepresentativeComponent,
    OtherComponent,
    DisclaimersComponent,
    DocumentsComponent,
    SettingsComponent,
    PasswordChangeComponent,
    CreateAdminComponent,
    CreditAlertComponent,
    EmailServerComponent,
    EmailTemplateComponent,
    VideoComponent,
    IdentityDocumentComponent,
    DocumentCheckerComponent,
    UserUploaderComponent,
    UserReviewComponent,
    ShortNavbarComponent,
    MatchesComponent,
    SanctionListPipe,
    CommentComponent,
    ControlsComponent,
    DashboardComponent,
    FullReportComponent,
    FullSearchRiskComponent,
    InformationComponent,
    LoadModalComponent,
    MenuComponent,
    NewSearchComponent,
    PiechartComponent,
    ReplaceSpacePipe,
    ReportComponent,
    ReportPreviewComponent,
    ReportResultsComponent,
    ResultsComponent,
    RiskComponent,
    RiskAssessmentComponent,
    RiskLevelComponent,
    SaveModalComponent,
    SearchComponent,
    EntityDetailComponent,
    AddDossierComponent,
    AddEntityComponent,
    PersonFormComponent,
    CompanyFormComponent,
    AddDocumentComponent,
    NonAdminComponent,
    AddModalComponent,
    AzureOrGoogleModalComponent,
    ManualTotalRiskAdjustmentComponent,
    ManualPEPRiskAdjustmentComponent,
    RemoveRiskAdjustmentComponent,
    AddRoleSimpleComponent,
    AddEntitySimpleComponent,
    EditBasicInformationComponent,
    UserManagementComponent,
    ManageUsersComponent,
    BulkOwnershipComponent,
    Setup2FAComponent,
    LegalConfigurationComponent,
    MyAccountComponent,
    ScreeningComponent,
    LegalPersonsSettingsComponent,
    BrandingSettingsComponent,
    LegalPersonTypesSettingsComponent,
    LegalRolesSettingsComponent,
    DocumentsSettingsComponent,
    RequiredDocumentsPipe,
    AllowedEntitiesPipe,
    OrgLinksPipe,
    SplitMimeTypePipe,
    PrettyTimestampPipe,
    ShowAboutComponent,
    ViewDocumentComponent,
    ScreenerResultsModalComponent,
    SplitTimestampPipe,
    SecondsToDaysPipe,
    MobileVideoOnboardingComponent,
    DecodeOwnerPipe,
    DateParserPipe,
    DateParserRemoveZPipe,
    ShorterTextPipe,
    PrettyEventNamePipe,
    PrettyStatePipe,
    UnixToDateTimestampPipe,
    GeoRoleNamePipe,
    MediumTextPipe,
    FilterPipe,
    ParseSourcePipe,
    TruncateTextPipe,
    ScreeningRiskSettingsComponent,
    PepSanctionScreenerSettingsComponent,
    MediaScreenerSettingsComponent,
    SearchEngineScreenerSettingsComponent,
    RiskAnalysisSettingsComponent,
    ShowCommentsComponent,
    ReplaceDocumentComponent,
    RenameEntityComponent,
    ReportingComponent,
    EntityReportComponent,
    ActivityReportComponent,
    ScreeningReportComponent,
    ManageUserRolesComponent,
    HiddenTagsComponent,
    TagManagementComponent,
    GeoEntitiesSettingsComponent,
    MobileDocumentOnboardingComponent,
    DocumentEditorComponent,
    PhoneAssessmentPipe,
    ConnectionAssessmentPipe,
    GeoAssessmentPipe,
    MobileDocumentOnboardingImageCaptureComponent,
    MobileVideoOnboardingRecordingComponent,
    Error404Component,
    DocumentManagerComponent,
    StepPageWrapperComponent,
    PackageManagerComponent
  ],
  entryComponents: [
    AddModalComponent,
    AzureOrGoogleModalComponent,
    CreditAlertComponent,
    ShowAboutComponent,
    ViewDocumentComponent,
    ScreenerResultsModalComponent,
    ShowCommentsComponent,
    RenameEntityComponent,
    LoadModalComponent,
    ManualTotalRiskAdjustmentComponent,
    ManualPEPRiskAdjustmentComponent,
    RemoveRiskAdjustmentComponent,
    SaveModalComponent,
    EntityDetailComponent,
    MobileDocumentOnboardingImageCaptureComponent,
    MobileVideoOnboardingRecordingComponent,
    DocumentManagerComponent,
    DisclaimersComponent,
    VideoComponent,
    IdentityDocumentComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 60000 },

    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: (config: ConfigService) => () => config.load(),
      deps: [
        ConfigService
      ],
      multi: true
    },
    AppComponent,
    AuthGuard,
    AlertService,
    CallsService,
    EnterpriseGuard,
    UsernameGuard,
    UserGroupGuard,
    DocumentManagerService,
    DataService,
    SearchService,
    SharedService,
    WebAlertService,
    WebCallsService,
    WebDataService,
    DatePipe,
    KYBService,
    VerificationService,
    AuthenticationService,
    DeviceDetectorService,
    VideoRecordingService,
    { provide: FormDataService, useClass: FormDataService },
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        networkgraph,
        sankey,
        organization,
        exporting,
        serieslabel,
      ],
    },

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
