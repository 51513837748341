export class Entity {
    entityTypeId: any;
    primaryName: any;
    secondaryName: any;
    selfRole: NewRole;
    roles: Role[];
    delegateEmail: any;
    ownerId: any;
    entityStatus: any;
    tagIds: any;
    email: any;
    phoneNumber: any;
}

export class Document {
    docTypeId: any;
    mimeType: any;
    validTo: any;
    retainTo: any;
    fileId: any;
    totalFiles: any;
    description: any;
}

export class NewRole {
    roleTypeId: any;
    roleName: any;
    documentsIds: any;
}

export class Role {
    e1Id: any;
    e2Id: any;
    roleTypeId: any;
    documents: Document[];
    documentIds: any[];
}

export class User {
    email: string;
    externalId: string;
    hiddenTags: number[];
    identifier: string;
    role: string;
}

export class UserList {
    message: string;
    data: User[];
}

export class Permissions {
    permissions: string[];
}

export class UserPermissions {
    message: string;
    data: Permissions;
}

export class EntityType {
    entityType: any;
    legalPersonType: any;
    autoLinkedEntities: any;
    entityDetailForm: any;
}

export class RoleType {
    defaultRoleName: any;
    self: boolean;
    defaultRisk: any;
    allowableE1EntityTypes: any;
    allowableE2EntityTypes: any;
    requiredDocumentTypeIds: any;
}

export class DocumentType {
    documentTypeName: any;
    allowableMimeTypes: any;
    docForm: any;
    description: any;
}

export class RoleUpdate {
    additionalDocumentIds: any[];
    e1Id: any;
    e2Id: any;
    ownerId: any;
    requiredDocumentIds: any;
    roleId: any;
}

export class EntityUpdate {
    delegateEmail: any;
    entityId: any;
    entityStatus2E: any;
    entityTypeId: any;
    ownerId: any;
    primaryName: any;
    secondaryName: any;
    comment: any;
    tagIds: any;
    entityPhoneNumber: any;
    entityEmail: any;
}

export class DocumentMetadataUpdate {
    comment: string;
    deleteFiles: boolean;
    description: string;
    documentId: number;
    documentStatus: string;
    userId: string;
}

export class OwnershipTransfer {
    currentUserId: any;
    fromUserId: any;
    toUserId: any;
}
