<div class="col-md-12">
    <!-- <tabset>
      <tab heading="Manage Users" id="tab1">Manage Users</tab>
      <tab heading="Bulk Ownership">Bulk Ownership</tab>
      <tab heading="User Uploader">
          <app-user-uploader></app-user-uploader>
      </tab>
    </tabset> -->
    <tabset type="pills" #staticTabs class="main-topic-tabs">
        <tab heading="Manage Users">
            <div class="row">
                <app-manage-users *ngIf="staticTabs.tabs[0]?.active"></app-manage-users>
            </div>
        </tab>
        <!-- <tab heading="Manage User Roles">
            <div class="row">
                <app-manage-user-roles></app-manage-user-roles>
            </div>
        </tab> -->
        <tab heading="Bulk Ownership">
            <div class="row">
                <app-bulk-ownership *ngIf="staticTabs.tabs[1]?.active"></app-bulk-ownership>
            </div>
        </tab>

        <!-- <tab heading="User Uploader">
            <div class="row">
                <app-user-uploader></app-user-uploader>
            </div>
        </tab> -->
    </tabset>
</div>
