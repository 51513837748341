import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertService, CallsService, KYBService, DataService } from '../../../../_services';
// import { Papa, PapaParseConfig } from 'ngx-papaparse';
import { NgxCsvParser } from 'ngx-csv-parser';
import { Entity, Role } from '../../../../_models/kyb';
/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
var UserUploaderComponent = /** @class */ (function () {
    function UserUploaderComponent(alertService, kybService, 
    // private papa: Papa,
    callService, ngxCsvParser, dataService) {
        this.alertService = alertService;
        this.kybService = kybService;
        this.callService = callService;
        this.ngxCsvParser = ngxCsvParser;
        this.dataService = dataService;
        this.model = {};
        this.batchFileFilled = false;
        this.batchFile = null;
        this.csv = '';
        // papaConfig: PapaParseConfig = {header: true};
        this.loading = false;
        this.currentUser = 0;
        this.userObject = {};
        this.rows = [];
        this.csvRecords = [];
        this.header = false;
        this.entityResponse = {};
        this.roleResponse = {};
    }
    UserUploaderComponent.prototype.ngOnInit = function () {
        // this.model = null;
    };
    /**
    * Function that detects if batch file is filled
    */
    UserUploaderComponent.prototype.isBatchFileFilled = function () {
        this.batchFileFilled = true;
    };
    /**
    * Function that opens batch file input window
    */
    UserUploaderComponent.prototype.openBatchFileInput = function () {
        document.getElementById('batchFile').click();
    };
    /**
    * Function that shows batch file name
    */
    UserUploaderComponent.prototype.showBatchFile = function (event) {
        if (event.target.files && event.target.files.length) {
            var file = event.target.files[0];
            this.batchFile = file;
        }
        document.getElementById('filenameBatchFile').innerHTML = this.batchFile.name;
    };
    /**
    * Function that sends parsed information to database
    */
    UserUploaderComponent.prototype.process = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                this.ngxCsvParser.parse(this.batchFile, { header: this.header, delimiter: ',' })
                    .pipe().subscribe(function (result) {
                    _this.csvRecords = result;
                    for (var i = 0; i < _this.csvRecords.length; i++) {
                        _this.newEntity = new Entity();
                        _this.newEntity.entityTypeId = 80;
                        _this.newEntity.primaryName = _this.csvRecords[i][0];
                        _this.newEntity.secondaryName = _this.csvRecords[i][1];
                        _this.newEntity.roles = [];
                        _this.newEntity.delegateEmail = null;
                        _this.newEntity.entityStatus = _this.csvRecords[i][3];
                        // this.selfRoleId = this.csvRecords[i][2];
                        // this.dataService.setBulkRoleId(this.csvRecords[i][2]);
                        _this.newEntity.ownerId = localStorage.getItem('identifier');
                        _this.kybService.createEntity(_this.newEntity).subscribe(function (entityResponse) {
                            _this.entityResponse = entityResponse;
                            _this.selfRole = new Role();
                            _this.selfRole.e1Id = _this.entityResponse.data.entityId;
                            _this.selfRole.e2Id = null;
                            _this.selfRole.documentIds = [];
                            _this.selfRole.roleTypeId = 65;
                            // this.selfRole.roleTypeId = this.dataService.bulkSelfRoleId.getValue();
                            _this.kybService.createRole(_this.selfRole).subscribe(function (role) {
                                _this.roleResponse = role;
                            }, function (error) {
                                _this.loading = false;
                                _this.alertService.showError('Something went wrong.');
                            });
                        }, function (error) {
                            _this.loading = false;
                            _this.alertService.showError('Something went wrong.');
                        });
                    }
                    _this.loading = false;
                }, function (error) {
                    _this.loading = false;
                });
                return [2 /*return*/];
            });
        });
    };
    return UserUploaderComponent;
}());
export { UserUploaderComponent };
