import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from './../../../_services';
import { DocumentManagerService } from './documentManager.service';

/**
 * Implements the user detail page
*/
@Component({
  moduleId: module.id,
  templateUrl: 'documentManager.component.html',
  // tslint:disable-next-line:component-selector
  selector: 'document-manager',
  styleUrls: ['./style.css']
})

export class DocumentManagerComponent implements OnInit {

  requiredDocuments: any[] = [];
  entities: any[] = [];
  documents: any[] = [];
  packages: any[] = [];
  fileSelected: boolean;
  documentFile: File;
  documentResponse: any;
  documentId: any;
  document1: any;
  isveryfing = false;

  constructor(
    private callsService: CallsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private dataService: DataService,
    private kybService: KYBService,
    private router: Router,
    private documentService: DocumentManagerService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.documentService.getPackages();
    this.documentService.packages.subscribe((data: any) => {
      this.handlePackages(data);
    });
  }

  handlePackages(data: any) {
    this.packages = [];
    this.entities = [];
    this.packages = data;
    this.documents = [];

    if (data.packages && data.packages.length) {
      for (const p of data.packages) {
        for (const entity of p.entities) {
          this.kybService.getEntity(entity.entityId).subscribe((res: any) => {
            this.entities.push(res.data);
          });

          for (const role of entity.roles) {
            for (const doc of role.requestedDocuments) {
              if (doc.documentStatus !== 'EMPTY') {
                continue;
              }

              this.kybService.getDocument(doc.documentId).subscribe((res: any) => {
                this.documents.push(
                  {
                    entityId: entity.entityId,
                    docId: doc.documentId,
                    role,
                    doc: res.data
                  }
                );

                this.ref.detectChanges();
              });
            }
          }
        }
      }
    }
  }

  getEntity(id) {
    for (const e of this.entities) {
      if (e.id === id) {
        return e.name;
      }
    }
  }

  processDocument(files: FileList, fileName: any) {
    if (files.item(0) == null) {
      this.fileSelected = false;
    } else {
      this.document1 = new Document();
      this.document1.docTypeId = 2;
      this.document1.mimeType = 'image/jpeg';
      this.document1.retainTo = '2026-01-01T11:52:05.381';
      this.document1.validTo = '2026-01-01T11:52:05.381';
      this.documentFile = files.item(0);
      this.fileSelected = true;
    }
  }

  submitFile(entityId, roleId, documentId) {
    this.isveryfing = true;

    this.kybService
      .saveFileNoId(this.documentFile, documentId)
      .subscribe(res => {
        this.documentService.getPackages();
        this.alertService.showSuccess('Document has been saved');
        this.isveryfing = false;
      });

    // this.kybService.createDocument(this.document1, parseInt(entityId), roleId, null, null).subscribe(
    //   (data: any) => {
    //     this.documentResponse = data;
    //     this.documentId = this.documentResponse.data.documentId;
    //     this.kybService
    //       .saveFileNoId(this.documentFile, this.documentResponse.data.documentId)
    //       .subscribe(res => {
    //         this.documentService.getPackages();
    //         this.alertService.showSuccess('Document has been saved');
    //         this.isveryfing = false
    //       })
    //   });
  }
}
