<div class="col-lg-12 box" xmlns:height="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div class="col-lg-4"></div>
    <div class="col-lg-4"></div>
    <div class="col-lg-4" style="color:black;text-align: right; padding-right: 40px">Logged in as <span [style.color]="mainColor" style="font-size: 16px">{{userName}}</span></div>
  </div>
  <div style="height: 20px"></div>
  <p class="text-center" style="color: black;font-family: 'Open Sans', sans-serif;">Entity List</p> 
  <div style="height: 20px"></div>
  <div class="row">
    <div class="col-lg-12">
        <ul class="list-unstyled list-inline" style="width: 100%;">
            <li *ngIf="displayStructureBuilder" class="list-inline-item">
              <button *ngIf="userPermissions.indexOf('CreateEntity') !== -1 && userPermissions.indexOf('CreateRole') !== -1 && userPermissions.indexOf('CreateDocument') !== -1" style="padding: 8px;background-color:#355B89;border-color: #355B89" class="btn btn-success" (click)="structureBuilder()" title="Go to structure builder">Structure Builder&nbsp;&nbsp;<i class="fa fa-magic" aria-hidden="true"></i>
              </button>
            </li>
            <li class="list-inline-item">
              <button style="padding: 8px;background-color:#355B89;border-color: #355B89" class="btn btn-success" (click)="addEntity()" title="Add new entity" *ngIf="userPermissions.indexOf('CreateEntity') !== -1">{{ "ADD_ENTITY" | translate }}&nbsp;&nbsp;<i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </li>
            <li *ngIf="displayAddRole" class="list-inline-item">
              <button style="padding: 8px;background-color:#355B89;border-color: #355B89" class="btn btn-success" (click)="addRole()" title="Add role" *ngIf="userPermissions.indexOf('CreateRole') !== -1">Add Role&nbsp;&nbsp;<i class="fa fa-plus" aria-hidden="true"></i>
              </button>
          </li>
          </ul>
          <button class="btn btn-primary" (click)="toggleFilters()">{{ "FILTERS" | translate }}&nbsp;<i class="fa fa-filter" aria-hidden="true"></i></button>
          <ul *ngIf="displayFilters" class="list-unstyled list-inline" style="width: 100%;padding-left: 5px;">
            <li class="list-inline-item">
              <div style="height: 20px;"></div>
              Select the tag(s) that you would like to see:
              <div style="height: 10px;"></div>
              <ng-select
              class="custom"
              [items]="userTags"
              [multiple]="true"
              bindLabel="name"
              [closeOnSelect]="false"
              bindValue="id"
              [clearable]="false"
              (add)="tagAdded($event)"
              (remove)="tagRemoved($event)"
              [compareWith]="compareFn"
              [(ngModel)]="includedTags">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
              </ng-template>
          </ng-select>  
<!--             <tabset type="pills">
                <tab *ngFor="let tag of userTags" heading="{{tag.name}}" (selectTab)="onSelect($event)"></tab>
            </tabset> -->
          </li>
          <li class="list-inline-item"><button (click)="selectAllTags()" style="background-color:#355B89;border-color: #355B89;padding: 3px;font-size: 12px;" class="btn btn-success">Select all</button></li>
          <li class="list-inline-item"><button (click)="deselectAllTags()" style="background-color:#355B89;border-color: #355B89;padding: 3px;font-size: 12px;" class="btn btn-success">Clear all</button></li>
              <!-- <li class="list-inline-item">
                <button id="all-filter" style="padding: 4px;background-color:gray;border-color: gray" class="btn btn-success" (click)="showAll()" title="Show all entities">All entities&nbsp;&nbsp;
                </button>
              </li>
              <li class="list-inline-item">
                <button id="counterparties-filter" style="padding: 4px;background-color:gray;border-color: gray" class="btn btn-success" (click)="showCounterparties()" title="Show only counterparties">Only counterparties&nbsp;&nbsp;
                </button>
              </li> -->
          </ul>
          <ul *ngIf="displayFilters && isAdmin" class="list-unstyled list-inline" style="width: 100%;padding-left: 5px;">
            <li class="list-inline-item">
              <div style="height: 20px;"></div>
              {{ "SELECT_THE_OWNER" | translate }}
              <div style="height: 10px;"></div>
              <ng-select class="custom-owner" [items]="users"
                  bindLabel="name"
                  bindValue="name"
                 [(ngModel)]="owner"
                 (change)="filterOwner($event)"
              >
              </ng-select>
          </li>
          </ul>
          <ul *ngIf="displayFilters && isAdmin" class="list-unstyled list-inline" style="width: 100%;padding-left: 5px;">
            <li class="list-inline-item">
              <div style="height: 20px;"></div>
              Select the entity status you would like to see:
              <div style="height: 10px;"></div>
              <ng-select class="custom-owner" [items]="entityStatus2EList"
                  bindLabel="name"
                  bindValue="name"
                 [(ngModel)]="entityStatus2E"
                 (change)="filterEntityStatus2E($event)"
              >
              </ng-select>
          </li>
          </ul>

    </div>
  </div>  
      <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;"> 
        <div style="height: 40px"></div> 
        <div class="row">
          <div class="col-sm-6" style="text-align: left;">
            Show
            <select [(ngModel)]="pageSize" (ngModelChange)="pageSizeChanged()">
              <option [value]="30">30</option>
              <option [value]="50">50</option>
              <option [value]="100">100</option>
              <option [value]="totalItems">All</option>
            </select> entities
          </div>
          <div class="col-sm-6" style="text-align: right;">
            Search: <input type="text" [(ngModel)]="searchQuery" (ngModelChange)="search()"/>  
          </div>
        </div>   
        <div style="height: 20px"></div>  
        <table id="entity-table" class="table" style="color: black;">
              <thead>
                <tr>
                    <th></th>
                    <th>ID&nbsp;<i id="id-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('id','asc')"></i>&nbsp;<i id="id-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('id','desc')"></i></th>
                    <th *ngIf="userPermissions.indexOf('EntityDetailAccess') !== -1">{{ "NAME" | translate }}&nbsp;<i id="name-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('name','asc')"></i>&nbsp;<i id="name-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('name','desc')"></i></th>
                    <th style="text-align: center;">PEP&nbsp;<i id="pepRisk-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('pepRisk','asc')"></i>&nbsp;<i id="pepRisk-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('pepRisk','desc')"></i></th>
                    <th style="text-align: center;">Sanction&nbsp;<i id="sanctionRisk-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('sanctionRisk','asc')"></i>&nbsp;<i id="sanctionRisk-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('sanctionRisk','desc')"></i></th>
                    <th style="text-align: center;" *ngIf="displayCompleteColumn">Complete&nbsp;<i id="complete-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('complete','asc')"></i>&nbsp;<i id="complete-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('complete','desc')"></i></th>
                    <th style="text-align: center;" *ngIf="displayOverallStatusColumn">Overall Status&nbsp;<i id="entityStatus2E-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityStatus2E','asc')"></i>&nbsp;<i id="entityStatus2E-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityStatus2E','desc')"></i></th>
                    <th style="text-align: center;">{{ "COMPLETE" | translate }}&nbsp;<i id="overallRisk-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('overallRisk','asc')"></i>&nbsp;<i id="overallRisk-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('overallRisk','desc')"></i></th>
                    <th style="text-align: center;">{{ "OWNER" | translate }}&nbsp;<i id="owner-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('owner','asc')"></i>&nbsp;<i id="owner-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('owner','desc')"></i></th>
                    <th style="text-align: center;">Type&nbsp;<i id="entityType-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityType','asc')"></i>&nbsp;<i id="entityType-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityType','desc')"></i></th>
                    <th *ngIf="userPermissions.indexOf('UpdateEntity') !== -1" style="text-align: center;">Archive&nbsp;</th>
                  </tr>
            </thead>
            <tbody>
                <tr *ngIf="isEmpty" aria-colspan="9">
                  <td colspan="9" style="text-align: center;">
                    <div style="height: 20px;"></div>
                    <p style="text-align: center;font-family: Arial,sans-serif;font-size: 14px;">No entities found.</p>
                    <div style="height: 20px;"></div>
                  </td>
                </tr>
                <tr class="main-rows" *ngFor="let entity of entities; let i = index">
                    <td class="details-control"><i class="fa fa-plus-square" style="color: green;" (click)="getRoles(entity.id,i, entity.primaryName, entity.secondaryName, entity.entityStatus, entity.entityStatus2E)"></i></td>
                    <td>{{entity.id}}</td>
                    <td *ngIf="userPermissions.indexOf('EntityDetailAccess') !== -1"><a style='color:black' [routerLink]="['/detail', entity.id]"><u>{{entity.primaryName}} {{entity.secondaryName}}</u></a></td>
                    <td [ngSwitch]="entity.pepRisk" style="text-align: center;">
                      <i *ngSwitchCase="'HIGH'" title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'LOW'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'MEDIUM'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i> 
                    </td>
                    <td [ngSwitch]="entity.sanctionRisk" style="text-align: center;">
                      <i *ngSwitchCase="'HIGH'" title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'LOW'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'MEDIUM'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i> 
                    </td>
                    <td [ngSwitch]="entity.complete" style="text-align: center;" *ngIf="displayCompleteColumn">
                      <i *ngSwitchCase="false" title="Incomplete" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="true" title="Complete" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    </td>
                    <td style="text-align: center;" *ngIf="displayOverallStatusColumn">{{entity.entityStatus2E}}</td>
                    <td [ngSwitch]="entity.overallRisk" style="text-align: center;">
                      <i *ngSwitchCase="'HIGH'" title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'LOW'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'MEDIUM'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i> 
                      <i *ngSwitchCase="'UNEVALUATED'" title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i> 
                    </td>
                    <td style="text-align: center;">{{entity.ownerEmail}}</td>
                    <td style="text-align: center;">
                      <span *ngIf="entity.company == false"><i title="Physical Person" style="color: black;font-size: 22px;" class="fa fa-male" aria-hidden="true"></i></span>
                      <span *ngIf="entity.company == true"><i title="Moral Person" style="color: black;font-size: 22px;" class="fa fa-building" aria-hidden="true"></i></span>
                    </td>
                    <td *ngIf="userPermissions.indexOf('UpdateEntity') !== -1" style="text-align: center;">
                      <button (click)="updateArchived(entity.id)" style="border: none;background-color: white;"  [disabled]="entity.entityStatus2E=='ARCHIVED'"><i  class="fa fa-fw fa-lg fa-archive" style="color: #355B89;"></i></button>
                    </td>
                </tr>
            </tbody>
           <tfoot style="border: none;">
              <tr aria-colspan="9" style="border: none;">
                <td colspan="9" style="border: none;">
                  <div style="height: 5px;"></div>
                  <p style="font-family: Arial,sans-serif;font-size: 14px;color: #333;margin-bottom: -15px;margin-left: -10px;">Showing <b>{{numberOfElements}}</b> of <b>{{totalItems}}</b> entities</p>
                </td>
              </tr>
            </tfoot>
          </table>  
          <pagination [totalItems]="totalItems" [itemsPerPage]="pageSize" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"></pagination>
    </div> 
    <div style="height: 10px"></div>
   
    <button style="display: none;" id="navigation-button" (click)="goToDetail()"></button>
</div>

