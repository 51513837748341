import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'allowedEntitiesPipe'})
export class AllowedEntitiesPipe implements PipeTransform {
  transform(value: any): string {
    let result = '';

    for (let i = 0; i < value.length; i++) {
        result += value[i].legalForm + ' ';
    }

    return result;
  }
}
