import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Document, EntityUpdate } from '../../_models';
import { AlertService, DataService, KYBService } from '../../_services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

interface JsonFormValidators {
  min?: number;
  max?: number;
  required?: boolean;
  requiredTrue?: boolean;
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  nullValidator?: boolean;
}

interface JsonFormControlOptions {
  min?: string;
  max?: string;
  step?: string;
  icon?: string;
}

interface JsonFormControls {
  name: string;
  label: string;
  value: string;
  type: string;
  options?: JsonFormControlOptions;
  required?: boolean;
  validators: JsonFormValidators;
}

export interface JsonFormData {
  controls: JsonFormControls[];
}

@Component({
  selector: 'app-document-editor',
  templateUrl: 'documentEditor.component.html',
  styleUrls: [ './documentEditor.component.css' ],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DocumentEditorComponent implements OnInit {

  @Input() jsonFormData: JsonFormData;
  @Input() documentId: any;
  @Input() roleId: any;
  @Input() entityId: any;
  @Input() e1Id: any;
  @Input() e2Id: any;
  @Input() isGeoEntity: boolean;
  @Input() mode: string;
  @Input() entityTypeId: any;
  @Input() primaryName: string;
  @Input() secondaryName: string;

  fileId: any;

  documentMetadataResponse: any = {};
  documentContentResponse: any = {};
  fileContent: any = {};
  formDefinition: any = {};
  documentTypeId: any;
  documentTypeName: any;
  loading: any = false;

  document: Document;
  documentResponse: any = {};
  file: File;
  updatedEntity: EntityUpdate;

  public formData: JsonFormData;
  public myForm: FormGroup = this.fb.group({});

  constructor (
    private kybService: KYBService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private dataService: DataService
  ) {}

  ngOnInit() {
    this.kybService.getDocumentMetadata(this.documentId).subscribe(
      data => {
        this.documentMetadataResponse = data;
        this.fileId = this.documentMetadataResponse.data.fileIds[0];
        this.documentTypeId = this.documentMetadataResponse.data.documentType.id;
        this.documentTypeName = this.documentMetadataResponse.data.documentType.documentTypeName;

        this.kybService.getFile(this.documentId, this.fileId).subscribe(
          documentContentResponse => {
            this.documentContentResponse = documentContentResponse;
            this.fileContent = JSON.parse(atob(this.documentContentResponse.data.content));
            // Removing empty key-value pairs from JSON object
            for (const key in this.fileContent) {
              if (this.fileContent.hasOwnProperty(key)) {
                const value = this.fileContent[key];

                if (value !== null) {
                  if (value.toString() === '') {
                    delete this.fileContent[key];
                  }
                }
              }
            }

            if (!this.isGeoEntity) {
              this.fileContent = Object.assign({ primaryName: this.primaryName }, { secondaryName: this.secondaryName }, this.fileContent);
            }

            this.formData = this.inputTransform(this.fileContent);
            this.createForm(this.formData.controls);
          },
          error => {}
        );
      },
      error => {}
    );
  }

  inputTransform(fileContent: any) {
    const output = {'controls': []};

    for (const key in fileContent) {
      if (this.fileContent.hasOwnProperty(key)) {
        let type;

        if (typeof(fileContent[key]) === 'number') {
          type = 'number';
        } else {
          type = 'text';
        }

        output.controls.push({ 'name': key, 'label': key, 'value': fileContent[key], 'type': type, 'validators': { 'required': true } });
      }
    }

    return output;
  }

  createForm(controls: JsonFormControls[]) {
    for (const control of controls) {
      const validatorsToAdd = [];

      for (const [key, value] of Object.entries(control.validators)) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }

            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }

            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }

            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }

            break;
          default:
            break;
        }
      }

      this.myForm.addControl(
        control.name,
        this.fb.control(control.value, validatorsToAdd)
      );
    }
  }

  testJSON(text) {
    if (typeof text !== 'string') {
        return false;
    }

    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
  }

  onSubmit() {
    const parentThis = this;
    this.loading = true;
    this.kybService.updateDocumentMetadata(this.documentId, 'Document of type ' + this.documentTypeId
    + ' was edited.', 'REPLACED', null, null, null).subscribe(
      data => {
        this.document = new Document();
        this.document.docTypeId = this.documentTypeId;
        this.document.mimeType = 'application/json';
        this.document.retainTo = '2030-10-20T11:52:05.381';
        this.document.validTo = '2030-10-20T11:52:05.381';
        this.document.totalFiles = 1;
        this.kybService.createDocument(this.document, this.entityId, this.roleId, this.e1Id, this.e2Id).subscribe(
          documentResponse => {
            this.documentResponse = documentResponse;
            const file = new Blob([JSON.stringify(this.myForm.value)], { type: 'application/json' });
                  this.file = this.blobToFile(file, this.documentTypeName);
                  this.kybService.saveFile(this.file, this.documentResponse.data.documentId,
                    this.documentResponse.data.fileIds[0]).subscribe(
                      resSaveFile => {
                        if (this.isGeoEntity === true) {
                          this.updatedEntity = new EntityUpdate();
                          this.updatedEntity.entityId = this.entityId;
                          this.updatedEntity.primaryName = this.myForm.value.name;
                          this.updatedEntity.secondaryName = '';
                          this.updatedEntity.entityTypeId = this.entityTypeId;

                          this.kybService.updateEntity(this.updatedEntity).subscribe(
                            entityData => {
                              this.alertService.showSuccess('Document edited successfully.');
                              this.loading = false;
                              this.dataService.triggerCloseEditDocumentModalEvent(3);
                            },
                            error => {
                            }
                          );
                        } else if (this.myForm.value.personPhone != null ||
                          this.myForm.value.personEmail != null || this.myForm.value.primaryName != null
                          || this.myForm.value.contactEmail != null ||
                          this.myForm.value.contactPhone != null) {
                          this.updatedEntity = new EntityUpdate();
                          this.updatedEntity.entityId = this.entityId;
                          this.updatedEntity.primaryName = this.myForm.value.primaryName;
                          this.updatedEntity.secondaryName = this.myForm.value.secondaryName;
                          this.updatedEntity.entityTypeId = this.entityTypeId;

                          if (this.myForm.value.personPhone !== null) {
                            this.updatedEntity.entityPhoneNumber = this.myForm.value.personPhone;
                          }

                          if (this.myForm.value.contactPhone !== null) {
                            this.updatedEntity.entityPhoneNumber = this.myForm.value.contactPhone;
                          }

                          if (this.myForm.value.personEmail !== null) {
                            this.updatedEntity.entityEmail = this.myForm.value.personEmail;
                          }

                          if (this.myForm.value.contactEmail !== null) {
                            this.updatedEntity.entityEmail = this.myForm.value.contactEmail;
                          }

                          this.kybService.updateEntity(this.updatedEntity).subscribe(
                            entityData => {
                              this.alertService.showSuccess('Document edited successfully.');
                              this.loading = false;
                              this.dataService.triggerCloseEditDocumentModalEvent(3);
                            },
                            error => {
                              this.alertService.showError(error.error.errors[0].message);
                            }
                          );
                        } else {
                          setTimeout(() => {
                            parentThis.alertService.showSuccess('Document edited successfully.');
                            parentThis.loading = false;
                            parentThis.dataService.triggerCloseEditDocumentModalEvent(3);
                            // parentThis.ngOnInit();
                          }, 7000);
                        }
                      },
                      error => {
                        this.loading = false;
                      }
                  );
          },
          error => {}
        );
      },
      error => {}
    );
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }
}
