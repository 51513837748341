import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from './../../../_services';
import { DocumentManagerService } from './documentManager.service';
/**
 * Implements the user detail page
*/
var DocumentManagerComponent = /** @class */ (function () {
    function DocumentManagerComponent(callsService, route, alertService, dataService, kybService, router, documentService, ref) {
        this.callsService = callsService;
        this.route = route;
        this.alertService = alertService;
        this.dataService = dataService;
        this.kybService = kybService;
        this.router = router;
        this.documentService = documentService;
        this.ref = ref;
        this.requiredDocuments = [];
        this.entities = [];
        this.documents = [];
        this.packages = [];
        this.isveryfing = false;
    }
    DocumentManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.documentService.getPackages();
        this.documentService.packages.subscribe(function (data) {
            _this.handlePackages(data);
        });
    };
    DocumentManagerComponent.prototype.handlePackages = function (data) {
        var _this = this;
        this.packages = [];
        this.entities = [];
        this.packages = data;
        this.documents = [];
        if (data.packages && data.packages.length) {
            for (var _i = 0, _a = data.packages; _i < _a.length; _i++) {
                var p = _a[_i];
                var _loop_1 = function (entity) {
                    this_1.kybService.getEntity(entity.entityId).subscribe(function (res) {
                        _this.entities.push(res.data);
                    });
                    var _loop_2 = function (role) {
                        var _loop_3 = function (doc) {
                            if (doc.documentStatus !== 'EMPTY') {
                                return "continue";
                            }
                            this_1.kybService.getDocument(doc.documentId).subscribe(function (res) {
                                _this.documents.push({
                                    entityId: entity.entityId,
                                    docId: doc.documentId,
                                    role: role,
                                    doc: res.data
                                });
                                _this.ref.detectChanges();
                            });
                        };
                        for (var _i = 0, _a = role.requestedDocuments; _i < _a.length; _i++) {
                            var doc = _a[_i];
                            _loop_3(doc);
                        }
                    };
                    for (var _i = 0, _a = entity.roles; _i < _a.length; _i++) {
                        var role = _a[_i];
                        _loop_2(role);
                    }
                };
                var this_1 = this;
                for (var _b = 0, _c = p.entities; _b < _c.length; _b++) {
                    var entity = _c[_b];
                    _loop_1(entity);
                }
            }
        }
    };
    DocumentManagerComponent.prototype.getEntity = function (id) {
        for (var _i = 0, _a = this.entities; _i < _a.length; _i++) {
            var e = _a[_i];
            if (e.id === id) {
                return e.name;
            }
        }
    };
    DocumentManagerComponent.prototype.processDocument = function (files, fileName) {
        if (files.item(0) == null) {
            this.fileSelected = false;
        }
        else {
            this.document1 = new Document();
            this.document1.docTypeId = 2;
            this.document1.mimeType = 'image/jpeg';
            this.document1.retainTo = '2026-01-01T11:52:05.381';
            this.document1.validTo = '2026-01-01T11:52:05.381';
            this.documentFile = files.item(0);
            this.fileSelected = true;
        }
    };
    DocumentManagerComponent.prototype.submitFile = function (entityId, roleId, documentId) {
        var _this = this;
        this.isveryfing = true;
        this.kybService
            .saveFileNoId(this.documentFile, documentId)
            .subscribe(function (res) {
            _this.documentService.getPackages();
            _this.alertService.showSuccess('Document has been saved');
            _this.isveryfing = false;
        });
        // this.kybService.createDocument(this.document1, parseInt(entityId), roleId, null, null).subscribe(
        //   (data: any) => {
        //     this.documentResponse = data;
        //     this.documentId = this.documentResponse.data.documentId;
        //     this.kybService
        //       .saveFileNoId(this.documentFile, this.documentResponse.data.documentId)
        //       .subscribe(res => {
        //         this.documentService.getPackages();
        //         this.alertService.showSuccess('Document has been saved');
        //         this.isveryfing = false
        //       })
        //   });
    };
    return DocumentManagerComponent;
}());
export { DocumentManagerComponent };
