<div class="col-md-12">
  <div style="height: 40px"></div>
  <p class="text-center" style="text-decoration: underline;font-size: 20pt;">Audit Log Reports</p>
  <p style="text-align: center;font-size: 15px;"> Here you can export a CSV or PDF document of all the events that
    happened on this box in the given time period. This is useful for maintaining audit logs of the actions that were
    taken. </p>
  <div class="table-responsive" style="overflow-x: hidden;">
    <div class="row">
      <div class="col-sm-12">
        <div style="height: 40px"></div>
        <div class="row">
          <div class="col-sm-6">
            <p>Select dates:</p>
            <div style="height: 20px"></div>
            <input type="text" [(ngModel)]="dateRange" placeholder="Select dates..." class="form-control input-md"
              placement="bottom"
              [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', showWeekNumbers: false}"
              bsDaterangepicker (bsValueChange)="datesChanged($event)" required>
          </div>
          <div class="col-sm-6">
          </div>
        </div>
        <div style="height: 40px"></div>
        <button class="btn btn-primary" (click)="getEvents()" [disabled]="!dateRange">Get events</button>&nbsp;<button
          class="btn btn-primary" (click)="toggleFilters()" [disabled]="!haveResults">Filters&nbsp;<i
            class="fa fa-filter" aria-hidden="true"></i>
        </button>
        <div style="height: 40px"></div>
        <div class="row" *ngIf="displayFilters">
          <div *ngIf="users.length > 0" class="col-sm-6">
            <p>Analyst:</p>
            <ng-select [(ngModel)]="analyst" class="custom" [items]="users" [loading]="usersLoading" bindLabel="name"
              bindValue="name" appendTo="body" (change)="getEventsForAnalyst()"></ng-select>
          </div>
          <div *ngIf="entities.length > 0" class="col-sm-6">
            <p>Entity:</p>
            <ng-select [(ngModel)]="entityId" class="custom" [items]="entities" [loading]="entitiesLoading"
              bindLabel="name" bindValue="id" appendTo="body" (change)="getEventsForEntity()"></ng-select>
          </div>
          <div style="height: 20px"></div>
        </div>
      </div>
    </div>
    <div style="height: 40px"></div>
    <div class="row">
      <div class="col-sm-6" style="text-align: left;" *ngIf="haveResults"> Show <select [(ngModel)]="pageSize"
          (ngModelChange)="pageSizeChanged()">
          <option [value]="30">30</option>
          <option [value]="50">50</option>
          <option [value]="100">100</option>
          <!-- <option [value]="totalItems">All</option> -->
        </select> events </div>
      <div class="col-sm-6" style="text-align: right;" *ngIf="haveResults">
        <button class="btn btn-primary" (click)="exportCSV()">Export CSV</button>
      </div>
    </div>
    <div style="height: 20px"></div>
    <table id="activity-table" class="table" style="color: black;" *ngIf="haveResults">
      <thead>
        <tr>
          <th>Analyst</th>
          <th>Entity</th>
          <th>Event</th>
          <th>Timestamp</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isEmpty" aria-colspan="7">
          <td colspan="7" style="text-align: center;">
            <div style="height: 20px;"></div>
            <p style="text-align: center;font-family: Arial,sans-serif;font-size: 14px;">No events found.</p>
            <div style="height: 20px;"></div>
          </td>
        </tr>
        <tr *ngFor="let event of events">
          <td>{{event.actor}}</td>
          <td>{{event?.entityNames[0]}}</td>
          <td>{{event.message}}</td>
          <td>{{event.timestamp | date:'dd/MM/yyyy, h:mm:ss a z'}}</td>
        </tr>
      </tbody>
      <tfoot style="border: none;">
        <tr aria-colspan="7" style="border: none;">
          <td colspan="7" style="border: none;">
            <div style="height: 5px;"></div>
            <p
              style="font-family: Arial,sans-serif;font-size: 14px;color: #333;margin-bottom: -15px;margin-left: -7px;">
              <!-- Showing <b>{{numberOfElements}}</b> of <b>{{totalItems}}</b> events</p>-->
          </td>
        </tr>
      </tfoot>
    </table>
    <pagination [totalItems]="totalItems" [maxSize]="5" [itemsPerPage]="pageSize" [(ngModel)]="currentPage"
      (pageChanged)="pageChanged($event)" *ngIf="haveResults"></pagination>
  </div>
</div>
<ng-template #reportPreviewTemplate>
  <div class="modal-header">
    <p class="modal-title pull-left">Report content preview</p>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" style="color: gray; font-size: 35px">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-1"></div>
      <div class="col-md-10">
        <div style="height: 40px;"></div>
        <div id="report-content">
        </div>
        <div style="height: 40px;"></div>
      </div>
      <div class="col-md-1"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-success" (click)="generateReport()"
      style="background-color: #355B89;border-color: #355B89;">Generate PDF</button>
    <img *ngIf="loading" [src]="loadingImg" style="width: 20px;" />
    <button class="btn btn-danger" (click)="closeModal()">Close</button>
  </div>
</ng-template>
