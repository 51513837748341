<div class="row" *ngIf="isBrowserSupported">
    <div class="col-lg-12">
        <section style="background:white;">
            <div class="container">

                <div class="board">
                    <div  class="row">
                        <div class="row">
                            <div *ngIf="isveryfing" class="verify-loader">
                                <p> {{ LOADING_MESSAGE }} </p>
                                <div class="lds-ripple">
                                    <div></div>
                                    <div></div>
                                </div> <br />
                                <p>Please wait</p>
                            </div>

                            <div class="onb-hidden">
                                <h4 class="head text-center"> {{"THANK_YOU" | translate}}! </h4>
                                <h5 class="head text-center"> {{"THANK_YOU_1" | translate}} </h5>
                                <p class="head text-center">{{"CLOSE_TAB" | translate}}</p>
                            </div>
                            <div *ngIf="!onboardingPermissionEnabled"> {{ errorMessage }} </div>
                            <section *ngIf="!isveryfing && onboardingPermissionEnabled">
                                <div *ngIf="!onboardingCompleted && registrationCompleted" class="tab-content"
                                    class="{{ isMobile && 'mobileview'}}">
                                    <mat-horizontal-stepper #stepper (selectionChange)="setStepIndex($event)"
                                        [selectedIndex]="registerState">
                                        <mat-step *ngFor='let step of configSteps'>
                                            <ng-template matStepLabel>{{ step.stepName | translate }} </ng-template>

                                            <app-step-page-wrapper [item]="step"></app-step-page-wrapper>

                                        </mat-step>

                                    </mat-horizontal-stepper>
                                    <div class="form-group text-center" *ngIf="!onboardingCompleted">
                                        <button type="button"    
                                        [disabled]="isDisabled()"
                                        *ngIf="videoOrLastStep() && !disableNextButtonMobile() && registrationCompleted"
                                            class=" btn btn-outline-rounded btn-info no-margin-bottom"
                                            (click)="goToNext()">{{ "NEXT" | translate }} <span
                                                style="margin-left:10px;"
                                                class="glyphicon glyphicon-arrow-right"></span></button>
                                        <button  *ngIf="lastStep() && !customOnboarding" type="button"
                                            class="btn btn-outline-rounded btn-info no-margin-bottom"
                                            (click)="finish()">{{ "FINISH" | translate }} <span
                                                style="margin-left:10px;"
                                                class="glyphicon glyphicon-arrow-right"></span></button>
                                        <!-- <img [hidden]="!loading" src="{{loadingImg}}" width="35" /> -->
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div *ngIf="!isBrowserSupported">
    <p> {{ "ERROR_SUPPORTED_BROWSERS_HEADER" | translate }}: </p>
    <p> {{ "ERROR_SUPPORTED_BROWSERS_CONTENT" | translate }} </p>
</div>
