import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'geoAssessment'})
export class GeoAssessmentPipe implements PipeTransform {
  transform(value: any): string {
    const json = JSON.parse(value);
    value = '';
    Object.keys(json).forEach(element => {
        value += '<b>' + element + ': </b>' + json[element] + '</br>';
    });
    return value;
  }
}
