<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div class="col-md-12">
      <div style="height: 20px"></div>
      <p class="text-center" style="color: black;font-family: 'Open Sans', sans-serif;">Package Creator (Debug Version)</p>
      <div style="height: 20px"></div>
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <h4 style="text-decoration: underline;">Company</h4>
          <div style="height: 20px"></div>
          <label class="control-label" for="companyName"><b>Name:</b></label><br>
          <input class="form-control input-md" type="text" [(ngModel)]="onboardingEntity.primaryName" name="companyName" /><br>
          <div style="height: 20px"></div>
          <label class="control-label" for="requestedDocs"><b>Requested document(s):</b></label><br>
          <form [formGroup]="requiredLinkForm">
            <div formArrayName="links">
                <div *ngFor="let requiredDoc of links.controls; let i = index" [formGroupName]="i">
                    <table style="border: none;">
                        <tr>
                            <td>Type:</td>
                            <td>External ID:</td>
                            <td>Note:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <select class="select-role-type" formControlName="documentType" placeholder="Document Type">
                                <option value=""></option>
                                <option *ngFor="let document of documentTypes" [value]="document.documentTypeName">
                                    {{document.description}} </option>
                              </select>
                            </td>
                            <td>
                              <input formControlName="externalDocumentId" type="text">
                            </td>
                            <td>
                              <input formControlName="note" type="text">
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteDocument(requiredDoc.value)">Delete &nbsp;
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="height: 20px"></div>
            <button class="btn btn-primary" (click)="addDocument()">Add Document&nbsp;<i
                    class="fa fa-plus" aria-hidden="true"></i></button>
        </form>
        <div style="height: 20px"></div>
          <h4 style="text-decoration: underline;">Representative Entity</h4>
          <div style="height: 20px"></div>
          <label class="control-label" for="name"><b>Name:</b></label><br>
          <input class="form-control input-md" type="text" [(ngModel)]="representativeEntity.primaryName" name="primaryName" /><br>
          <div style="height: 20px"></div>
          <label class="control-label" for="email"><b>Email:</b></label><br>
          <input class="form-control input-md" type="text" [(ngModel)]="representativeEntity.email" name="email" /><br>
          <div style="height: 20px"></div>
          <label class="control-label" ><b>Requested document(s):</b></label><br>
          <form [formGroup]="representativeRequiredDocsForm">
            <div formArrayName="representativeLinks">
                <div *ngFor="let requiredRepresentativeDoc of representativeLinks.controls; let i = index" [formGroupName]="i">
                    <table style="border: none;">
                        <tr>
                            <td>Type:</td>
                            <td>External ID:</td>
                            <td>Note:</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>
                              <select class="select-role-type" formControlName="documentType" placeholder="Document Type">
                                <option value=""></option>
                                <option *ngFor="let document of documentTypes" [value]="document.documentTypeName">
                                    {{document.description}} </option>
                              </select>
                            </td>
                            <td>
                              <input formControlName="externalDocumentId" type="text">
                            </td>
                            <td>
                              <input formControlName="note" type="text">
                            </td>
                            <td>
                                <button class="btn btn-danger" (click)="deleteRepresentativeDocument(requiredRepresentativeDoc.value)">Delete &nbsp;
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div style="height: 20px"></div>
            <button class="btn btn-primary" (click)="addRepresentativeDocument()">Add Document&nbsp;<i
                    class="fa fa-plus" aria-hidden="true"></i></button>
            <div style="height: 40px"></div>
        </form>
          <button type="button" class="btn btn-success" (click)="createPackage()" [disabled]="loading"> Create Package </button>
          <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;">
          <div style="height: 20px"></div>
          {{onboardingUrlResponse?.url}}
        </div>
        </div>
        <div class="col-md-2"></div>
    </div>
  </div>
  </div>
</div>
