import { Component, ChangeDetectorRef, OnInit, Output, EventEmitter, Type} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, DataService, KYBService } from '../../_services';
import { ConfigService } from '../../_services/config.service';
import { config } from '../../../assets/configuration';
import { FormDataService } from './data/formData.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VerificationService } from './verification.service';
import { RoleUpdate } from '../../_models';
import { BrandingService } from './../../branding/branding.service';
import { BrandingSetup } from './../../branding/branding.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocumentManagerComponent } from './documentManager/documentManager.component';
import { DisclaimersComponent } from './disclaimers/disclaimers.component';
import { IdentityDocumentComponent } from './identityDocument/identityDocument.component';
import { VideoComponent } from './video/video.component';

/**
 * Implements the registration page
*/
@Component({
  moduleId: module.id,
  templateUrl: 'register.component.html',
  styleUrls: ['./register.component.css'],
})


export class RegisterComponent implements OnInit {
errorMessage = 'Page not found, Please make sure that you use the latest onboarding URL';
  public get agent(): any { return navigator.userAgent || navigator.vendor; }
  public get chrome(): boolean { return !!this.agent.toLowerCase().match(/(chrome)/i); }
  public get safari(): boolean { return !!this.agent.toLowerCase().match(/(safari)/i); }
  public get firefox(): boolean { return !!this.agent.toLowerCase().match(/(firefox)/i); }
  public get firefoxIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(fxios)/i); }
  public get chromeIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(crios)/i); }
  public get operaIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(opios)/i); }
  public get samsungAndroid(): boolean { return !!navigator.userAgent.toLowerCase().match(/(samsungbrowser)/i); }
  public get android(): boolean { return !!this.agent.match(/(android)/i); }
  public get ios(): boolean { return !!this.agent.match(/(iPhone)|(iPod)/i); }

  constructor(private formDataService: FormDataService,
    private ref: ChangeDetectorRef,
    private dataService: DataService,
    private kybService: KYBService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private _verificationService: VerificationService,
    private brandingService: BrandingService,
    private deviceDetectorService: DeviceDetectorService,
    private configService: ConfigService,
    public authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) {
    this.dataService.documentReady.subscribe(
      message => {
        this.documentReady = message;
      }
    );
    this.configSteps = this.configService.get('onboardSteps');


    this.onboardingLanguage = this.configService.get('onboardingLanguage');
    if (this.configService.get('documentManagerFlow')) {
      this.customOnboarding = true;
      if (this.configService.get('azureSSO') !== undefined || this.configService.get('googleSSO') !== undefined) {
        if (this.configService.get('azureSSO') && this.configService.get('googleSSO')) {
          // tslint:disable-next-line:no-use-before-declare
          this.azureOrGoogleModal = this.modalService.show(AzureOrGoogleModalComponent, { backdrop: 'static' });

          this.dataService.closeModalEvent.subscribe(() => {
            this.azureOrGoogleModal.hide();
          });

          this.azureOrGoogleModal.content.ssoEntry.subscribe((sso) => {
            this.ssoHost = sso;
            window.location.href = '/api/oauth2/authorize/' + this.ssoHost + '?redirect_uri=' + window.location.origin + '/onboard';
          });
        } else if (this.configService.get('azureSSO') && !this.configService.get('googleSSO')) {
          this.ssoHost = 'azure';
        } else if (!this.configService.get('azureSSO') && this.configService.get('googleSSO')) {
          this.ssoHost = 'google';
        } else {
          this.customOnboarding = false;
        }
      }
    } else {
      this.customOnboarding = false;
    }
  }
  public get isBrowserSupported(): boolean {
    if (this.ios && (this.safari === true && this.chromeIOS === false && this.firefoxIOS === false && this.operaIOS === false)) {
      return true;
    } else if (this.android && (this.chrome === true && this.samsungAndroid === false && this.firefox === false)) {
      return true;
    } else if (!this.deviceDetectorService.isMobile()) {
      return true;
    } else {
      return false;
    }
  }

  error: string;
  companyName: string = config.companyName;
  registerState = 0;
  deviceInfo = null;
  isMobile: any = false;
  thirdParty: any = false;
  initStack: any = {};
  configSteps: any = [];
  onboardingComnponents: { name: string, type: Type<any> }[] = [

    { name: 'document-manager', type: DocumentManagerComponent },
    { name: 'identity-document', type: IdentityDocumentComponent },
    { name: 'video-onboarding', type: VideoComponent },
    { name: 'disclaimers', type: DisclaimersComponent },

  ];
  LOADING_MESSAGE = 'We are veryfing you ';
  webUrl: string;
  disclaimersChecked = false;

  // wizard props
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  canFinishOnboarding: boolean;
  finishOnboarding: boolean;
  onboardingCompleted = false;
  redirectUrl: string = null;
  canFinish = false;
  verifyToken: string;
  verifiedEntity;
  isveryfing = true;
  onboardingPermissionEnabled = false;
  isQrEnabled = false;
  documentIds: any = [];
  documentReady = false;
  urlQrCode = '';
  emaildata: any;
  personalForm: any;
  isMobileDevice = false;
  customOnboarding = false;
  registrationCompleted = true;
  firstConnection = true;
  ssoHost = 'azure';
  onboardingLanguage = 'en';

  azureOrGoogleModal: BsModalRef;

  additionalDocumentIds: any = [];
  requiredDocumentIds: any = [];
  requiredDocumentTypes: any = [];
  getEntityResponse: any = {};
  getRoleResponse: any = {};
  updatedRole: RoleUpdate;

  createUserResponse: any;

  disableNextButtonMobile() {
    if (this.isMobileDevice === true && this.registerState === 1) {
      return true;
    }
    return false;
  }
  videoOrLastStep() {
    if (this.configSteps[this.registerState].name === 'video-onboarding' || (this.registerState === this.configSteps.length - 1 )) {
      return false;
    }
    return true;
  }
  lastStep() {

    if (this.registerState === this.configSteps.length - 1 && this.configSteps[this.registerState].name !== 'video-onboarding') {
      return true;
    }
    return false;
  }
  ngOnInit() {
    this.isMobileDevice = this.deviceDetectorService.isMobile();
    this.dataService.currentDocumentIds.subscribe(message => this.documentIds = message);
    this.dataService.currentPersonalForm.subscribe(message => {
      this.personalForm = message;
    });
    this.route.queryParams
      .subscribe(params => {
        if (params.token !== undefined) {
          this.onboardingPermissionEnabled = true;
          localStorage.setItem('auth_token', JSON.stringify(params.token));
          this.isveryfing = false;
          this.dataService.setOnboardingEntityId(localStorage.getItem('onboardingEntityId'));

          this.kybService.getRolesForEntity(localStorage.getItem('onboardingEntityId')).subscribe(
            data => {
              // window.alert("Get roles for entity triggered, response " + JSON.stringify(data));
              this.getEntityResponse = data;

              for (let r = 0; r < this.getEntityResponse.data.length; r++) {
                this.isveryfing = false;

                if (this.getEntityResponse.data[r].roleName.indexOf('Identity') !== -1) {
                  this.dataService.setOnboardingEntitySelfRoleId(this.getEntityResponse.data[r].id);
                }
              }

              this.kybService.validateRepresentative().subscribe(
                response => {
                  let res: any;
                  res = response;
                  if (res.hasIdAttached === true && res.hasIdAttached === true) {
                    this.registrationCompleted = true;
                    this.firstConnection = false;
                    this.formDataService.setfinishOnboarding(true);
                  } else {
                    this.firstConnection = true;
                  }
                  this.dataService.setFirstConnection(this.firstConnection);
                },
                error => {
                  this.firstConnection = true;
                  this.dataService.setFirstConnection(this.firstConnection);
                });
              this.isveryfing = false;
            }, error => { }
          );
        }
      }
      );

    this.route.paramMap.subscribe(params => {
      this.onboardingLanguage = this.configService.get('onboardingLanguage');
      this.useLanguage(this.onboardingLanguage);
      // const lang = params.get("lang")
      // if (lang) {
      //   this.useLanguage(lang);
      // }

      this.redirectUrl = params.get('redirectUrl');
      this.verifyToken = params.get('token');

      if (this.verifyToken) {
        this.handleToken();
      }

      this.isQrEnabled = true;

      const ua = navigator.userAgent;

      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) { this.isMobile = true; } else if (/Chrome/i.test(ua)) { this.isMobile = false; } else { this.isMobile = false; }

      this.urlQrCode = window.location.href;
      //  if (navigator.mediaDevices.getUserMedia) {
      //   navigator.mediaDevices.getUserMedia({ video: true })
      //  .then(stream => {
      //    this.isQrEnabled = false;
      //   }).catch(err => {
      //    this.isQrEnabled = true;
      //  });}
    });

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.initStack.entities = [];
    this.initStack.roles = [];
    this.initStack.documents = [];
    this.dataService.setCurrentStack(this.initStack);

    // TODO - delete this line
    this.dataService.setThirdParty('2');
    this.formDataService.resetFormData();
    this.formDataService.setRegisterState(0);
    this.formDataService.currentRegisterState.subscribe(message => {
      this.registerState = message;
    });

    this.formDataService.finishOnboarding.subscribe(message => {

      // this.onboardingCompleted = true;
      if (message === true) {
        this.additionalDocumentIds = [];
        this.requiredDocumentIds = [];
        this.requiredDocumentTypes = [];
        this.isveryfing = false;

        // add a custom class to body to allow new design for thank you page
        if (this.customOnboarding !== true) {
          document.body.className += ' onboarding-finished';
          this.onboardingCompleted = true;

          if (this.redirectUrl) {
            window.location.href = 'https://' + this.redirectUrl;
          }

          this._verificationService.deactivateToken(this.verifyToken).subscribe(res => {
            localStorage.removeItem('auth_token');
          }, error => { }
          );

          this.brandingService.brandingSetup.subscribe((response: BrandingSetup) => {
            if (!response) { return; }
            this.webUrl = response.companyUrl;
          });
        } else {
          this.goToNext();
          this.ref.detectChanges();
        }

        window.scrollTo(0, 0);
      }
    });

    this.formDataService.currentDisclaimersState.subscribe(message => {
      this.disclaimersChecked = message;
    });

    this.formDataService.videoRegisterState.subscribe(message => {
      if (message === true) {
        for (let i = 0; i < this.configSteps.length; i++) {
          if (this.configSteps[i].name === 'video-onboarding') {
            this.formDataService.setRegisterState(i);
          }
        }
      }
    });

    this.dataService.thirdParty.subscribe(
      message => {
        if (message === 1 || message === 2) { this.thirdParty = true; } else { this.thirdParty = false; }
      }
    );
  }

  handleToken() {
    this.isveryfing = true;

    localStorage.removeItem('auth_token');

    this.verifiedEntity = this._verificationService.verifyToken(this.verifyToken);
    // window.alert("verifiedEntity is: " + JSON.stringify(this.verifiedEntity));

    this.verifiedEntity.subscribe(res => {
      this.dataService.setOnboardingEntityId(res.entityId);
      localStorage.setItem('onboardingEntityId', res.entityId);
      this.onboardingPermissionEnabled = true;
      localStorage.setItem('auth_token', JSON.stringify(res.token));

      this.kybService.getRolesForEntity(res.entityId).subscribe(
        data => {
          this.dataService.setFirstConnection(this.firstConnection);
          // window.alert("Get roles for entity triggered, response " + JSON.stringify(data));
          this.getEntityResponse = data;
          for (let r = 0; r < this.getEntityResponse.data.length; r++) {
            /*  this.kybService.checkUserStatus(this.getEntityResponse.data[r].e1Instance.email).subscribe(
               response => {
                 this.emaildata = response;
                 if (this.emaildata.data.role != "invited" && this.customOnboarding) {
                   this.firstConnection = false;
                 }
                 this.dataService.setFirstConnection(this.firstConnection);
               },
               error => { }); */

            this.isveryfing = false;

            if (this.getEntityResponse.data[r].roleName.indexOf('Identity') !== -1) {
              this.dataService.setOnboardingEntitySelfRoleId(this.getEntityResponse.data[r].id);
              const currenturl = window.location.origin;

              if (!this.isveryfing && this.onboardingPermissionEnabled && this.customOnboarding) {
                // for local env
                // window.location.href = "https://test-invesco.kycbox.io/api/oauth2/authorize/" +
                // this.ssoHost + "?redirect_uri=" + currenturl + "/onboard";
                window.location.href = '/api/oauth2/authorize/' + this.ssoHost + '?redirect_uri=' + currenturl + '/onboard';
              }
            }
          }
        }, error => { }
      );
    }, e => {
      this.isveryfing = false;
      this.onboardingCompleted = false;
    });
  }

  captureEvent(event) {
    //
    // let oldStep = +this.registerState + 1;
    // this.formDataService.setRegisterState(oldStep);
  }

  close() {
    window.close();
  }

  setStepIndex(stepObj) {
    if (stepObj.selectedIndex === this.registerState) { return; }
    this.formDataService.setRegisterState(stepObj.selectedIndex);
  }

  hastStep(name) {
    for (const step of this.configSteps) {
      if (step.name === name) { return true; }
    }
    return false;
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  goToNext() {
    const oldStep = +this.registerState + 1;
    if (oldStep < this.configSteps.length) {
      this.formDataService.setRegisterState(oldStep);
    }
  }

  goToPrevious() {
    const oldStep = +this.registerState - 1;
    this.formDataService.setRegisterState(oldStep);
  }

  isDisabled() {
    if ((this.disclaimersChecked === false && this.configSteps[this.registerState].name === 'disclaimers') ||
    (!this.documentReady && this.configSteps[this.registerState].name === 'identity-document')) {
      return true;
    }
    return false;
  }

  finish() {
    if (this.customOnboarding === true) {
    } else {
      this._verificationService.deactivateToken(this.verifyToken).subscribe(res => {
        localStorage.removeItem('auth_token');
      }, error => { }
      );
    }

    if (this.customOnboarding !== true) {
      setTimeout(() => {
        this.additionalDocumentIds = [];
        this.requiredDocumentIds = [];
        this.requiredDocumentTypes = [];
        // window.alert("Finish called");
        // window.alert("onboardingEntityId is: " + this.dataService.onboardingEntityId.getValue());
        // this.isveryfing = true;
        // this.onboardingCompleted = false;
        this.isveryfing = false;
        this.onboardingCompleted = true;

        // add a custom class to body to allow new design for thank you page

        document.body.className += ' onboarding-finished';

        setTimeout(() => {
          if (this.redirectUrl) {
            window.location.href = 'https://' + this.redirectUrl;
          }
        }, 1000);
      }, 1000);

      this.brandingService.brandingSetup.subscribe((res: BrandingSetup) => {
        if (!res) { return; }
        this.webUrl = res.companyUrl;
      });
    }
  }
}

@Component({
  // tslint:disable-next-line
  selector: 'modal-content',
  template: `
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="overflow:hidden;">
      Would you like to choose Azure SSO or Google SSO?
    </div>
    <div class="modal-footer" style="text-align: right;">
      <button class="btn btn-primary" (click)="chooseAzure()">AzureSSO</button>
      <button class="btn btn-primary" (click)="chooseGoogle()">GoogleSSO</button>
    </div>
  `
})

export class AzureOrGoogleModalComponent implements OnInit {

  @Output() ssoEntry: EventEmitter<any> = new EventEmitter();

  constructor(private dataService: DataService) { }

  ngOnInit() { }

  chooseAzure() {
    this.ssoEntry.emit('azure');
  }

  chooseGoogle() {
    this.ssoEntry.emit('google');
  }

  close() {
    this.dataService.triggerCloseModalEvent(1);
  }
}
