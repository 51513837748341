<form #mailServerForm="ngForm" class="editForm" novalidate>
        <h3 class="text-center">Email Server Setup</h3><br>
        <div class="row">
                <div class='col-xs-12 col-sm-6'>
                        <div class="form-group">
                                <label class="control-label" for="host"><b>Host:</b></label>  
                                <input class="form-control input-md" #hostt="ngModel" id="host" name="host" type="text" pattern="^(?!\s*$).+" placeholder="Host" [(ngModel)]="mailServer.host">
                                <div class="alert alert-danger" [hidden]="hostt.valid">Host is required</div>
                        </div>
                </div>
                <div class='col-xs-12 col-sm-6'>
                        <div class="form-group">
                                <label class="control-label" for="username"><b>Username:</b></label>  
                                <input class="form-control input-md" #usernamee="ngModel" id="username" name="username" type="text" pattern="^(?!\s*$).+" placeholder="Username" [(ngModel)]="mailServer.username">
                                <div class="alert alert-danger" [hidden]="usernamee.valid">Username is required</div>
                        </div>
 <!--                        <div class="form-group">
                                <label class="control-label" for="post"><b>Port:</b></label>  
                                <input class="form-control input-md" #portt="ngModel" id="port" name="port" type="text" pattern="^\d+$" placeholder="Port" [(ngModel)]="mailServer.port">
                                <div class="alert alert-danger" [hidden]="portt.valid">Port is required</div>
                        </div> -->
                </div>
        </div>   
        <div class="row">
            <div class='col-xs-12 col-sm-6'>
                <div class="form-group">
                        <label class="control-label" for="password"><b>Password:</b></label>  
                        <input class="form-control input-md" #passwordd="ngModel" id="password" name="password" type="password" placeholder="Password" [(ngModel)]="mailServer.password">
                        <div class="alert alert-danger" [hidden]="passwordd.valid">Password is required</div>
                </div>
            </div> 
            <div class='col-xs-12 col-sm-6'>
                <div class="form-group">
                        <label class="control-label" for="from"><b>From Email:</b></label>  
                        <input class="form-control input-md" #fromm="ngModel" id="from" name="from" type="text" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$" placeholder="From Email" [(ngModel)]="mailServer.from">
                        <div class="alert alert-danger" [hidden]="fromm.valid">From email is required and must be valid</div>
                </div>
            </div>
        </div>
        <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="saveServer(mailServerForm)"> Save </button>
        </div>
    </form>
    