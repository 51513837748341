<div class="col-md-3">
    <div style="height: 5px;"></div>
    <div class="label">
        <span>MARK AS:</span>
    </div>
</div>
<div class="col-md-3">
    <button type="button" class="btn btn-success" (click)="addRiskAssessment('approved')">Approved</button>
</div>
<div class="col-md-4">
    <button type="button" class="btn btn-warning" (click)="addRiskAssessment('further')">Further Analysis</button>
</div>
<div class="col-md-2">
    <button type="button" class="btn btn-danger" (click)="addRiskAssessment('rejected')">Rejected&nbsp;</button>
</div>
