import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, AuthenticationService, CallsService, KYBService, SharedService } from '../../_services';
import { EnterpriseGuard } from '../../_guards/enterprise.guard';

/**
 * Implements the login page
 */
@Component({
  moduleId: module.id,
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private callsService: CallsService,
    private guard: EnterpriseGuard,
    private kybService: KYBService,
    private sharedService: SharedService
  ) {}
  model: any = {};
  response: any = {};
  admin = false;
  loading = false;
  returnUrl: string;
  encodedPassword: string;
  devloginUrl: string;
  deviceInfo = null;
  isRecoverEnabled = false;
rec1: any;
rec2: any;
rec3: any;
rec4: any;
rec5: any;

  loginResponse: any = {};

  userPermissions: any = [];
  isTfaEnabled = false;
  errorMessage = "";
  mfaCode: any ;

  ngOnInit() {
    this.sharedService.emitChange([]);

    this.authenticationService.logout();
  }
  /**
   * Function implementing the login process
   */
  recoverEnable() {
    this.isRecoverEnabled = true;
  }
  login() {
    if (this.model.userName !== null && this.model.password !== null) {
      this.authenticationService.login(this.model.userName, this.model.password).subscribe(
        data => {
          if (data !== null) {
            this.loginResponse = data;

            if (this.loginResponse.isAdmin === true) {
              localStorage.setItem('username', this.model.userName);
              // localStorage.setItem('token',JSON.stringify(this.loginResponse.token));
              localStorage.setItem('auth_token', JSON.stringify(this.loginResponse.token));
              localStorage.setItem('login_token', JSON.stringify(this.loginResponse.token));
              localStorage.setItem('identifier', this.loginResponse.userIdentifier);
              localStorage.setItem('expires_at', this.loginResponse.validUntilTimestamp);
              this.callsService.setAdmin(true);
              localStorage.setItem('isAdmin', '1');
            } else {
              localStorage.setItem('isAdmin', '0');
              localStorage.setItem('username', this.model.userName);
              // localStorage.setItem('token',JSON.stringify(this.loginResponse.token));
              localStorage.setItem('auth_token', JSON.stringify(this.loginResponse.token));
              localStorage.setItem('login_token', JSON.stringify(this.loginResponse.token));
              localStorage.setItem('identifier', this.loginResponse.userIdentifier);
              localStorage.setItem('expires_at', this.loginResponse.validUntilTimestamp);
              // window.alert('You are not allowed to access this area');
            }
            this.kybService.getUserPermissions().subscribe(value => {
              this.userPermissions = value.data.permissions;
              this.sharedService.emitChange(this.userPermissions);
            },
            error => {

            });
            this.router.navigate(['/enterprise']);
          } else {
            this.alertService.showError('Login failed.');
          }
        },
        error => {
          if (error.error.mfaRequired === undefined) {
            this.alertService.showError('Login failed.');


}
          if (error.error.mfaRequired) {
            this.isTfaEnabled = true;
            localStorage.setItem('username', this.model.userName);

}

                 }
      );
    } else {
      this.alertService.showError('Please fill in a valid username and password.');
    }
  }

  loginUser () {
    const usern = localStorage.getItem('username');
    const pass = this.model.password;



      this.authenticationService.loginUserTfa(usern, pass, this.mfaCode)
      .subscribe(response => {
      localStorage.setItem("token_mfa", response.data);
      if (response.isAdmin === true) {
        // localStorage.setItem('token',JSON.stringify(response.token));
        localStorage.setItem('auth_token', JSON.stringify(response.token));
        localStorage.setItem('login_token', JSON.stringify(response.token));
        localStorage.setItem('identifier', response.userIdentifier);
        localStorage.setItem('expires_at', response.validUntilTimestamp);
        // this.callsService.setAdmin(true);
        localStorage.setItem('isAdmin', '1');
      } else {
        localStorage.setItem('isAdmin', '0');
        // localStorage.setItem('token',JSON.stringify(response.token));
        localStorage.setItem('auth_token', JSON.stringify(response.token));
        localStorage.setItem('login_token', JSON.stringify(response.token));
        localStorage.setItem('identifier', response.userIdentifier);
        localStorage.setItem('expires_at', response.validUntilTimestamp);
        // window.alert('You are not allowed to access this area');
      }
      this.kybService.getUserPermissions().subscribe(value => {
        this.userPermissions = value.data.permissions;
        this.sharedService.emitChange(this.userPermissions);
        this.router.navigate(['/enterprise']);

      },
      error => {
      });
    },
    error => {
      this.alertService.showError('Wrong Code');

      this.errorMessage = error.message;

    });

}
recover() {
  const usern = localStorage.getItem('username');
  const pass = this.model.password;
 const body =  {
    recoveryCodes: [this.rec1, this.rec2, this.rec3, this.rec4, this.rec5],
    auth: {
      "email": usern,
      "password": pass
  }

  };
  this.authenticationService.recoverTfa(body)
  .subscribe(response => {
  this.isRecoverEnabled = false;
  this.isTfaEnabled = false;
  this.alertService.showSuccess('Recovered sucessfully.');


},
error => {
  this.alertService.showError('Recovery fail.');
});

}
}
