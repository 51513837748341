<div class="col-md-12">
  <tabset type="pills" #staticTabs  class="main-topic-tabs">
    <tab heading="Activity Reports"  (selectTab)="tab1 = true">
        <div class="row" >
            <app-activity-report *ngIf="tab1"></app-activity-report>
        </div>
    </tab>
      <tab heading="Entity Reports" (selectTab)="tab2 = true">
          <div class="row">
              <app-entity-report  *ngIf="tab2"></app-entity-report>
          </div>
      </tab>
      <tab heading="Screening Reports" (selectTab)="tab3 = true">
          <div class="row">
              <app-screening-report  *ngIf="tab3"></app-screening-report>
          </div>
      </tab>
  </tabset>
</div>
