import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { Role } from '../../../_models/kyb';
/**
 * Implements the enterprise page
*/
var AddRoleSimpleComponent = /** @class */ (function () {
    function AddRoleSimpleComponent(router, callsService, alertService, dataService, kybService, chRef, modalService, domSanitizer) {
        this.router = router;
        this.callsService = callsService;
        this.alertService = alertService;
        this.dataService = dataService;
        this.kybService = kybService;
        this.chRef = chRef;
        this.modalService = modalService;
        this.domSanitizer = domSanitizer;
        this.e1Instance = '';
        this.e2Instance = '';
        this.entitiesResponse = {};
        this.roleTypeSelect = false;
        this.entityType = '';
        this.newEntities = [];
        this.GEOentities = [];
        this.roleTypeResponse = {};
        this.roleTypesLoading = false;
        this.entitiesLoading = false;
        this.showGEOentities = false;
        this.roleResponse = {};
        this.roleName = '';
        this.e1Name = '';
        this.e2Name = '';
        this.getEntityResponse = {};
        this.GEOResponse = {};
    }
    AddRoleSimpleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.roleTypesLoading = true;
        this.entitiesLoading = true;
        this.entities = [];
        this.roleTypes = [];
        this.dataService.stack.subscribe(function (message) { return _this.currentStack = message; });
        this.kybService.getAllEntities({}).subscribe(function (data) {
            _this.entitiesResponse = data;
            _this.entities = _this.entitiesResponse.data;
            if (_this.entityId !== undefined) {
                for (var k = 0; k < _this.entities.length; k++) {
                    if (_this.entities[k].id === _this.entityId) {
                        _this.e1Instance = _this.entityId;
                        _this.entityType = _this.entities[k].entityType;
                    }
                }
            }
            if (_this.entityId !== undefined) {
                _this.e1InstanceChanged({ id: _this.e1Instance });
            }
            _this.entitiesLoading = false;
        }, function (error) { });
        this.kybService.getRoleTypes().subscribe(function (data) {
            _this.roleTypeResponse = data;
            var _loop_1 = function (i) {
                if (_this.roleTypeResponse.data[i].self === false) {
                    _this.roleTypeResponse.data[i].allowableE1EntityTypes.forEach(function (type) {
                        if (_this.entityType === type.entityType) {
                            _this.roleTypes = _this.roleTypes.concat([_this.roleTypeResponse.data[i]]);
                        }
                    });
                }
            };
            for (var i = 0; i < _this.roleTypeResponse.data.length; i++) {
                _loop_1(i);
            }
            var newRoleTypes = [];
            _this.roleTypes.forEach(function (roleType) {
                if (!newRoleTypes.includes(roleType)) {
                    newRoleTypes.push(roleType);
                }
            });
            _this.roleTypes = newRoleTypes;
            if (_this.roleTypeId !== undefined) {
                for (var j = 0; j < _this.roleTypes.length; j++) {
                    if (_this.roleTypes[j].id === _this.roleTypeId) {
                        _this.roleType = _this.roleTypeId;
                        // Logic for detecting roles including a GEO entity as E2
                        var allowableE2EntityTypesTemp = [];
                        for (var k = 0; k < _this.roleTypes[j].allowableE2EntityTypes.length; k++) {
                            allowableE2EntityTypesTemp.push(_this.roleTypes[j].allowableE2EntityTypes[k].entityType);
                        }
                        if (allowableE2EntityTypesTemp.indexOf('GEO') !== -1) {
                            _this.showGEOentities = true;
                            _this.kybService.getAllEntitiesPaged(600, 0, null, null, null, null, true, false, null).subscribe(function (entityData) {
                                _this.GEOResponse = entityData;
                                _this.GEOentities = _this.GEOResponse.data.content;
                            });
                        }
                    }
                }
            }
            _this.roleTypesLoading = false;
        });
        // }
    };
    AddRoleSimpleComponent.prototype.e1InstanceChanged = function (event) {
        var _this = this;
        this.newEntities = this.entities.slice();
        for (var i = 0; i < this.newEntities.length; i++) {
            if (event.id === this.newEntities[i].id) {
                this.newEntities.splice(i, 1);
            }
        }
        this.roleTypesLoading = true;
        this.entitiesLoading = true;
        this.entities = [];
        this.roleTypes = [];
        this.dataService.stack.subscribe(function (message) { return _this.currentStack = message; });
        this.kybService.getAllEntities({}).subscribe(function (data) {
            _this.entitiesResponse = data;
            _this.entities = _this.entitiesResponse.data;
            if (event.id !== undefined) {
                for (var k = 0; k < _this.entities.length; k++) {
                    if (_this.entities[k].id === event.id) {
                        _this.e1Instance = event.id;
                        _this.entityType = _this.entities[k].entityType;
                    }
                }
            }
            _this.entitiesLoading = false;
        }, function (error) { });
        this.kybService.getRoleTypes().subscribe(function (data) {
            _this.roleTypeResponse = data;
            var _loop_2 = function (i) {
                if (_this.roleTypeResponse.data[i].self === false) {
                    _this.roleTypeResponse.data[i].allowableE1EntityTypes.forEach(function (type) {
                        if (_this.entityType === type.entityType) {
                            _this.roleTypes = _this.roleTypes.concat([_this.roleTypeResponse.data[i]]);
                        }
                    });
                }
            };
            for (var i = 0; i < _this.roleTypeResponse.data.length; i++) {
                _loop_2(i);
            }
            var newRoleTypes = [];
            _this.roleTypes.forEach(function (roleType) {
                if (!newRoleTypes.includes(roleType)) {
                    newRoleTypes.push(roleType);
                }
            });
            _this.roleTypes = newRoleTypes;
            if (_this.roleTypeId !== undefined) {
                for (var j = 0; j < _this.roleTypes.length; j++) {
                    if (_this.roleTypes[j].id === _this.roleTypeId) {
                        _this.roleType = _this.roleTypeId;
                        // Logic for detecting roles including a GEO entity as E2
                        var allowableE2EntityTypesTemp = [];
                        for (var k = 0; k < _this.roleTypes[j].allowableE2EntityTypes.length; k++) {
                            allowableE2EntityTypesTemp.push(_this.roleTypes[j].allowableE2EntityTypes[k].entityType);
                        }
                        if (allowableE2EntityTypesTemp.indexOf('GEO') !== -1) {
                            _this.showGEOentities = true;
                            _this.kybService.getAllEntitiesPaged(600, 0, null, null, null, null, true, false, null).subscribe(function (entityData) {
                                _this.GEOResponse = entityData;
                                _this.GEOentities = _this.GEOResponse.data.content;
                            });
                        }
                    }
                }
            }
            _this.roleTypesLoading = false;
        });
    };
    AddRoleSimpleComponent.prototype.roleSelected = function () {
        this.roleTypeSelect = true;
    };
    AddRoleSimpleComponent.prototype.roleTypeChanged = function () {
        var _this = this;
        for (var j = 0; j < this.roleTypes.length; j++) {
            if (this.roleTypes[j].id === this.roleType) {
                // Logic for detecting roles including a GEO entity as E2
                var allowableE2EntityTypesTemp = [];
                for (var k = 0; k < this.roleTypes[j].allowableE2EntityTypes.length; k++) {
                    allowableE2EntityTypesTemp.push(this.roleTypes[j].allowableE2EntityTypes[k].entityType);
                }
                if (allowableE2EntityTypesTemp.indexOf('GEO') !== -1) {
                    this.showGEOentities = true;
                    this.kybService.getAllEntitiesPaged(600, 0, null, null, null, null, true, false, null).subscribe(function (entityData) {
                        _this.GEOResponse = entityData;
                        _this.GEOentities = _this.GEOResponse.data.content;
                    });
                }
                else {
                    this.showGEOentities = false;
                }
            }
        }
    };
    AddRoleSimpleComponent.prototype.addRole = function () {
        var _this = this;
        this.kybService.getEntity(this.e2Instance).subscribe(function (data) {
            _this.getEntityResponse = data;
            _this.role = new Role();
            if (_this.getEntityResponse.data.entityType.entityType === 'GEO') {
                _this.role.e1Id = _this.e1Instance;
                _this.role.e2Id = _this.e2Instance;
            }
            else {
                _this.role.e1Id = _this.e2Instance;
                _this.role.e2Id = _this.e1Instance;
            }
            _this.role.roleTypeId = _this.roleType;
            _this.role.documents = [];
            _this.role.documentIds = [];
            _this.kybService.createRole(_this.role).subscribe(function (roleResponse) {
                _this.roleResponse = roleResponse;
                for (var i = 0; i < _this.roleTypes.length; i++) {
                    if (_this.roleTypes[i].id === _this.roleType) {
                        _this.roleName = _this.roleTypes[i].defaultRoleName;
                    }
                }
                for (var j = 0; j < _this.entities.length; j++) {
                    if (_this.entities[j].id === _this.e1Instance) {
                        _this.e1Name = _this.entities[j].primaryName + ' ' + _this.entities[j].secondaryName;
                    }
                    if (_this.entities[j].id === _this.e2Instance) {
                        _this.e2Name = _this.entities[j].primaryName + ' ' + _this.entities[j].secondaryName;
                    }
                }
                if (_this.currentStack !== undefined) {
                    if (_this.currentStack.roles === undefined) {
                        _this.currentStack.roles = [];
                    }
                    _this.currentStack.roles.push({ 'entity1': _this.e1Name, 'entity2': _this.e2Name,
                        'type': _this.roleName, 'delegated': false, 'id': _this.roleResponse.data.id });
                    _this.dataService.setCurrentStack(_this.currentStack);
                }
                _this.dataService.triggerRefreshEvent(2);
                _this.role = new Role();
                _this.dataService.triggerCloseModalEvent(2);
                _this.alertService.showSuccess('Role created successfully.');
            }, function (error) {
                _this.alertService.showError(error.error.fieldErrors[0].message);
            });
        });
    };
    return AddRoleSimpleComponent;
}());
export { AddRoleSimpleComponent };
